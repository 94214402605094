function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { GiveawayPaymentSortingOption } from 'swag-common/constants/giveaway.constants';
import { createSelector } from 'reselect';
import { getTotalAmountForLink } from 'swag-common/business-logic/giveaway/get-total-amount-for-giveaway-link';
import { isAfter } from 'swag-common/utils/date-lib';
import { Price } from 'swag-common/utils/price';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
export const giveawaySelector = state => state.giveaway;
export const selectedGiveawaySelector = createSelector(giveawaySelector, giveaway => giveaway === null || giveaway === void 0 ? void 0 : giveaway.selectedGiveaway);
export const isSelectedGiveawaySingleLink = createSelector(selectedGiveawaySelector, giveaway => Boolean(giveaway === null || giveaway === void 0 ? void 0 : giveaway.isSingleLink));
export const completedLinksPopupTitleSelector = createSelector(isSelectedGiveawaySingleLink, isSingleLink => isSingleLink ? 'Submissions' : 'Links');
export const sortGiveawayRecipients = (list, sorting) => {
  const newList = list.sort((a, b) => {
    switch (sorting) {
      case GiveawayPaymentSortingOption.new:
        if (isAfter(a.createdAt, b.createdAt)) {
          return -1;
        } else if (isAfter(b.createdAt, a.createdAt)) {
          return 1;
        }
        return 0;
      case GiveawayPaymentSortingOption.old:
        if (isAfter(a.createdAt, b.createdAt)) {
          return 1;
        } else if (isAfter(b.createdAt, a.createdAt)) {
          return -1;
        }
        return 0;
      case GiveawayPaymentSortingOption.high:
        {
          const totalAmountA = getTotalAmountForLink(a);
          const totalAmountB = getTotalAmountForLink(b);
          if (totalAmountA > totalAmountB) {
            return -1;
          } else if (totalAmountB > totalAmountA) {
            return 1;
          }
          return 0;
        }
      case GiveawayPaymentSortingOption.low:
        {
          const totalAmountA = getTotalAmountForLink(a);
          const totalAmountB = getTotalAmountForLink(b);
          if (totalAmountA > totalAmountB) {
            return 1;
          } else if (totalAmountB > totalAmountA) {
            return -1;
          }
          return 0;
        }
      default:
        return 0;
    }
  });
  return [...newList];
};
export const getDiscountForGiveawayRecipient = (totalPrice, recipientsAmount, appliedPromocode) => {
  if (!(appliedPromocode !== null && appliedPromocode !== void 0 && appliedPromocode.discount) || !(appliedPromocode !== null && appliedPromocode !== void 0 && appliedPromocode.measurement)) {
    return null;
  }
  const discountPerItem = Price.getAbsoluteDiscountAmount(totalPrice, appliedPromocode.discount, appliedPromocode.measurement);
  return appliedPromocode.measurement === PromocodeMeasurementEnum.ABSOLUTE ? Number((discountPerItem / recipientsAmount).toFixed(2)) : discountPerItem;
};
export const formatRecipientsForHistory = list => list.map(item => {
  const {
    shipment,
    address,
    unit,
    city,
    state,
    zip,
    firstName,
    lastName,
    pickAndPackPrice = 0,
    appliedPromocode
  } = item;
  const {
    taxes,
    shipmentPrice = 0
  } = shipment || {};
  const fullName = `${firstName} ${lastName}`;
  const fullAddress = `${address} ${unit}, ${city}, ${state} ${zip}`;
  const taxVat = taxes ? taxes.vat : 0;
  const taxDutyFees = taxes ? taxes.dutyFees : 0;
  const taxFees = taxes ? taxes.fees : 0;
  const totalAmount = pickAndPackPrice + shipmentPrice + taxVat + taxDutyFees + taxFees;
  const priceForDiscount = item.budget ? totalAmount + item.budget : totalAmount;
  const discount = getDiscountForGiveawayRecipient(priceForDiscount, list.length, appliedPromocode);
  return _objectSpread({}, item, {
    fullName,
    fullAddress,
    totalAmount,
    taxVat,
    taxDutyFees,
    taxFees,
    discount
  });
});
export const giveawayIdSelector = createSelector(selectedGiveawaySelector, giveaway => {
  if (giveaway && giveaway._id) {
    return String(giveaway._id);
  }
  return '';
});
export const sortGiveawayCharges = (charges, sorting) => {
  const newList = charges.sort((a, b) => {
    switch (sorting) {
      case GiveawayPaymentSortingOption.new:
        if (a.created * 1000 > b.created * 1000) {
          return -1;
        } else if (b.created * 1000 > a.created * 1000) {
          return 1;
        }
        return 0;
      case GiveawayPaymentSortingOption.old:
        if (a.created > b.created) {
          return 1;
        } else if (b.created > a.created) {
          return -1;
        }
        return 0;
      case GiveawayPaymentSortingOption.high:
        if (a.amount > b.amount) {
          return -1;
        } else if (b.amount > a.amount) {
          return 1;
        }
        return 0;
      case GiveawayPaymentSortingOption.low:
        if (a.amount > b.amount) {
          return 1;
        } else if (b.amount > a.amount) {
          return -1;
        }
        return 0;
      default:
        return 0;
    }
  });
  return [...newList];
};
export const filterByOtherResults = (recipients, results) => {
  if (!results.length) {
    return [];
  }
  const filtered = recipients.filter(({
    _id
  }) => results.includes(String(_id)));
  return filtered;
};