import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/custom-store-api/v1/goodie-giveaway';
export const goodieGiveawayApi = createApi({
  getAllCategories: {
    url: `${baseUrl}/category`,
    method: 'get'
  },
  getCategoryById: {
    url: `${baseUrl}/category/:id`,
    method: 'get'
  },
  updateCategory: {
    url: `${baseUrl}/category`,
    method: 'put'
  },
  createCategory: {
    url: `${baseUrl}/category`,
    method: 'post'
  },
  removeCategory: {
    url: `${baseUrl}/category`,
    method: 'delete'
  },
  getProductsCatalog: {
    url: `${baseUrl}/product`,
    method: 'get'
  },
  getProductsCount: {
    url: `${baseUrl}/product/count`,
    method: 'get'
  },
  createProduct: {
    url: `${baseUrl}/product`,
    method: 'post'
  },
  updateProduct: {
    url: `${baseUrl}/product/:productId`,
    method: 'put'
  },
  removeProduct: {
    url: `${baseUrl}/product/:productId`,
    method: 'delete'
  },
  getProductById: {
    url: `${baseUrl}/product/:productId`
  },
  getProductsAveragePrice: {
    url: `${baseUrl}/product/average-price`,
    method: 'get'
  },
  getSettings: {
    url: `${baseUrl}/settings`,
    method: 'get'
  }
});
const publicUrl = '/custom-store-api/v1/goodie-giveaway/public';
export const publicGoodieGiveawayApi = createApi({
  getAllCategories: {
    url: `${publicUrl}/category`,
    method: 'get'
  },
  getProductsByCategory: {
    url: `${publicUrl}/product`,
    method: 'get'
  }
});
export const goodieGiveawayUpdateProductHelper = productId => createApi({
  updateProduct: {
    url: `${baseUrl}/product/:productId`.replace(':productId', productId),
    method: 'put'
  }
});