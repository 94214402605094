import _formValueSelector from "redux-form/es/formValueSelector";
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { FormStatePicker as FormStatePickerComponent } from './form-state-picker';
const formNameSelector = (_, props) => {
  return props.meta.form;
};
const valueSelectorFactory = fieldName => createSelector(state => state, formNameSelector, (state, formName) => {
  return _formValueSelector(formName)(state, fieldName);
});
const mapStateToProps = createStructuredSelector({
  country: valueSelectorFactory('country'),
  state: valueSelectorFactory('state')
});
export const FormStatePicker = connect(mapStateToProps)(FormStatePickerComponent);