function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { checkIsShippingRateFlat } from './check-is-shipping-rate-flat.service';
export function calculatePickAndPackAmount(receivers, serviceCode) {
  if (!(receivers !== null && receivers !== void 0 && receivers.length)) {
    return 0;
  }
  const pickAndPack = receivers.map(applyNewServiceCode(serviceCode)).reduce((total, receiver) => {
    var _receiver$pickAndPack;
    return total + ((_receiver$pickAndPack = receiver.pickAndPackPrice) !== null && _receiver$pickAndPack !== void 0 ? _receiver$pickAndPack : 0);
  }, 0) || 0;
  return pickAndPack;
}
function applyNewServiceCode(serviceCode) {
  return receiver => {
    if (!receiver) {
      return receiver;
    }
    const {
      shipment,
      productsToShip
    } = receiver;
    const productsValues = Object.values(productsToShip);
    const canUseFlatRate = productsValues.length === 1 && productsValues[0] === 1;
    const isFlatRate = checkIsShippingRateFlat({
      serviceCode
    }) && canUseFlatRate;
    if (!shipment || isFlatRate) {
      return receiver;
    }
    const delivery = shipment.possibleDeliveries ? shipment.possibleDeliveries[serviceCode] : null;
    if (!delivery) {
      return receiver;
    }
    return _objectSpread({}, receiver, {
      shipment: _objectSpread({}, shipment, {
        selectedDeliveryServiceCode: serviceCode
      })
    });
  };
}