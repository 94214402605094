function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { errorAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { isBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { ProductsApi } from '../api/product.api';
import { selectedInventorySelector } from '../redux/inventory/inventory.selectors';
import { selectedProductsIdsSelector } from '../redux/products/products.selectors';
export const getCustomProductsWarehouseQuantities = ({
  requestActionType,
  successActionType,
  failureActionType,
  successHandler
}) => createAsyncLoadedAction({
  transformData: (data = {}, getState) => {
    const state = getState();
    const parentIds = selectedProductsIdsSelector(state);
    const inventoryId = selectedInventorySelector(state);
    return _objectSpread({}, data, {
      parentIds: JSON.stringify(parentIds),
      inventoryId
    });
  },
  preFn: dispatch => {
    dispatch({
      type: requestActionType
    });
  },
  fn: ProductsApi.getWarehouseQuantities,
  success: quantities => ({
    type: successActionType,
    payload: {
      quantities
    }
  }),
  postSuccess: dispatch => {
    if (successHandler) {
      dispatch(successHandler());
    }
  },
  error: errorAction(failureActionType),
  postError: (dispatch, getState, err) => {
    const errorMessage = err.message || 'Sorry, something went wrong';
    dispatch(showNotification({
      text: errorMessage
    }));
  }
});
export const getProductionInHand = ({
  inProductionTransactions,
  inStock,
  inReserve,
  inReserveProduction
}, requestedQuantity) => {
  let quantityAccumulator = 0;
  const inReserveStock = inReserve - inReserveProduction;
  const stockAvailable = inStock - inReserveStock;
  const inProductionNeeded = requestedQuantity - stockAvailable;
  if (inProductionNeeded <= 0) {
    return null;
  }
  for (const _ref of inProductionTransactions) {
    const {
      inHandDate,
      quantity
    } = _ref;
    if (!inHandDate) {
      continue;
    }
    if (quantityAccumulator + quantity < inProductionNeeded) {
      quantityAccumulator += quantity;
      continue;
    }
    return inHandDate;
  }
  return null;
};
export const mergeQuantities = (quantities, quantityByProductIdMap) => {
  return Object.entries(quantityByProductIdMap).reduce((mergedQuantities, [_, {
    quantitiesByColor
  }]) => {
    Object.entries(quantitiesByColor).forEach(([colorId, quantity]) => {
      var _mergedQuantities$col;
      mergedQuantities[colorId] = ((_mergedQuantities$col = mergedQuantities[colorId]) !== null && _mergedQuantities$col !== void 0 ? _mergedQuantities$col : 0) + quantity;
    });
    return mergedQuantities;
  }, _objectSpread({}, quantities));
};
export const getIsAdvanceDistribution = (products, warehouseQuantities, quantities, quantityByProductIdMap) => {
  return products.some(({
    variants
  }) => {
    var _variants$0$bundleCon;
    const variantsToUse = (_variants$0$bundleCon = variants[0].bundleContent) !== null && _variants$0$bundleCon !== void 0 && _variants$0$bundleCon.length ? variants[0].bundleContent.map(({
      product
    }) => product) : variants;
    const mergedQuantities = quantityByProductIdMap ? mergeQuantities(quantities, quantityByProductIdMap) : quantities;
    return variantsToUse.some(variant => {
      const variantId = String(variant._id);
      const warehouseLevel = warehouseQuantities[variantId];
      const requestedQuantity = mergedQuantities[variantId] || 0;
      if (!warehouseLevel || !requestedQuantity) {
        return false;
      }
      return getIsProductInAdvanceDistribution(requestedQuantity, warehouseLevel);
    });
  });
};
export const getIsProductInAdvanceDistribution = (requestedQuantity, {
  inStock,
  inReserve,
  inProduction,
  inReserveProduction
}) => {
  const reservedInStock = inReserve - inReserveProduction;
  const availableInStock = inStock - reservedInStock;
  const availableInProduction = inProduction - inReserveProduction;
  return requestedQuantity > 0 && requestedQuantity > availableInStock && availableInStock + availableInProduction >= requestedQuantity;
};
export const sortInProdTransactionInWarehouseQuantities = warehouseQuantities => {
  return Object.entries(warehouseQuantities).reduce((acc, [key, val]) => {
    const filteredTransactions = val.inProductionTransactions.reduce((acc, transaction) => {
      if (transaction.inHandDate) {
        acc.push(_objectSpread({}, transaction, {
          inHandDate: transaction.inHandDate
        }));
      }
      return acc;
    }, []);
    filteredTransactions.sort((first, second) => {
      const firstDate = Number(new Date(first.inHandDate));
      const secondDate = Number(new Date(second.inHandDate));
      return firstDate - secondDate;
    });
    acc[key] = _objectSpread({}, val, {
      inProductionTransactions: filteredTransactions
    });
    return acc;
  }, {});
};
const getAdvanceDistributionSelectedVariantsStockInHandMap = (products, warehouseQuantities, quantities) => {
  const variantsMap = {};
  products.forEach(({
    variants
  }) => {
    let variantIds = variants.map(({
      _id
    }) => String(_id));
    if (isBundle(variants[0])) {
      var _variants$0$bundleCon2;
      variantIds = ((_variants$0$bundleCon2 = variants[0].bundleContent) !== null && _variants$0$bundleCon2 !== void 0 ? _variants$0$bundleCon2 : []).map(({
        product
      }) => String(product === null || product === void 0 ? void 0 : product._id));
    }
    return variantIds.forEach(variantId => {
      const warehouseLevel = warehouseQuantities[variantId];
      const requestedQuantity = quantities[variantId] || 0;
      if (!warehouseLevel || !requestedQuantity) {
        return;
      }
      variantsMap[variantId] = requestedQuantity === 0 ? null : getProductionInHand(warehouseLevel, requestedQuantity);
    });
  });
  return variantsMap;
};
const getVariantsLatestInHandDate = variantsMap => {
  const variantsDates = Object.values(variantsMap);
  if (!variantsDates.length) {
    return null;
  }
  return variantsDates.reduce((acc, inHandDate) => {
    if (!inHandDate || !acc) {
      return inHandDate || acc;
    }
    const inHandParsed = new Date(inHandDate);
    const accParsed = new Date(acc);
    return inHandParsed > accParsed ? inHandParsed : accParsed;
  }, null);
};
export const calculateAdvanceDistributionStockInHand = (products, warehouseQuantities, selectedQuantities, quantityByProductIdMap) => {
  const sortedWarehouseQuantities = sortInProdTransactionInWarehouseQuantities(warehouseQuantities);
  const mergedQuantities = quantityByProductIdMap ? mergeQuantities(selectedQuantities, quantityByProductIdMap) : selectedQuantities;
  const variantsStockInHandMap = getAdvanceDistributionSelectedVariantsStockInHandMap(products, sortedWarehouseQuantities, mergedQuantities);
  return getVariantsLatestInHandDate(variantsStockInHandMap);
};