import _debounce from "lodash/debounce";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { ProductApi } from 'swag-client-common/api/product.api';
import { OrderApi } from 'swag-client-common/api/order.api';
import { userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { INVENTORY_PURCHASE_FLOWS } from 'swag-common/constants/inventory';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { DEMO_INVENTORY_WARNING_POPUP, GIVEAWAY_NOTECARD_PAY_POPUP, GIVEAWAY_NOTECARD_PAYMENT_SUCCESS_POPUP } from 'swag-client-common/constants';
import { hideLoader, showLoader } from 'swag-client-common/redux/common/common.actions';
import { GiveawayStep } from 'swag-common/constants/giveaway.constants';
import { MOCKUP_GIVEAWAY_NOTECARD_HIDDEN_CONTAINER_ID } from 'swag-client-common/constants/html-to-image';
import { generateImageFromHtml } from 'swag-client-common/utils/generate-image-from-html';
import { OrderApi as CSOrderApi } from '../../../../custom-store-admin/api/order.api';
import { isCreditsMoreToBuyFlowSelector, isSelectedDemoInventorySelector, paymentMethodSelector, selectedInventorySelector } from '../../inventory/inventory.selectors';
import { inventoryPaymentBillingFormSelector, isManualPayment, paymentMethodIdSelector } from '../../payment-methods/payment-methods.selectors';
import { preparePaymentToInventoryProceed, preselectCreditCard } from '../../payment-methods/payment-methods.actions';
import { listInventoryProductsWithoutLoader } from '../../products/products.actions';
import { STRIPE_FORM_SUBMIT_ERROR, STRIPE_FORM_SUBMIT_SUCCESS } from '../../stripe-form/stripe-form.actions';
import { giveawayEditingPrimaryColorSelector, giveawayEditingLogoSelector, giveawayPassedStepsSelector, giveawayEditingTypeSelector } from '../giveaway.selectors';
import { giveawayIdSelector, selectedGiveawaySelector } from '../selectors/common.selectors';
import { giveawayNotecardColorSelector, giveawayNotecardDefaultImageSelector, giveawayNotecardTextContentSelector, isGiveawayNotecardPaidSelector, selectedGiveawayNotecardAmountSelector, selectedGiveawayNotecardCostSelector } from '../selectors/giveaway-notecard.selectors';
import { getInventoryCredits, resetCreditsMoreToBuyFlow, setInventoryPurchaseFlow } from '../../inventory/inventory.actions';
import { uploadLogoCreator } from '../../common/common.actions';
import { createOrUpdateGiveaway } from './giveaway.actions';
import { GIVEAWAY_ADMIN_PREFIX } from './giveaway-common.actions';
export const SET_NOTECARDS_AMOUNT = `${GIVEAWAY_ADMIN_PREFIX}SET_NOTECARDS_AMOUNT`;
export const SET_NOTECARD_COST = `${GIVEAWAY_ADMIN_PREFIX}SET_NOTECARD_COST`;
export const RESET_NOTECARD_COST = `${GIVEAWAY_ADMIN_PREFIX}RESET_NOTECARD_COST`;
export const SET_NOTECARD_PROPS = `${GIVEAWAY_ADMIN_PREFIX}SET_NOTECARD_PROPS`;
export const SET_IS_AMOUNT_INPUT_VALID = `${GIVEAWAY_ADMIN_PREFIX}SET_IS_AMOUNT_INPUT_VALID`;
export const setGiveawayNotecardsAmountAction = quantity => ({
  type: SET_NOTECARDS_AMOUNT,
  payload: quantity
});
export const setGiveawayNotecardCostAction = data => ({
  type: SET_NOTECARD_COST,
  payload: data
});
export const resetGiveawayNotecardCostAction = () => ({
  type: RESET_NOTECARD_COST
});
export const setGiveawayNotecardPropsAction = data => ({
  type: SET_NOTECARD_PROPS,
  payload: data
});
export const setIsNotecardAmountInputValid = isInputValid => ({
  payload: {
    isInputValid
  },
  type: SET_IS_AMOUNT_INPUT_VALID
});
export const countNotecardCost = createAsyncLoadedAction({
  preFn: (_, getState) => {
    const giveaway = selectedGiveawaySelector(getState());
    if (giveaway !== null && giveaway !== void 0 && giveaway.notecardId) {
      return false;
    }
  },
  transformData: (quantity, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const giveawayType = giveawayEditingTypeSelector(state);
    return {
      inventoryId,
      giveawayType,
      quantity
    };
  },
  fn: CSOrderApi.calculateGiveawayNotecardCost,
  success: (res, incomeData) => dispatch => {
    dispatch(setGiveawayNotecardCostAction(res));
    dispatch(setGiveawayNotecardsAmountAction(incomeData.quantity));
    dispatch(setIsNotecardAmountInputValid(true));
  },
  error: () => setIsNotecardAmountInputValid(false),
  postError: dispatch => dispatch(resetGiveawayNotecardCostAction())
});
export const getGiveawayNotecardProduct = createAsyncAction({
  fn: ProductApi.getGiveawayNotecard,
  success: data => setGiveawayNotecardPropsAction(data),
  error: () => showNotification({
    text: 'Sorry, there are problems while getting notecard properties'
  })
});
export const SET_NOTECARD_EDITOR_RAW_CONTENT = `${GIVEAWAY_ADMIN_PREFIX}/SET_NOTECARD_EDITOR_RAW_CONTENT`;
export const setNotecardEditorRawContentAction = editorRawContent => ({
  payload: {
    editorRawContent
  },
  type: SET_NOTECARD_EDITOR_RAW_CONTENT
});
export const setNotecardEditorRawContent = editorRawContent => dispatch => {
  dispatch(setNotecardEditorRawContentAction(editorRawContent));
  _debounce(() => dispatch(createOrUpdateGiveaway()), 300);
};
export const SET_NOTECARD_COLOR = `${GIVEAWAY_ADMIN_PREFIX}SET_NOTECARD_COLOR`;
export const setNotecardColorActionCreator = color => ({
  type: SET_NOTECARD_COLOR,
  payload: {
    color
  }
});
export const setNotecardColor = color => dispatch => {
  dispatch(setNotecardColorActionCreator(color));
  dispatch(createOrUpdateGiveaway());
};
export const SET_NOTECARD_LOGO = `${GIVEAWAY_ADMIN_PREFIX}SET_NOTECARD_LOGO`;
export const setNotecardLogoActionCreator = logo => ({
  type: SET_NOTECARD_LOGO,
  payload: {
    logo
  }
});
export const setNotecardLogo = logo => dispatch => {
  dispatch(setNotecardLogoActionCreator(logo));
  dispatch(createOrUpdateGiveaway());
};
const uploadLogoForNotecard = uploadLogoCreator(formData => setNotecardLogoActionCreator(formData.customerDesignLink));
export const setNotecardLogoFromFile = file => dispatch => {
  dispatch(uploadLogoForNotecard(file));
  dispatch(createOrUpdateGiveaway);
};
export const deleteNotecardLogo = () => dispatch => void dispatch(setNotecardLogo(''));
export const CREATE_EMPTY_NOTECARD = `${GIVEAWAY_ADMIN_PREFIX}CREATE_EMPTY_NOTECARD`;
export const createEmptyNotecardActionCreator = () => ({
  type: CREATE_EMPTY_NOTECARD
});
export const REMOVE_NOTECARD = `${GIVEAWAY_ADMIN_PREFIX}REMOVE_NOTECARD`;
export const removeNotecardActionCreator = () => ({
  type: REMOVE_NOTECARD
});
export const setNotecardLogoAndColorFromGiveaway = () => (dispatch, getState) => {
  const state = getState();
  const giveawayColor = giveawayEditingPrimaryColorSelector(state);
  const giveawayLogo = giveawayEditingLogoSelector(state);
  dispatch(setNotecardLogoActionCreator(giveawayLogo));
  dispatch(setNotecardColorActionCreator(giveawayColor));
};
export const restoreNotecardEditorState = giveaway => dispatch => {
  if (!giveaway.notecard) {
    dispatch(removeNotecardActionCreator());
    return;
  }
  const {
    logo = '',
    color = '',
    editorRawContent = {}
  } = giveaway.notecard;
  dispatch(setNotecardLogoActionCreator(logo));
  dispatch(setNotecardColorActionCreator(color));
  dispatch(setNotecardEditorRawContentAction(editorRawContent));
};
export const setNotecardLogoAndColorFromGiveawayBeforeNotecardStep = () => (dispatch, getState) => {
  const state = getState();
  const passedSteps = giveawayPassedStepsSelector(state);
  if (passedSteps.includes(GiveawayStep.notecard)) {
    return;
  }
  dispatch(createEmptyNotecardActionCreator());
  dispatch(setNotecardLogoAndColorFromGiveaway());
};
export const setHasGiveawayNotecard = status => dispatch => {
  if (status) {
    dispatch(createEmptyNotecardActionCreator());
    dispatch(setNotecardLogoAndColorFromGiveaway());
  } else {
    dispatch(removeNotecardActionCreator());
  }
  dispatch(createOrUpdateGiveaway());
};
export const SET_IS_NOTECARD_EDITOR_INPUT_VALID = `${GIVEAWAY_ADMIN_PREFIX}SET_IS_NOTECARD_EDITOR_INPUT_VALID`;
export const setIsNotecardEditorInputValid = isInputValid => ({
  payload: {
    isInputValid
  },
  type: SET_IS_NOTECARD_EDITOR_INPUT_VALID
});
export const PROCEED_NOTECARD_PAYMENT_SUCCESS = `${GIVEAWAY_ADMIN_PREFIX}PROCEED_NOTECARD_PAYMENT_SUCCESS`;
export const proceedGiveawayNotecard = createAsyncAction({
  transformData: ({
    stripeToken,
    savedCard,
    isReorder
  } = {
    stripeToken: null,
    isReorder: false
  }, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const userId = userIdSelector(state);
    const giveawayId = giveawayIdSelector(state);
    const paymentMethod = paymentMethodSelector(state);
    const quantity = selectedGiveawayNotecardAmountSelector(state);
    const cost = selectedGiveawayNotecardCostSelector(state);
    const defaultImage = giveawayNotecardDefaultImageSelector(state);
    const color = giveawayNotecardColorSelector(state);
    const textContent = giveawayNotecardTextContentSelector(state);
    const commonBody = {
      userId,
      inventoryId,
      giveawayId,
      paymentMethod,
      expectedTotal: cost.total,
      notecardData: {
        quantity,
        defaultImage,
        color,
        textContent
      },
      isReorder
    };
    if (savedCard) {
      const cardId = paymentMethodIdSelector(state);
      return _objectSpread({}, commonBody, {
        cardId
      });
    }
    const form = inventoryPaymentBillingFormSelector(state);
    if (form) {
      const {
        savePaymentDetails = false,
        firstName,
        lastName
      } = form.values;
      return _objectSpread({}, commonBody, {
        stripeToken,
        paymentData: {
          firstName,
          lastName,
          savePaymentDetails
        }
      });
    }
    return _objectSpread({}, commonBody, {
      stripeToken
    });
  },
  fn: OrderApi.proceedGiveawayNotecard,
  success: (_, data) => (dispatch, getState) => {
    const {
      isReorder
    } = data || {};
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    dispatch(getInventoryCredits({
      inventoryId
    }));
    const isCreditsToBuyMoreFlow = isCreditsMoreToBuyFlowSelector(getState());
    if (isCreditsToBuyMoreFlow) {
      dispatch(resetCreditsMoreToBuyFlow());
    }
    if (isReorder) {
      dispatch(showPopup({
        popup: GIVEAWAY_NOTECARD_PAYMENT_SUCCESS_POPUP
      }));
      dispatch(listInventoryProductsWithoutLoader({
        inventoryId
      }));
    } else {
      dispatch(createOrUpdateGiveaway());
    }
    dispatch({
      type: PROCEED_NOTECARD_PAYMENT_SUCCESS
    });
    dispatch({
      type: STRIPE_FORM_SUBMIT_SUCCESS
    });
  },
  error: error => {
    var _error$message;
    return showNotification({
      text: (error === null || error === void 0 ? void 0 : (_error$message = error.message) === null || _error$message === void 0 ? void 0 : _error$message.replace('Error: ', '')) || USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    });
  },
  postError: (dispatch, getState, error) => {
    dispatch(hideLoader());
    dispatch({
      type: STRIPE_FORM_SUBMIT_ERROR,
      error: error === null || error === void 0 ? void 0 : error.message
    });
  },
  postSuccess: dispatch => {
    dispatch(hideLoader());
  }
});
export const handleInventoryPaymentForGiveawayNotecardSubmit = (stripe, card, isReorder = false, mockupContainer = null) => async (dispatch, getState) => {
  const state = getState();
  const isManualCard = isManualPayment(state);
  const isNotecardPaid = isGiveawayNotecardPaidSelector(state);
  const isDemo = isSelectedDemoInventorySelector(state);
  if (isDemo) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.GiveawayPayment
    }));
  }
  if (!isReorder && isNotecardPaid) {
    return;
  }
  dispatch(showLoader());
  if (!isReorder && mockupContainer) {
    try {
      const imgUrl = await generateImageFromHtml({
        mockupContainer,
        hiddenContainerId: MOCKUP_GIVEAWAY_NOTECARD_HIDDEN_CONTAINER_ID
      });
      if (imgUrl) {
        dispatch(setGiveawayNotecardDefaultImage(imgUrl));
      }
    } catch {
      dispatch(hideLoader());
      dispatch(showNotification({
        text: 'Sorry, there are problems while getting notecard mockup'
      }));
      return;
    }
  }
  if (isManualCard) {
    return dispatch(preparePaymentToInventoryProceed(stripe, card, token => {
      dispatch(proceedGiveawayNotecard({
        stripeToken: token.id,
        isReorder
      }));
    }));
  }
  dispatch(proceedGiveawayNotecard({
    savedCard: true,
    isReorder
  }));
};
export const SET_GIVEAWAY_NOTECARD_DEFAULT_IMAGE = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_NOTECARD_DEFAULT_IMAGE`;
export const setGiveawayNotecardDefaultImage = defaultImage => ({
  type: SET_GIVEAWAY_NOTECARD_DEFAULT_IMAGE,
  payload: {
    defaultImage
  }
});
export const SET_ARE_WIDGETS_SHOWN_AFTER_PAYMENT = `${GIVEAWAY_ADMIN_PREFIX}SET_ARE_WIDGETS_SHOWN_AFTER_PAYMENT`;
export const setAreWidgetsShownAfterPayment = value => ({
  type: SET_ARE_WIDGETS_SHOWN_AFTER_PAYMENT,
  payload: {
    value
  }
});
export const orderMoreGiveawayNotecard = () => (dispatch, getState) => {
  const isDemo = isSelectedDemoInventorySelector(getState());
  if (isDemo) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.GiveawayPayment
    }));
  }
  dispatch(preselectCreditCard());
  dispatch(setInventoryPurchaseFlow(INVENTORY_PURCHASE_FLOWS.GIVEAWAY_NOTECARD));
  dispatch(showPopup({
    popup: GIVEAWAY_NOTECARD_PAY_POPUP
  }));
};