function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { checkIsApparelInBundle } from 'swag-client-common/utils/product.utils';
import { getUniqueColorsOfProductWithQuantities, productByProductIdSelector, productsSelector } from '../../products/products.selectors';
import { swagBundlesSelector } from '../swag-bundles.selectors';
export const swagBundlesSelectedProductsVariantsSelector = createSelector(swagBundlesSelector, ({
  swagBundleSelectedProductsVariants
}) => swagBundleSelectedProductsVariants);
export const swagBundlesCurrentlyMaxPossibleQuantitiesOfBundlesSelector = createSelector(swagBundlesSelectedProductsVariantsSelector, ({
  currentlyMaxPossibleQuantitiesOfBundles: currentlyMaxPossibleQuantityOfBundles
}) => currentlyMaxPossibleQuantityOfBundles);
export const swagBundlesCurrentlyAvailableToSendBundlesSelector = createSelector(swagBundlesCurrentlyMaxPossibleQuantitiesOfBundlesSelector, ({
  availableToSend
}) => availableToSend);
export const swagBundlesLimitingProductSelector = createSelector(swagBundlesSelectedProductsVariantsSelector, ({
  bundleLimitingProduct
}) => bundleLimitingProduct);
export const swagBundlesLimitingLoadedSelector = createSelector(swagBundlesSelectedProductsVariantsSelector, ({
  loaded
}) => loaded);
export const findBundleLimitingVariantSelector = createSelector(swagBundlesLimitingProductSelector, state => state, (limitingProduct, state) => {
  if (!limitingProduct) {
    return undefined;
  }
  const product = productByProductIdSelector(state, {
    productId: limitingProduct.productId
  });
  const productVariant = product === null || product === void 0 ? void 0 : product.variants.find(variant => variant.colorValue === limitingProduct.colorValue);
  if (!productVariant) {
    return undefined;
  }
  const variantWithQuantity = swagBundlesSelectedVariantByProductIdAndColorValueSelector(state, limitingProduct);
  return _objectSpread({}, productVariant, {
    quantityPerBundle: (variantWithQuantity === null || variantWithQuantity === void 0 ? void 0 : variantWithQuantity.quantity) || 0
  });
});
export const swagBundlesSelectedProductsVariantsWithQuantitySelector = createSelector(swagBundlesSelectedProductsVariantsSelector, ({
  selectedProductsVariantsWithQuantity
}) => selectedProductsVariantsWithQuantity);
export const selectedVariantsWithQuantityByProductIdSelector = productId => createSelector(swagBundlesSelectedProductsVariantsWithQuantitySelector, selectedProducts => selectedProducts[productId] || []);
export const isSwagBundleProductVariantActiveSelector = (productId, colorValue) => createSelector(swagBundlesSelectedProductsVariantsWithQuantitySelector, selectedProducts => {
  const selectedVariants = selectedProducts[productId] || [];
  return selectedVariants.some(variantWithQuantity => variantWithQuantity.colorValue === colorValue);
});
export const swagBundlesProductsColorsWithCalculatedQuantitySelector = createSelector(productByProductIdSelector, product => {
  if (!product) {
    return [];
  }
  return getUniqueColorsOfProductWithQuantities(product, true);
});
export const swagBundlesSelectedVariantByProductIdAndColorValueSelector = createSelector(swagBundlesSelectedProductsVariantsWithQuantitySelector, (_, {
  productId,
  colorValue
}) => ({
  productId,
  colorValue
}), (selectedProducts, {
  productId,
  colorValue
}) => {
  const selectedVariants = selectedProducts[productId] || [];
  return selectedVariants.find(variantWithQuantity => variantWithQuantity.colorValue === (colorValue || ''));
});
export const swagBundlesSelectedProductVariantsWithQuantityByProductIdSelector = createSelector(productByProductIdSelector, swagBundlesSelectedProductsVariantsWithQuantitySelector, swagBundlesProductsColorsWithCalculatedQuantitySelector, (product, selectedProducts, productVariantsGropedByColorValue) => {
  if (!product) {
    return [];
  }
  const productVariants = selectedProducts[product._id] || [];
  const variantsWithQuantityPerBundle = productVariantsGropedByColorValue.filter(variant => productVariants.some(selectedVariant => selectedVariant.colorValue === variant.colorValue)).map(variant => {
    var _productVariants$find;
    return _objectSpread({}, variant, {
      inReserveProductionQuantity: variant.inReserveProduction,
      inReserveStockQuantity: variant.inReserveStock,
      quantityPerBundle: ((_productVariants$find = productVariants.find(selectedVariant => selectedVariant.colorValue === variant.colorValue)) === null || _productVariants$find === void 0 ? void 0 : _productVariants$find.quantity) || 0
    });
  });
  return variantsWithQuantityPerBundle;
});
export const swagBundlesSelectedCustomProductsWithQuantityPerBundleSelector = createSelector(swagBundlesSelectedProductsVariantsWithQuantitySelector, productsSelector, (selectedProductsVariantsWithQuantity, allProducts) => {
  const customProducts = [];
  for (const productId in selectedProductsVariantsWithQuantity) {
    const product = allProducts.find(product => product._id === productId);
    if (product) {
      const colorsWithQuantity = selectedProductsVariantsWithQuantity[productId];
      for (const _ref of colorsWithQuantity) {
        const {
          colorValue,
          quantity
        } = _ref;
        const variantsWithColor = product.variants.filter(variant => variant.colorValue === colorValue);
        if (variantsWithColor.length > 0) {
          customProducts.push(...variantsWithColor.map(variant => _objectSpread({}, variant, {
            quantityPerBundle: quantity
          })));
        }
      }
    }
  }
  return customProducts;
});
export const swagBundlesSelectedCustomProductIdsWithQuantitySelector = createSelector(swagBundlesSelectedCustomProductsWithQuantityPerBundleSelector, selectedProductsVariantsWithQuantity => selectedProductsVariantsWithQuantity.map(variant => ({
  customProductId: String(variant._id),
  quantity: variant.quantityPerBundle
})));
export const swagBundlesSelectedProductsOnlyApparelsSelector = createSelector(swagBundlesSelectedCustomProductsWithQuantityPerBundleSelector, selectedProductsVariantsWithQuantity => selectedProductsVariantsWithQuantity.filter(variant => checkIsApparelInBundle(variant)));
export const swagBundlesSelectedProductsOnlyNonApparelsSelector = createSelector(swagBundlesSelectedCustomProductsWithQuantityPerBundleSelector, selectedProductsVariantsWithQuantity => selectedProductsVariantsWithQuantity.filter(variant => !checkIsApparelInBundle(variant)));
export const swagBundlesSelectedProductsCommonSizesSelector = createSelector(swagBundlesSelectedProductsOnlyApparelsSelector, selectedApparels => {
  const variants = selectedApparels.map(variant => {
    var _variant$sizeSettings;
    return (_variant$sizeSettings = variant.sizeSettings) === null || _variant$sizeSettings === void 0 ? void 0 : _variant$sizeSettings.options;
  }).filter(sizesOptions => sizesOptions && sizesOptions.length > 1);
  return variants.reduce((acc, sizes) => {
    if (!sizes) {
      return acc;
    }
    acc = [...(acc || []), ...sizes.filter(size => variants.every(sizes => sizes && sizes.includes(size)))];
    return [...new Set(acc)];
  }, []);
});
export const getAllSelectedApparelsSpecificSizeSelector = sizeName => createSelector(swagBundlesSelectedProductsOnlyApparelsSelector, selectedApparels => {
  return selectedApparels.filter(variant => {
    const sizeSettings = variant === null || variant === void 0 ? void 0 : variant.sizeSettings;
    if (!sizeSettings) {
      return false;
    }
    const sizeIndex = sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options.findIndex(size => size === sizeName);
    return (variant === null || variant === void 0 ? void 0 : variant.sizeIndex) === sizeIndex;
  });
});
export const areApparelsInSelectedProductsSelector = createSelector(swagBundlesSelectedProductsOnlyApparelsSelector, selectedApparels => selectedApparels.length);
export const areAnySelectedProductsVariantsWithQuantitySelector = createSelector(swagBundlesSelectedProductsVariantsWithQuantitySelector, selectedQuantities => {
  if (!selectedQuantities) {
    return false;
  }
  return Object.values(selectedQuantities).some(selectedProduct => {
    var _selectedProduct$;
    return (selectedProduct === null || selectedProduct === void 0 ? void 0 : (_selectedProduct$ = selectedProduct[0]) === null || _selectedProduct$ === void 0 ? void 0 : _selectedProduct$.quantity) > 0;
  });
});