import { createSelector } from 'reselect';
import { GOODIE_GIVEAWAY_MAX_BUDGET, GOODIE_GIVEAWAY_MIN_BUDGET } from 'swag-client-common/constants/goodie-giveaway';
import { Price } from 'swag-client-common/utils/price';
import { pluralize } from 'swag-common/utils/strings';
import { giveawaySelector } from './common.selectors';
export const goodieGiveawayBudgetRootSelector = createSelector(giveawaySelector, state => state.goodieBudget);
export const goodieGiveawayMostPopularBudgetSelector = createSelector(goodieGiveawayBudgetRootSelector, state => state.mostPopularBudget);
const goodieGiveawayFlatRatesSelector = createSelector(goodieGiveawayBudgetRootSelector, state => state.flatRates);
export const goodieGiveawayDomesticFlatRatesSelector = createSelector(goodieGiveawayFlatRatesSelector, flatRates => (flatRates === null || flatRates === void 0 ? void 0 : flatRates.DomesticFlatRate) || null);
export const goodieGiveawayInternationalFlatRatesSelector = createSelector(goodieGiveawayFlatRatesSelector, flatRates => (flatRates === null || flatRates === void 0 ? void 0 : flatRates.InternationalFlatRate) || null);
export const goodieGiveawayAverageProductPriceSelector = createSelector(goodieGiveawayBudgetRootSelector, state => state.averageProductPrice);
export const goodieGiveawayBudgetSelector = createSelector(goodieGiveawayBudgetRootSelector, state => state.budget);
export const goodieGiveawayBudgetToShowSelector = createSelector(goodieGiveawayBudgetSelector, goodieGiveawayMostPopularBudgetSelector, (budget, mostPopularBudget) => {
  return budget || mostPopularBudget;
});
const APPROX_RANGE = 5;
export const getApproxProductsAmountSelector = createSelector(goodieGiveawayBudgetSelector, goodieGiveawayAverageProductPriceSelector, (budget, averageProductPrice) => {
  const approximateProducts = budget && averageProductPrice && Math.floor(budget / averageProductPrice);
  return {
    from: approximateProducts > APPROX_RANGE ? approximateProducts - APPROX_RANGE : 1,
    to: approximateProducts + APPROX_RANGE
  };
});
export const goodieGiveawayRecipientQuantitySelector = createSelector(goodieGiveawayBudgetRootSelector, goodieBudget => {
  return goodieBudget.recipientQuantity;
});
export const goodieGiveawayRecipientsIsNotZero = createSelector(goodieGiveawayRecipientQuantitySelector, recipientQuantity => {
  return recipientQuantity !== 0;
});
export const goodieGiveawayShippingCostSelector = createSelector(goodieGiveawayDomesticFlatRatesSelector, goodieGiveawayInternationalFlatRatesSelector, goodieGiveawayRecipientQuantitySelector, (domesticRates, internationalRates, recipients) => {
  if (!(domesticRates !== null && domesticRates !== void 0 && domesticRates.rate) || !(internationalRates !== null && internationalRates !== void 0 && internationalRates.rate)) {
    return 0;
  }
  if (recipients < 0) {
    return 0;
  }
  const domesticCost = recipients * domesticRates.rate;
  return domesticCost;
});
export const goodieGiveawayCostSelector = createSelector(goodieGiveawayRecipientQuantitySelector, goodieGiveawayBudgetSelector, (recipients, budget) => {
  return recipients * budget;
});
export const isRecipientInputsTouchedSelector = createSelector(goodieGiveawayBudgetRootSelector, goodieBudget => {
  return goodieBudget.areRecipientInputsTouched;
});
export const isRecipientInputsTouchedAndValidSelector = createSelector(isRecipientInputsTouchedSelector, areRecipientInputsTouched => {
  return areRecipientInputsTouched;
});
export const isBudgetValidSelector = createSelector(goodieGiveawayBudgetSelector, budget => {
  return budget >= GOODIE_GIVEAWAY_MIN_BUDGET && budget <= GOODIE_GIVEAWAY_MAX_BUDGET;
});

// To avoid circular dependency
const giveawayIsEditingSelector = createSelector(giveawaySelector, giveaway => giveaway.editing);
export const isBudgetStepValidSelector = createSelector(isBudgetValidSelector, isRecipientInputsTouchedAndValidSelector, giveawayIsEditingSelector, goodieGiveawayRecipientsIsNotZero, (isBudgetValid, isRecipientInputsTouchedAndValid, isEditing, isRecipientsNotZero) => {
  if (isEditing) {
    return isBudgetValid && isRecipientsNotZero;
  }
  return isBudgetValid && isRecipientInputsTouchedAndValid && isRecipientsNotZero;
});
export const goodieGiveawayEstimatedRowTitleSelector = createSelector(goodieGiveawayRecipientQuantitySelector, goodieGiveawayBudgetSelector, (recipientQuantity, budget) => {
  return `Based on ${pluralize(recipientQuantity, 'recipient')} (${Price.formatPrice(budget, {
    onlyInteger: true
  })} per recipient):`;
});
export const shippingInfoSelector = createSelector(goodieGiveawayDomesticFlatRatesSelector, goodieGiveawayInternationalFlatRatesSelector, (domesticFlatRates, internationalFlatRates) => {
  var _domesticFlatRates$de;
  if (!(domesticFlatRates !== null && domesticFlatRates !== void 0 && (_domesticFlatRates$de = domesticFlatRates.deliveryTimeDays) !== null && _domesticFlatRates$de !== void 0 && _domesticFlatRates$de.from) || !(internationalFlatRates !== null && internationalFlatRates !== void 0 && internationalFlatRates.deliveryTimeDays.to)) {
    return '';
  }
  const domesticShippingTime = `${domesticFlatRates.deliveryTimeDays.from}-${domesticFlatRates.deliveryTimeDays.to}`;
  return `Please note, domestic shipping will take ${domesticShippingTime} business days and will cost an additional ${Price.formatPrice(domesticFlatRates.rate, {
    onlyInteger: true
  })} per recipient. We currently cannot ship Goodie Giveaway items internationally.`;
});
export const flatRatesInfoSelector = createSelector(shippingInfoSelector, shippingInfo => {
  return `${shippingInfo}  Once your recipients make their selections, you will be prompted to pay. Once paid, we will ship out these gifts.`;
});