import _isValid from "redux-form/es/isValid";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { sortCards } from 'swag-client-common/utils/stripe-cards.utils';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
import { CREDITS, CREDIT_CARD } from 'swag-common/constants/payment-methods';
import { dateFormatter, dateFormatterWithYear, isSameMonth } from 'swag-common/utils/date-lib';
import { inventoryCreditsCurrentValueSelector, selectedFullInventorySelector, paymentMethodSelector, isCreditsMoreToBuyFlowSelector, purchaseFlowSelector, currentInventoryCreatedAtSelector } from '../inventory/inventory.selectors';
import { commonCardsSelector, currentInventoryTotalFeesSelector, isAutopayOnSelector, autopayPaymentMethodSelector, pendingRangeWithCurrentSelector, pendingReportsWithCurrentMonthSelector } from '../warehouse-inventory/warehouse-inventory.selectors';
import { PFITotalChargesSelector } from '../pull-from-inventory/pull-from-inventory.selectors';
import { giveawayTotalCostWithPromocodeSelector } from '../giveaway/selectors/giveaway-pay-fees-popup.selectors';
import { shipoutTotalCostToPaySelector } from '../send-to-multiple-addresses/send-to-multiple-addresses.selectors';
import { getCurrentTotal } from '../../utils/get-current-total';
import { isStripeElementsValid } from '../../../customer/redux/stripe-form/stripe-form.selectors';
import { selectedGiveawayNotecardCostSelector } from '../giveaway/selectors/giveaway-notecard.selectors';
import { isAutoPayCheckboxValid } from './payment-methods.utils';
const formSelector = state => state.form.paymentMethodForm;
export const paymentMethodsCountryFormSelector = createSelector(formSelector, form => {
  return form && form.values && form.values.country;
});
export const paymentMethodsRootSelector = state => state.paymentMethods;
export const isUserDefaultCardSelector = createSelector(paymentMethodsRootSelector, (state, props) => props.card._id, (paymentMethods, cardId) => paymentMethods.userSettings && paymentMethods.userSettings.defaultCard === cardId);
export const isPaymentMethodLoadingSelector = createSelector(paymentMethodsRootSelector, paymentMethods => paymentMethods.isLoading);
export const userDefaultCardIdSelector = createSelector(paymentMethodsRootSelector, paymentMethods => paymentMethods.userSettings && paymentMethods.userSettings.defaultCard);
export const inventoryDefaultCardIdSelector = createSelector(paymentMethodsRootSelector, paymentMethods => paymentMethods.userSettings && paymentMethods.userSettings.defaultInventoryCard);
export const isInventoryDefaultCardSelector = createSelector(inventoryDefaultCardIdSelector, (state, props) => props.card._id, (inventoryDefaultCard, cardId) => inventoryDefaultCard === cardId);
export const paymentMethodIdSelector = createSelector(paymentMethodsRootSelector, state => state.selectedCard);
export const isSelectedCardInventoryDefaultSelector = createSelector(inventoryDefaultCardIdSelector, paymentMethodIdSelector, (inventoryId, cardId) => !!inventoryId && String(inventoryId) === cardId);
export const isCardOwnerSelector = createSelector(userIdSelector, (state, props) => props.card.userId, (userId, ownerId) => userId === ownerId);
export const paymentMethodIsEditSelector = createSelector(paymentMethodIdSelector, selectedCard => Boolean(selectedCard));
export const userCardsSelector = createSelector(paymentMethodsRootSelector, userDefaultCardIdSelector, inventoryDefaultCardIdSelector, commonCardsSelector, (paymentMethods, userDefaultId, inventoryDefaultId, commonCards) => (commonCards.length ? commonCards : paymentMethods.list).map(card => _objectSpread({}, card, {
  inventoryDefault: String(card._id) === inventoryDefaultId,
  userDefault: String(card._id) === userDefaultId,
  expireYear: getExpireDate(card.expireYear)
})).sort(sortCards));
export const inventoryDefaultCardSelector = createSelector(userCardsSelector, cards => cards.find(card => card.inventoryDefault));
export const selectedPaymentMethodSelector = createSelector(userCardsSelector, paymentMethodIdSelector, (list, selectedId) => list.find(({
  _id
}) => String(_id) === String(selectedId)));
export const paymentMethodFormSelector = createSelector(state => state.form.paymentMethodForm, form => form ? form.values : {});
export const paymentMethodsInitialFormValuesSelector = createSelector(selectedPaymentMethodSelector, userDefaultCardIdSelector, inventoryDefaultCardIdSelector, paymentMethodFormSelector, (card, defaultUserMethod, defaultInventoryMethod, values) => {
  const isEmpty = !Object.keys(values).length;
  if (!card && isEmpty) {
    return {
      country: COUNTRY_CODES.US,
      state: 'AL'
    };
  }
  if (!card && !isEmpty) {
    return values;
  }
  if (card) {
    const {
      name,
      country,
      address,
      city,
      expireMonth,
      expireYear,
      last4,
      state = '',
      zip,
      lastName,
      firstName,
      _id
    } = card;
    const [street, unit] = address.split(',');
    return {
      city,
      unit: unit ? unit.trim() : '',
      state: state ? state.trim() : '',
      street: street ? street.trim() : '',
      country,
      lastName,
      firstName,
      cvc: 'xxx',
      holder: name,
      zipcode: zip,
      number: `xxxx xxxx xxxx ${last4}`,
      userDefault: String(_id) === defaultUserMethod,
      inventoryDefault: String(_id) === defaultInventoryMethod,
      expDate: `${expireMonth} / ${getExpireDate(expireYear)}`
    };
  }
});
export const isAdminSelector = createSelector(selectedFullInventorySelector, userIdSelector, (inventory, userId) => {
  if (inventory) {
    const {
      ownerId,
      adminsIds
    } = inventory;
    return [ownerId, ...adminsIds].includes(userId);
  }
  return false;
});
export const isInventoryDefaultRemovedSelector = createSelector(paymentMethodsRootSelector, state => state.isInventoryDefaultRemoved);
function getExpireDate(date) {
  return +String(date).slice(-2);
}
export const defaultCardSelector = createSelector(userCardsSelector, userDefaultCardIdSelector, inventoryDefaultCardIdSelector, (cards, userDefault, inventoryDefault) => {
  const defaultCardId = userDefault || inventoryDefault;
  if (defaultCardId) {
    return cards.find(card => card._id.toString() === defaultCardId.toString());
  }
});
export const autoPayCardSelector = createSelector(isAutopayOnSelector, paymentMethodsRootSelector, (isAutopayOn, paymentMethods) => {
  const {
    autopayCard
  } = paymentMethods;
  if (isAutopayOn && autopayCard) {
    const autopayCardFull = paymentMethods.list.find(card => String(card._id) === autopayCard);
    return autopayCardFull || null;
  }
  return null;
});
export const autopayLabelSelector = createSelector(isAutopayOnSelector, pendingRangeWithCurrentSelector, autoPayCardSelector, autopayPaymentMethodSelector, (isAutopayOn, range, cardWithAutoPay, paymentMethod) => {
  if (isAutopayOn) {
    if (!range) {
      return 'Auto pay';
    }
    const end = new Date(range.end);
    end.setDate(end.getDate() + 1);
    const dataString = dateFormatter(end);
    const paymentMethodLabel = paymentMethod === CREDIT_CARD ? `card number ending in ${cardWithAutoPay === null || cardWithAutoPay === void 0 ? void 0 : cardWithAutoPay.last4}` : 'credits';
    return `Auto pay (Charged on ${dataString} from ${paymentMethodLabel}):`;
  }
  return 'Enable auto pay:';
});
export const inventoryPaymentBillingFormSelector = state => state.form && state.form.paymentDetailsBillingAddressForm;
export const isManualPayment = state => Boolean(state.form && state.form.paymentDetailsBillingAddressForm);
export const preselectedCardSelector = createSelector(userDefaultCardIdSelector, inventoryDefaultCardIdSelector, defaultCardSelector, (userDefault, inventoryDefault, defaultCard) => {
  if (!defaultCard) {
    return null;
  }
  return (userDefault || inventoryDefault) && defaultCard;
});
export const selectedPaymentMethodIdSelector = createSelector(paymentMethodsRootSelector, preselectedCardSelector, (state, preselectedCard) => {
  if (state.selectedCard) {
    return state.selectedCard;
  }
  if (preselectedCard) {
    return preselectedCard === null || preselectedCard === void 0 ? void 0 : preselectedCard._id;
  }
});
const isFormValidSelector = createSelector(inventoryPaymentBillingFormSelector, state => state, (paymentDetailsBillingAddressForm, state) => {
  return isAutoPayCheckboxValid(paymentDetailsBillingAddressForm) && _isValid('paymentDetailsBillingAddressForm')(state);
});
export const isPaymentValid = createSelector([selectedPaymentMethodIdSelector, isManualPayment, isFormValidSelector, isStripeElementsValid, inventoryCreditsCurrentValueSelector, paymentMethodSelector, PFITotalChargesSelector, giveawayTotalCostWithPromocodeSelector, shipoutTotalCostToPaySelector, currentInventoryTotalFeesSelector, isCreditsMoreToBuyFlowSelector, purchaseFlowSelector, selectedGiveawayNotecardCostSelector], (selectedId, isFormExist, isFormValid, isStripeElementsValid, credits, paymentMethod, totalSOA, totalGiveaway, totalSMA, totalWarehousing, isCreditsMoreToBuyFlow, purchaseFlow, {
  total: totalGiveawayNotecard
}) => {
  const currentTotal = getCurrentTotal({
    purchaseFlow,
    totalSOA,
    totalSMA,
    totalGiveaway,
    totalWarehousing,
    totalGiveawayNotecard
  });
  const isCreditsAmountEnough = credits - Number(currentTotal) >= 0;
  if (paymentMethod === CREDITS && !isCreditsMoreToBuyFlow) {
    return isCreditsAmountEnough;
  }
  if (paymentMethod === CREDITS && isCreditsMoreToBuyFlow && isCreditsAmountEnough) {
    return true;
  }
  return isFormExist ? isFormValid && isStripeElementsValid : Boolean(selectedId);
});
export const inventoryExistedPaymentMethodsFormSelector = state => state.form && state.form.existedPaymentMethods;
export const inventoryPreselectedPaymentMethodsFormSelector = state => state.form && state.form.preselectedPaymentMethods;
export const inventoryPayCreditsFormSelector = state => state.form && state.form.inventoryPayCredits;
export const isAutoPaySelected = createSelector(inventoryPaymentBillingFormSelector, inventoryExistedPaymentMethodsFormSelector, inventoryPreselectedPaymentMethodsFormSelector, inventoryPayCreditsFormSelector, (billingForm, existedPaymentMethods, preselectedPaymentMethods, inventoryPayCredits) => {
  var _billingForm$values, _existedPaymentMethod, _preselectedPaymentMe, _inventoryPayCredits$;
  return Boolean(billingForm === null || billingForm === void 0 ? void 0 : (_billingForm$values = billingForm.values) === null || _billingForm$values === void 0 ? void 0 : _billingForm$values.saveAutoPay) || Boolean(existedPaymentMethods === null || existedPaymentMethods === void 0 ? void 0 : (_existedPaymentMethod = existedPaymentMethods.values) === null || _existedPaymentMethod === void 0 ? void 0 : _existedPaymentMethod.saveAutoPay) || Boolean(preselectedPaymentMethods === null || preselectedPaymentMethods === void 0 ? void 0 : (_preselectedPaymentMe = preselectedPaymentMethods.values) === null || _preselectedPaymentMe === void 0 ? void 0 : _preselectedPaymentMe.saveAutoPay) || Boolean(inventoryPayCredits === null || inventoryPayCredits === void 0 ? void 0 : (_inventoryPayCredits$ = inventoryPayCredits.values) === null || _inventoryPayCredits$ === void 0 ? void 0 : _inventoryPayCredits$.saveAutoPay);
});
export const isAutoPayCheckboxAvailableSelector = createSelector(inventoryPaymentBillingFormSelector, paymentDetails => {
  var _paymentDetails$value;
  return Boolean(paymentDetails === null || paymentDetails === void 0 ? void 0 : (_paymentDetails$value = paymentDetails.values) === null || _paymentDetails$value === void 0 ? void 0 : _paymentDetails$value.savePaymentDetails);
});
export const isAutoPayCheckboxDisabledSelector = createSelector(isAutoPayCheckboxAvailableSelector, isAutoPayCheckboxAvailable => {
  return !isAutoPayCheckboxAvailable;
});
export const paymentWidgetTitleRangeSelector = createSelector(pendingReportsWithCurrentMonthSelector, currentInventoryCreatedAtSelector, (reports, createdAt) => {
  const creationDate = createdAt ? new Date(createdAt) : null;
  if (!reports || !reports.length) {
    return 'Storage fee amount';
  }
  const firstReport = reports.sort((prev, next) => new Date(prev.dateRange.from).getTime() - new Date(next.dateRange.from).getTime())[0];
  const tempFrom = new Date(firstReport.dateRange.from);
  const start = creationDate && tempFrom < creationDate && isSameMonth(tempFrom, creationDate) ? new Date(creationDate) : tempFrom;
  const end = reports.length === 1 ? firstReport.dateRange.to : reports[reports.length - 2].dateRange.to;
  if (start && end) {
    const startString = dateFormatterWithYear(new Date(start));
    const endString = dateFormatterWithYear(new Date(end));
    return `Amount due storage period (${startString} - ${endString})`;
  }
  return '';
});
export const autopayTooltipSelector = createSelector(isAutopayOnSelector, isAutopayOn => isAutopayOn ? 'Auto pay enabled' : 'Auto pay disabled');
export const paymentPopupTabSelector = createSelector(paymentMethodsRootSelector, state => state.tab);
export const publicAPIDefaultPaymentSelector = createSelector(paymentMethodsRootSelector, paymentMethods => paymentMethods.publicAPIDefaultPayment);
export const isPublicAPIDefaultPaymentMethodCreditCardSelector = createSelector(publicAPIDefaultPaymentSelector, defaultPayment => defaultPayment.value === CREDIT_CARD);
export const isPublicAPIDefaultPaymentMethodCreditsSelector = createSelector(publicAPIDefaultPaymentSelector, defaultPayment => defaultPayment.value === CREDITS);
export const publicApiDefaultPaymentMethodSelector = createSelector(publicAPIDefaultPaymentSelector, defaultPayment => defaultPayment.value);
export const publicAPIDefaultCreditCardIdSelector = createSelector(publicAPIDefaultPaymentSelector, defaultPayment => defaultPayment.card);
export const publicAPIDefaultCreditCardAddingNewSelector = createSelector(publicAPIDefaultPaymentSelector, defaultPayment => defaultPayment.isAddingNew);
export const isPublicAPIDefaultPaymentMethodSelectedCreditCardSelector = createSelector(isPublicAPIDefaultPaymentMethodCreditCardSelector, publicAPIDefaultCreditCardIdSelector, selectedPaymentMethodIdSelector, publicAPIDefaultCreditCardAddingNewSelector, (isCard, card, selectedCard, isAddingNew) => isCard && (card === selectedCard || isAddingNew));
export const isPublicAPIDefaultPaymentDirtySelector = createSelector(publicAPIDefaultPaymentSelector, ({
  value,
  card,
  initialValue,
  initialCard
}) => value !== initialValue || card !== initialCard);