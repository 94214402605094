function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import dateFormat from 'dateformat';
import { find } from 'lodash/collection';
import { RECEIVER_STATUSES_TITLES, SHIPOUT_STATUSES, SHIPOUT_STATUSES_SEQUENCE } from 'swag-common/constants/custom-store-shipouts.constants';
import { USA_STATES_LIST } from 'swag-common/constants/shipping.constants';
import { Price } from 'swag-client-common/utils/price';
import { hasProductSizes } from 'swag-common/business-logic/custom-store-product/has-product-sizes.logic';
import isProductColored from 'swag-common/business-logic/custom-store-product/is-product-colored.logic';
import { canShipoutBeRemoved } from 'swag-common/business-logic/custom-store-shipout/can-shipout-be-removed.logic';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import isBoxWithSizes from 'swag-common/business-logic/custom-store-product/is-box-with-sizes.logic';
import getBoxSizeSet from 'swag-common/business-logic/products/get-box-size-set.logic';
import { getBundleSizeSet, isBundle, isBundleWithSizes } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { calculatePickAndPackAmount } from 'swag-common/utils/distributions/calculate-pick-and-pack-amount.service';
import { getCSVFileLink } from 'swag-common/services/links/get-download-link-from-s3.service';
import { getInventoryProductsDetails } from 'swag-common/business-logic/inventory/get-distribution-products-details.logic';
import { totalPriceAfterPromocodeApplied } from 'swag-common/business-logic/payments/total-price-after-promocode-applied';
import { renameDeliveryOptionsForActivityPage } from 'swag-common/utils/rename-delivery-options';
import generateProductPropertyName from 'swag-common/business-logic/custom-store-shipout/generate-product-property-name';
import { PRODUCT_PROPERTIES } from 'swag-common/constants/product.constants';
import { generateProductName } from 'swag-common/utils/distributions/generate-product-name.util';
import { mapProductsByName } from 'swag-common/utils/distributions/map-products-by-name.util';
import { getCountOfReceiversByStatuses, getShippedDate } from 'swag-common/business-logic/custom-store-shipout/get-shipout-shipped-date.logic';
import { SHIPOUT_CSV_KEYS, SHIPOUT_CSV_HEADERS, SHIPOUT_CSV_EXAMPLES, SHIPOUT_CSV_KEYS_SEQUENCE, SHIPOUT_CSV_KEYS_ADDED, SHIPMENT_CSV_KEYS, TRACKING_INFO_CSV_KEYS_SEQUENCE, SHIPOUT_CSV_EXAMPLES_OVERSEAS, SHIPOUT_SHIPMENT_DATA_CSV_HEADERS } from '../../utils/constants';
export const generateCsvTemplateData = (products, selectedColors) => {
  const headers = Object.values(SHIPOUT_CSV_HEADERS);
  const examples = Object.values(SHIPOUT_CSV_EXAMPLES);
  const examplesOverseas = Object.values(SHIPOUT_CSV_EXAMPLES_OVERSEAS);
  const register = [];
  const productsMap = mapProductsByName(products);
  Object.values(productsMap).forEach(products => products.forEach((product, index, array) => {
    const productCode = generateProductName(product.name, array.length, index);
    const {
      _id: id,
      variants,
      sizeSettings
    } = product;
    const sizeHeader = generateProductPropertyName({
      productCode,
      propertyName: PRODUCT_PROPERTIES.size,
      isInQuotes: true
    });
    const colorHeader = generateProductPropertyName({
      productCode,
      propertyName: PRODUCT_PROPERTIES.color,
      isInQuotes: true
    });
    const quantityHeader = generateProductPropertyName({
      productCode,
      propertyName: PRODUCT_PROPERTIES.quantity,
      isInQuotes: true
    });
    const hasSize = hasProductSizes(product);
    if (isBox(product.swagProductId) && isBoxWithSizes(product)) {
      const contentSizeHeader = generateProductPropertyName({
        productCode,
        propertyName: PRODUCT_PROPERTIES.apparelSize,
        isInQuotes: true
      });
      const boxSizeSet = getBoxSizeSet(product);
      headers.push(contentSizeHeader);
      examples.push(`${Object.keys(boxSizeSet)[0]}`);
      examplesOverseas.push(`${Object.keys(boxSizeSet)[0]}`);
    }
    if (isBundle(variants[0]) && isBundleWithSizes(variants[0])) {
      const contentSizeHeader = generateProductPropertyName({
        productCode,
        propertyName: PRODUCT_PROPERTIES.apparelSize,
        isInQuotes: true
      });
      const boxSizeSet = getBundleSizeSet(product);
      headers.push(contentSizeHeader);
      examples.push(`${Object.keys(boxSizeSet)[0]}`);
      examplesOverseas.push(`${Object.keys(boxSizeSet)[0]}`);
    }
    if (hasSize) {
      headers.push(sizeHeader);
      examples.push(`${sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options[0]}`);
      examplesOverseas.push(`${sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options[0]}`);
    }
    if (isProductColored(variants)) {
      headers.push(colorHeader);
      const productSelectedColor = selectedColors[id];
      const productSelectedVariant = variants.find(v => v.colorValue === productSelectedColor);
      examples.push(`${(productSelectedVariant === null || productSelectedVariant === void 0 ? void 0 : productSelectedVariant.colorName) || variants[0].colorName}`);
      examplesOverseas.push(`${(productSelectedVariant === null || productSelectedVariant === void 0 ? void 0 : productSelectedVariant.colorName) || variants[0].colorName}`);
    }
    headers.push(quantityHeader);
    examples.push('1');
    examplesOverseas.push('1');
    register.push({
      id,
      name: productCode
    });
  }));
  return {
    headers,
    examples,
    examplesOverseas,
    register
  };
};
export const getShipoutDetailsCsvData = shipout => {
  const taxesHeaders = {};
  const pickAndPackHeaders = {};
  const promocodeHeaders = {};
  if (!shipout) {
    return {
      keys: [],
      headers: [],
      data: []
    };
  }
  const {
    receivers,
    totalAmountPaid,
    totalWithoutDiscount
  } = shipout;
  const isVatExists = receivers.some(r => {
    var _r$shipment, _r$shipment$taxes;
    return ((_r$shipment = r.shipment) === null || _r$shipment === void 0 ? void 0 : (_r$shipment$taxes = _r$shipment.taxes) === null || _r$shipment$taxes === void 0 ? void 0 : _r$shipment$taxes.vat) && r.shipment.taxes.vat > 0;
  });
  const isDutyFeesExists = receivers.some(r => {
    var _r$shipment2, _r$shipment2$taxes;
    return ((_r$shipment2 = r.shipment) === null || _r$shipment2 === void 0 ? void 0 : (_r$shipment2$taxes = _r$shipment2.taxes) === null || _r$shipment2$taxes === void 0 ? void 0 : _r$shipment2$taxes.dutyFees) && r.shipment.taxes.dutyFees > 0;
  });
  const isFeesExists = receivers.some(r => {
    var _r$shipment3, _r$shipment3$taxes;
    return ((_r$shipment3 = r.shipment) === null || _r$shipment3 === void 0 ? void 0 : (_r$shipment3$taxes = _r$shipment3.taxes) === null || _r$shipment3$taxes === void 0 ? void 0 : _r$shipment3$taxes.dutyFees) && r.shipment.taxes.dutyFees > 0;
  });
  const doDutiesAndTaxesExist = isFeesExists || isDutyFeesExists || isVatExists;
  const areOnlyTaxes = isVatExists && !isDutyFeesExists && !isFeesExists;
  const isPickAndPackExists = receivers.some(r => {
    var _r$shipment4;
    const pickAndPack = calculatePickAndPackAmount([r], (r === null || r === void 0 ? void 0 : (_r$shipment4 = r.shipment) === null || _r$shipment4 === void 0 ? void 0 : _r$shipment4.selectedDeliveryServiceCode) || '');
    return pickAndPack > 0;
  });
  if (areOnlyTaxes) {
    taxesHeaders[SHIPOUT_CSV_KEYS_ADDED.TAXES] = SHIPOUT_CSV_KEYS_ADDED.TAXES;
  } else if (doDutiesAndTaxesExist) {
    taxesHeaders[SHIPOUT_CSV_KEYS_ADDED.DUTIES_AND_TAXES] = SHIPOUT_CSV_KEYS_ADDED.DUTIES_AND_TAXES;
  }
  if (isPickAndPackExists) {
    pickAndPackHeaders[SHIPOUT_CSV_KEYS_ADDED.PICK_AND_PACK_PRICE] = SHIPOUT_CSV_KEYS_ADDED.PICK_AND_PACK_PRICE;
  }
  const shipoutWithDiscount = totalAmountPaid !== totalWithoutDiscount;
  if (shipoutWithDiscount) {
    promocodeHeaders[SHIPOUT_CSV_KEYS_ADDED.DISCOUNT] = SHIPOUT_CSV_KEYS_ADDED.DISCOUNT;
  }
  const csvData = receivers.map(receiver => {
    var _receiver$shipment;
    const {
      shipment
    } = receiver;
    if (!shipment) {
      return [];
    }
    const {
      selectedDeliveryServiceCode
    } = shipment;
    const state = USA_STATES_LIST.find(({
      code
    }) => receiver[SHIPOUT_CSV_KEYS.STATE] === code);
    const recipientData = SHIPOUT_CSV_KEYS_SEQUENCE.map(key => {
      if (key === SHIPOUT_CSV_KEYS.STATE) {
        return state && state.name;
      }
      return receiver[key] || 'N/A';
    });
    const status = RECEIVER_STATUSES_TITLES[receiver[SHIPOUT_CSV_KEYS_ADDED.STATUS]];
    const taxes = (_receiver$shipment = receiver.shipment) === null || _receiver$shipment === void 0 ? void 0 : _receiver$shipment.taxes;
    const taxesValues = [];
    const pickAndPackValues = [];
    const discountValues = [];
    const shipmentPrice = shipment[SHIPMENT_CSV_KEYS.SHIPMENT_PRICE] && Price.formatPrice(shipment[SHIPMENT_CSV_KEYS.SHIPMENT_PRICE]);
    const shippedAt = shipment[SHIPMENT_CSV_KEYS.SHIPPED_AT] ? dateFormat(shipment[SHIPMENT_CSV_KEYS.SHIPPED_AT] || '', 'mm/dd/yyyy h:MM tt') : 'N/A';
    const trackingInfo = TRACKING_INFO_CSV_KEYS_SEQUENCE.map(key => shipment.trackingInfo && shipment.trackingInfo[key] || 'N/A');
    const vat = (taxes === null || taxes === void 0 ? void 0 : taxes.vat) || 0;
    const dutyFees = (taxes === null || taxes === void 0 ? void 0 : taxes.dutyFees) || 0;
    const fees = (taxes === null || taxes === void 0 ? void 0 : taxes.fees) || 0;
    const total = vat + dutyFees + fees;
    if (doDutiesAndTaxesExist) {
      taxesValues.push(Price.formatPrice(total));
    }
    if (isPickAndPackExists) {
      const pickAndPack = calculatePickAndPackAmount([receiver], selectedDeliveryServiceCode || '');
      pickAndPackValues.push(Price.formatPrice(pickAndPack || 0));
    }
    if (shipoutWithDiscount) {
      discountValues.push(Price.formatPrice(getReceiverDiscount(shipout, receiver)));
    }
    return [...recipientData, status, shipmentPrice, ...taxesValues, ...pickAndPackValues, ...discountValues, shippedAt, ...trackingInfo];
  });
  const keysCommon = [...SHIPOUT_CSV_KEYS_SEQUENCE, SHIPOUT_CSV_KEYS_ADDED.STATUS, SHIPMENT_CSV_KEYS.SHIPMENT_PRICE, ...Object.keys(taxesHeaders), ...Object.keys(pickAndPackHeaders), ...Object.keys(promocodeHeaders), SHIPMENT_CSV_KEYS.SHIPPED_AT, ...TRACKING_INFO_CSV_KEYS_SEQUENCE];
  const headers = keysCommon.reduce((result, key) => {
    if (!SHIPOUT_SHIPMENT_DATA_CSV_HEADERS[key]) {
      return [...result, SHIPOUT_CSV_HEADERS[key]];
    }
    return [...result, SHIPOUT_SHIPMENT_DATA_CSV_HEADERS[key]];
  }, []);
  return {
    keys: keysCommon,
    headers,
    data: csvData
  };
};
export const calculateShipoutsData = shipouts => {
  return shipouts.map(shipout => {
    const canBeRemoved = canShipoutBeRemoved(shipout);
    const {
      receivers,
      status: shipoutStatus,
      shippingDate,
      totalAmountPaid: totalCost = 0
    } = shipout;
    const shippedDate = getShippedDate(shippingDate);
    const {
      shippedReceiversNumber,
      deliveredReceiversNumber,
      shipmentCalculationErrorReceiversNumber
    } = getCountOfReceiversByStatuses({
      receivers
    });
    const wasShipmentStarted = SHIPOUT_STATUSES_SEQUENCE.indexOf(shipoutStatus) >= SHIPOUT_STATUSES_SEQUENCE.indexOf(SHIPOUT_STATUSES.SHIPMENT_IN_PROGRESS);
    return _objectSpread({}, shipout, {
      totalCost,
      shippedDate,
      canBeRemoved,
      wasShipmentStarted,
      shippedReceiversNumber,
      deliveredReceiversNumber,
      shipmentCalculationErrorReceiversNumber,
      atLeastOneItemDelivered: deliveredReceiversNumber > 0
    });
  });
};
export const fulfillWithShippingDetails = shipouts => shipouts.map(shipout => {
  var _receivers$;
  const {
    shipmentCalculationErrorReceiversNumber,
    status,
    paidAt,
    products,
    receivers,
    isCreatedFromAPI
  } = shipout;
  const shipment = (_receivers$ = receivers[0]) === null || _receivers$ === void 0 ? void 0 : _receivers$.shipment;
  const isError = shipmentCalculationErrorReceiversNumber > 0 && status === SHIPOUT_STATUSES.SHIPMENT_CALCULATED;
  const isShipmentCsvCanBeDownloaded = SHIPOUT_STATUSES_SEQUENCE.indexOf(status) >= SHIPOUT_STATUSES_SEQUENCE.indexOf(SHIPOUT_STATUSES.SHIPMENT_CALCULATED) && !isError;
  const isDateHidden = [SHIPOUT_STATUSES.SHIPMENT_CALCULATED, SHIPOUT_STATUSES.REJECTED].includes(status);
  const productsToShow = getInventoryProductsDetails(products);
  const canDownloadInvoice = Boolean(paidAt) || isCreatedFromAPI;
  const fulfilledShipout = _objectSpread({}, shipout, {
    receivers: formatReceivers(shipout, shipout.receivers, shipout.products),
    isError,
    isShipmentCsvCanBeDownloaded,
    isPaid: Boolean(paidAt),
    canDownloadInvoice,
    csvUrl: getCSVFileLink(shipout.csvUrl),
    productsToShow
  });
  fulfilledShipout.shippedDate = isDateHidden ? null : shipout.shippedDate;
  fulfilledShipout.estInHand = isDateHidden ? null : shipout.estInHand;
  if (!shipment) {
    return fulfilledShipout;
  }
  return _objectSpread({}, fulfilledShipout, {
    shippingDetails: find(renameDeliveryOptionsForActivityPage(shipment.possibleDeliveries), {
      serviceCode: shipment.selectedDeliveryServiceCode
    })
  });
});
const formatReceivers = (shipout, receivers, products) => {
  const {
    totalCost,
    promocode
  } = shipout;
  const totalCostWithPromocode = promocode ? totalPriceAfterPromocodeApplied(totalCost, promocode) : totalCost;
  const promocodeValue = totalCost - totalCostWithPromocode;
  return receivers.map(rec => {
    let receiverTotalCost;
    if (rec.totalCost) {
      receiverTotalCost = rec.totalCost * 100;
    } else {
      var _rec$shipment, _rec$shipment$taxes, _rec$shipment$taxes2;
      receiverTotalCost = (_rec$shipment = rec.shipment) !== null && _rec$shipment !== void 0 && _rec$shipment.shipmentPrice ? ((rec === null || rec === void 0 ? void 0 : rec.pickAndPackPrice) || 0) + rec.shipment.shipmentPrice + (((_rec$shipment$taxes = rec.shipment.taxes) === null || _rec$shipment$taxes === void 0 ? void 0 : _rec$shipment$taxes.vat) || 0) + (((_rec$shipment$taxes2 = rec.shipment.taxes) === null || _rec$shipment$taxes2 === void 0 ? void 0 : _rec$shipment$taxes2.dutyFees) || 0) : null;
      if (promocode && receiverTotalCost) {
        receiverTotalCost = receiverTotalCost - receiverTotalCost / totalCost * promocodeValue;
      }
    }
    return {
      _id: String(rec._id),
      firstName: rec.firstName,
      lastName: rec.lastName,
      email: rec.email,
      phone: rec.phone || '',
      shipment: rec.shipment,
      address: rec.address,
      unit: rec.unit || '',
      city: rec.city,
      state: rec.state,
      zip: rec.zip,
      country: rec.country,
      totalCost: receiverTotalCost,
      products: getInventoryProductsDetails(products.filter(product => Object.keys(rec.productsToShip).includes(String(product._id))), rec.productsToShip),
      status: rec.status
    };
  });
};
function getReceiverDiscount(shipout, receiver) {
  const shipment = receiver.shipment || {};
  const discountAmount = shipout.totalWithoutDiscount - shipout.totalAmountPaid;
  let receiverTaxSum = 0;
  let receiverTaxVat = 0;
  let receiverTaxDutyFees = 0;
  if (shipment.taxes) {
    const {
      taxes
    } = shipment;
    receiverTaxVat = taxes.vat;
    receiverTaxDutyFees = taxes.dutyFees;
    receiverTaxSum = receiverTaxVat + receiverTaxDutyFees;
  }
  const pickAndPackPrice = calculatePickAndPackAmount([receiver], shipment.selectedDeliveryServiceCode);
  const receiverTotal = pickAndPackPrice + (shipment.shipmentPrice || 0) + receiverTaxSum;
  return discountAmount * (receiverTotal / shipout.totalWithoutDiscount);
}