import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { GiveawayPayFeesPopupFilterOption, GiveawayPopupType } from 'swag-common/constants/giveaway.constants';
import { DEMO_INVENTORY_WARNING_POPUP, REMOVE_GIVEAWAY_LINK_CONFIRMATION_POPUP, GIVEAWAY_PAY_FEES_POPUP } from 'swag-client-common/constants';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { instantScrollTop } from 'swag-client-common/utils/tricks';
import { selectedLinkToBeRemovedSelector, giveawayPaymentRecipientsSelector, giveawayPaymentActiveSortingSelector, giveawayPaymentSearchCurrentResultSelector, giveawayPaymentSortedRecipientsSelector, payFeesPopupSearchQuerySelector, payFeesPopupFilterSelector, giveawayPaymentSelectedRecipientsSelector, payFeesSelectedRecipientsCountSelector, isGiveawayPayFeesNeedToBeRestoredSelector, giveawayPaymentListLength, giveawayPaymentSearchCurrentIndexSelector, getLinkToBeRemoved } from '../selectors/giveaway-pay-fees-popup.selectors';
import { giveawayIdSelector, selectedGiveawaySelector } from '../selectors/common.selectors';
import { InventoryApi } from '../../../api/inventory.api';
import { selectedInventorySelector, isSelectedDemoInventorySelector } from '../../inventory/inventory.selectors';
import { searchScrollParamsGenerator } from '../../../utils/search.util';
import { getAdvancedDistributionForProducts } from '../../advanced-distributions/advanced-distributions.actions';
import { selectCompletedGiveaway } from './giveaway-campaigns.actions';
import { GIVEAWAY_ADMIN_PREFIX } from './giveaway-common.actions';
import { removeGiveawayLinkSuccess } from './giveaway-campaigns.actions';
const PAY_FEES_PREFIX = `${GIVEAWAY_ADMIN_PREFIX}PAY_FEES_POPUP_`;
export const GET_PRICES_REQUEST = `${PAY_FEES_PREFIX}GET_PRICES_REQUEST`;
export const GET_PRICES_SUCCESS = `${PAY_FEES_PREFIX}GET_PRICES_SUCCESS`;
export const GET_PRICES_FAILURE = `${PAY_FEES_PREFIX}GET_PRICES_FAILURE`;
export const SELECT_RECIPIENT = `${PAY_FEES_PREFIX}SELECT_GIVEAWAY_RECIPIENT`;
export const DESELECT_RECIPIENT = `${PAY_FEES_PREFIX}DESELECT_GIVEAWAY_RECIPIENT`;
export const SELECT_ALL_RECIPIENTS = `${PAY_FEES_PREFIX}SELECT_ALL_GIVEAWAY_RECIPIENTS`;
export const DESELECT_ALL_RECIPIENTS = `${PAY_FEES_PREFIX}DESELECT_ALL_GIVEAWAY_RECIPIENTS`;
export const GET_RECIPIENTS_REQUEST = `${PAY_FEES_PREFIX}PAY_FEES_RECIPIENTS_REQUEST`;
export const GET_RECIPIENTS_FAILURE = `${PAY_FEES_PREFIX}PAY_FEES_RECIPIENTS_FAILURE`;
export const GET_RECIPIENTS_SUCCESS = `${PAY_FEES_PREFIX}PAY_FEES_RECIPIENTS_SUCCESS`;
export const INIT_SAVED_RESULTS = `${PAY_FEES_PREFIX}INIT_SAVED_RESULTS`;
export const SET_RECIPIENTS_SORTING = `${PAY_FEES_PREFIX}SET_GIVEAWAY_RECIPIENTS_SORTING`;
export const SET_LINK_TO_BE_REMOVED = `${PAY_FEES_PREFIX}SET_LINK_TO_BE_REMOVED`;
export const RESET_FILTER_RESULTS = `${PAY_FEES_PREFIX}RESET_FILTER_RESULTS`;
export const SET_FILTER_RESULTS = `${PAY_FEES_PREFIX}SET_FILTER_RESULTS`;
export const SET_FILTER = `${PAY_FEES_PREFIX}SET_FILTER`;
export const FILTER_CHANGE_REQUEST = `${PAY_FEES_PREFIX}FILTER_CHANGE_REQUEST`;
export const FILTER_CHANGE_SUCCESS = `${PAY_FEES_PREFIX}FILTER_CHANGE_SUCCESS`;
export const FILTER_CHANGE_FAILURE = `${PAY_FEES_PREFIX}FILTER_CHANGE_FAILURE`;
export const SET_SEARCH_QUERY = `${PAY_FEES_PREFIX}SET_SEARCH_QUERY`;
export const RESET_SEARCH_QUERY = `${PAY_FEES_PREFIX}RESET_SEARCH_QUERY`;
export const SEARCH_REQUEST = `${PAY_FEES_PREFIX}SEARCH_REQUEST`;
export const SEARCH_REQUEST_SUCCESS = `${PAY_FEES_PREFIX}SEARCH_REQUEST_SUCCESS`;
export const SEARCH_REQUEST_FAILURE = `${PAY_FEES_PREFIX}SEARCH_REQUEST_FAILURE`;
export const SET_CURRENT_SEARCH_INDEX = `${PAY_FEES_PREFIX}SET_CURRENT_SEARCH_INDEX`;
export const SAVE_SEARCH_RESULTS = `${PAY_FEES_PREFIX}SAVE_SEARCH_RESULTS`;
export const RESTORE_FILTER_AND_SEARCH = `${PAY_FEES_PREFIX}RESTORE_FILTER_AND_SEARCH`;
export const SET_NEED_TO_BE_RESTORED = `${PAY_FEES_PREFIX}SET_NEED_TO_BE_RESTORED`;
export const EXCLUDE_RECIPIENT = `${PAY_FEES_PREFIX}EXCLUDE_RECIPIENT`;
export const getLinksPrices = createAsyncAction({
  preFn: (dispatch, _, data) => {
    dispatch({
      type: GET_PRICES_REQUEST,
      payload: {
        disableResetDetails: data === null || data === void 0 ? void 0 : data.disableResetDetails
      }
    });
  },
  transformData: ({
    selected
  }, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const giveawayId = giveawayIdSelector(state);
    return {
      selected,
      inventoryId,
      giveawayId
    };
  },
  fn: InventoryApi.giveaway.getPrices,
  success: ({
    prices,
    products
  }) => ({
    type: GET_PRICES_SUCCESS,
    payload: {
      prices,
      products
    }
  }),
  error: error => dispatch => {
    dispatch(hidePopup());
    dispatch(showNotification({
      text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
    dispatch({
      type: GET_PRICES_FAILURE,
      error
    });
  }
});
export const selectGiveawayRecipient = recipientId => (dispatch, getState) => {
  dispatch({
    type: SELECT_RECIPIENT,
    payload: recipientId
  });
  const selected = giveawayPaymentSelectedRecipientsSelector(getState());
  if (selected.length) {
    dispatch(getLinksPrices({
      selected: selected.map(link => String(link._id)),
      disableResetDetails: true
    }));
  }
};
export const deselectGiveawayRecipient = recipientId => (dispatch, getState) => {
  dispatch({
    type: DESELECT_RECIPIENT,
    payload: recipientId
  });
  const selected = giveawayPaymentSelectedRecipientsSelector(getState());
  if (selected.length) {
    dispatch(getLinksPrices({
      selected: selected.map(link => String(link._id))
    }));
  }
};
export const selectAllRecipients = () => (dispatch, getState) => {
  dispatch({
    type: SELECT_ALL_RECIPIENTS
  });
  const selected = giveawayPaymentSelectedRecipientsSelector(getState());
  if (selected.length) {
    dispatch(getLinksPrices({
      selected: selected.map(link => String(link._id))
    }));
  }
};
export const deselectAllRecipients = () => ({
  type: DESELECT_ALL_RECIPIENTS
});
export const getPendingRecipients = createAsyncLoadedAction({
  preFn: dispatch => {
    dispatch({
      type: GET_RECIPIENTS_REQUEST
    });
  },
  transformData: (data, getState) => {
    const {
      inventoryId: paramInventoryId,
      giveawayId: paramGiveawayId
    } = data;
    const state = getState();
    const inventoryId = selectedInventorySelector(state) || paramInventoryId;
    const giveawayId = giveawayIdSelector(state) || paramGiveawayId;
    if (!giveawayId) {
      return true;
    }
    return {
      inventoryId,
      giveawayId
    };
  },
  fn: InventoryApi.giveaway.getPendingLinks,
  success: ({
    links,
    total
  }, data) => dispatch => {
    const {
      giveawayId
    } = data;
    dispatch(selectCompletedGiveaway(giveawayId));
    dispatch({
      type: GET_RECIPIENTS_SUCCESS,
      payload: {
        links,
        total
      }
    });
  },
  postSuccess: (dispatch, getState) => {
    const state = getState();
    const toBeRestored = isGiveawayPayFeesNeedToBeRestoredSelector(state);
    if (toBeRestored) {
      dispatch(restoreFilterAndSearchValues());
      return;
    }
    dispatch({
      type: INIT_SAVED_RESULTS
    });
    instantScrollTop();
  },
  error: error => dispatch => {
    dispatch({
      type: GET_RECIPIENTS_FAILURE,
      error
    });
    dispatch(hidePopup());
    dispatch(showNotification({
      text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
  }
});
export const showPayFees = (inventoryId, giveawayId) => dispatch => {
  dispatch(restoreFilterAndSearchValues());
  dispatch(selectCompletedGiveaway(giveawayId));
  dispatch(getPendingRecipients({
    inventoryId,
    giveawayId
  }));
};
export const scroll = ref => (dispatch, getState) => {
  if (!ref) {
    return;
  }
  const state = getState();
  const list = giveawayPaymentSortedRecipientsSelector(state);
  const current = giveawayPaymentSearchCurrentResultSelector(state);
  if (current) {
    var _ref$current;
    const index = list.findIndex(({
      _id
    }) => String(_id) === current);
    const indexWithHandlingNoResults = index < 0 ? 0 : index;
    return (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.scrollToIndex(searchScrollParamsGenerator(indexWithHandlingNoResults));
  }
};
export const setGiveawayRecipientsSorting = (sort, ref) => dispatch => {
  dispatch({
    type: SET_RECIPIENTS_SORTING,
    payload: sort
  });
  dispatch(search({
    ref
  }));
};
export const setLinkToBeRemoved = linkId => {
  return {
    type: SET_LINK_TO_BE_REMOVED,
    payload: linkId
  };
};
export const cancelRemoveLinkAction = () => dispatch => {
  dispatch(setLinkToBeRemoved(null));
  dispatch(showPopup({
    popup: GIVEAWAY_PAY_FEES_POPUP
  }));
};
export const removeGiveawayLinkById = id => (dispatch, getState) => {
  const isDemo = isSelectedDemoInventorySelector(getState());
  if (isDemo) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.RemoveGiveawayRecipient
    }));
  }
  dispatch(setNeedToRestore());
  dispatch(setLinkToBeRemoved(id));
  dispatch(showPopup({
    popup: REMOVE_GIVEAWAY_LINK_CONFIRMATION_POPUP
  }));
};
export const excludeRecipient = id => dispatch => {
  dispatch({
    type: EXCLUDE_RECIPIENT,
    payload: id
  });
  dispatch(setLinkToBeRemoved(null));
};
export const removeLinkAction = createAsyncLoadedAction({
  transformData: (_, getState) => {
    const state = getState();
    const giveaway = selectedGiveawaySelector(state);
    const inventoryId = selectedInventorySelector(state);
    const id = selectedLinkToBeRemovedSelector(state);
    return {
      id,
      giveawayId: String(giveaway === null || giveaway === void 0 ? void 0 : giveaway._id),
      inventoryId
    };
  },
  fn: InventoryApi.giveaway.removeLink,
  success: (response, data) => (dispatch, getState) => {
    const state = getState();
    const selected = giveawayPaymentSelectedRecipientsSelector(state);
    const isGiveawayLinkSelected = selected.find(selectedLink => selectedLink._id === data.id);
    if (isGiveawayLinkSelected) {
      const list = giveawayPaymentRecipientsSelector(state);
      const link = list.find(item => item._id === data.id);
      dispatch(removeGiveawayLinkSuccess(link, data.giveawayId, response.giveawayStep));
    }
  },
  postSuccess: (dispatch, getState, transformed) => {
    const state = getState();
    const list = giveawayPaymentRecipientsSelector(state);
    const isLast = list.length === 1 && list[0]._id === transformed.id;
    const id = selectedLinkToBeRemovedSelector(state);
    dispatch(excludeRecipient(String(id)));
    dispatch(hidePopup());
    if (!isLast) {
      dispatch(showPayFees());
    }
  },
  error: (error, transformedData, dispatch) => {
    dispatch(showPayFees());
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
export const setGiveawayPaymentPopupFilter = filter => (dispatch, getState) => {
  const state = getState();
  const selectedLinksCount = payFeesSelectedRecipientsCountSelector(state);
  const filterValue = payFeesPopupFilterSelector(state);
  if (selectedLinksCount) {
    dispatch(deselectAllRecipients());
  }
  if (filterValue !== filter) {
    dispatch({
      type: SET_FILTER,
      payload: filter
    });
  }
};
export const onFilterChangeAction = (filter, ref) => (dispatch, getState) => {
  dispatch(setGiveawayPaymentPopupFilter(filter));
  const searchQuery = payFeesPopupSearchQuerySelector(getState());
  if (searchQuery) {
    return dispatch(search({
      ref
    }));
  }
  if (filter !== GiveawayPayFeesPopupFilterOption.all) {
    return dispatch(search({
      ref
    }));
  }
};
export const handleResetQueryAction = () => (dispatch, getState) => {
  const state = getState();
  const toBeRestored = isGiveawayPayFeesNeedToBeRestoredSelector(state);
  const filter = payFeesPopupFilterSelector(state);
  if (toBeRestored) {
    return;
  }
  dispatch(resetSearchQuery());
  dispatch(deselectAllRecipients());
  if (filter !== GiveawayPayFeesPopupFilterOption.all) {
    dispatch(search());
    return;
  }
};
export const setSearchQuery = query => {
  return {
    type: SET_SEARCH_QUERY,
    payload: query
  };
};
export const resetSearchQuery = () => ({
  type: RESET_SEARCH_QUERY
});
export const setCurrentSearchIndex = currentIndex => ({
  type: SET_CURRENT_SEARCH_INDEX,
  payload: currentIndex
});
export const saveSearchResults = results => {
  return {
    type: SAVE_SEARCH_RESULTS,
    payload: results
  };
};
export const search = createAsyncLoadedAction({
  preFn: dispatch => {
    dispatch({
      type: SEARCH_REQUEST
    });
  },
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const giveawayId = giveawayIdSelector(state);
    const search = payFeesPopupSearchQuerySelector(state);
    const linksType = GiveawayPopupType.payFees;
    const sorting = giveawayPaymentActiveSortingSelector(state);
    const filter = payFeesPopupFilterSelector(state);
    return {
      search,
      giveawayId,
      inventoryId,
      linksType,
      sorting,
      filter
    };
  },
  fn: InventoryApi.giveaway.searchOnPopup,
  success: results => dispatch => {
    dispatch(saveSearchResults(results));
    dispatch({
      type: SEARCH_REQUEST_SUCCESS
    });
  },
  postSuccess: (dispatch, getState, transformed, res, data) => {
    if (data !== null && data !== void 0 && data.ref) {
      dispatch(scroll(data.ref));
    }
  },
  error: error => dispatch => {
    dispatch({
      type: SEARCH_REQUEST_FAILURE,
      error
    });
    dispatch(hidePopup());
    dispatch(showNotification({
      text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
  }
});
export const setNeedToRestore = () => dispatch => {
  dispatch({
    type: SET_NEED_TO_BE_RESTORED
  });
};
export const restoreFilterAndSearchValues = () => {
  return {
    type: RESTORE_FILTER_AND_SEARCH
  };
};
export const handleQueryChangeAction = (debouncedDispatch, value, ref) => dispatch => {
  dispatch(setSearchQuery(value));
  dispatch(deselectAllRecipients());
  if (value) {
    debouncedDispatch(search({
      ref,
      value
    }));
    return;
  }
  dispatch(resetSearchQuery());
};
export const handleNextResultAction = ref => (dispatch, getState) => {
  const state = getState();
  const activeResult = giveawayPaymentSearchCurrentIndexSelector(state);
  const currentListLength = giveawayPaymentListLength(state);
  const nextResultIndex = activeResult === currentListLength - 1 ? 0 : activeResult + 1;
  dispatch(setCurrentSearchIndex(nextResultIndex));
  dispatch(scroll(ref));
};
export const handlePrevResultAction = ref => (dispatch, getState) => {
  const state = getState();
  const activeResult = giveawayPaymentSearchCurrentIndexSelector(state);
  const currentListLength = giveawayPaymentListLength(state);
  const prevResultIndex = activeResult === 0 ? currentListLength - 1 : activeResult - 1;
  dispatch(setCurrentSearchIndex(prevResultIndex));
  dispatch(scroll(ref));
};
export const SET_GIVEAWAY_PAY_POPUP_FLOW = 'SET_GIVEAWAY_PAY_POPUP_FLOW';
export const setGiveawayPayPopupFlowAction = flow => dispatch => {
  dispatch({
    type: SET_GIVEAWAY_PAY_POPUP_FLOW,
    payload: flow
  });
};
export const SET_BUY_CREDITS_FROM_GIVEAWAY_FEES_PAGE_FLOW = 'SET_BUY_CREDITS_FROM_GIVEAWAY_FEES_PAGE_FLOW';
export const setBuyCreditsFromGiveawayFeesPageFlow = setFlow => dispatch => {
  return dispatch({
    type: SET_BUY_CREDITS_FROM_GIVEAWAY_FEES_PAGE_FLOW,
    payload: setFlow
  });
};
export const getAdvancedDistributionForRemovingLink = () => (dispatch, getState) => {
  const state = getState();
  const link = getLinkToBeRemoved(state);
  const productIds = Object.keys((link === null || link === void 0 ? void 0 : link.products) || {});
  if (productIds.length) {
    dispatch(getAdvancedDistributionForProducts({
      productIds
    }));
  }
};