function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { sortBoxContentInCustomBox } from 'swag-common/business-logic/order/get-order-of-box-content-items.logic';
function getSwagProductFactory(swagProducts) {
  const swagProductsMap = swagProducts === null || swagProducts === void 0 ? void 0 : swagProducts.reduce((acc, product) => _objectSpread({}, acc, {
    [String(product._id)]: product
  }), {});
  return _id => {
    return swagProductsMap[String(_id)];
  };
}
function getPsProductFactory(psProducts) {
  const psProductsMap = psProducts === null || psProducts === void 0 ? void 0 : psProducts.reduce((acc, product) => _objectSpread({}, acc, {
    [String(product._id)]: product
  }), {});
  return _id => {
    return psProductsMap[String(_id)];
  };
}
function getSizeSettingsFactory(sizeSettings) {
  const sizeSettingsMap = sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.reduce((acc, size) => _objectSpread({}, acc, {
    [String(size._id)]: size
  }), {});
  return _id => {
    return sizeSettingsMap[String(_id)];
  };
}
export function decompressCustomProducts(products, swagProducts, psProducts, sizeSettings) {
  const getSwagProduct = getSwagProductFactory(swagProducts);
  const getPsProduct = getPsProductFactory(psProducts);
  const getSizeSettings = getSizeSettingsFactory(sizeSettings);
  return products.map(product => {
    const updatedProduct = _objectSpread({}, product);
    if (updatedProduct.swagProductId) {
      updatedProduct.swagProductId = getSwagProduct(product.swagProductId);
    }
    if (updatedProduct.isProductSourcing) {
      updatedProduct.psProduct = getPsProduct(product.parentId);
    }
    if (updatedProduct.sizeSettings) {
      updatedProduct.sizeSettings = getSizeSettings(product.sizeSettings);
    }
    updatedProduct.variants = updatedProduct.variants.map(variant => {
      var _updatedVariant$boxCo, _updatedVariant$bundl;
      const updatedVariant = _objectSpread({}, variant);
      if (updatedVariant.swagProductId) {
        updatedVariant.swagProductId = getSwagProduct(variant.swagProductId);
      }
      if (updatedVariant.sizeSettings) {
        updatedVariant.sizeSettings = getSizeSettings(variant.sizeSettings);
      }
      (_updatedVariant$boxCo = updatedVariant.boxContent) === null || _updatedVariant$boxCo === void 0 ? void 0 : _updatedVariant$boxCo.forEach(content => {
        if (content.product.swagProductId) {
          content.product.swagProductId = getSwagProduct(content.product.swagProductId);
        }
        if (content.product.sizeSettings) {
          content.product.sizeSettings = getSizeSettings(content.product.sizeSettings);
        }
      });
      (_updatedVariant$bundl = updatedVariant.bundleContent) === null || _updatedVariant$bundl === void 0 ? void 0 : _updatedVariant$bundl.forEach(content => {
        var _content$product, _content$product2;
        if ((_content$product = content.product) !== null && _content$product !== void 0 && _content$product.swagProductId) {
          content.product.swagProductId = getSwagProduct(content.product.swagProductId);
        }
        if ((_content$product2 = content.product) !== null && _content$product2 !== void 0 && _content$product2.sizeSettings) {
          content.product.sizeSettings = getSizeSettings(content.product.sizeSettings);
        }
        if (content.sizeSettings) {
          content.sizeSettings = getSizeSettings(content.sizeSettings);
        }
      });
      return sortBoxContentInCustomBox(updatedVariant);
    });
    return updatedProduct;
  });
}