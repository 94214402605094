import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import withStripeElementsMethodsHOC from '../stripe-elements-hoc';
import { stripeCardExpiryValidationErrorStringSelector } from './../../../../customer/redux/stripe-form/stripe-form.selectors';
import { setStripeFieldValidationError, setStripeFieldEmpty, setStripeFieldCompleted } from './../../../../customer/redux/stripe-form/stripe-form.actions';
import StripeCardExpiryElement from './stripe-element-card-expiry';
const mapStateToProps = createStructuredSelector({
  errorCardExpiryString: stripeCardExpiryValidationErrorStringSelector
});
const mapDispatchToProps = dispatch => ({
  setStripeFieldError: error => {
    dispatch(setStripeFieldValidationError(error));
  },
  setStripeFieldEmpty: isEmpty => {
    dispatch(setStripeFieldEmpty(isEmpty));
  },
  setStripeFieldCompleted: isCompleted => {
    dispatch(setStripeFieldCompleted(isCompleted));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(withStripeElementsMethodsHOC(StripeCardExpiryElement));