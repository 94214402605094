import { cartUrlModifier } from 'swag-client-common/libs/url-modifier';
import { CART_LINK_PREFIX_ROUTE } from 'swag-common/constants/main-swag.constants';
export const SPECIAL_ROUTES = {
  ACCOUNT_ORDERS: cartUrlModifier('/account/orders'),
  ACCOUNT_PROFILE: cartUrlModifier('/account/profile'),
  PROFILE_SETTINGS: cartUrlModifier('/account/profile/settings'),
  PROFILE_PASSWORD: cartUrlModifier('/account/profile/password'),
  COMPANY_ORDERS: cartUrlModifier('/company/orders'),
  CART: cartUrlModifier('/cart'),
  CHECKOUT: cartUrlModifier('/checkout'),
  DELIVERY: cartUrlModifier('/delivery'),
  ALL_SWAG: cartUrlModifier('/collections/all-swag'),
  PRODUCT: cartUrlModifier('/product'),
  COLLECTIONS: cartUrlModifier('/collections'),
  PRIVACY_POLICY: cartUrlModifier('/privacy-policy'),
  TOS: cartUrlModifier('/terms-of-service'),
  SIZE_SUBMISSIONS: cartUrlModifier('/account/size-submissions'),
  PREBUILT_CART_MODE: CART_LINK_PREFIX_ROUTE,
  CART_UNAVAILABLE: '/cart-unavailable',
  CART_LINK: '/cart-link/'
};