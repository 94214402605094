import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { browserHistory } from 'react-router';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { getSuperSpeedSettings } from 'swag-client-common/redux/settings/settings-other.actions';
import { isLoadedSelector, isAvailableSuperSpeedSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { SUPER_SPEED_ERR_MSG } from 'swag-common/constants/error.const';
import { FilterIds } from 'swag-common/constants/main-swag.constants';
import { PROD_TIME } from 'swag-common/constants/product.constants';
import { applyFilter, changeRadioFilter, getProducts, openFilterPopup, resetProducts, setAnimationVisibility, setFilterVisibility } from '../../../redux/product-listing/product-listing.actions';
import { GalleryHoc } from './gallery-hoc';
const showSuperSpeedWidgetSelector = createSelector(isLoadedSelector, isAvailableSuperSpeedSelector, (isLoaded, isAvailable) => isLoaded && isAvailable);
const showBuildBoxWidgetSelector = createSelector(isLoadedSelector, isAvailableSuperSpeedSelector, (isLoaded, isAvailable) => isLoaded && !isAvailable);
const mapStateToProps = createStructuredSelector({
  showSuperSpeedWidget: showSuperSpeedWidgetSelector,
  showBuildBoxWidget: showBuildBoxWidgetSelector
});
const mapDispatchToProps = dispatch => ({
  onSuperSpeedClick: async () => {
    var _payload$config;
    const {
      payload
    } = await dispatch(getSuperSpeedSettings());
    const isSuperSpeedEnabled = payload === null || payload === void 0 ? void 0 : (_payload$config = payload.config) === null || _payload$config === void 0 ? void 0 : _payload$config.enabled;
    if (!isSuperSpeedEnabled) {
      return dispatch(showNotification({
        text: SUPER_SPEED_ERR_MSG
      }));
    }
    dispatch(setAnimationVisibility(false));
    dispatch(openFilterPopup());
    dispatch(setFilterVisibility(true));
    browserHistory.push(APP_ROUTES.ALL_SWAG);
    dispatch(changeRadioFilter({
      filterId: FilterIds.productionTime,
      filterPropertyValue: PROD_TIME.LESS_THAN_5_DAYS
    }));
    dispatch(applyFilter());
    dispatch(resetProducts());
    dispatch(getProducts());
    dispatch(setAnimationVisibility(true));
  }
});
export const HomepageGallery = connect(mapStateToProps, mapDispatchToProps)(GalleryHoc);