import { isGiveawayStepPassed } from 'swag-common/business-logic/giveaway/is-giveaway-step-passed';
import { GiveawayStep } from '../../../constants/giveaway.constants';
const isSelectProductsStepSkipped = skippedSteps => skippedSteps.includes(GiveawayStep.selectProducts);
export const giveawayStepTransitions = [{
  key: GiveawayStep.naming,
  nextState: ({
    data
  }) => {
    if (!isSelectProductsStepSkipped(data.step.skippedSteps)) {
      return GiveawayStep.selectProducts;
    }
    if (data.productIds.length > 1) {
      return GiveawayStep.productsChoice;
    }
    return GiveawayStep.reserve;
  }
}, {
  key: GiveawayStep.selectProducts,
  nextState: ({
    data
  }) => {
    if (data.productIds.length > 1) {
      return GiveawayStep.productsChoice;
    }
    return GiveawayStep.reserve;
  },
  previousState: () => GiveawayStep.naming
}, {
  key: GiveawayStep.productsChoice,
  nextState: () => {
    return GiveawayStep.reserve;
  },
  previousState: ({
    data
  }) => {
    if (!isSelectProductsStepSkipped(data.step.skippedSteps)) {
      return GiveawayStep.selectProducts;
    }
    return GiveawayStep.naming;
  }
}, {
  key: GiveawayStep.reserve,
  nextState: ({
    data,
    passedStepsSaved
  }) => {
    if (data.lastGiveaway.item && !isGiveawayStepPassed(passedStepsSaved, GiveawayStep.selectLast)) {
      return GiveawayStep.selectLast;
    }
    return GiveawayStep.customize;
  },
  previousState: ({
    data
  }) => {
    if (data.productIds.length > 1) {
      return GiveawayStep.productsChoice;
    }
    if (!isSelectProductsStepSkipped(data.step.skippedSteps)) {
      return GiveawayStep.selectProducts;
    }
    return GiveawayStep.naming;
  }
}, {
  key: GiveawayStep.customize,
  previousState: () => {
    return GiveawayStep.reserve;
  },
  nextState: () => GiveawayStep.notecard
}, {
  key: GiveawayStep.selectLast,
  previousState: () => {
    return GiveawayStep.reserve;
  },
  nextState: () => GiveawayStep.notecard
}, {
  key: GiveawayStep.notecard,
  previousState: ({
    data,
    passedStepsSaved
  }) => {
    if (data.lastGiveaway.item && !isGiveawayStepPassed(passedStepsSaved, GiveawayStep.selectLast)) {
      return GiveawayStep.selectLast;
    }
    return GiveawayStep.customize;
  },
  nextState: () => GiveawayStep.send
}, {
  key: GiveawayStep.send,
  previousState: () => {
    return GiveawayStep.notecard;
  },
  nextState: () => GiveawayStep.completed
}];
export const goodieGiveawayStepTransitions = [{
  key: GiveawayStep.naming,
  nextState: () => {
    return GiveawayStep.budget;
  }
}, {
  key: GiveawayStep.budget,
  nextState: ({
    data,
    passedStepsSaved
  }) => {
    if (data.lastGiveaway.item && !isGiveawayStepPassed(passedStepsSaved, GiveawayStep.selectLast)) {
      return GiveawayStep.selectLast;
    }
    return GiveawayStep.customize;
  },
  previousState: () => GiveawayStep.naming
}, {
  key: GiveawayStep.customize,
  previousState: () => {
    return GiveawayStep.budget;
  },
  nextState: () => GiveawayStep.notecard
}, {
  key: GiveawayStep.selectLast,
  previousState: () => {
    return GiveawayStep.budget;
  },
  nextState: () => GiveawayStep.notecard
}, {
  key: GiveawayStep.notecard,
  previousState: ({
    data,
    passedStepsSaved
  }) => {
    if (data.lastGiveaway.item && !isGiveawayStepPassed(passedStepsSaved, GiveawayStep.selectLast)) {
      return GiveawayStep.selectLast;
    }
    return GiveawayStep.customize;
  },
  nextState: () => GiveawayStep.send
}, {
  key: GiveawayStep.send,
  previousState: () => {
    return GiveawayStep.notecard;
  },
  nextState: () => GiveawayStep.completed
}];