function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { browserHistory } from 'react-router';
import isProductLowOnStock from 'swag-common/business-logic/custom-store-product/is-product-low-on-stock.logic';
import { OrderApi } from 'swag-client-common/api/order.api';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { removeRecentlyViewedForGuests } from 'swag-client-common/redux/cart/cart.utils';
import { getCart, toggleCartBadgeLoader } from 'swag-client-common/redux/cart/cart.actions';
import { decompressCustomProducts } from 'swag-common/utils/custom-product/decompress-custom-products.util';
import { loggedInUserIdSelector, userSelector } from 'swag-client-common/redux/user/user.selectors';
import { SWAG_BUNDLE_SUCCESS_ACTIONS } from 'swag-common/constants/swag-bundle-actions-text';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import UploadApi from 'swag-client-common/api/upload.api';
import { hideLoader, showLoader } from 'swag-client-common/redux/common/common.actions';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { FORBIDDEN_ERROR_CODE } from 'swag-common/constants/error-codes';
import { checkAccessRights } from '../../utils/enterprise-access-wrapper';
import { inventoryLoadedAsyncParameters } from '../inventory/inventory.actions';
import { storeIdSelector } from '../store-settings/store-settings.selectors';
import { orderMoreItemsSelector } from '../common/multi-slice.selectors';
import { isSelectedDemoInventorySelector, selectedInventorySelector } from '../inventory/inventory.selectors';
import { ProductsApi } from '../../api/product.api';
import { addStoreId, mergeCreateAsyncOptions } from '../redux.utils';
import { InventoryApi } from '../../api/inventory.api';
import { resetVariantsAndQuantities, setProductListAvailableSizesAction } from '../order-more/order-more.actions';
import { exitSuccessAction } from '../swag-bundles/actions/swag-bundles.actions';
import { areSomeProductsSelectedSelector, isSingleProductColoredSelector, isSingleProductInStockSelector, productIdToManageSelector, productsSelector, selectedInStoreProductsSelector, selectedOutOfStoreProductsSelector, selectedProductsIdsSelector, selectedStoreProductsSelector, singleProductColorWithoutImagesSelector, singleProductCustomImagesSelector, singleProductDefaultCustomImagesSelector, singleProductImagesNumberSelector, singleProductImagesToRemove, singleProductPreorderAllowedSelector, singleProductPreorderCanBeAllowedSelector, singleProductUniqueColorsSelector } from './products.selectors';
import { openFirstDemoInventoryEnterPopup } from './products.actions-other';
const PREFIX = 'PRODUCTS/';
const inventoryLoadedAsyncParametersObject = inventoryLoadedAsyncParameters();
export const LIST_INVENTORY_PRODUCTS_STARTED = `${PREFIX}LIST_INVENTORY_PRODUCTS_STARTED`;
export const LIST_INVENTORY_PRODUCTS_SUCCESS = `${PREFIX}LIST_INVENTORY_PRODUCTS_SUCCESS`;
export const LIST_INVENTORY_PRODUCTS_FAILED = `${PREFIX}LIST_INVENTORY_PRODUCTS_FAILED`;
const initialListInventoryProductsParams = {
  fn: InventoryApi.listProducts,
  success: ({
    products,
    swagProducts,
    psProducts,
    sizeSettings
  }, _, dispatch) => {
    dispatch(setProductListAvailableSizesAction(sizeSettings));
    const decompressedProducts = decompressCustomProducts(products, swagProducts, psProducts, sizeSettings);
    return {
      type: LIST_INVENTORY_PRODUCTS_SUCCESS,
      payload: decompressedProducts
    };
  },
  error: errorAction(LIST_INVENTORY_PRODUCTS_FAILED)
};
const listInventoryProductsParams = _objectSpread({}, initialListInventoryProductsParams, {
  preFn: dispatch => {
    dispatch({
      type: LIST_INVENTORY_PRODUCTS_STARTED
    });
  },
  postSuccess: (dispatch, getState) => {
    const user = userSelector(getState());
    if (user && user.passedActions && !user.passedActions.demoInventoryTourShown) {
      openFirstDemoInventoryEnterPopup(dispatch);
    }
  },
  postError: (dispatch, getState, error) => {
    if (error.status === FORBIDDEN_ERROR_CODE) {
      browserHistory.push(APP_ROUTES.HOME);
      window.location.reload();
    }
  }
});
export const listInventoryProducts = createAsyncAction(listInventoryProductsParams);
export const listInventoryProductsWithoutLoader = createAsyncAction(initialListInventoryProductsParams);
export const listInventoryProductsEntering = createAsyncAction(mergeCreateAsyncOptions(listInventoryProductsParams, inventoryLoadedAsyncParametersObject));
export const UPDATE_PRODUCT_DETAILS_STARTED = `${PREFIX}UPDATE_PRODUCT_DETAILS_STARTED`;
export const UPDATE_PRODUCT_DETAILS_SUCCESS = `${PREFIX}UPDATE_PRODUCT_DETAILS_SUCCESS`;
export const UPDATE_PRODUCT_DETAILS_FAILED = `${PREFIX}UPDATE_PRODUCT_DETAILS_FAILED`;
const variantDistributionDetails = {
  preFn: dispatch => {
    dispatch({
      type: UPDATE_PRODUCT_DETAILS_STARTED
    });
    dispatch(showLoader());
  },
  transformData: (data, getState) => {
    const state = getState();
    return {
      inventoryId: state.inventory.selectedInventory,
      productId: data.productId
    };
  },
  fn: ProductsApi.getVariantDistributionDetails,
  success: (res, transformedData, dispatch, data) => {
    dispatch({
      type: UPDATE_PRODUCT_DETAILS_SUCCESS,
      payload: res
    });
    return showPopup({
      popup: data.type,
      productId: data.productId
    });
  },
  postSuccess: dispatch => {
    dispatch(hideLoader());
  },
  error: errorAction(UPDATE_PRODUCT_DETAILS_FAILED),
  postError: (dispatch, getState, err) => {
    const errorMessage = err.message || 'Sorry, something went wrong';
    dispatch(hideLoader());
    dispatch(showNotification({
      text: errorMessage
    }));
  }
};
export const getDistributionDetailsByProduct = createAsyncAction(variantDistributionDetails);
export const updateProductDetails = productDetails => ({
  type: UPDATE_PRODUCT_DETAILS_SUCCESS,
  payload: productDetails
});
export const UPDATE_PRODUCT_IN_STOCK_SUCCESS = `${PREFIX}UPDATE_PRODUCT_IN_STOCK_SUCCESS`;
export const updateProductInStock = ({
  productId,
  inStock
}) => ({
  type: UPDATE_PRODUCT_IN_STOCK_SUCCESS,
  payload: {
    productId,
    inStock
  }
});
export const LIST_STORE_PRODUCTS_STARTED = `${PREFIX}LIST_STORE_PRODUCTS_STARTED`;
export const LIST_STORE_PRODUCTS_SUCCESS = `${PREFIX}LIST_STORE_PRODUCTS_SUCCESS`;
export const LIST_STORE_PRODUCTS_FAILED = `${PREFIX}LIST_STORE_PRODUCTS_FAILED`;
export const listStoreProducts = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: LIST_STORE_PRODUCTS_STARTED
    });
    dispatch(showLoader());
  },
  transformData: addStoreId,
  fn: ProductsApi.fetchProducts,
  success: products => ({
    type: LIST_STORE_PRODUCTS_SUCCESS,
    payload: products
  }),
  error: errorAction(LIST_STORE_PRODUCTS_FAILED),
  postSuccess: dispatch => {
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
});
export const SELECT_PRODUCT = `${PREFIX}SELECT_PRODUCT`;
export const DESELECT_PRODUCT = `${PREFIX}DESELECT_PRODUCT`;
export const toggleSelectionProduct = ({
  productId,
  isSelected
}) => {
  const type = isSelected ? SELECT_PRODUCT : DESELECT_PRODUCT;
  return {
    type,
    payload: productId
  };
};
export const deselectSimpleProduct = () => (dispatch, getState) => {
  const selectedProducts = selectedProductsIdsSelector(getState());
  if (selectedProducts.length === 1) {
    dispatch(toggleSelectionProduct({
      productId: selectedProducts[0],
      isSelected: false
    }));
  }
};
export const DESELECT_ALL_PRODUCTS = `${PREFIX}DESELECT_ALL_PRODUCTS`;
export const deselectAllProducts = () => dispatch => {
  dispatch({
    type: DESELECT_ALL_PRODUCTS
  });
};
export const PRE_SELECT_PRODUCTS = `${PREFIX}PRE_SELECT_PRODUCTS`;
export const preselectProducts = productIds => {
  return {
    type: PRE_SELECT_PRODUCTS,
    payload: productIds
  };
};
export const SELECT_PRODUCT_TO_MANAGE = `${PREFIX}SELECT_PRODUCT_TO_MANAGE`;
export const selectProductToManage = productId => ({
  type: SELECT_PRODUCT_TO_MANAGE,
  payload: productId
});
export const DESELECT_PRODUCT_TO_MANAGE = `${PREFIX}DESELECT_PRODUCT_TO_MANAGE`;
export const deselectProductToManage = () => ({
  type: DESELECT_PRODUCT_TO_MANAGE
});
export const RESET_INVENTORY_PRODUCTS = `${PREFIX}RESET_INVENTORY_PRODUCTS`;
export const resetInventoryProducts = () => ({
  type: RESET_INVENTORY_PRODUCTS
});
export const OPEN_PRODUCT_DELETE_POPUP = `${PREFIX}OPEN_PRODUCT_DELETE_POPUP`;
export const CLOSE_PRODUCT_DELETE_POPUP = `${PREFIX}CLOSE_PRODUCT_DELETE_POPUP`;
export const openProductDeletePopup = productId => ({
  type: OPEN_PRODUCT_DELETE_POPUP,
  payload: productId
});
export const closeProductDeletePopup = () => ({
  type: CLOSE_PRODUCT_DELETE_POPUP
});
export const HIDE_PRODUCT_STARTED = `${PREFIX}HIDE_PRODUCT_STARTED`;
export const HIDE_PRODUCT_SUCCESS = `${PREFIX}HIDE_PRODUCT_SUCCESS`;
export const HIDE_PRODUCT_FAILED = `${PREFIX}HIDE_PRODUCT_FAILED`;
export const hideInventoryProduct = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: HIDE_PRODUCT_STARTED
    });
    dispatch(showLoader());
  },
  transformData: (data, getState) => {
    const state = getState();
    return {
      inventoryId: state.inventory.selectedInventory,
      productId: state.products.productIdToManage
    };
  },
  fn: InventoryApi.removeProduct,
  success: products => ({
    type: HIDE_PRODUCT_SUCCESS,
    payload: products
  }),
  error: errorAction(HIDE_PRODUCT_FAILED),
  postSuccess: dispatch => {
    dispatch(closeProductDeletePopup());
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
});
export const SELECT_PRODUCT_COLOR = `${PREFIX}SELECT_PRODUCT_COLOR`;
export const selectProductColor = ({
  productId,
  colorValue
}) => ({
  type: SELECT_PRODUCT_COLOR,
  payload: {
    productId,
    colorValue
  }
});
export const PRESELECT_PRODUCTS_COLOR = `${PREFIX}PRESELECT_PRODUCTS_COLOR`;
export const preselectProductsColor = () => (dispatch, getState) => {
  const state = getState();
  const products = productsSelector(state);
  const preselectedColors = products.reduce((preselected, product) => {
    const productColors = singleProductUniqueColorsSelector(state, {
      productId: product._id
    });
    const isColored = isSingleProductColoredSelector(state, {
      productId: product._id
    });
    if (!isColored) {
      return preselected;
    }
    const lowOnStockVariants = product.variants.filter(isProductLowOnStock);
    if (lowOnStockVariants.length) {
      preselected[product._id] = lowOnStockVariants[0].colorValue;
    } else {
      preselected[product._id] = productColors[0].colorValue;
    }
    return preselected;
  }, {});
  return dispatch({
    type: PRESELECT_PRODUCTS_COLOR,
    payload: preselectedColors
  });
};
export const UPDATE_PRODUCT_SETTINGS_STARTED = `${PREFIX}UPDATE_PRODUCT_SETTINGS_STARTED`;
export const UPDATE_PRODUCT_SETTINGS_SUCCESS = `${PREFIX}UPDATE_PRODUCT_SETTINGS_SUCCESS`;
export const UPDATE_PRODUCT_SETTINGS_FAILED = `${PREFIX}UPDATE_PRODUCT_SETTINGS_FAILED`;
export const updateProductSettings = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: UPDATE_PRODUCT_SETTINGS_STARTED
    });
    dispatch(showLoader());
  },
  transformData: (data, getState) => {
    return _objectSpread({}, data, {
      inventoryId: selectedInventorySelector(getState())
    });
  },
  fn: ProductsApi.updateProductSettings,
  success: settings => ({
    type: UPDATE_PRODUCT_SETTINGS_SUCCESS,
    payload: settings
  }),
  error: errorAction(UPDATE_PRODUCT_SETTINGS_FAILED),
  postSuccess: dispatch => {
    dispatch(hideLoader());
  },
  postError: dispatch => {
    dispatch(hideLoader());
  }
});
export const ORDER_MORE_SUCCESS = `${PREFIX}ORDER_MORE_SUCCESS`;
export const ORDER_MORE_FAILED = `${PREFIX}ORDER_MORE_FAILED`;
export const orderMoreRequest = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const state = getState();
    const items = orderMoreItemsSelector(state);
    return {
      items,
      orderToken: null,
      userId: loggedInUserIdSelector(state)
    };
  },
  fn: OrderApi.addItemToCart,
  success: products => ({
    type: ORDER_MORE_SUCCESS,
    payload: products
  }),
  error: errorAction(ORDER_MORE_FAILED),
  postError: (dispatch, getState, error) => {
    if (error.status === 401) {
      return;
    }
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  },
  postSuccess: async (dispatch, getState, _, response) => {
    dispatch(hidePopup());
    dispatch(toggleCartBadgeLoader(true));
    dispatch(resetIsMoreOrdered());
    dispatch(resetVariantsAndQuantities());
    await dispatch(getCart());
    dispatch(toggleCartBadgeLoader(false));
    removeRecentlyViewedForGuests(getState, response);
  }
});
export const orderMore = checkAccessRights(orderMoreRequest, DemoInventoryWarningTypes.Products);
export const UPDATE_STORE_PRODUCTS_STARTED = `${PREFIX}UPDATE_STORE_PRODUCTS_STARTED`;
export const UPDATE_STORE_PRODUCTS_FAILED = `${PREFIX}UPDATE_STORE_PRODUCTS_FAILED`;
export const UPDATE_STORE_PRODUCTS_SUCCESS = `${PREFIX}UPDATE_STORE_PRODUCTS_SUCCESS`;
export const updateStoreProducts = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: UPDATE_STORE_PRODUCTS_STARTED
    });
  },
  transformData: (data, getState) => {
    const state = getState();
    let selectedProducts = [productIdToManageSelector(state)];
    if (data.products) {
      selectedProducts = data.products;
      delete data.products;
    } else if (areSomeProductsSelectedSelector(state)) {
      selectedProducts = selectedProductsIdsSelector(state);
    }
    return _objectSpread({}, data, {
      storeId: storeIdSelector(getState()),
      _id: selectedProducts
    });
  },
  fn: ProductsApi.updateStoreProducts,
  success: products => ({
    type: UPDATE_STORE_PRODUCTS_SUCCESS,
    payload: products
  }),
  error: errorAction(UPDATE_STORE_PRODUCTS_FAILED),
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(deselectAllProducts());
    dispatch(showNotification({
      text: 'Product(s) were successfully updated'
    }));
  },
  postError: dispatch => {
    dispatch(showNotification({
      text: 'Sorry, something went wrong'
    }));
  }
});
export const setProductsFavorited = () => (dispatch, getState) => {
  const selectedItems = selectedStoreProductsSelector(getState());
  const productsToUpdate = selectedItems.map(item => ({
    productId: item._id,
    isFavorited: !selectedItems.every(item => item.isFavorited)
  }));
  dispatch(toggleProductsFavorited(productsToUpdate));
};
export const toggleProductsFavorited = createAsyncAction({
  transformData: (data, getState) => {
    const storeId = storeIdSelector(getState());
    return {
      products: data,
      storeId
    };
  },
  preFn: dispatch => {
    dispatch({
      type: UPDATE_STORE_PRODUCTS_STARTED
    });
  },
  fn: ProductsApi.setProductsFavoritedStatus,
  success: product => ({
    type: UPDATE_STORE_PRODUCTS_SUCCESS,
    payload: product
  }),
  error: errorAction(UPDATE_STORE_PRODUCTS_FAILED),
  postError: (dispatch, getState, err) => {
    const errorMessage = err.message || 'Sorry, something went wrong';
    dispatch(showNotification({
      text: errorMessage
    }));
  }
});
export const togglePreorder = (isPreorderAllowed, productId) => {
  return updateStoreProducts({
    products: [productId],
    isPreorderAllowed
  });
};
export const togglePublishedStatus = ({
  _id,
  isPublished
}) => (dispatch, getState) => {
  const state = getState();
  const productImagesNumber = singleProductImagesNumberSelector(state, {
    productId: _id
  });
  const productHasColorWithoutImages = singleProductColorWithoutImagesSelector(state, {
    productId: _id
  });
  const isProductInStock = isSingleProductInStockSelector(state, {
    productId: _id
  });
  const preorderCanBeAllowed = singleProductPreorderCanBeAllowedSelector(state, {
    productId: _id
  });
  const isPreorderAllowed = singleProductPreorderAllowedSelector(state, {
    productId: _id
  });
  if (productImagesNumber < 1) {
    return dispatch(showNotification({
      text: 'This product has no images. Please add at least one image before publishing it to store.',
      hideIn: 5000
    }));
  }
  if (productHasColorWithoutImages && isPublished) {
    return dispatch(showNotification({
      text: 'This product has some colors with no images. Please add at least one image per color before publishing it to store.',
      hideIn: 5000
    }));
  }
  if (isPublished && !isProductInStock) {
    dispatch(showPopup({
      popup: 'confirmProductPublish',
      productIds: [_id],
      isPreorderAllowed,
      preorderCanBeAllowed
    }));
  } else {
    const params = {
      products: [_id],
      isPublished
    };
    if (isPublished === false) {
      params.isFavorited = false;
    }
    dispatch(updateStoreProducts(params));
  }
};
export const multiTogglePublishedStatus = (products, isPublished) => (dispatch, getState) => {
  const state = getState();
  const isSomeSelectedNoImages = products.some(id => singleProductImagesNumberSelector(state, {
    productId: id
  }) < 1);
  const isEverySelectedInStock = products.every(id => isSingleProductInStockSelector(state, {
    productId: id
  }) === true);
  if (isSomeSelectedNoImages) {
    return dispatch(showNotification({
      text: 'At least one of selected products has no images. Please add at least one image before publishing it to store.',
      hideIn: 5000
    }));
  }
  if (isPublished && !isEverySelectedInStock) {
    dispatch(showPopup({
      popup: 'confirmProductPublish',
      productIds: products,
      isMultiselect: true
    }));
  } else {
    dispatch(updateStoreProducts({
      products,
      isPublished
    }));
  }
};
export const removeAllSelectedProductsFromStore = () => (dispatch, getState) => {
  const state = getState();
  const inStoreSelected = selectedInStoreProductsSelector(state);
  return dispatch(multiTogglePublishedStatus(inStoreSelected, false));
};
export const moveAllSelectedProductsToStore = () => (dispatch, getState) => {
  const state = getState();
  const outOfStoreSelected = selectedOutOfStoreProductsSelector(state);
  return dispatch(multiTogglePublishedStatus(outOfStoreSelected, true));
};
export const RESET_ORDER_MORE = `${PREFIX}RESET_ORDER_MORE`;
export const resetIsMoreOrdered = () => ({
  type: RESET_ORDER_MORE
});
const renameProductRequest = createAsyncLoadedAction({
  transformData: ({
    name
  }, getState) => {
    const productId = productIdToManageSelector(getState());
    const inventoryId = selectedInventorySelector(getState());
    return {
      name,
      productId,
      inventoryId
    };
  },
  fn: ProductsApi.setName,
  success: () => (dispatch, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    dispatch(hidePopup());
    dispatch(deselectProductToManage());
    dispatch(listInventoryProducts({
      inventoryId
    }));
  },
  postSuccess: (dispatch, getState, payload, response, {
    fromEditBundle
  }) => {
    if (fromEditBundle) {
      const inventoryId = selectedInventorySelector(getState());
      dispatch(exitSuccessAction(`/inventory/${inventoryId}`));
      dispatch(showNotification({
        text: SWAG_BUNDLE_SUCCESS_ACTIONS.BUNDLE_UPDATED
      }));
    }
  },
  postError: (dispatch, getState, err) => {
    dispatch(showNotification({
      text: err.message
    }));
  }
});
export const renameProduct = checkAccessRights(renameProductRequest, DemoInventoryWarningTypes.RenameProduct);
export const PRESELECT_PRODUCT_IMAGES = `${PREFIX}PRESELECT_PRODUCT_IMAGES`;
export const preselectProductImages = productId => (dispatch, getState) => {
  const customImagesMap = singleProductDefaultCustomImagesSelector(getState(), {
    productId
  });
  dispatch({
    type: PRESELECT_PRODUCT_IMAGES,
    payload: customImagesMap
  });
};
export const RESET_PRODUCT_IMAGES = `${PREFIX}RESET_PRODUCT_IMAGES`;
export const resetProductImages = () => ({
  type: RESET_PRODUCT_IMAGES
});
export const SET_VARIANT_IMAGE = `${PREFIX}SET_VARIANT_IMAGE`;
export const setVariantImage = (colorValue, imageUrl) => ({
  type: SET_VARIANT_IMAGE,
  payload: {
    colorValue,
    imageUrl
  }
});
export const uploadProductImage = createAsyncLoadedAction({
  transformData: ({
    data
  }) => {
    const formData = new FormData();
    formData.append('logo', data);
    return formData;
  },
  fn: UploadApi.uploadInventoryProductImage,
  success: (files, transformedData, dispatch, {
    colorValue
  }) => setVariantImage(colorValue, files[0].src),
  error: () => showNotification({
    text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
  })
});
export const closeImageManagementPopup = () => dispatch => {
  dispatch(deselectProductToManage());
  dispatch(resetProductImages());
  dispatch(hidePopup());
};
export const saveCustomProducts = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const state = getState();
    const productId = productIdToManageSelector(state);
    const inventoryId = selectedInventorySelector(state);
    const imagesMap = singleProductCustomImagesSelector(state);
    const imagesToRemove = singleProductImagesToRemove(state);
    return {
      productId,
      inventoryId,
      imagesMap,
      imagesToRemove
    };
  },
  fn: InventoryApi.saveCustomImages,
  success: () => showNotification({
    text: 'Images saved successfully'
  }),
  postSuccess: (dispatch, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    dispatch(closeImageManagementPopup());
    dispatch(listInventoryProducts({
      inventoryId
    }));
  }
});
export const saveInventoryProductImages = () => (dispatch, getState) => {
  const isDemo = isSelectedDemoInventorySelector(getState());
  if (isDemo) {
    dispatch(showNotification({
      text: 'Sorry you are not able to proceed as this is just for demo purposes to show you how to use the platform'
    }));
    return dispatch(closeImageManagementPopup());
  }
  dispatch(saveCustomProducts());
};