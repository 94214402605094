import { createSelector } from 'reselect';
import { GiveawayStep } from 'swag-common/constants/giveaway.constants';
import { getInHandDateFormatted } from 'swag-common/utils/order';
import { GiveawayTypes } from 'swag-common/interfaces';
import { productsSelector } from '../../products/products.selectors';
import { selectedGiveawaySelector } from './common.selectors';
import { payFeesSelectedRecipientsCountSelector } from './giveaway-pay-fees-popup.selectors';
import { selectedGiveawayNotecardPropsSelector } from './giveaway-notecard.selectors';
export const selectedGiveawayNameSelector = createSelector(selectedGiveawaySelector, giveaway => giveaway ? giveaway.name : '');
export const selectedGiveawayTypeSelector = createSelector(selectedGiveawaySelector, giveaway => giveaway ? giveaway.giveawayType : GiveawayTypes.swag);
export const giveawayFeesHeaderSelector = createSelector(selectedGiveawayTypeSelector, selectedGiveawayNameSelector, (giveawayType, name) => {
  if (giveawayType === GiveawayTypes.goodie) {
    return "Let's review and pay your fees for your Goodie Giveaway!";
  }
  return `Let's pay your fees for ${name} ${giveawayType.toLocaleUpperCase()} Giveaway!`;
});
export const giveawayFeesSubHeaderSelector = createSelector(selectedGiveawayTypeSelector, selectedGiveawayNameSelector, giveawayType => {
  if (giveawayType === GiveawayTypes.goodie) {
    return 'We will send your recipients the Goodie Giveaway items they have selected as soon as you submit payment for the associated shipping and distribution costs! You can easily do this by selecting all of your pending Goodie Giveaway distributions below, or selecting specific individuals from the list.';
  }
  return 'Once your shipping and pick & package fees have been paid we will dispatch the products from our fulfillment center to the recipients! You can select individuals or pay for all the fees.';
});
export const selectedGiveawayInventoryIdSelector = createSelector(selectedGiveawaySelector, giveaway => giveaway ? String(giveaway.inventoryId) : '');
export const areThereAnyChargesSelector = createSelector(selectedGiveawaySelector, giveaway => {
  var _giveaway$charges;
  if (!giveaway) {
    return false;
  }
  return ((_giveaway$charges = giveaway.charges) === null || _giveaway$charges === void 0 ? void 0 : _giveaway$charges.length) > 0;
});
export const isGiveawayNotSavedInDraftSelector = createSelector(selectedGiveawaySelector, giveaway => {
  return !!giveaway && !giveaway.authorId && ![GiveawayStep.completed, GiveawayStep.ended].includes(giveaway.step);
});
export const giveawayNotecardSelector = createSelector(selectedGiveawaySelector, productsSelector, (giveaway, products) => {
  if (!giveaway || !giveaway.notecardId) {
    return null;
  }
  const notecardProduct = products.find(({
    variants
  }) => variants.some(variant => variant._id === giveaway.notecardId));
  if (!notecardProduct) {
    return null;
  }
  const {
    _id,
    inStock,
    inProduction,
    inHandDate,
    isStrictInHandDate
  } = notecardProduct;
  return {
    _id,
    inStock,
    inProduction,
    inHandDate: getInHandDateFormatted({
      inHandDate: inHandDate,
      isStrictInHandDate: isStrictInHandDate
    })
  };
});
export const giveawayNotecardDeficientAmountSelector = createSelector(giveawayNotecardSelector, selectedGiveawayNotecardPropsSelector, payFeesSelectedRecipientsCountSelector, (giveawayNotecard, giveawayNotecardProps, recipientsQuantity) => {
  if (!giveawayNotecard) {
    return 0;
  }
  const {
    inStock = 0,
    inProduction = 0
  } = giveawayNotecard;
  const {
    minQuant
  } = giveawayNotecardProps;
  const actualDeficientAmount = recipientsQuantity - inStock - inProduction;
  if (actualDeficientAmount > 0) {
    return actualDeficientAmount < minQuant ? minQuant : actualDeficientAmount;
  }
  return actualDeficientAmount;
});
export const isOrderMoreShownSelector = createSelector(giveawayNotecardDeficientAmountSelector, deficientAmount => {
  return deficientAmount > 0;
});