import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { addRedirectUrl, deleteRedirectUrl, resetApiCredentials } from '../../../../redux/api-settings/api-settings.action';
import { clientIdSelector, clientSecretSelector, isLoadingSelector, redirectUrlsSelector } from '../../../../redux/api-settings/api-settings.selectors';
import { ApiSettings } from './api-settings';
const mapStateToProps = createStructuredSelector({
  apiClientKey: clientIdSelector,
  apiClientSecret: clientSecretSelector,
  redirectUrls: redirectUrlsSelector,
  isLoading: isLoadingSelector
});
const mapDispatchToProps = dispatch => ({
  onRedirectUrlDelete: redirectUrlId => {
    dispatch(deleteRedirectUrl({
      redirectUrlId
    }));
  },
  onRedirectUrlAdd: url => {
    if (url) {
      dispatch(addRedirectUrl({
        url
      }));
    }
  },
  onResetApiCredentials: () => {
    dispatch(resetApiCredentials());
  }
});
export const AccountApiSettings = connect(mapStateToProps, mapDispatchToProps)(ApiSettings);