function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { isBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { singleBundleTotalQuantitySelector } from './pull-from-inventory.selectors';
export const getTotalQuantityToPull = ({
  variants
}, quantities) => {
  if (!variants) {
    return 0;
  }
  return variants.reduce((total, {
    _id
  }) => quantities[String(_id)] + total, 0);
};
export const extendCustomProductWithTotalQuantity = ({
  product,
  quantities,
  warehouseQuantities,
  stockOnly = false,
  state
}) => {
  var _product$variants;
  let isQuantitiesExceeded = false;
  const {
    inStock,
    inProduction,
    inReserve
  } = product.variants.reduce((all, variant) => {
    const warehousing = warehouseQuantities[String(variant._id)];
    if (!warehousing) {
      return all;
    }
    const {
      inProduction,
      inStock,
      inReserve,
      inReserveProduction
    } = warehousing;
    if (!isQuantitiesExceeded) {
      const linksReserve = inReserve - inReserveProduction;
      const deltaBetweenStockAndReserve = inStock - linksReserve;
      const stockLevel = deltaBetweenStockAndReserve >= 0 ? deltaBetweenStockAndReserve : 0;
      const quantityToPull = quantities[String(variant._id)] || 0;
      isQuantitiesExceeded = stockOnly ? stockLevel < quantityToPull : inStock + inProduction - inReserve < quantityToPull;
    }
    return {
      inProduction: inProduction + all.inProduction,
      inStock: inStock + all.inStock,
      inReserve: inReserve + all.inReserve,
      inReserveProduction: inReserveProduction + all.inReserveProduction
    };
  }, {
    inProduction: 0,
    inReserve: 0,
    inStock: 0,
    inReserveProduction: 0
  });
  const quantityToPull = isBundle(product === null || product === void 0 ? void 0 : (_product$variants = product.variants) === null || _product$variants === void 0 ? void 0 : _product$variants[0]) ? singleBundleTotalQuantitySelector(state, {
    productId: product._id
  }) : getTotalQuantityToPull(product, quantities);
  return _objectSpread({}, product, {
    quantityToPull,
    inStock,
    inReserveQuantity: inReserve,
    inProduction,
    quantityExceeded: isQuantitiesExceeded
  });
};
export const getQuantitiesByVariant = ({
  warehouseQuantities,
  variant
}) => {
  const variantId = String((variant === null || variant === void 0 ? void 0 : variant._id) || '');
  const warehouseLevel = warehouseQuantities[variantId];
  if (!variant || !warehouseLevel) {
    return {
      inStock: 0,
      inProduction: 0,
      inReserve: 0,
      inReserveStock: 0,
      inReserveProduction: 0
    };
  }
  const {
    inStock = 0,
    inReserve = 0,
    inReserveProduction = 0,
    inProduction = 0
  } = warehouseLevel;
  return {
    inStock,
    inReserve,
    inProduction,
    inReserveProduction,
    inReserveStock: inReserve - inReserveProduction
  };
};
export const getAvailableQuantityByVariant = ({
  warehouseQuantities,
  variant,
  isTransfer = false
}) => {
  if (!variant) {
    return 0;
  }
  const {
    inStock,
    inReserve,
    inProduction,
    inReserveStock
  } = getQuantitiesByVariant({
    variant,
    warehouseQuantities
  });
  if (isTransfer) {
    return inStock - inReserveStock;
  }
  return inStock + inProduction - inReserve;
};
export const getTotalQuantitiesByColor = ({
  color,
  warehouseLevel
}) => {
  const inStockTotal = ((color === null || color === void 0 ? void 0 : color.inStock) || 0) + ((warehouseLevel === null || warehouseLevel === void 0 ? void 0 : warehouseLevel.inStock) || 0);
  const inProductionTotal = ((color === null || color === void 0 ? void 0 : color.inProduction) || 0) + ((warehouseLevel === null || warehouseLevel === void 0 ? void 0 : warehouseLevel.inProduction) || 0);
  const inReserveTotal = ((color === null || color === void 0 ? void 0 : color.inReserveQuantity) || 0) + ((warehouseLevel === null || warehouseLevel === void 0 ? void 0 : warehouseLevel.inReserve) || 0);
  const inProductionBreakdown = [...((color === null || color === void 0 ? void 0 : color.inProductionBreakdown) || []), ...((warehouseLevel === null || warehouseLevel === void 0 ? void 0 : warehouseLevel.inProductionTransactions) || [])];
  const inProductionReserve = ((color === null || color === void 0 ? void 0 : color.inProductionReserve) || 0) + ((warehouseLevel === null || warehouseLevel === void 0 ? void 0 : warehouseLevel.inReserveProduction) || 0);
  return {
    inStockTotal,
    inProductionTotal,
    inReserveTotal,
    inProductionBreakdown,
    inProductionReserve
  };
};
export const getInitialBreakdown = ({
  withSizes,
  size
}) => {
  if (withSizes && size) {
    const emptyArray = new Array(size.options.length).fill({
      inReserve: 0,
      available: 0,
      sizeName: ''
    });
    return {
      production: [...emptyArray],
      stock: [...emptyArray]
    };
  }
  return {
    production: [],
    stock: []
  };
};
export const getBreakdownByColor = ({
  breakdownPerColors,
  size,
  warehouseLevel,
  inProductionQuantity,
  withSizes,
  colorValue,
  sizeIndex
}) => {
  const perColorBreakdown = _objectSpread({}, breakdownPerColors[colorValue] || getInitialBreakdown({
    withSizes,
    size
  }));
  if (withSizes) {
    const {
      options
    } = size;
    const productionReserve = (warehouseLevel === null || warehouseLevel === void 0 ? void 0 : warehouseLevel.inReserveProduction) || 0;
    const allReserve = (warehouseLevel === null || warehouseLevel === void 0 ? void 0 : warehouseLevel.inReserve) || 0;
    const stockQuantity = (warehouseLevel === null || warehouseLevel === void 0 ? void 0 : warehouseLevel.inStock) || 0;
    const stockReserve = allReserve - productionReserve;
    perColorBreakdown.production[sizeIndex] = {
      inReserve: productionReserve,
      available: inProductionQuantity - productionReserve,
      sizeName: options[sizeIndex] || ''
    };
    perColorBreakdown.stock[sizeIndex] = {
      inReserve: stockReserve,
      available: stockQuantity - stockReserve,
      sizeName: options[sizeIndex] || ''
    };
  }
  return perColorBreakdown;
};
export const getColorTextOfQuantitiesUsage = ({
  requestedQuantity,
  inStockQuantity,
  inProductionQuantity,
  inProductionReserveQuantity,
  inReserveQuantity,
  isTransfer,
  stockInHandDate
}) => {
  const nonScheduledReserve = inReserveQuantity - inProductionReserveQuantity;
  const stockQuantityWithoutReservation = inStockQuantity - nonScheduledReserve;
  const productionQuantityWithoutReservation = inProductionQuantity - inProductionReserveQuantity;
  if (isTransfer) {
    return {
      productsText: '',
      dateText: '',
      stockOnly: false,
      availableQuantity: stockQuantityWithoutReservation
    };
  }
  const availableQuantity = stockQuantityWithoutReservation + productionQuantityWithoutReservation;
  if (requestedQuantity > 0 && stockQuantityWithoutReservation === 0 && productionQuantityWithoutReservation >= requestedQuantity) {
    return {
      availableQuantity,
      productsText: 'We will ship out your products once they are received in the warehouse.',
      dateText: `The products that you are trying to distribute are in production${stockInHandDate ? ' with an Est In Stock: ' : '.'}`,
      stockOnly: true
    };
  }
  if (requestedQuantity > 0 && requestedQuantity > stockQuantityWithoutReservation && requestedQuantity <= availableQuantity) {
    return {
      availableQuantity,
      productsText: `We will ship out all of your products (in production + in stock) once the in
              production products are received in the warehouse. If you want to send out
              whats is in stock immediately and not wait for the in production products,
              please select only products that are in stock.`,
      dateText: `Some of the products that you are trying to distribute are in production${stockInHandDate ? ' with an Est In Stock: ' : '.'}`,
      stockOnly: false
    };
  }
  return {
    productsText: '',
    dateText: '',
    stockOnly: false,
    availableQuantity
  };
};
export const getDescriptionByColor = ({
  warehouseLevel,
  isTransfer,
  colorValue,
  descriptionPerColors,
  requestedQuantity,
  stockInHandDate
}) => {
  const existedDescription = descriptionPerColors[colorValue];
  if (!warehouseLevel) {
    return existedDescription || {
      availableQuantity: 0,
      productsText: '',
      dateText: '',
      stockOnly: false
    };
  }
  const variantDescription = getColorTextOfQuantitiesUsage({
    requestedQuantity,
    isTransfer,
    inStockQuantity: warehouseLevel.inStock,
    inProductionQuantity: warehouseLevel.inProduction,
    inProductionReserveQuantity: warehouseLevel.inReserveProduction,
    inReserveQuantity: warehouseLevel.inReserve,
    stockInHandDate
  });
  if (!existedDescription) {
    return variantDescription;
  }
  if (!variantDescription.stockOnly && variantDescription.dateText) {
    return variantDescription;
  }
  if (!existedDescription.dateText && variantDescription.dateText) {
    return variantDescription;
  }
  return existedDescription;
};