import { SWAG_BUNDLE_EDIT_REMOVE_PACKAGE_NOT_ALLOWED_POPUP } from 'swag-client-common/constants';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { isBoxType } from 'swag-common/utils/custom-store-product/product-is-box-type.util';
import { findPackagingInBundleContent } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { preselectProducts, toggleSelectionProduct } from '../../products/products.actions';
import { productByProductIdSelector } from '../../products/products.selectors';
import { getEditedSwagBundleContentSelector, selectedSwagBundleSelector } from '../selectors/swag-bundles-selected.selectors';
import { swagBundlesSelectedProductsIdsSelector } from '../swag-bundles.selectors';
import { SWAG_BUNDLES_ADMIN_PREFIX } from './swag-bundles-common.actions';
import { initialSelectSwagBundlesProductVariant, initialSelectSwagBundlesProductsVariantsWithQuantity, unselectSwagBundlesProductVariants } from './swag-bundles-select-variants-with-quantity.actions';
import { changeSwagBundleEditAddonsRemoveApproving } from './swag-bundles.actions';
export const SWAG_BUNDLES_POPULATE_PRODUCTS_FROM_INVENTORY = `${SWAG_BUNDLES_ADMIN_PREFIX}_POPULATE_PRODUCTS_FROM_INVENTORY`;
export const SWAG_BUNDLES_SELECT_PRODUCT = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_PRODUCT`;
export const SWAG_BUNDLES_UNSELECT_PRODUCT = `${SWAG_BUNDLES_ADMIN_PREFIX}_UNSELECT_PRODUCT`;
export const SWAG_BUNDLES_SELECT_NOTECARD = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_NOTECARD`;
export const SWAG_BUNDLES_UNSELECT_NOTECARD = `${SWAG_BUNDLES_ADMIN_PREFIX}_UNSELECT_NOTECARD`;
export const SWAG_BUNDLES_SELECT_PACKAGES = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_PACKAGE`;
export const SWAG_BUNDLES_UNSELECT_PACKAGES = `${SWAG_BUNDLES_ADMIN_PREFIX}_UNSELECT_PACKAGE`;
const getProductActionType = product => {
  const {
    specialProductType
  } = (product === null || product === void 0 ? void 0 : product.swagProductId) || {};
  if (specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD) {
    return SWAG_BUNDLES_SELECT_NOTECARD;
  }
  if (product !== null && product !== void 0 && product.swagProductId && isBoxType(product === null || product === void 0 ? void 0 : product.swagProductId)) {
    return SWAG_BUNDLES_SELECT_PACKAGES;
  }
  return SWAG_BUNDLES_SELECT_PRODUCT;
};
const getUnselectProductActionType = product => {
  const {
    specialProductType
  } = (product === null || product === void 0 ? void 0 : product.swagProductId) || {};
  if (specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD) {
    return SWAG_BUNDLES_UNSELECT_NOTECARD;
  }
  if (product !== null && product !== void 0 && product.swagProductId && isBoxType(product === null || product === void 0 ? void 0 : product.swagProductId)) {
    return SWAG_BUNDLES_UNSELECT_PACKAGES;
  }
  return SWAG_BUNDLES_UNSELECT_PRODUCT;
};
const categorizeProducts = (productIds, getState) => {
  const state = getState();
  const selectedProducts = [];
  const selectedNotecards = [];
  const selectedPackages = [];
  productIds.forEach(productId => {
    const product = productByProductIdSelector(state, {
      productId: String(productId)
    });
    const actionType = getProductActionType(product);
    if (actionType === SWAG_BUNDLES_SELECT_NOTECARD) {
      selectedNotecards.push(productId);
      return;
    }
    if (actionType === SWAG_BUNDLES_SELECT_PACKAGES) {
      selectedPackages.push(productId);
      return;
    }
    selectedProducts.push(productId);
  });
  return {
    selectedProducts,
    selectedNotecards,
    selectedPackages
  };
};
export const addProductWithInformationToSwagBundles = productId => (dispatch, getState) => {
  const state = getState();
  const product = productByProductIdSelector(state, {
    productId: String(productId)
  });
  const actionType = getProductActionType(product);
  dispatch({
    type: actionType,
    payload: productId
  });
  dispatch(toggleSelectionProduct({
    productId,
    isSelected: true
  }));
  if (actionType === SWAG_BUNDLES_SELECT_PRODUCT) {
    dispatch(initialSelectSwagBundlesProductVariant(String(productId)));
  }
};
export const removeProductWithInformationFromSwagBundles = productId => (dispatch, getState) => {
  const state = getState();
  const product = productByProductIdSelector(state, {
    productId: String(productId)
  });
  const actionType = getUnselectProductActionType(product);
  if (actionType === SWAG_BUNDLES_UNSELECT_PACKAGES) {
    var _product$variants$, _packaging$product, _packaging$product2;
    const bundleContent = getEditedSwagBundleContentSelector(state);
    const packaging = findPackagingInBundleContent(bundleContent);
    if (packaging && String(packaging.customProductId) === String(product === null || product === void 0 ? void 0 : (_product$variants$ = product.variants[0]) === null || _product$variants$ === void 0 ? void 0 : _product$variants$._id) && ((_packaging$product = packaging.product) !== null && _packaging$product !== void 0 && _packaging$product.inStockQuantity || packaging !== null && packaging !== void 0 && (_packaging$product2 = packaging.product) !== null && _packaging$product2 !== void 0 && _packaging$product2.inProductionQuantity)) {
      dispatch(showPopup({
        popup: SWAG_BUNDLE_EDIT_REMOVE_PACKAGE_NOT_ALLOWED_POPUP
      }));
      return;
    }
  }
  dispatch({
    type: actionType,
    payload: productId
  });
  dispatch(toggleSelectionProduct({
    productId,
    isSelected: false
  }));
  dispatch(unselectSwagBundlesProductVariants(productId));
  if (actionType !== SWAG_BUNDLES_UNSELECT_PRODUCT) {
    dispatch(changeSwagBundleEditAddonsRemoveApproving(false));
  }
};
export const populateSelectedProductsFromInventory = productIds => (dispatch, getState) => {
  const {
    selectedProducts,
    selectedNotecards,
    selectedPackages
  } = categorizeProducts(productIds, getState);
  dispatch({
    type: SWAG_BUNDLES_POPULATE_PRODUCTS_FROM_INVENTORY,
    payload: {
      selectedProducts,
      selectedNotecards,
      selectedPackages
    }
  });
};
export const preselectEditedBundleProducts = () => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProductsIds = swagBundlesSelectedProductsIdsSelector(state);
    if (selectedProductsIds.length > 0) {
      return;
    }
    const selectedBundle = selectedSwagBundleSelector(state);
    if (!(selectedBundle !== null && selectedBundle !== void 0 && selectedBundle._id)) {
      return;
    }
    const bundleContent = (selectedBundle === null || selectedBundle === void 0 ? void 0 : selectedBundle.variants[0].bundleContent) || [];
    const selectedParentProductsIds = [...new Set(bundleContent.map(({
      product
    }) => String(product === null || product === void 0 ? void 0 : product.parentId)))];
    dispatch(preselectProducts(selectedParentProductsIds));
    dispatch(populateSelectedProductsFromInventory(selectedParentProductsIds));
    dispatch(initialSelectSwagBundlesProductsVariantsWithQuantity());
  };
};