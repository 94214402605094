import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import withStripeElementsMethodsHOC from '../stripe-elements-hoc';
import { stripeCardNumberValidationErrorStringSelector } from '../../../../customer/redux/stripe-form/stripe-form.selectors';
import { setStripeFieldValidationError, setStripeFieldEmpty, setStripeFieldCompleted } from '../../../../customer/redux/stripe-form/stripe-form.actions';
import StripeCardNumberElement from './stripe-element-card-number';
const mapStateToProps = createStructuredSelector({
  errorCardNumberString: stripeCardNumberValidationErrorStringSelector
});
const mapDispatchToProps = dispatch => ({
  setStripeFieldError: error => {
    dispatch(setStripeFieldValidationError(error));
  },
  setStripeFieldEmpty: empty => {
    dispatch(setStripeFieldEmpty(empty));
  },
  setStripeFieldCompleted: complete => {
    dispatch(setStripeFieldCompleted(complete));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(withStripeElementsMethodsHOC(StripeCardNumberElement));