import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import white from 'assets/svg/swag-white.svg';
import black from 'assets/svg/swag-black.svg';
import blue from 'assets/svg/swag-blue.svg';
import limitedEdition from 'assets/svg/swag-limited-edition.svg';
import { SwagLogo as SwagLogoComponent } from './swag-logo';
const colorsMap = {
  white,
  black,
  blue,
  limitedEdition
};
const logoLinkSelector = createSelector((state, props) => props.color, color => colorsMap[color]);
const mapStateToProps = () => createStructuredSelector({
  logoLink: logoLinkSelector,
  imgAlt: () => 'Logo of SWAG.COM'
});
export const SwagLogo = connect(mapStateToProps)(SwagLogoComponent);