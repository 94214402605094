function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { userSelector } from 'swag-client-common/redux/user/user.selectors';
const rootSelector = state => {
  return state.approvalSettings;
};
const getUserSettingLabel = setting => {
  const {
    userId
  } = setting;
  if (userId.firstName) {
    return `${userId.firstName || ''} ${userId.lastName || ''}`;
  }
  return userId.email;
};
export const rootUserSettingSelector = createSelector(rootSelector, state => state.userSettings);
export const rootRoleSettingSelector = createSelector(rootSelector, state => state.roleSettings);
export const userSettingsSelector = createSelector(rootUserSettingSelector, userSelector, (userSettings, user) => userSettings.map(setting => _objectSpread({}, setting, {
  dataId: setting.userId._id,
  label: getUserSettingLabel(setting),
  amount: Math.floor(setting.amount / 100) || 0
})).filter(s => s.userId._id !== user._id));
export const isRoleApprovalEnabledSelector = createSelector(rootSelector, state => state.isRoleApprovalEnabled);
export const isUserApprovalEnabledSelector = createSelector(rootSelector, state => state.isUserApprovalEnabled);