import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { isVideoPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { updateIntercom } from '../../../customer/utils/ui.utils';
import { VideoPopup as VideoPopupComponent } from './video-popup';
const mapStateToProps = createStructuredSelector({
  isOpen: isVideoPopupShownSelector
});
const mapDispatchToProps = dispatch => ({
  onCloseVideoPopup: () => {
    updateIntercom();
    dispatch(hidePopup());
  }
});
export const VideoPopup = connect(mapStateToProps, mapDispatchToProps)(VideoPopupComponent);