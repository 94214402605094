import _getFormValues from "redux-form/es/getFormValues";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { STORE_SETTINGS_REDUX_FORM } from '../redux.constants';
import { HEADER_IMAGES_FIELDS, IMAGE_ACTIONS } from './store.settings.constants';
export const storeSettingsSelector = state => state.storeSettings;
export const headerTextSelector = state => state.storeSettings.headerText;
export const buttonHeaderTextSelector = state => state.storeSettings.headerButtonText;
export const headerImagesSelector = state => state.storeSettings.headerImages;
export const storeTitleSelector = state => state.storeSettings.logoText;
export const logoImageSelector = state => state.storeSettings.logoImage;
export const supportEmailSelector = state => state.storeSettings.supportEmail;
export const instagramLinkSelector = state => state.storeSettings.instagramLink;
export const facebookLinkSelector = state => state.storeSettings.facebookLink;
export const twitterLinkSelector = state => state.storeSettings.twitterLink;
export const youtubeLinkSelector = state => state.storeSettings.youtubeLink;
export const copyrightInformationSelector = state => state.storeSettings.copyrightInformation;
export const hasStoreSelector = state => state.storeSettings.hasStore;
export const storeIdSelector = state => state.storeSettings._id;
export const isStorePublishedSelector = state => state.storeSettings.isPublished;
export const isAwardsStoreSelector = state => state.storeSettings.isAwardsStore;
export const warehousingPaymentMethodSelector = state => state.storeSettings.warehousingPaymentMethod;
export const storeWarehousePaymentMethodSelector = state => state.storeSettings.warehousingPaymentMethod;
export const storeUrlSelector = state => state.storeSettings.url;
export const imageOnlySliderSelector = state => state.storeSettings.imageOnlySlider;
export const isHqTriviaStoreSelector = createSelector(storeUrlSelector, url => url === 'hqtrivia');
export const socialLinksSelector = createSelector(facebookLinkSelector, youtubeLinkSelector, instagramLinkSelector, twitterLinkSelector, (facebookLink, youtubeLink, instagramLink, twitterLink) => {
  return [{
    name: 'facebook',
    link: facebookLink
  }, {
    name: 'youtube',
    link: youtubeLink
  }, {
    name: 'instagram',
    link: instagramLink
  }, {
    name: 'twitter',
    link: twitterLink
  }];
});
export const headerImagesLinksSelector = createSelector(headerImagesSelector, images => {
  const validImages = images.filter(imageData => imageData);
  return images && validImages.length ? validImages.map(({
    url
  }) => url) : [undefined];
});
export const settingsFormSelector = _getFormValues(STORE_SETTINGS_REDUX_FORM);
export const uploadedHeaderImagesSelector = createSelector(settingsFormSelector, headerImagesSelector, (fields, oldHeaderImages) => {
  if (!fields) {
    return null;
  }
  return HEADER_IMAGES_FIELDS.map((fieldName, index) => {
    const value = fields[fieldName];
    const noInputForImage = !value;
    const fileIsUploaded = Array.isArray(value);
    const imageIsDeleted = value === IMAGE_ACTIONS.DELETE_IMAGE;
    let finalValue;
    if (fileIsUploaded) {
      finalValue = value[0] || null;
    }
    if (noInputForImage) {
      finalValue = oldHeaderImages[index] || null;
    }
    if (imageIsDeleted) {
      finalValue = null;
    }
    return {
      field: fieldName,
      value: finalValue
    };
  });
});
export const faviconSelector = state => state.storeSettings.favicon;
export const tabTitleSelector = state => state.storeSettings.tabTitle;
export const uploadedFaviconSelector = createSelector(settingsFormSelector, faviconSelector, (fields, oldFavicon) => {
  if (!fields) {
    return null;
  }
  const value = fields.favicon;
  const noInputForImage = !value;
  const fileIsUploaded = Array.isArray(value);
  const imageIsDeleted = value === IMAGE_ACTIONS.DELETE_IMAGE;
  let finalValue;
  if (fileIsUploaded) {
    finalValue = value[0] || null;
  }
  if (noInputForImage) {
    finalValue = oldFavicon || null;
  }
  if (imageIsDeleted) {
    finalValue = IMAGE_ACTIONS.DELETE_IMAGE;
  }
  return finalValue;
});
const touchedStoreSettingsFieldsSelector = createSelector(settingsFormSelector, fields => {
  return Object.keys(fields).reduce((touchedFields, fieldName) => {
    if (fields[fieldName] !== undefined) {
      return _objectSpread({}, touchedFields, {
        [fieldName]: fields[fieldName]
      });
    }
    return touchedFields;
  }, {});
});
export const storeSettingsFormDataSelector = createSelector(touchedStoreSettingsFieldsSelector, uploadedHeaderImagesSelector, uploadedFaviconSelector, (fields, headerImages, favicon) => {
  if (!fields) {
    return null;
  }
  const {
    primaryColor,
    secondaryColor,
    siteUrl,
    mailDomain,
    supportEmail,
    isPrivate,
    name,
    tabTitle
  } = fields;
  const formData = new FormData();

  // @todo. auto generated. fix this error
  // @ts-ignore
  headerImages.forEach(({
    field,
    value
  }) => {
    formData.append(field, value instanceof File ? value : JSON.stringify(value));
  });
  formData.append('favicon', favicon);
  const brandColors = {
    primary: `#${primaryColor}`,
    secondary: `#${secondaryColor}`
  };
  if (Object.keys(brandColors)) {
    formData.set('brandColors', JSON.stringify(brandColors));
  }
  if ('name' in fields) {
    formData.set('name', name || '');
  }
  if (siteUrl) {
    formData.set('url', siteUrl);
  }
  if (mailDomain) {
    formData.set('mailDomain', mailDomain);
  }
  if ('isPrivate' in fields) {
    formData.set('isPrivate', (!!isPrivate).toString());
  }
  if ('supportEmail' in fields) {
    formData.set('supportEmail', supportEmail);
  }
  if ('tabTitle' in fields) {
    formData.set('tabTitle', tabTitle);
  }
  return formData;
});
export const customDomainFormDataSelector = createSelector(settingsFormSelector, fields => {
  if (!fields) {
    return null;
  }
  const {
    domainName
  } = fields;
  return {
    domainName
  };
});
export const socialLinkToEditSelector = state => state.popupManager.params.linkName;
export const initialSocialLinkToEditSelector = createSelector(socialLinkToEditSelector, storeSettingsSelector, (linkName, storeSettings) => storeSettings[linkName]);