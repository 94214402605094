const OPEN_NEW_WINDOW = 'OPEN_NEW_WINDOW';
export const openNewWindowAction = url => ({
  type: OPEN_NEW_WINDOW,
  payload: url
});
const SCROLL_WINDOW_TOP = 'SCROLL_WINDOW_TOP';
export const scrollWindowToTopAction = () => ({
  type: SCROLL_WINDOW_TOP
});
const SCROLL_WINDOW_TO = 'SCROLL_WINDOW_TO';
export const scrollWindowToRef = documentElement => ({
  type: SCROLL_WINDOW_TO,
  payload: {
    documentElement
  }
});
const WINDOW_CHANGE_HREF = 'WINDOW_CHANGE_HREF';
export const windowChangeHref = url => ({
  type: WINDOW_CHANGE_HREF,
  payload: url
});
export const windowManipulationMiddleware = () => next => action => {
  if (action.type === OPEN_NEW_WINDOW) {
    // @ts-ignore
    window.open(action.payload);
    return;
  }
  if (action.type === SCROLL_WINDOW_TOP) {
    // @ts-ignore
    window.scrollTo({
      top: 0,
      left: 0
    });
    return;
  }
  if (action.type === SCROLL_WINDOW_TO) {
    scrollElement(action.payload.documentElement);
    return;
  }
  if (action.type === WINDOW_CHANGE_HREF) {
    // @ts-ignore
    window.location.href = action.payload;
    return;
  }
  next(action);
};
function scrollElement(documentElement) {
  if (!documentElement) {
    return;
  }
  documentElement.scrollIntoView();
}