import Joi from 'joi';
import { giveawayModelValidationKeys } from '../giveaway-model.validation';
const schema = Joi.object().keys({
  colors: giveawayModelValidationKeys.colors.required()
}).unknown();
function createJOICompatibleResult(error) {
  return {
    error,
    errors: false,
    warning: false,
    value: null
  };
}
export const validateGiveawayColors = ({
  editing: data,
  productsWithColors
}) => {
  if (schema.validate(data).error) {
    return schema.validate(data);
  }
  const isValid = productsWithColors.map(({
    product
  }) => {
    if (!data.colors[product._id] || Array.isArray(data.colors[product._id]) && !data.colors[product._id].length) {
      return false;
    }
    return true;
  }).every(a => !!a);
  return createJOICompatibleResult(!isValid);
};