import { StripeElementsValidationErrorCodes } from 'swag-common/interfaces';
export const getStripeElementsErrorMessage = errorCardNumber => {
  switch (errorCardNumber.code) {
    case StripeElementsValidationErrorCodes.INCOMPLETE_NUMBER:
      {
        return 'Your card number is incomplete';
      }
    case StripeElementsValidationErrorCodes.INVALID_NUMBER:
      {
        return 'Invalid credit card number';
      }
    case StripeElementsValidationErrorCodes.INVALID_EXPIRY_YEAR_PAST:
    case StripeElementsValidationErrorCodes.INCOMPLETE_EXPIRY:
      {
        return 'Invalid date';
      }
    case StripeElementsValidationErrorCodes.INCOMPLETE_CVC:
      {
        return 'Incorrect cvc';
      }
    default:
      {
        return errorCardNumber.message;
      }
  }
};