function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { calculateMaxPossibleQuantityOfBundles } from 'swag-common/utils/swag-bundles/calculate-max-possible-quantity-of-bundles.util';
import { areApparelsInBundlesSelector, bundleSizesSelector, getAllApparelsSpecificSizeInBundleSelector, getAllNonApparelsInBundleSelector } from '../swag-bundles.selectors';
export const commonBundleSizesWithCalculatedQuantitiesSelector = createSelector(bundleSizesSelector, state => state, (_, {
  productId
}) => productId, (bundleSizes, state, productId) => {
  if (!(bundleSizes !== null && bundleSizes !== void 0 && bundleSizes.length)) {
    return [];
  }
  return bundleSizes === null || bundleSizes === void 0 ? void 0 : bundleSizes.map(sizeName => {
    const variantsBySpecificSize = getAllApparelsSpecificSizeInBundleSelector(sizeName)(state, {
      productId
    });
    const calculationData = variantsBySpecificSize.reduce((acc, variant) => {
      if (variant.product) {
        const {
          quantityPerBundle,
          inStockQuantity,
          inReserveBundleQuantity,
          inProductionQuantity,
          inReserveStockQuantity,
          inReserveProductionQuantity,
          inReserveStockBundleQuantity,
          inReserveProductionBundleQuantity,
          colorValue
        } = variant.product;
        acc.push({
          colorValue,
          quantityPerBundle,
          inStockQuantity,
          inStockReservedQuantity: inReserveStockQuantity,
          inReserveBundleQuantity,
          inReserveStockBundleQuantity,
          inReserveProductionBundleQuantity,
          inProductionQuantity,
          inProductionReservedQuantity: inReserveProductionQuantity
        });
      }
      return acc;
    }, []);
    const {
      inProduction,
      inStock,
      inReserveStockBundle,
      inReserveProductionBundle
    } = calculateMaxPossibleQuantityOfBundles(calculationData);
    return {
      sizeName,
      inStockOverall: inStock,
      inProductionOverall: inProduction,
      inReserveStockBundleOverall: inReserveStockBundle,
      inReserveProductionBundleOverall: inReserveProductionBundle
    };
  });
});
export const bundleCalculatedQuantitiesForApparelsInBundleSelector = createSelector(commonBundleSizesWithCalculatedQuantitiesSelector, areApparelsInBundlesSelector, (commonBundleSizesWithCalculatedQuantities, areApparelsInBundles) => {
  if (!areApparelsInBundles) {
    return;
  }
  return commonBundleSizesWithCalculatedQuantities.reduce((acc, {
    inProductionOverall,
    inStockOverall,
    inReserveStockBundleOverall,
    inReserveProductionBundleOverall
  }) => {
    return {
      inStockQuantity: acc.inStockQuantity + inStockOverall,
      inProductionQuantity: acc.inProductionQuantity + inProductionOverall,
      inReserveStockBundleQuantity: ((acc === null || acc === void 0 ? void 0 : acc.inReserveStockBundleQuantity) || 0) + inReserveStockBundleOverall,
      inReserveProductionBundleQuantity: ((acc === null || acc === void 0 ? void 0 : acc.inReserveProductionBundleQuantity) || 0) + inReserveProductionBundleOverall,
      quantityPerBundle: 1
    };
  }, {
    inStockQuantity: 0,
    inProductionQuantity: 0,
    quantityPerBundle: 1,
    inReserveStockBundleQuantity: 0,
    inReserveProductionBundleQuantity: 0
  });
});
export const calculateBundleQuantitiesByContentSelector = createSelector(getAllNonApparelsInBundleSelector, bundleCalculatedQuantitiesForApparelsInBundleSelector, (bundleNonApparelsContent, bundleCalculatedQuantitiesForApparels) => {
  const calculationDataForNonApparels = bundleNonApparelsContent.reduce((acc, variant) => {
    if (variant.product) {
      const {
        quantityPerBundle,
        inStockQuantity,
        inProductionQuantity,
        inReserveStockQuantity,
        inReserveProductionQuantity,
        inReserveBundleQuantity,
        inReserveStockBundleQuantity,
        inReserveProductionBundleQuantity,
        colorValue
      } = variant.product;
      acc.push({
        colorValue,
        quantityPerBundle,
        inStockQuantity,
        inStockReservedQuantity: inReserveStockQuantity,
        inProductionQuantity,
        inProductionReservedQuantity: inReserveProductionQuantity,
        inReserveBundleQuantity,
        inReserveStockBundleQuantity,
        inReserveProductionBundleQuantity
      });
    }
    return acc;
  }, []);
  const calculationDataOverall = [...calculationDataForNonApparels, ...(bundleCalculatedQuantitiesForApparels ? [bundleCalculatedQuantitiesForApparels] : [])];
  return calculateMaxPossibleQuantityOfBundles(calculationDataOverall);
});
export const calculateBundleQuantitiesByContentWithReserveSelector = createSelector(calculateBundleQuantitiesByContentSelector, result => {
  const {
    availableToSend,
    inStock,
    inProduction,
    inReserveStockBundle,
    inReserveProductionBundle
  } = result;
  return _objectSpread({}, result, {
    availableToSend: availableToSend + inReserveStockBundle + inReserveProductionBundle,
    inStock: inStock + inReserveStockBundle,
    inProduction: inProduction + inReserveProductionBundle,
    // in fields above we return all quantity for bundle: available + reserve
    inReserveStockBundle,
    inReserveProductionBundle
  });
});