import { INVENTORY_PURCHASE_FLOWS } from 'swag-common/constants/inventory';
export const getCurrentTotal = ({
  purchaseFlow,
  totalSOA,
  totalSMA,
  totalGiveaway,
  totalWarehousing,
  totalGiveawayNotecard
}) => {
  switch (purchaseFlow) {
    case INVENTORY_PURCHASE_FLOWS.SMA:
      {
        return totalSMA;
      }
    case INVENTORY_PURCHASE_FLOWS.GIVEAWAY:
      {
        return totalGiveaway;
      }
    case INVENTORY_PURCHASE_FLOWS.STORAGE_FEE:
      {
        return totalWarehousing;
      }
    case INVENTORY_PURCHASE_FLOWS.GIVEAWAY_NOTECARD:
      {
        return totalGiveawayNotecard;
      }
    case INVENTORY_PURCHASE_FLOWS.SOA:
    default:
      {
        return totalSOA;
      }
  }
};