import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/custom-store-api/v1/product';
export const ProductsApi = createApi({
  fetchProducts: {
    url: `${baseUrl}/manage`,
    method: 'get'
  },
  updateProducts: {
    url: baseUrl,
    method: 'put'
  },
  updateStoreProducts: {
    url: `${baseUrl}/store-products`,
    method: 'put'
  },
  getPossibleColors: {
    url: `${baseUrl}/:swagProductId/possible-colors`,
    method: 'get'
  },
  getProduct: {
    url: `${baseUrl}/:storeProductId/product`,
    method: 'get'
  },
  getVariantDistributionDetails: {
    url: `${baseUrl}/with-distribution-details`,
    method: 'post'
  },
  setName: {
    url: `${baseUrl}/set-name`,
    method: 'post'
  },
  setProductsFavoritedStatus: {
    url: `${baseUrl}/favorites`,
    method: 'post'
  },
  updateCustomStoreProductVariants: {
    url: `${baseUrl}/custom-store-variants`,
    method: 'put'
  },
  updateInventoryProductVariants: {
    url: `${baseUrl}/inventory-variants`,
    method: 'put'
  },
  updateProductSettings: {
    url: `${baseUrl}/product-settings`,
    method: 'put'
  },
  getWarehouseQuantities: {
    url: `${baseUrl}/warehouse/quantities`,
    method: 'get'
  },
  getAdvancedDistribution: {
    url: `${baseUrl}/warehouse/quantities/distributions`,
    method: 'get'
  }
});