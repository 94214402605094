import { createSelector } from 'reselect';
const rootSelector = state => state.stripeForm;
export const cardInfoSelector = createSelector(rootSelector, state => state.cardInfo);
export const invalidInputsSelector = createSelector(rootSelector, state => state.invalidInputs);
export const stripeErrorSelector = createSelector(rootSelector, state => state.error);
export const isLoadingSelector = createSelector(rootSelector, state => state.isLoading);
export const stripeCardNumberValidationErrorSelector = createSelector(rootSelector, form => {
  return form.cardValidationError.number;
});
export const stripeCardExpiryValidationErrorSelector = createSelector(rootSelector, form => {
  return form.cardValidationError.expiry;
});
export const stripeCardCvcValidationErrorSelector = createSelector(rootSelector, form => {
  return form.cardValidationError.cvc;
});
export const isStripeCardFieldsCompletedSelector = createSelector(rootSelector, form => form.cardValidationCompleted.number && form.cardValidationCompleted.expiry && form.cardValidationCompleted.cvc);
export const isStripeCardHasEmptyFieldSelector = createSelector(rootSelector, form => form.cardValidationEmpty.number || form.cardValidationEmpty.expiry || form.cardValidationEmpty.cvc);
export const stripeCardHasErrorSelector = createSelector(stripeCardNumberValidationErrorSelector, stripeCardExpiryValidationErrorSelector, stripeCardCvcValidationErrorSelector, (numberError, expiryError, cvcError) => {
  return numberError || expiryError || cvcError;
});
export const isStripeElementsValid = createSelector(stripeCardHasErrorSelector, isStripeCardHasEmptyFieldSelector, isStripeCardFieldsCompletedSelector, (cardHasErrors, cardHasEmptyFields, isCardFieldsCompleted) => {
  return !cardHasErrors && !cardHasEmptyFields && isCardFieldsCompleted;
});