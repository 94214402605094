function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export class InventoryUrl {}
_defineProperty(InventoryUrl, "getMainPage", inventoryId => {
  return `/inventory/${inventoryId}`;
});
_defineProperty(InventoryUrl, "getProducts", inventoryId => {
  return `/inventory/${inventoryId}/products`;
});
_defineProperty(InventoryUrl, "getCreateGiveaway", (inventoryId, giveawayType) => {
  return `/inventory/${inventoryId}/giveaway/${giveawayType}/create`;
});
_defineProperty(InventoryUrl, "getGiveawayPage", (inventoryId, giveawayId, step) => {
  return `/inventory/${inventoryId}/giveaway/${giveawayId}/${step}`;
});
_defineProperty(InventoryUrl, "getCreateSMA", inventoryId => {
  return `/inventory/${inventoryId}/send-to-multiple-addresses/create`;
});
_defineProperty(InventoryUrl, "getDraftTableCSVPage", (inventoryId, draftId) => {
  return `/inventory/${inventoryId}/send-to-multiple-addresses/create/${draftId}`;
});
_defineProperty(InventoryUrl, "getSMAPage", (inventoryId, shipoutId) => {
  return `/inventory/${inventoryId}/send-to-multiple-addresses/${shipoutId}`;
});
_defineProperty(InventoryUrl, "getActivitiesPFIPage", inventoryId => {
  return `/inventory/${inventoryId}/activity/pfi`;
});
_defineProperty(InventoryUrl, "getActivitiesCSVPage", inventoryId => {
  return `/inventory/${inventoryId}/activity/csv`;
});
_defineProperty(InventoryUrl, "getActivitiesGiveawayCampaignsPage", inventoryId => {
  return `/inventory/${inventoryId}/activity/giveaway/campaigns`;
});
_defineProperty(InventoryUrl, "getActivitiesGiveawayShipmentsPage", inventoryId => {
  return `/inventory/${inventoryId}/activity/giveaway/shipments`;
});
_defineProperty(InventoryUrl, "getActivitiesGiveawayPayFeesPage", (inventoryId, giveawayId) => {
  return `/inventory/${inventoryId}/activity/giveaway/fees/${giveawayId}`;
});
_defineProperty(InventoryUrl, "getShipoutInvoiceLink", ({
  inventoryId,
  shipoutId,
  baseUrl
}) => {
  return `${baseUrl || ''}/custom-store-api/v1/inventory/${inventoryId}/shipout/${shipoutId}/generate-invoice`;
});
_defineProperty(InventoryUrl, "getGiveawayLinkInvoiceLink", (inventoryId, giveaway, chargeId) => {
  return `/custom-store-api/v1/inventory/${inventoryId}/giveaway/${giveaway}/charge/${chargeId}/generate-invoice`;
});
_defineProperty(InventoryUrl, "getCreditsInvoiceLink", activityId => {
  return `/custom-store-api/v1/inventory/credits/ach-invoice/${activityId}`;
});
_defineProperty(InventoryUrl, "getInvoiceLink", invoiceId => {
  return `/custom-store-api/v1/inventory/invoices/${invoiceId}/pdf`;
});
_defineProperty(InventoryUrl, "getStorageFeePage", inventoryId => {
  return `/inventory/${inventoryId}/storage-fees`;
});
_defineProperty(InventoryUrl, "getGiveawayChargeRecipientsByChargeCSVlink", (inventoryId, giveawayId, chargeId) => {
  return `/custom-store-api/v1/inventory/${inventoryId}/export/giveaway/${giveawayId}/recipients/${chargeId}/link-csv`;
});
_defineProperty(InventoryUrl, "getCreateSwagBundle", inventoryId => {
  return `/inventory/${inventoryId}/bundle/create`;
});
_defineProperty(InventoryUrl, "getEditSwagBundle", (inventoryId, swagBundleId) => {
  return `/inventory/${inventoryId}/bundle/${swagBundleId}/edit`;
});