function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { Price } from 'swag-client-common/utils/price';
import { CREDIT_CARD } from 'swag-common/constants/payment-methods';
import { getDateRangeBasedOnMonth, transformReport, fillReportRecordsWithProductInfo } from 'swag-common/utils/storage-fee';
import { totalPriceAfterPromocodeApplied } from 'swag-common/business-logic/payments/total-price-after-promocode-applied';
import { isSameMonth, dateFormatter, addMonth, addDay, offsetRemover, dateFormatterWithYear } from 'swag-common/utils/date-lib';
import { PAYMENT_METHODS, SWAG_PAYMENT } from 'swag-common/constants/payment-methods';
import { promocodeSelector } from '../payment/payment.selectors';
import { paymentMethodSelector as inventoryPaymentMethodSelector, selectedFullInventorySelector, isSelectedDemoInventorySelector, demoInventoryNotExtendedSelector, currentInventoryNameSelector, currentInventoryCreatedAtSelector } from '../inventory/inventory.selectors';
import { productsListSelector } from '../products/products.selectors';
const warehouseRootSelector = state => state.warehouseInventory;
const reportFeeCalculator = (acc, curr) => acc + curr.adjustments + curr.total;
export const feesDataLoadedSelector = createSelector(warehouseRootSelector, warehouseInventory => warehouseInventory.feesLoaded);
export const inventoryReportsSelector = createSelector(warehouseRootSelector, ({
  reports
}) => reports);
export const pendingReportsSelector = createSelector(warehouseRootSelector, ({
  reports
}) => reports.filter(report => !report.paid && !isSameMonth(offsetRemover(new Date(report.dateRange.from)), new Date())));
export const pendingReportsWithCurrentMonthSelector = createSelector(warehouseRootSelector, ({
  reports
}) => reports.filter(report => !report.paid));
export const pendingTotalFeesWithCurrentMonth = createSelector(pendingReportsWithCurrentMonthSelector, reports => reports.reduce(reportFeeCalculator, 0));
export const commonCardsSelector = createSelector(warehouseRootSelector, warehouse => warehouse.commonCards);
export const isAutopayOnSelector = createSelector(warehouseRootSelector, warehouse => warehouse.isAutopayOn);
export const autopayPaymentMethodSelector = createSelector(warehouseRootSelector, warehouse => warehouse.autopayPaymentMethod);
export const currentDateRangeSelector = createSelector(warehouseRootSelector, warehouse => warehouse.currentDateRange);
export const selectedInventoriesIdsToPaySelector = createSelector(warehouseRootSelector, warehouseInventory => warehouseInventory.selectedToPay);
export const currentInventoryTotalFeesWithoutDiscount = createSelector(pendingReportsSelector, pendingReports => {
  if (pendingReports.length === 1) {
    return pendingReports.reduce(reportFeeCalculator, 0);
  }
  return pendingReports.filter(report => {
    return !isSameMonth(offsetRemover(new Date(report.dateRange.from)), new Date());
  }).reduce(reportFeeCalculator, 0);
});
export const currentMonthPendingFeeSelector = createSelector(warehouseRootSelector, ({
  reports
}) => {
  const report = reports.find(report => isSameMonth(offsetRemover(new Date(report.dateRange.from)), new Date()));
  return report ? reportFeeCalculator(0, report) : 0;
});
export const currentInventoryTotalFeesSelector = createSelector(currentInventoryTotalFeesWithoutDiscount, promocodeSelector, (amount, promocode) => {
  return promocode ? totalPriceAfterPromocodeApplied(amount, promocode) : amount;
});
export const currentInventoryTotalFeesWithCurrentMonth = createSelector(pendingReportsWithCurrentMonthSelector, reports => reports.reduce(reportFeeCalculator, 0));
export const pendingRangeSelector = createSelector(pendingReportsSelector, pending => {
  if (!pending.length) {
    return null;
  }
  if (pending.length === 1) {
    return {
      start: pending[0].dateRange.from,
      end: pending[0].dateRange.to
    };
  }
  return {
    start: pending[0].dateRange.from,
    end: pending[pending.length - 1].dateRange.to
  };
});
export const pendingRangeWithCurrentSelector = createSelector(pendingReportsWithCurrentMonthSelector, pending => {
  if (!pending || !pending.length) {
    return null;
  }
  return {
    start: pending[0].dateRange.from,
    end: pending[pending.length - 1].dateRange.to
  };
});
export const hasPendingPaymentSelector = createSelector(pendingTotalFeesWithCurrentMonth, pendingReportsWithCurrentMonthSelector, fee => fee > 0);
export const isAbleToPayForCurrentFeeSelector = createSelector(pendingReportsSelector, hasPendingPaymentSelector, (reports, hasPendingFee) => {
  if (hasPendingFee) {
    return Boolean(reports.length);
  }
  return false;
});
export const pendingFeesListSelector = createSelector(pendingReportsWithCurrentMonthSelector, currentInventoryCreatedAtSelector, (pendingReports, createdAt) => {
  const creationDate = createdAt ? new Date(createdAt) : null;
  if (!pendingReports.length) {
    return null;
  }
  return pendingReports.sort((prev, next) => new Date(prev.dateRange.from).getTime() - new Date(next.dateRange.from).getTime()).flatMap(({
    total,
    adjustments,
    dateRange: {
      from,
      to
    }
  }) => {
    if (total + adjustments === 0) {
      return [];
    }
    const end = new Date(to);
    const tempFrom = new Date(from);
    const start = creationDate && tempFrom < creationDate && isSameMonth(tempFrom, creationDate) ? new Date(creationDate) : tempFrom;
    const endPeriod = dateFormatterWithYear(end);
    return {
      start,
      end: addDay(end),
      startPeriod: dateFormatter(start),
      endPeriod: endPeriod,
      total: total + adjustments
    };
  });
});
export const selectedInventoriesToPaySelector = createSelector(selectedInventoriesIdsToPaySelector, selected => selected);
export const currentInventoryPaymentMethodSelector = createSelector(selectedFullInventorySelector, inventoryPaymentMethodSelector, (inventory, inventoryPaymentMethod) => {
  if (!inventory || inventory && inventory.isDemo) {
    return CREDIT_CARD;
  }
  return inventoryPaymentMethod || inventory.paymentMethod;
});
export const paymentMethodSelector = createSelector(selectedFullInventorySelector, inventory => inventory === null || inventory === void 0 ? void 0 : inventory.paymentMethod);
export const demoInventoryChartDataSelector = createSelector(demoInventoryNotExtendedSelector, demoInventory => {
  return {
    name: demoInventory === null || demoInventory === void 0 ? void 0 : demoInventory.name,
    data: []
  };
});
export const storageFeeChartDataSelector = createSelector(isSelectedDemoInventorySelector, inventoryReportsSelector, demoInventoryChartDataSelector, currentInventoryNameSelector, (isDemo, reports, demoChart, name) => {
  var _reports$;
  if (!reports || !(reports !== null && reports !== void 0 && reports.length)) {
    return {
      data: [],
      name,
      id: ''
    };
  }
  const additionalReports = [];
  if (reports.length < 3) {
    const {
      dateRange
    } = reports[reports.length - 1];
    const commonAdditionalEmptyReportForCorrectChartView = {
      total: 0,
      cost: Price.formatPrice(0, {
        addComma: false
      }),
      date: addMonth(new Date(dateRange.from)).toISOString(),
      invoiceUrl: '',
      paidBy: '',
      paid: '',
      paymentMethod: ''
    };
    additionalReports.push(commonAdditionalEmptyReportForCorrectChartView);
    if (reports.length === 1) {
      additionalReports.push(_objectSpread({}, commonAdditionalEmptyReportForCorrectChartView, {
        date: addMonth(new Date(additionalReports[0].date)).toISOString()
      }));
    }
  }
  const chartDataReports = reports.sort((prev, next) => new Date(prev.dateRange.from).getTime() - new Date(next.dateRange.from).getTime()).map(({
    invoiceUrl,
    paid,
    total,
    adjustments,
    dateRange,
    paidBy,
    paymentMethod
  }) => {
    let paymentMethodLabel = '';
    if (paymentMethod === SWAG_PAYMENT) {
      paymentMethodLabel = 'SWAG';
    } else {
      const method = PAYMENT_METHODS.find(method => method.code === paymentMethod);
      paymentMethodLabel = method ? method.name : '';
    }
    return {
      total: total + adjustments,
      cost: Price.formatPrice(total + adjustments, {
        addComma: false
      }),
      invoiceUrl: invoiceUrl || '',
      paidBy: paidBy || '',
      date: new Date(dateRange.from).toISOString(),
      paid: paid || '',
      paymentMethod: paymentMethodLabel
    };
  });
  return {
    id: (_reports$ = reports[0]) === null || _reports$ === void 0 ? void 0 : _reports$.inventoryId,
    name: name,
    data: [...chartDataReports, ...additionalReports]
  };
});
export const isAutopayPaymentMethodSelector = createSelector(autopayPaymentMethodSelector, autopayPaymentMethod => Boolean(autopayPaymentMethod));
export const currentReportSelector = createSelector(currentDateRangeSelector, inventoryReportsSelector, (range, reports) => {
  const report = reports.find(report => report.range === range);
  return transformReport(report);
});
export const reportProductsTotalSelector = createSelector(currentReportSelector, currentProductsReport => {
  if (currentProductsReport) {
    const {
      total
    } = currentProductsReport;
    return (total / 100).toFixed(2);
  }
  return '0';
});
export const isStubShownSelector = createSelector(currentReportSelector, currentProductsReport => {
  return !currentProductsReport || !Object.keys(currentProductsReport.report).length;
});
export const reportProductsAdjustmentSelector = createSelector(currentReportSelector, report => {
  let num = 0;
  if (report) {
    num = Math.abs(report.adjustment / 100);
  }
  return num.toFixed(2);
});
export const reportProductsAdjustmentTypeSelector = createSelector(currentReportSelector, report => {
  if (report !== null && report !== void 0 && report.adjustmentType) {
    return String(report === null || report === void 0 ? void 0 : report.adjustmentType);
  }
  return '';
});
export const reportProductsSubTotalSelector = createSelector(currentReportSelector, report => {
  if (report !== null && report !== void 0 && report.subtotal) {
    return report === null || report === void 0 ? void 0 : report.subtotal.toFixed(2);
  }
  return '';
});
export const popupReportHeaderSelector = createSelector(currentDateRangeSelector, rangeName => {
  const [year, month] = rangeName.split('-');
  return getDateRangeBasedOnMonth(+month, +year);
});
export const reportProductsSelector = createSelector(currentReportSelector, productsListSelector, (currentProductsReport, inventoryProducts) => {
  if (currentProductsReport && currentProductsReport.report) {
    const filled = fillReportRecordsWithProductInfo(currentProductsReport, inventoryProducts);
    return Object.keys(currentProductsReport === null || currentProductsReport === void 0 ? void 0 : currentProductsReport.report).map(id => ({
      id,
      records: filled.filter(record => record.productId === id)
    })).filter(({
      records
    }) => records.length);
  }
  return [];
});
export const shouldPayButtonBeDisabledSelector = createSelector(promocodeSelector, isSelectedDemoInventorySelector, currentInventoryTotalFeesSelector, isAbleToPayForCurrentFeeSelector, (isFreePromocode, isDemo, futureAmountToPay, isAbleToPay) => {
  const shouldDisableBasedOnAmount = isFreePromocode ? false : !futureAmountToPay;
  const shouldBeDisabled = shouldDisableBasedOnAmount || !isAbleToPay;
  return shouldBeDisabled;
});