function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import getProductQuantityForBox from 'swag-common/business-logic/products/get-product-quantity-for-box.logic';
import { ORDER_MORE_BOX_POPUP } from 'swag-client-common/constants';
import { selectedProductSelector } from '../order-more/order-more.selectors';
import { distributiveQuantityBasedOnSizeSet } from '../../../customer/redux/boxes/boxes.utils';
import { boxContentQuantitiesSelector, boxContentSelector, boxProductsSelector, boxSizeSetSelector, isDistributedEvenlySelector, boxContentQuantitiesPerBoxSelector } from './order-more-box.selectors';
import { getProductKey } from './order-more-box.utils';
const PREFIX = 'ORDER_MORE_BOX_';
export const CHANGE_BOX_QUANTITY = `${PREFIX}CHANGE_BOX_QUANTITY`;
export const changeBoxQuantity = boxQuantity => (dispatch, getState) => {
  const state = getState();
  const sizeSet = boxSizeSetSelector(state);
  const boxContent = boxContentSelector(state);
  const isDistributedEvenly = isDistributedEvenlySelector(state);
  const previousContentQuantities = boxContentQuantitiesSelector(state);
  const contentQuantitiesPerBox = boxContentQuantitiesPerBoxSelector(state);
  const contentQuantities = boxContent.reduce((all, contentItem, position) => {
    const {
      product: {
        colorVariantId
      },
      quantity
    } = contentItem;
    const swagProduct = contentItem.product.swagProductId;
    const colorVariant = swagProduct.variants.find(variant => variant.variantId === colorVariantId);
    const id = (colorVariant === null || colorVariant === void 0 ? void 0 : colorVariant.variantId) || swagProduct._id;
    const key = getProductKey(String(id), position);
    const productQuantityInBox = contentQuantitiesPerBox[key] || contentQuantitiesPerBox[swagProduct._id] || quantity;
    const productQuantityToOrder = getProductQuantityForBox(_objectSpread({}, swagProduct, {
      quantity: productQuantityInBox
    }), boxQuantity);
    if (!swagProduct.productSize || !swagProduct.hasSize) {
      return _objectSpread({}, all, {
        [key]: [productQuantityToOrder]
      });
    }
    if (swagProduct.sizeDependentPrice) {
      const selectedProduct = boxContent.find(({
        product: variant
      }, index) => variant.swagProductId._id === swagProduct._id && index === position).product;
      const {
        sizeSettings,
        sizeIndex
      } = selectedProduct;
      const count = sizeSettings && Number.isInteger(sizeSettings.count) ? sizeSettings.count : sizeIndex;
      const quantities = new Array(count).fill(0);
      quantities[sizeIndex] = productQuantityToOrder;
      return _objectSpread({}, all, {
        [key]: quantities
      });
    }
    if (isDistributedEvenly) {
      return _objectSpread({}, all, {
        [key]: distributiveQuantityBasedOnSizeSet(_objectSpread({}, swagProduct, {
          quantity: productQuantityInBox
        }), sizeSet, boxQuantity)
      });
    }
    return _objectSpread({}, all, {
      [key]: previousContentQuantities[key]
    });
  }, {});
  dispatch({
    type: CHANGE_BOX_QUANTITY,
    payload: {
      boxQuantity,
      contentQuantities
    }
  });
};
export const CHANGE_ITEM_IN_BOX_QUANTITY = `${PREFIX}CHANGE_ITEM_IN_BOX_QUANTITY`;
export const changeQuantityPerBox = (variantId, position, quantity) => (dispatch, getState) => {
  const state = getState();
  const contentQuantitiesPerBox = boxContentQuantitiesPerBoxSelector(state);
  dispatch({
    type: CHANGE_ITEM_IN_BOX_QUANTITY,
    payload: _objectSpread({}, contentQuantitiesPerBox, {
      [getProductKey(variantId, position)]: quantity
    })
  });
};
export const startBoxReorder = () => dispatch => {
  dispatch(prefillWithMinQuant());
  dispatch(showPopup({
    popup: ORDER_MORE_BOX_POPUP
  }));
};
export const RESET_REMOVED_PRODUCTS = `${PREFIX}RESET_REMOVED_PRODUCTS`;
export const prefillWithMinQuant = () => (dispatch, getState) => {
  const state = getState();
  const customProduct = selectedProductSelector(state);
  if (customProduct) {
    const {
      minQuant
    } = customProduct.swagProductId;
    dispatch({
      type: RESET_REMOVED_PRODUCTS
    });
    return dispatch(changeBoxQuantity(minQuant));
  }
};
export const CHANGE_SIZE_QUANTITY = `${PREFIX}CHANGE_SIZE_QUANTITY`;
export const changeSizeQuantities = (variantId, position, size, quantity) => (dispatch, getState) => {
  const state = getState();
  const products = boxProductsSelector(state);
  const quantities = boxContentQuantitiesSelector(state);
  const root = products[position];
  const key = getProductKey(variantId, position);
  const {
    productSize,
    hasSize,
    sizeDependentPrice
  } = root;
  const productQuantity = [...quantities[key]];
  const index = productSize && hasSize && !sizeDependentPrice ? productSize.options.findIndex(option => option === size) : size;
  productQuantity[index] = quantity;
  dispatch({
    type: CHANGE_SIZE_QUANTITY,
    payload: {
      contentQuantities: _objectSpread({}, quantities, {
        [key]: productQuantity
      })
    }
  });
};
export const TOGGLE_PRODUCT_IN_BOX = `${PREFIX}TOGGLE_PRODUCT_IN_BOX`;
export const toggleProductInBox = (variantId, position) => ({
  type: TOGGLE_PRODUCT_IN_BOX,
  payload: {
    productId: getProductKey(variantId, position)
  }
});