import { createSelector } from 'reselect';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { isBoxType } from 'swag-common/utils/custom-store-product/product-is-box-type.util';
import { hasNotecardInBundle, hasPackagingInBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { SwagBundleAddonTypeInEditApprovePopupEnum } from '../../../interfaces/swag-bundles.interface';
import { getSwagBundleCombinedProductQuantities, getSwagBundleQuantitiesByParentIdAndColor, getSwagBundleQuantitiesDiffByParentIdAndColor } from '../../../utils/swag-bundle-group-content';
import { getSwagBundleContentByProductIdSelector, swagBundleGetAllVariantsGroupedByParentIdWithColorsSelector, swagBundlesEditingSelector, swagBundlesSelectedNotecardsSelector, swagBundlesSelectedPackagesSelector, swagBundlesSelectedSelector } from '../swag-bundles.selectors';
import { isBundleEditPageUrlSelector } from '../../routing/routing.selectors';
import { allBundlesInInventorySelector, selectedProductsSelector } from '../../products/products.selectors';
import { swagBundlesLimitingLoadedSelector, swagBundlesSelectedProductsVariantsWithQuantitySelector } from './swag-bundles-selected-variants-with-quantity.selectors';
export const selectedSwagBundleSelector = createSelector(swagBundlesSelectedSelector, ({
  item
}) => item);
export const selectedSwagBundleIdSelector = createSelector([selectedSwagBundleSelector], item => item === null || item === void 0 ? void 0 : item._id);
export const getEditedSwagBundleContentSelector = createSelector(state => state, selectedSwagBundleIdSelector, (state, productId) => getSwagBundleContentByProductIdSelector(state, {
  productId: String(productId)
}));
export const isSwagBundleEditSelector = createSelector([isBundleEditPageUrlSelector], isBundleEditPage => isBundleEditPage);
export const isLoadedSwagBundleSelector = createSelector(swagBundlesSelectedSelector, ({
  isLoaded
}) => isLoaded);
export const isApprovedEditBundleRemoveAddonsSelector = createSelector(swagBundlesSelectedSelector, ({
  isApprovedEditBundleRemoveAddons
}) => isApprovedEditBundleRemoveAddons);
export const hasSwagBundleNameChangedSelector = createSelector([swagBundlesSelectedSelector, swagBundlesEditingSelector], ({
  item
}, editing) => (item === null || item === void 0 ? void 0 : item.name) !== editing.name);
export const getSwagBundleCategorizedItemsSelector = createSelector([swagBundlesSelectedSelector], ({
  item
}) => {
  var _variant$bundleConten;
  const [variant] = (item === null || item === void 0 ? void 0 : item.variants) || [];
  const output = {
    notecard: null,
    packaging: null,
    products: []
  };
  variant === null || variant === void 0 ? void 0 : (_variant$bundleConten = variant.bundleContent) === null || _variant$bundleConten === void 0 ? void 0 : _variant$bundleConten.forEach(bundleItem => {
    const {
      product
    } = bundleItem || {};
    const {
      specialProductType
    } = (product === null || product === void 0 ? void 0 : product.swagProductId) || {};
    if (specialProductType === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD) {
      output.notecard = bundleItem;
    } else if (product !== null && product !== void 0 && product.swagProductId && isBoxType(product === null || product === void 0 ? void 0 : product.swagProductId)) {
      output.packaging = bundleItem;
    } else {
      output.products.push(bundleItem);
    }
  });
  return output;
});
export const getSwagBundleAllItemsSelector = createSelector([swagBundlesSelectedSelector], ({
  item
}) => {
  const [variant] = (item === null || item === void 0 ? void 0 : item.variants) || [];
  return (variant === null || variant === void 0 ? void 0 : variant.bundleContent) || [];
});
export const hasSwagBundleSelectedNotecardChangedSelector = createSelector([getSwagBundleCategorizedItemsSelector, swagBundlesLimitingLoadedSelector, swagBundlesSelectedNotecardsSelector], ({
  notecard
}, bundleLimitingLoaded, [selectedNotecard]) => {
  var _notecard$product;
  if (!notecard && !selectedNotecard || !bundleLimitingLoaded) {
    return false;
  }
  return String(notecard === null || notecard === void 0 ? void 0 : (_notecard$product = notecard.product) === null || _notecard$product === void 0 ? void 0 : _notecard$product.parentId) !== String(selectedNotecard);
});
export const hasSwagBundleSelectedPackagingChangedSelector = createSelector([getSwagBundleCategorizedItemsSelector, swagBundlesLimitingLoadedSelector, swagBundlesSelectedPackagesSelector], ({
  packaging
}, bundleLimitingLoaded, [selectedPackaging]) => {
  var _packaging$product;
  if (!packaging && !selectedPackaging || !bundleLimitingLoaded) {
    return false;
  }
  return String(packaging === null || packaging === void 0 ? void 0 : (_packaging$product = packaging.product) === null || _packaging$product === void 0 ? void 0 : _packaging$product.parentId) !== String(selectedPackaging);
});
export const getEditSwagBundleGroupedProductVariantsSelector = createSelector(selectedSwagBundleSelector, state => state, (selectedBundle, state) => {
  const productId = String(selectedBundle === null || selectedBundle === void 0 ? void 0 : selectedBundle._id);
  return swagBundleGetAllVariantsGroupedByParentIdWithColorsSelector(state, {
    productId
  });
});
export const getEditSwagBundleGroupedProductsColorsWithQuantitySelector = createSelector(getEditSwagBundleGroupedProductVariantsSelector, groupedProductVariants => Object.entries(groupedProductVariants).reduce((result, [parentId, variants]) => {
  result[parentId] = variants.map(variant => ({
    colorValue: variant.colorValue || '',
    quantity: variant.quantityPerBundle
  }));
  return result;
}, {}));
export const getSwagBundleSelectedProductsDifferencesSelector = createSelector([getSwagBundleCategorizedItemsSelector, swagBundlesLimitingLoadedSelector, swagBundlesSelectedProductsVariantsWithQuantitySelector], ({
  products
}, bundleLimitingLoaded, editedProductsWithQuantities) => {
  if (!bundleLimitingLoaded) {
    return {};
  }
  const currentProductsWithQuantities = getSwagBundleQuantitiesByParentIdAndColor(products);
  const diffProductsWithQuantities = getSwagBundleQuantitiesDiffByParentIdAndColor(currentProductsWithQuantities, editedProductsWithQuantities);
  return diffProductsWithQuantities;
});
export const isSwagBundleSelectedProductsAndQuantitiesDifferentSelector = createSelector([getSwagBundleCategorizedItemsSelector, swagBundlesLimitingLoadedSelector, swagBundlesSelectedProductsVariantsWithQuantitySelector], ({
  products
}, bundleLimitingLoaded, editedProductsWithQuantities) => {
  if (!bundleLimitingLoaded) {
    return false;
  }
  const currentProductsWithQuantities = getSwagBundleQuantitiesByParentIdAndColor(products);
  const currentQuantities = getSwagBundleCombinedProductQuantities(currentProductsWithQuantities);
  const editedQuantities = getSwagBundleCombinedProductQuantities(editedProductsWithQuantities);
  const anyDiff = Object.keys(currentQuantities).some(currentParentId => {
    return !(editedQuantities[currentParentId] && editedQuantities[currentParentId] === currentQuantities[currentParentId]);
  });
  if (anyDiff) {
    return true;
  }
  return Object.keys(editedQuantities).some(editedParentId => !currentQuantities[editedParentId]);
});
export const isEditSwagBundleContentProductsSimilarSelector = createSelector([getSwagBundleCategorizedItemsSelector, swagBundlesSelectedProductsVariantsWithQuantitySelector], ({
  products
}, editedProductsWithQuantities) => {
  const currentProductsWithQuantities = getSwagBundleQuantitiesByParentIdAndColor(products);
  const currentProductsKeys = Object.keys(currentProductsWithQuantities);
  const editedProductsKeys = Object.keys(editedProductsWithQuantities);
  return currentProductsKeys.every(key => editedProductsKeys.includes(key)) && currentProductsKeys.length === editedProductsKeys.length;
});
export const isSwagBundleSelectedProductsChangedSelector = createSelector([getSwagBundleSelectedProductsDifferencesSelector], diffProductsWithQuantities => {
  return Object.keys(diffProductsWithQuantities).some(parentId => {
    var _diffProductsWithQuan;
    return (_diffProductsWithQuan = diffProductsWithQuantities[parentId]) === null || _diffProductsWithQuan === void 0 ? void 0 : _diffProductsWithQuan.length;
  });
});
export const isSwagBundleSelectedProductsChangedWithoutQuantitiesSelector = createSelector([getSwagBundleSelectedProductsDifferencesSelector], diffProductsWithQuantities => {
  return Object.keys(diffProductsWithQuantities).some(parentId => {
    if (!diffProductsWithQuantities[parentId].length || diffProductsWithQuantities[parentId].some(item => item.quantity < 1)) {
      return true;
    }
    return false;
  });
});
export const getTypeOfRemovedAddonsFromEditedBundleSelector = createSelector(getEditedSwagBundleContentSelector, swagBundlesSelectedPackagesSelector, swagBundlesSelectedNotecardsSelector, (editedBundleContent, selectedPackages, selectedNotecards) => {
  const isPackagingInEditedBundle = hasPackagingInBundle(editedBundleContent);
  const isNotecardInEditedBundle = hasNotecardInBundle(editedBundleContent);
  const isPackagingRemoved = isPackagingInEditedBundle && !selectedPackages.length;
  const isNotecardRemoved = isNotecardInEditedBundle && !selectedNotecards.length;
  if (isPackagingRemoved && isNotecardRemoved) {
    return SwagBundleAddonTypeInEditApprovePopupEnum.BothAddons;
  }
  if (isPackagingRemoved) {
    return SwagBundleAddonTypeInEditApprovePopupEnum.Packaging;
  }
  if (isNotecardRemoved) {
    return SwagBundleAddonTypeInEditApprovePopupEnum.Notecard;
  }
});
export const selectedProductsAlreadyInBundlesSelector = createSelector(selectedProductsSelector, allBundlesInInventorySelector, selectedSwagBundleSelector, (selectedProducts, allBundlesInInventory, editingBundle) => {
  return selectedProducts.filter(selectedProduct => {
    const notDeletedBundlesWithoutEditingOne = allBundlesInInventory.filter(bundle => {
      const bundleVariant = bundle.variants[0];
      const editingBundleVariant = editingBundle === null || editingBundle === void 0 ? void 0 : editingBundle.variants[0];
      if (!bundleVariant || !editingBundleVariant) {
        return true;
      }
      return !bundleVariant.bundleDeletedAt && String(bundleVariant._id) !== String(editingBundleVariant._id);
    });
    const thisProductInBundle = notDeletedBundlesWithoutEditingOne.find(({
      variants
    }) => {
      const bundle = variants[0];
      const {
        bundleContent
      } = bundle;
      return bundleContent === null || bundleContent === void 0 ? void 0 : bundleContent.find(({
        product
      }) => (product === null || product === void 0 ? void 0 : product.parentId) === selectedProduct.parentId);
    });
    return !!thisProductInBundle;
  });
});