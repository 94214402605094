import dateFormat from 'dateformat';
import { addDays, isSameDateOrAfter } from 'swag-common/utils/date-lib';
import { PAYMENT_STATUSES } from 'swag-common/constants/custom-store.constants';
import { RECEIVER_STATUSES, RECEIVER_STATUSES_SEQUENCE, SHIPOUT_TYPES } from 'swag-common/constants/custom-store-shipouts.constants';
import { checkIsAllRecipientsShippedOrDelivered } from 'swag-common/business-logic/custom-store-shipout/check-is-all-recipients-shipped-or-delivered';
import { isPaymentCC, isPaymentCredits } from '../../utils/order';
function isSupportedMethod(paymentMethod) {
  return isPaymentCC(paymentMethod) || isPaymentCredits(paymentMethod);
}
function formatDate(date) {
  return dateFormat(new Date(date), 'mmm dS');
}
export function getShipoutShippedDate(shipout, isAllRecipientsShippedOrDelivered = false) {
  const {
    scheduledDate,
    paymentMethod,
    paidAt,
    confirmedAt,
    paymentStatus,
    receivers
  } = shipout;
  const paymentDate = isSupportedMethod(paymentMethod) ? paidAt : confirmedAt;
  const isPaid = isSupportedMethod(paymentMethod) ? !!paidAt : paymentStatus === PAYMENT_STATUSES.PAID;
  const hasValidScheduledDate = scheduledDate ? isSameDateOrAfter(scheduledDate, new Date()) : false;
  if (!isPaid && !hasValidScheduledDate) {
    return null;
  }
  if (receivers[0] && isAllRecipientsShippedOrDelivered) {
    var _earliestReceiver$shi;
    const earliestReceiver = receivers.reduce((pre, cur) => {
      var _pre$shipment, _cur$shipment;
      return new Date((pre === null || pre === void 0 ? void 0 : (_pre$shipment = pre.shipment) === null || _pre$shipment === void 0 ? void 0 : _pre$shipment.shippedAt) || Date.now()).getTime() > new Date((cur === null || cur === void 0 ? void 0 : (_cur$shipment = cur.shipment) === null || _cur$shipment === void 0 ? void 0 : _cur$shipment.shippedAt) || Date.now()).getTime() ? cur : pre;
    });
    const shippedAt = earliestReceiver === null || earliestReceiver === void 0 ? void 0 : (_earliestReceiver$shi = earliestReceiver.shipment) === null || _earliestReceiver$shi === void 0 ? void 0 : _earliestReceiver$shi.shippedAt;
    if (!shippedAt) {
      return null;
    }
    return new Date(shippedAt).toISOString();
  }
  if (hasValidScheduledDate && scheduledDate) {
    return new Date(scheduledDate).toISOString();
  }
  if (!paymentDate) {
    return null;
  }
  return new Date(paymentDate).toISOString();
}
export function getShipoutShippedDateFormatted(shipout, isAllRecipientsShippedOrDelivered = false, withFormatting = true) {
  const {
    scheduledDate,
    paymentMethod,
    paidAt,
    paymentStatus,
    type
  } = shipout;
  const isPaid = isSupportedMethod(paymentMethod) ? !!paidAt : paymentStatus === PAYMENT_STATUSES.PAID;
  const hasValidScheduledDate = scheduledDate ? isSameDateOrAfter(scheduledDate, new Date()) : false;
  const date = getShipoutShippedDate(shipout, isAllRecipientsShippedOrDelivered);
  if (date && (isAllRecipientsShippedOrDelivered || hasValidScheduledDate)) {
    if (!withFormatting) {
      return date;
    }
    return dateFormat(date, 'mmm dS');
  }
  if (isPaid) {
    return getRangeOfDaysToShipAfter(date, type, withFormatting);
  }
  return null;
}
export function getRangeOfDaysToShipAfter(paidAt, type, withFormatting) {
  let startDayOfRange;
  let endDayOfRange;
  if (!paidAt) {
    return '';
  }
  const date = new Date(paidAt);
  const isPaidAfterAfternoon = date.getHours() > 15;
  if (type === SHIPOUT_TYPES.PULL_FROM_INVENTORY) {
    startDayOfRange = isPaidAfterAfternoon ? addDays(date, 1) : date;
    endDayOfRange = addDays(startDayOfRange, 2);
  } else {
    startDayOfRange = addDays(date, 1);
    endDayOfRange = isPaidAfterAfternoon ? addDays(date, 5) : addDays(date, 4);
  }
  if (!withFormatting) {
    return `${startDayOfRange} - ${endDayOfRange}`;
  }
  return `${dateFormat(startDayOfRange, 'mmm dS')} - ${dateFormat(endDayOfRange, 'mmm dS')}`;
}
export function getCountOfReceiversByStatuses({
  receivers
}) {
  const counters = {
    deliveredReceiversNumber: 0,
    shippedReceiversNumber: 0,
    shipmentCalculationErrorReceiversNumber: 0
  };
  receivers.forEach(({
    status
  }) => {
    if (status === RECEIVER_STATUSES.DELIVERED) {
      counters.deliveredReceiversNumber++;
    }
    if (RECEIVER_STATUSES_SEQUENCE.indexOf(status) >= RECEIVER_STATUSES_SEQUENCE.indexOf(RECEIVER_STATUSES.SHIPPED)) {
      counters.shippedReceiversNumber++;
    }
    if (status === RECEIVER_STATUSES.SHIPMENT_CALCULATION_ERROR) {
      counters.shipmentCalculationErrorReceiversNumber++;
    }
  });
  return counters;
}
export function getShippedDate(shippingDate) {
  const {
    min,
    max
  } = shippingDate;
  if (!max || !min) {
    return null;
  }
  if (max === min) {
    return formatDate(max);
  }
  return `${formatDate(min)} - ${formatDate(max)}`;
}
export function getShippingMinAndMax(shippedAt) {
  var _dates$;
  const shippingDate = {
    min: null,
    max: null
  };
  if (!shippedAt) {
    return shippingDate;
  }
  const dates = (shippedAt === null || shippedAt === void 0 ? void 0 : shippedAt.split('-')) || [];
  if (dates.length === 0) {
    return shippingDate;
  }
  const startDate = (_dates$ = dates[0]) === null || _dates$ === void 0 ? void 0 : _dates$.trim();
  const endDate = dates[1].trim();
  if (dates.length > 1) {
    shippingDate.min = new Date(startDate);
    shippingDate.max = new Date(endDate);
    return shippingDate;
  }
  shippingDate.min = new Date(startDate);
  shippingDate.max = new Date(startDate);
  return shippingDate;
}
export function getShippingDateRange(shipout) {
  const {
    receivers
  } = shipout;
  const counters = getCountOfReceiversByStatuses({
    receivers
  });
  const isAllRecipientsShippedOrDelivered = checkIsAllRecipientsShippedOrDelivered({
    totalRecipients: receivers.length,
    delivered: counters.deliveredReceiversNumber,
    shipped: counters.shippedReceiversNumber
  });
  const shippedAt = getShipoutShippedDateFormatted(shipout, isAllRecipientsShippedOrDelivered, false);
  return getShippingMinAndMax(shippedAt);
}