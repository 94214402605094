import _isEmpty2 from "lodash/isEmpty";
import _uniq2 from "lodash/uniq";
import _intersection2 from "lodash/intersection";
import _get from "lodash/get";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { v4 as uuidv4 } from 'uuid';
import { spreadQuantities } from 'swag-common/utils/product/product.utils';
import { FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME, FULL_DESIGN_PRODUCT_DEFAULT_COLOR_VALUE } from 'swag-common/constants/custom-store.constants';
import getProductQuantityForBox from 'swag-common/business-logic/products/get-product-quantity-for-box.logic';
import { OTHER_SUBCATEGORY_NAME } from '../../../common/constants';
export const setBoxItemsColors = products => {
  return products.reduce((acc, item) => {
    if (item.variants && item.variants.length) {
      const availableVariant = item.variants.find(variant => !variant.isHidden);
      if (!availableVariant) {
        return acc;
      }
      return _objectSpread({}, acc, {
        [item._id]: availableVariant.color
      });
    }
    return acc;
  }, {});
};
export const getBoxItemsColors = products => products.reduce((set, product) => {
  const {
    prod,
    variants
  } = product;
  if (prod && prod.variants && !prod.variants.length || !variants.length) {
    return set;
  }
  return _objectSpread({}, set, {
    [prod._id]: prod.variants[variants[0].variantIndex || 0].color
  });
}, {});
export const distributiveQuantityBasedOnSizeSet = (product, set, generalQuantity, sizeIndex = null) => {
  const {
    quantity,
    minQuant,
    productSize,
    size,
    hasSize,
    quantIncrement
  } = product;
  const productQuantityWithCustomCount = getProductQuantityForBox(product, generalQuantity);
  const initialProduct = {
    minQuant,
    quantIncrement,
    quantity: 1
  };
  const productQuantityWithInitialCount = getProductQuantityForBox(initialProduct, generalQuantity);
  const quantityForDistribution = productQuantityWithCustomCount > productQuantityWithInitialCount ? generalQuantity : productQuantityWithInitialCount;
  if (typeof sizeIndex === 'number') {
    const sizeOptions = size ? size.options : productSize.options;
    const spreadedQuantities = spreadQuantities({
      quantity: quantityForDistribution,
      itemsQuantityToSpread: sizeOptions.length,
      allQuantityTo: sizeIndex,
      sizeOptions
    });
    return spreadedQuantities.map(i => i * quantity);
  }
  const distributedQuantity = spreadQuantities({
    quantity: quantityForDistribution,
    itemsQuantityToSpread: Object.keys(set).length,
    sizeOptions: Object.keys(set)
  });
  if (!hasSize && !size) {
    return [productQuantityWithCustomCount];
  }
  return productSize.options.map(option => {
    if (set[option]) {
      const index = Object.keys(set).findIndex(key => key === option);
      return distributedQuantity[index] * quantity;
    }
    return 0;
  });
};
export const groupSubcategoriesByRootCategories = (root, subCategories) => {
  const categories = subCategories.reduce((list, next) => {
    const item = root.find(({
      _id
    }) => _id === next.categoryId);
    if (!item) {
      return list;
    }
    const subCategory = {
      name: item.name,
      categoryId: item._id,
      order: item.order
    };
    if (item.parent_id) {
      const parent = root.find(({
        _id
      }) => _id === item.parent_id);
      const index = list.findIndex(({
        categoryId
      }) => categoryId === item.parent_id);
      if (index !== -1) {
        const subCategories = list[index].subCategories || [];
        const updatedList = [...list];
        updatedList[index].subCategories = [...subCategories, subCategory].sort((a, b) => a.order - b.order);
        return updatedList;
      }
      return [...list, {
        name: parent.name,
        categoryId: parent._id,
        subCategories: [subCategory],
        order: parent.order
      }];
    }
    const rootIndex = list.findIndex(c => c.categoryId === next.categoryId);
    const itemSubcategory = rootIndex !== -1 ? list[rootIndex].subCategories : [];
    if (!item.parent_id && next.customBoxProductsCount && !!item.subcategories.length && rootIndex !== -1) {
      const category = _objectSpread({}, list[rootIndex], {
        subCategories: [...list[rootIndex].subCategories, {
          name: OTHER_SUBCATEGORY_NAME,
          categoryId: item._id,
          order: 50
        }]
      });
      if (root) {
        list[rootIndex] = category;
      }
      return list;
    }
    const category = {
      subCategories: itemSubcategory,
      name: item.name,
      categoryId: item._id,
      order: item.order
    };
    const rootSubcategories = item.subcategories.length ? item.subcategories.reduce((map, sub) => _objectSpread({}, map, {
      [sub._id]: sub._id
    }), {}) : [];
    const isOtherSubcategoriesExists = subCategories.some(sub => !!rootSubcategories[sub.categoryId]);
    if (isOtherSubcategoriesExists) {
      category.subCategories.push({
        name: OTHER_SUBCATEGORY_NAME,
        categoryId: item._id,
        order: 50
      });
    }
    return [...list, category];
  }, []);
  return categories.sort((a, b) => a.order - b.order);
};
export const getItemsInBoxForEditing = (items = [], box, apparelSubcategoryIds) => {
  return items.reduce((result, item) => {
    const {
      _id,
      prod,
      variants,
      quantity,
      boxItemsId,
      sizeInd,
      quantityPerBox: initialQuantityPerBox,
      customProductId,
      isReorder
    } = item;
    const {
      name,
      size,
      sizeDependentPrice,
      minQuant,
      hasSize,
      productSize,
      categories = []
    } = prod || {};
    const variant = _get(prod, ['variants', variants[0].variantIndex], {
      color: FULL_DESIGN_PRODUCT_DEFAULT_COLOR_VALUE,
      name: FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME,
      colorFamily: '',
      isHidden: false,
      isAlwaysInStockVariant: false,
      images: {},
      variantId: uuidv4()
    });
    const itemQuantities = _get(item, ['variants', 0, 'quantities']);
    const sizes = !sizeDependentPrice && size && size.options;
    const apparelCategories = _intersection2(apparelSubcategoryIds, categories.map(category => category.categoryId));
    const quantityPerBox = initialQuantityPerBox || Math.floor(quantity / box.quantity);
    return _objectSpread({}, result, {
      [_id.toString()]: {
        prod,
        _id,
        name,
        sizeInd,
        sizes,
        size,
        hasSize,
        minQuant,
        quantity,
        boxItemsId,
        productSize,
        itemQuantities,
        quantityPerBox,
        initialQuantityPerBox,
        productSku: variant.productSku,
        itemId: _id,
        prodId: prod === null || prod === void 0 ? void 0 : prod._id,
        color: variant.color,
        minQuantity: minQuant,
        colorName: variant.name,
        isApparel: apparelCategories.length > 0,
        itemSizeSet: (sizes || []).reduce((acc, size) => _objectSpread({}, acc, {
          [size]: size
        }), {}),
        isOrderMore: Boolean(customProductId) && isReorder
      }
    });
  }, {});
};
export const mapQuantitiesByProducts = items => items.reduce((all, next) => {
  if (next.prod.sizeDependentPrice || !next.prod.hasSize) {
    return all;
  }
  return _objectSpread({}, all, {
    [next._id]: next.variants[0].quantities
  });
}, {});
export const groupBoxProducts = (oldProducts, newProducts) => {
  const productIds = _uniq2([...oldProducts, ...newProducts].map(({
    _id
  }) => _id));
  return productIds.map(id => {
    const newProduct = newProducts.find(({
      _id
    }) => _id === id);
    const oldProduct = oldProducts.find(({
      _id
    }) => _id === id) || {};
    const oldProductExists = !_isEmpty2(oldProduct);
    if (newProduct && oldProductExists) {
      if (newProduct.hidden) {
        return null;
      }
      return _objectSpread({}, newProduct, {
        sizeIndex: oldProduct.sizeIndex || 0,
        itemId: oldProduct.itemId || null
      });
    }
    if (oldProductExists) {
      return oldProduct;
    }
    return null;
  }).filter(Boolean);
};
export const updateOriginalBoxByQuantity = newItems => item => {
  const {
    quantity,
    itemQuantities,
    quantityPerBox,
    blendedQuantity
  } = newItems[item._id.toString()];
  return _objectSpread({}, item, {
    quantity,
    quantityPerBox,
    blendedQuantity,
    variants: [{
      variantIndex: item.variants[0].variantIndex,
      quantities: itemQuantities
    }]
  });
};