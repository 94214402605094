function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { STORAGE_FEE_ADJUSTMENT_TYPE, MONTH_SHORT_NAMES, MONTH_LONG_NAMES } from 'swag-common/constants/inventory';
import { getFirstDayOfMonth, getLastDayOfMonth, isSameDate, getLastMomentOfMonth } from 'swag-common/utils/date-lib';
import { Price } from '../price';
export const getRangeNameFromTwoDate = (startRange, endRange) => {
  const [, startMonthIndex] = startRange.split('-');
  const [, endMonthIndex] = endRange.split('-');
  const startMonth = MONTH_SHORT_NAMES[startMonthIndex];
  const endMonth = MONTH_SHORT_NAMES[endMonthIndex];
  const startDate = getFirstDayOfRange(startRange).getDate();
  const endDate = getLastDayOfRange(endRange).getDate();
  return `${startMonth}${startDate} - ${endMonth}${endDate}`;
};
export const storageFeeReportRange = (month, year) => {
  return `${year}-${month}`;
};
export const storageFeeReportRangeFromDate = anyDayOfMonth => {
  const year = anyDayOfMonth.getUTCFullYear();
  const month = anyDayOfMonth.getUTCMonth();
  return `${year}-${month}`;
};
export const getDaysRangeName = (start, end) => {
  const month = start.getMonth();
  const startDay = start.getDate();
  const endDay = end.getDate();
  const monthName = MONTH_SHORT_NAMES[month];
  if (startDay === endDay) {
    return `${monthName} ${startDay}`;
  }
  return `${monthName} ${startDay}-${endDay}`;
};
export const generateMonthRanges = (startMonth, endMonth, startYear, endYear) => {
  const months = [];
  if (startMonth === endMonth) {
    months.push(storageFeeReportRange(startMonth, startYear));
  }
  if (endMonth > startMonth) {
    for (let i = startMonth; i <= endMonth; ++i) {
      months.push(storageFeeReportRange(i, startYear));
    }
  }
  if (startMonth > endMonth) {
    for (let i = startMonth; i <= 11; ++i) {
      months.push(storageFeeReportRange(i, startYear));
    }
    for (let i = 0; i <= startMonth; ++i) {
      months.push(storageFeeReportRange(i, endYear));
    }
  }
  return months;
};
export const getDayFromFormattedString = range => {
  const splitted = range.split('-');
  const year = splitted[0];
  const month = splitted[1];
  const day = splitted[2];
  return new Date(Date.UTC(+year, +month, +day, 0, 0, 0, 0));
};
const selectAdjustmentType = adjustment => {
  if (!adjustment) {
    return null;
  }
  if (adjustment < 0) {
    return STORAGE_FEE_ADJUSTMENT_TYPE.DISCOUNT;
  }
  return STORAGE_FEE_ADJUSTMENT_TYPE.ADJUSTMENT;
};
export const fillReportRecordsWithProductInfo = ({
  report
}, inventoryProducts) => Object.entries(report).reduce((report, [productId, records]) => {
  report.push(...records.map((record, index) => {
    const showBorder = records.length - 1 === index;
    if (!index) {
      const fullDescription = inventoryProducts.find(({
        _id
      }) => productId === _id);
      if (fullDescription) {
        const {
          name,
          defaultImage
        } = fullDescription;
        return _objectSpread({}, record, {
          productId,
          name,
          logo: defaultImage,
          showBorder
        });
      }
      return _objectSpread({}, record, {
        productId,
        showBorder
      });
    }
    return _objectSpread({}, record, {
      productId,
      showBorder
    });
  }));
  return report;
}, []);
const newRecordCreator = (date, quantity, costPerDayInRecord, quantityOld = 0, costPerDayInRecordOld = 0, hasOldProductPricing, priceNewProductPerDay, priceOldProductPerDay, hasOldAndNewProducts) => ({
  startDate: date,
  endDate: date,
  quantity: quantity,
  quantitySum: quantity + quantityOld,
  quantityOld,
  storedFor: 1,
  costPerDay: costPerDayInRecord,
  costPerDaySum: costPerDayInRecord + costPerDayInRecordOld,
  costPerDayOld: costPerDayInRecordOld,
  costPerDayInRecordOld,
  hasOldProductPricing: Boolean(hasOldProductPricing),
  amount: costPerDayInRecord + costPerDayInRecordOld,
  rangeName: '',
  priceNewProductPerDay,
  priceOldProductPerDay,
  hasOldAndNewProducts
});
const mergeRecordsWithSameDate = records => {
  const mergedRecords = [];
  for (let index = 0; index < records.length; index++) {
    const record = records[index];
    const sameDateRecord = records.find((r, innerIndex) => {
      return r.date === record.date && index !== innerIndex;
    });
    if (sameDateRecord) {
      const isAlreadyMerged = mergedRecords.find(r => r.date === sameDateRecord.date);
      if (!isAlreadyMerged) {
        const {
          quantity,
          costPerDay,
          isOldProductPricing
        } = sameDateRecord;
        let mergedRecord = {};
        if (isOldProductPricing) {
          mergedRecord = _objectSpread({}, record, {
            quantityOld: quantity,
            costPerDayOld: costPerDay,
            hasOldProductPricing: isOldProductPricing,
            hasOldAndNewProducts: true
          });
        } else {
          const {
            quantity,
            costPerDay,
            isOldProductPricing
          } = record;
          mergedRecord = _objectSpread({}, sameDateRecord, {
            quantityOld: quantity,
            costPerDayOld: costPerDay,
            hasOldProductPricing: isOldProductPricing,
            hasOldAndNewProducts: true
          });
        }
        mergedRecords.push(mergedRecord);
      }
      continue;
    }
    mergedRecords.push(record);
  }
  return mergedRecords;
};
const productsCalculation = records => {
  const filteredRecords = records.filter(item => item.costPerDay !== 0);
  const mergedRecords = mergeRecordsWithSameDate(filteredRecords);
  return mergedRecords.reduce((reportRecordsAcc, {
    date,
    quantity,
    costPerDay,
    quantityOld = 0,
    costPerDayOld = 0,
    hasOldProductPricing,
    hasOldAndNewProducts = false
  }) => {
    if (!quantity && !quantityOld) {
      return reportRecordsAcc;
    }
    const costPerDayInRecord = quantity * costPerDay;
    const costPerDayInRecordOld = quantityOld * costPerDayOld;
    const reportLength = reportRecordsAcc.length;
    const lastRecord = reportLength ? reportRecordsAcc[reportLength - 1] : null;
    if (lastRecord) {
      const isQuantityDiff = lastRecord.quantity !== quantity || lastRecord.quantityOld !== quantityOld;
      const isCostPerDayDiff = Number(lastRecord.costPerDay) !== costPerDayInRecord || Number(lastRecord.costPerDayOld) !== costPerDayInRecordOld;
      const isNewRecordRequired = isQuantityDiff || isCostPerDayDiff;
      if (isNewRecordRequired) {
        reportRecordsAcc.push(newRecordCreator(date, quantity, costPerDayInRecord, quantityOld, costPerDayInRecordOld, hasOldProductPricing, costPerDay, costPerDayOld, hasOldAndNewProducts));
        return reportRecordsAcc;
      }
      lastRecord.storedFor = Number(lastRecord.storedFor) + 1;
      lastRecord.amount = lastRecord.storedFor * costPerDayInRecord + lastRecord.storedFor * costPerDayInRecordOld;
      lastRecord.endDate = date;
      return reportRecordsAcc;
    }
    return [newRecordCreator(date, quantity, costPerDayInRecord, quantityOld, costPerDayInRecordOld, hasOldProductPricing, costPerDay, costPerDayOld, hasOldAndNewProducts)];
  }, []);
};
const getReportWithProductQuantityRanges = report => Object.entries(report.products || {}).reduce((reportAcc, [id, records]) => {
  return _objectSpread({}, reportAcc, {
    [id]: productsCalculation(records)
  });
}, {});
const getReportPreparedForView = report => {
  const reportWithRanges = getReportWithProductQuantityRanges(report);
  return Object.entries(reportWithRanges).reduce((acc, [id, records]) => {
    acc[id] = records.map(record => {
      const {
        startDate,
        endDate,
        costPerDay,
        amount,
        quantity,
        costPerDayOld = 0,
        quantityOld = 0,
        hasOldAndNewProducts,
        priceNewProductPerDay,
        priceOldProductPerDay
      } = record;
      const costPerDaySum = Number(costPerDay) + costPerDayOld;
      const quantitySum = quantity + quantityOld;
      const shouldShowIcon = hasOldAndNewProducts && priceNewProductPerDay !== priceOldProductPerDay;
      return _objectSpread({}, record, {
        quantity: quantitySum,
        rangeName: getDaysRangeName(new Date(startDate), new Date(endDate)),
        costPerDay: Price.formatPrice(costPerDaySum),
        amount: Price.formatPrice(amount),
        shouldShowIcon
      });
    });
    return acc;
  }, {});
};
export const transformReport = report => {
  if (!report) {
    return null;
  }
  return {
    total: report.total + report.adjustments,
    subtotal: report.total / 100,
    adjustment: report.adjustments,
    adjustmentType: selectAdjustmentType(report.adjustments),
    report: getReportPreparedForView(report)
  };
};
export const getDateRangeBasedOnMonth = (month, year) => {
  const date = new Date();
  date.setUTCMonth(month);
  date.setUTCFullYear(year);
  const monthShort = MONTH_SHORT_NAMES[month];
  const firstDay = new Date(year, date.getMonth(), 1).getDate();
  const lastDay = new Date(year, date.getMonth() + 1, 0).getDate();
  return `${monthShort} ${firstDay} - ${monthShort} ${lastDay} ${year}`;
};
export const prepareDateFormat = startDate => {
  const [year, month, day] = startDate.split('-');
  return new Date(Date.UTC(+year, +month, +day, 0, 0, 0, 0));
};
export const getDaysFromRange = range => {
  const days = [];
  const {
    from,
    to
  } = range;
  let temp = from;
  while (temp < to || isSameDate(temp, to)) {
    days.push(temp);
    temp = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate() + 1);
  }
  return days;
};
export const getMonthRangeOfDate = date => {
  const firstDay = getFirstDayOfMonth(date);
  const lastDay = getLastDayOfMonth(date);
  return {
    from: firstDay,
    to: lastDay
  };
};
export const getFirstDayOfRange = range => {
  const [year, month] = range.split('-');
  return new Date(Date.UTC(+year, +month, 1, 0, 0, 0, 0));
};
export const getLastDayOfRange = range => {
  const [year, month] = range.split('-');
  return getLastDayOfMonth(new Date(Date.UTC(+year, +month, 1, 0, 0, 0, 0)));
};
export const getReportsRange = reports => {
  const firstReport = reports[0];
  const lastReport = reports[reports.length - 1];
  const firstDay = getFirstDayOfRange(firstReport.range);
  const lastDay = getLastDayOfRange(lastReport.range);
  return {
    start: firstDay,
    end: lastDay
  };
};
export const getReportRange = ({
  range
}) => {
  return {
    start: getFirstDayOfRange(range),
    end: getLastMomentOfMonth(getLastDayOfRange(range))
  };
};
export const getPeriodStartFromReportRange = range => {
  const [year, month] = range.split('-');
  const monthName = MONTH_LONG_NAMES[month];
  return `${monthName} ${year}`;
};