import { createSelector } from 'reselect';
import { DEFAULT_CATEGORY_NAME, DEFAULT_CATEGORY_ID } from 'swag-common/constants/custom-store.constants';
export const categoriesSelector = state => state.categories.categories.length > 0 ? [{
  _id: DEFAULT_CATEGORY_ID,
  name: DEFAULT_CATEGORY_NAME
}, ...state.categories.categories] : [];
const categoriesRootSelector = state => state.categories;
export const categoriesToPresentSelector = createSelector(categoriesRootSelector, categories => categories.categories);
export const categoryToEditSelector = createSelector(categoriesRootSelector, categories => categories.categoryToEdit);
export const categoryToEditNameSelector = createSelector(categoriesSelector, categoryToEditSelector,
// @todo. auto generated. fix this error
// @ts-ignore
(categories, categoryId) => categories.find(({
  _id
}) => _id === categoryId).name);