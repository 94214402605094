import { SwagBundlesStep } from 'swag-common/constants/swag-bundles.constants';
import { validateSwagBundlesNaming } from './steps/swag-bundles-naming.validation';
export const swagBundlesValidators = {
  [SwagBundlesStep.naming]: validateSwagBundlesNaming
};
export const getSwagBundlesStepValidation = step => data => {
  const validator = swagBundlesValidators[step];
  if (!validator) {
    return true;
  }
  const result = validator(data);
  return !result.error;
};