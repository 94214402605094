function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export const swagBundleGroupContentByParentId = bundleContent => {
  return bundleContent.reduce((acc, content) => {
    const {
      product,
      customProductId,
      swagProductId,
      quantity
    } = content;
    if (product) {
      const parentId = String(product.parentId);
      acc[parentId] = acc[parentId] || [];
      acc[parentId].push(_objectSpread({}, product, {
        customProductId,
        swagProductId,
        variantId: content._id,
        quantityPerBundle: product.quantityPerBundle || quantity
      }));
    }
    return acc;
  }, {});
};
export const getSwagBundleQuantitiesByParentId = bundleContent => {
  return bundleContent.reduce((acc, content) => {
    const {
      product,
      quantity
    } = content;
    if (product) {
      const parentId = String(product.parentId);
      acc[parentId] = quantity;
    }
    return acc;
  }, {});
};
export const getSwagBundleQuantitiesByParentIdAndColor = bundleContent => {
  return bundleContent.reduce((result, variant) => {
    var _variant$product, _variant$product3, _variant$product4;
    const parentId = String(variant === null || variant === void 0 ? void 0 : (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : _variant$product.parentId);
    if (!parentId) {
      return result;
    }
    result[parentId] = result[parentId] || [];
    if (result[parentId].some(({
      colorValue
    }) => {
      var _variant$product2;
      return ((_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : _variant$product2.colorValue) === colorValue;
    })) {
      return result;
    }
    result[parentId].push({
      colorValue: (variant === null || variant === void 0 ? void 0 : (_variant$product3 = variant.product) === null || _variant$product3 === void 0 ? void 0 : _variant$product3.colorValue) || '',
      quantity: (variant === null || variant === void 0 ? void 0 : (_variant$product4 = variant.product) === null || _variant$product4 === void 0 ? void 0 : _variant$product4.quantityPerBundle) || 0
    });
    return result;
  }, {});
};
export const getSwagBundleQuantitiesDiffByParentIdAndColor = (currentProductsWithQuantities, editedProductsWithQuantities) => {
  const diffProducts = Object.keys(currentProductsWithQuantities).reduce((acc, parentId) => {
    const currentVariants = currentProductsWithQuantities[parentId];
    const editedVariants = editedProductsWithQuantities[parentId];
    if (!editedVariants) {
      const newItem = currentVariants.map(variant => _objectSpread({}, variant, {
        quantity: 0 - variant.quantity
      })).filter(({
        quantity
      }) => quantity);
      if (newItem.length) {
        acc[parentId] = newItem;
      }
      return acc;
    }
    const oldItems = currentVariants.map(variant => {
      const edited = editedVariants.find(({
        colorValue
      }) => colorValue === variant.colorValue);
      return _objectSpread({}, variant, {
        quantity: ((edited === null || edited === void 0 ? void 0 : edited.quantity) || 0) - variant.quantity
      });
    }).filter(({
      quantity
    }) => quantity);
    if (oldItems.length) {
      editedVariants.forEach(editedVariant => {
        const found = oldItems.find(({
          colorValue
        }) => colorValue === editedVariant.colorValue);
        if (!found) {
          oldItems.push(editedVariant);
        }
      });
      acc[parentId] = oldItems;
    }
    return acc;
  }, {});
  Object.keys(editedProductsWithQuantities).forEach(parentId => {
    if (currentProductsWithQuantities[parentId]) {
      return;
    }
    diffProducts[parentId] = editedProductsWithQuantities[parentId];
  });
  return diffProducts;
};
export const groupSwagBundleContentVariantsWithSizesByColor = variants => {
  const groupedMap = variants.reduce((colorsMap, variant) => {
    var _colorsMap$colorValue;
    const {
      colorValue = '',
      inStockQuantity,
      inProductionQuantity,
      inReserveProductionQuantity,
      inReserveStockQuantity
    } = variant;
    const existingColor = colorsMap[colorValue] || {
      inStockQuantity: 0,
      inProductionQuantity: 0,
      inReserveProductionQuantity: 0,
      inReserveStockQuantity: 0
    };
    const existingSizeVariants = ((_colorsMap$colorValue = colorsMap[colorValue]) === null || _colorsMap$colorValue === void 0 ? void 0 : _colorsMap$colorValue.sizeVariants) || [];
    colorsMap[colorValue] = _objectSpread({}, variant, {
      sizeVariants: [...existingSizeVariants, variant],
      inStockQuantity: existingColor.inStockQuantity + inStockQuantity,
      inProductionQuantity: existingColor.inProductionQuantity + inProductionQuantity,
      inReserveProductionQuantity: existingColor.inReserveProductionQuantity + inReserveProductionQuantity,
      inReserveStockQuantity: existingColor.inReserveStockQuantity + inReserveStockQuantity
    });
    return colorsMap;
  }, {});
  return Object.keys(groupedMap).map(colorValue => groupedMap[colorValue]);
};
export const getSwagBundleCombinedProductQuantities = map => Object.keys(map).reduce((acc, parentId) => {
  if (!acc[parentId]) {
    acc[parentId] = 0;
  }
  acc[parentId] += map[parentId].reduce((quantities, item) => quantities += item.quantity, 0);
  return acc;
}, {});