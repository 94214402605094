import { createSelector } from 'reselect';
import { findPackagingInBundleContent, hasNotecardInBundle, hasPackagingInBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { singleProductSelectorByProductId } from '../../products/products.selectors';
import { swagBundlesSelector } from '../swag-bundles.selectors';
import { selectedProductIdSelector } from '../../order-more/order-more.selectors';
export const swagBundlesOrderMoreDataSelector = createSelector(swagBundlesSelector, swagBundles => swagBundles.orderMoreData);
export const swagBundlesOrderMoreStepSelector = createSelector(swagBundlesOrderMoreDataSelector, ({
  step
}) => step);
export const swagBundlesOrderMoreSelectedBundleProductSelector = createSelector(selectedProductIdSelector, state => state, (selectedBundleId, state) => {
  return singleProductSelectorByProductId(state, {
    productId: selectedBundleId
  });
});
export const swagBundlesOrderMoreSelectedItemSelector = createSelector(swagBundlesOrderMoreDataSelector, ({
  selectedItem
}) => selectedItem);
export const selectedOrderMoreBundleHasPackagingSelector = createSelector(swagBundlesOrderMoreSelectedBundleProductSelector, product => {
  var _product$variants$;
  return hasPackagingInBundle(product === null || product === void 0 ? void 0 : (_product$variants$ = product.variants[0]) === null || _product$variants$ === void 0 ? void 0 : _product$variants$.bundleContent);
});
export const selectedOrderMoreBundleHasNotecardSelector = createSelector(swagBundlesOrderMoreSelectedBundleProductSelector, product => {
  var _product$variants$2;
  return hasNotecardInBundle(product === null || product === void 0 ? void 0 : (_product$variants$2 = product.variants[0]) === null || _product$variants$2 === void 0 ? void 0 : _product$variants$2.bundleContent);
});
export const findPackagingInOrderMoreBundleSelector = createSelector(swagBundlesOrderMoreSelectedBundleProductSelector, product => {
  var _product$variants$3;
  return findPackagingInBundleContent(product === null || product === void 0 ? void 0 : (_product$variants$3 = product.variants[0]) === null || _product$variants$3 === void 0 ? void 0 : _product$variants$3.bundleContent);
});
export const isOrderMoreAddonsCheckoutLoadedSelector = createSelector(swagBundlesOrderMoreDataSelector, ({
  isAddonsCheckoutLoaded
}) => isAddonsCheckoutLoaded);