const generateProductPropertyName = ({
  productCode,
  propertyName,
  isInQuotes,
  isLowercase
}) => {
  let generatedName = `${productCode} ${propertyName}`;
  if (isLowercase) {
    generatedName = generatedName.toLowerCase();
  }
  return isInQuotes ? `"${generatedName}"` : generatedName;
};
export default generateProductPropertyName;