import { useCallback, useState } from 'react';
export function useToggle(initialValue) {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(nextValue => {
    if (typeof nextValue === 'boolean') {
      setValue(nextValue);
    } else {
      setValue(currentValue => !currentValue);
    }
  }, []);
  return [value, toggle];
}