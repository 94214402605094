import { SwagBundlesStep } from 'swag-common/constants/swag-bundles.constants';
export const getNextSwagBundleStepBasedOnCurrent = currentStep => {
  switch (currentStep) {
    case SwagBundlesStep.naming:
      {
        return SwagBundlesStep.selectProducts;
      }
    case SwagBundlesStep.selectProducts:
      {
        return SwagBundlesStep.selectColorsAndQuantities;
      }
    case SwagBundlesStep.selectColorsAndQuantities:
      {
        return SwagBundlesStep.selectAddOns;
      }
    case SwagBundlesStep.selectAddOns:
      {
        return SwagBundlesStep.calculateShipping;
      }
    default:
      {
        return null;
      }
  }
};
export const getPrevSwagBundleStepBasedOnCurrent = currentStep => {
  switch (currentStep) {
    case SwagBundlesStep.calculateShipping:
      {
        return SwagBundlesStep.selectAddOns;
      }
    case SwagBundlesStep.selectAddOns:
      {
        return SwagBundlesStep.selectColorsAndQuantities;
      }
    case SwagBundlesStep.selectColorsAndQuantities:
      {
        return SwagBundlesStep.selectProducts;
      }
    case SwagBundlesStep.selectProducts:
      {
        return SwagBundlesStep.naming;
      }
    default:
      {
        return null;
      }
  }
};
export const getNextOrPrevNotSkippedStepForSwagBundles = (skippedSteps, nextOrPrevStep, strategy = getNextSwagBundleStepBasedOnCurrent) => {
  if (!skippedSteps.includes(nextOrPrevStep)) {
    return nextOrPrevStep;
  }
  const nextOrPrevNotSkippedStep = strategy(nextOrPrevStep);
  if (!nextOrPrevNotSkippedStep) {
    return SwagBundlesStep.naming;
  }
  return getNextOrPrevNotSkippedStepForSwagBundles(skippedSteps, nextOrPrevNotSkippedStep, strategy);
};