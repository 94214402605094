import _reduxForm from "redux-form/es/reduxForm";
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { PAYMENT_DETAILS_PUBLIC_API } from 'swag-client-common/constants';
import { PaymentDetails } from 'swag-client-common/components/partials/payment-details';
import { initialCreditCardValuesSelector } from '../../../../../../redux/api-settings/api-settings.selectors';
const mapStateToProps = createStructuredSelector({
  initialValues: initialCreditCardValuesSelector
});
const PaymentDetailsPublicApiReduxForm = _reduxForm({
  form: PAYMENT_DETAILS_PUBLIC_API,
  destroyOnUnmount: true,
  enableReinitialize: true
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
})(PaymentDetails);
export const PaymentDetailsPublicApi = connect(mapStateToProps)(PaymentDetailsPublicApiReduxForm);