import { SOCKET_EVENTS } from 'swag-common/constants/socket-events';
import { BC_ENTERPRISE, isBroadcastChannelSupported } from 'swag-client-common/shared-worker/broadcast-chanel';
import { port } from 'swag-client-common/shared-worker/enterprise/enterprise-worker-runner';
import { BCEvents } from 'swag-client-common/shared-worker/shared-worker.events';
let lastMessageId = '';
export function cancelValidation(userId) {
  port.postMessage({
    type: SOCKET_EVENTS.VALIDATE_SHIPOUT_CANCEL,
    payload: userId
  });
  lastMessageId = '';
}
export function trackShipoutValidationProgress({
  onProgress,
  onError
}) {
  BC_ENTERPRISE.addEventListener(BCEvents.MESSAGE, event => {
    const {
      type,
      payload
    } = isBroadcastChannelSupported ? event.data : event;
    switch (type) {
      case SOCKET_EVENTS.VALIDATE_SHIPOUT_PROGRESS:
        {
          if (payload._id !== lastMessageId) {
            onProgress(payload);
            lastMessageId = payload._id;
          }
          break;
        }
      case SOCKET_EVENTS.VALIDATE_SHIPOUT_ERROR:
        {
          if (payload._id !== lastMessageId) {
            onError(payload);
            lastMessageId = payload._id;
          }
          break;
        }
      default:
        {
          break;
        }
    }
  });
}