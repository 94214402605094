import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
export const tastyLinks = [{
  label: 'Swag bulk ordering',
  link: APP_ROUTES.ALL_SWAG
}, {
  label: 'Swag distribution',
  link: APP_ROUTES.DISTRIBUTE
}, {
  label: 'Swag in a box',
  link: APP_ROUTES.IN_A_BOX
}, {
  label: 'Corporate gifting',
  link: APP_ROUTES.GIVEAWAY
}, {
  label: 'Company Stores',
  link: APP_ROUTES.SHOPIFY
}, {
  label: 'Swag Automation',
  link: APP_ROUTES.AUTOMATION
}];
export const swagLinks = [{
  label: 'Blog',
  link: APP_ROUTES.BLOG,
  isDefaultBehavior: true
}, {
  label: 'Fully custom swag',
  link: APP_ROUTES.CUSTOM_SWAG
}, {
  label: 'Testimonials and reviews',
  link: APP_ROUTES.REVIEWS
}, {
  label: 'Our latest work',
  link: APP_ROUTES.PAST_WORK
}, {
  label: 'Swag.com brand shop',
  link: 'https://brandstore.swag.com/',
  rel: 'noopener noreferrer',
  target: '_blank',
  isDefaultBehavior: true
}];
export const informationLinks = [{
  label: 'Contact Us',
  link: APP_ROUTES.CONTACT
}, {
  label: 'Our story',
  link: APP_ROUTES.OUR_STORY
}, {
  label: 'Refer a friend',
  link: webpack_referralrock_registration_page,
  rel: 'noopener noreferrer',
  target: '_blank',
  isDefaultBehavior: true
}, {
  label: 'FAQ',
  link: APP_ROUTES.FAQ
}, {
  label: 'Privacy policy',
  link: APP_ROUTES.PRIVACY_POLICY
}, {
  label: 'Terms of Service',
  link: APP_ROUTES.TOS
}, {
  label: 'Commitment to Accessibility',
  link: APP_ROUTES.COMMITMENT_TO_ACCESSIBILITY
}];