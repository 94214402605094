import { createSelector } from 'reselect';
import { GiveawayStep } from 'swag-common/constants/giveaway.constants';
import { pathnameSelector } from '../../../customer/redux/common/common.selectors';
import { isLoadedInventoryEnteredSelector } from '../inventory/inventory.selectors';
import { giveawayActiveStepSelector } from '../giveaway/giveaway.selectors';
export const showLoadingOverlaySelector = state => {
  var _state$common;
  return Boolean(state === null || state === void 0 ? void 0 : (_state$common = state.common) === null || _state$common === void 0 ? void 0 : _state$common.loadersNumber);
};
export const locationSelector = state => {
  return state.routing.location || window.location;
};
export const routeNavigationConfirmationSelector = state => {
  return state.common.routeNavigationConfirmation;
};
export const showLoadingOverlayWithEnteredSelector = createSelector(showLoadingOverlaySelector, isLoadedInventoryEnteredSelector, (showCommonLoader, isLoadedEntered) => showCommonLoader || !isLoadedEntered);
export const isGiveawayNotecardPathSelector = createSelector(pathnameSelector, giveawayActiveStepSelector, (pathname = '', step) => {
  const isNotecardEditRoute = pathname.includes('giveaway') && pathname.includes('notecard');
  const isNotecardStep = step === GiveawayStep.notecard;
  return isNotecardEditRoute || isNotecardStep;
});