function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { Price } from 'swag-client-common/utils/price';
import { getTotalAmountForLink } from 'swag-common/business-logic/giveaway/get-total-amount-for-giveaway-link';
export const formatQuantitiesString = (quantities, size, isSizeDependentPrice) => {
  if (!size || !size.options) {
    return quantities.join(', ');
  }
  if (isSizeDependentPrice) {
    const index = quantities.findIndex(Boolean);
    return `${quantities[index]} - ${size.options[index]}`;
  }
  return size.options.map((option, index) => {
    if (quantities[index]) {
      return `${quantities[index]} - ${option}`;
    }
    return null;
  }).filter(Boolean).join(', ');
};
export const getAdditionalGiveaways = (quantities, giveaways, size, isSizeDependentPrice) => {
  if (!quantities) {
    return [];
  }
  return Object.keys(quantities).reduce((all, id) => {
    const giveaway = {
      name: giveaways[id],
      quantities: formatQuantitiesString(quantities[id], size, isSizeDependentPrice)
    };
    return [...all, giveaway];
  }, []);
};
export const getArraysTotalWithObjectStructure = (quantities, initialState = []) => {
  return Object.keys(quantities).reduce((all, key) => {
    return quantities[key].map((q, i) => (q || 0) + (all[i] || 0));
  }, initialState);
};
export const getTotalQuantities = (quantities, size, isSizeDependentPrice, rootQuantities) => {
  if (!quantities) {
    return [];
  }
  const total = getArraysTotalWithObjectStructure(quantities, rootQuantities);
  return formatQuantitiesString(total, size, isSizeDependentPrice);
};
const getErrorMessage = size => (quantity, index, isVariantInStock) => {
  if (!isVariantInStock) {
    return '';
  }
  const options = size && size.options ? size.options : null;
  if (!options) {
    return `You need at least ${quantity} of product`;
  }
  return `You need at least ${quantity} of ${options[index]}`;
};
const getOrderMoreQuantitiesErrors = (needStockQuantities, inputtedQuantities, size, isVariantInStock) => {
  const messageConstructor = getErrorMessage(size);
  return needStockQuantities.map((quantity, index) => quantity > inputtedQuantities[index] ? messageConstructor(quantity, index, isVariantInStock) : null);
};
export const getVariantForOrderMore = data => {
  const {
    color,
    images,
    colorFamily,
    isHidden,
    productSku,
    name,
    variantIndex,
    quantities,
    additionalQuantities,
    initialQuantities,
    giveaways,
    size,
    isSizeDependentPrice,
    customProductVariants = [],
    isVariantInStock = true,
    isAlwaysInStockVariant,
    sizeSkus,
    isBox
  } = data;
  const resultedVariant = customProductVariants.find(variant => {
    const isProductColorSameAsVariantColor = variant.colorName === name && (!variant.colorValue || color);
    if (isBox) {
      return isProductColorSameAsVariantColor;
    }
    const nestedVariants = variant.swagProductId.variants;
    const isSwagProductNestedVariantColorSameAsVariantColor = nestedVariants.length === 0 || nestedVariants.some(swagProduct => {
      return swagProduct.color === color && swagProduct.name === name && swagProduct.productSku === productSku;
    });
    return isProductColorSameAsVariantColor && isSwagProductNestedVariantColorSameAsVariantColor;
  }) || {
    defaultImage: undefined
  };
  return {
    color,
    colorFamily,
    images,
    isHidden,
    name,
    productSku,
    quantities,
    variantIndex,
    errors: getOrderMoreQuantitiesErrors(initialQuantities, quantities, size, isVariantInStock),
    additionalGiveaways: getAdditionalGiveaways(additionalQuantities, giveaways, size, isSizeDependentPrice),
    totalGiveawaysQuantities: getTotalQuantities(additionalQuantities, size, isSizeDependentPrice, initialQuantities),
    defaultImage: resultedVariant.defaultImage,
    isVariantInStock: isVariantInStock,
    isAlwaysInStockVariant,
    sizeSkus
  };
};
export const getColorsAmount = (logos = {}, isFullDesign, isBox) => {
  const DEFAULT_MINIMUM_COLORS_NUMBER = 1;
  if (isFullDesign || isBox) {
    return DEFAULT_MINIMUM_COLORS_NUMBER;
  }
  const colorsOfLogos = Object.keys(logos).reduce((colorsSum, location) => {
    const number = logos[location].reduce((colorsSumPerLocation, nextLogo) => nextLogo.colors.colorsNumber + colorsSumPerLocation, 0);
    return number + colorsSum;
  }, 0);
  return colorsOfLogos;
};
export const getVariantTotal = variants => {
  return variants.reduce((count, variant) => count + variant.quantities.reduce((sub, q) => sub + q, 0), 0);
};
export const getIsCustomizedBlank = productVariants => Boolean(productVariants[0].isCustomizedBlank);
export const getVariantErrors = variant => {
  return variant.reduce((all, variant) => [...all, ...(variant.errors || [])], []);
};
const formatCompletedLink = link => {
  const {
    _id,
    firstName,
    lastName,
    address,
    unit,
    city,
    state,
    zip,
    paidBy
  } = link;
  const fullName = `${firstName} ${lastName}`;
  const fullAddress = `${address} ${unit}, ${city}, ${state} ${zip}`;
  const totalAmount = getTotalAmountForLink(link);
  return _objectSpread({}, link, {
    fullName,
    fullAddress,
    totalAmount,
    paidBy,
    _id: String(_id)
  });
};
export const formatCompletedLinks = list => {
  return list.map(formatCompletedLink);
};
export const formatCompletedLinksForCSV = list => {
  return list.map(item => {
    var _link$tooltipProducts;
    const link = formatCompletedLink(item);
    const products = (_link$tooltipProducts = link.tooltipProducts) === null || _link$tooltipProducts === void 0 ? void 0 : _link$tooltipProducts.map(({
      _id,
      name,
      size,
      color
    }) => ({
      _id,
      name,
      size: size || 'N/A',
      color: color || 'N/A'
    }));
    const status = link.paidBy ? 'Paid' : 'Pending';
    return _objectSpread({}, link, {
      status,
      tooltipProducts: products
    });
  });
};
export const getGiveawayProducts = (productList, productIds) => productList.filter(product => productIds.includes(product._id));
export const getMinInStockValue = productList => {
  const sortedSelectedProducts = productList.sort((a, b) => Number(a.inStock) - Number(b.inStock));
  return sortedSelectedProducts.length ? Number(sortedSelectedProducts[0].inStock) : 1;
};
export function getGiveawayLinkAmount(link, amountField) {
  return link[amountField] > 0 ? Price.formatPrice(link[amountField]) : 'Free';
}
export function getProductsQuantitiesForDistribution(productsQuantities, excludedList) {
  const productsQuantitiesForDistribution = _objectSpread({}, productsQuantities);
  if (excludedList.length && Object.keys(productsQuantities).length) {
    const excludedLinksProducts = excludedList.reduce((map, link) => {
      const {
        products
      } = link;
      const newMap = _objectSpread({}, map);
      products.forEach(id => {
        newMap[id] = (map[id] || 0) + 1;
      });
      return newMap;
    }, {});
    Object.keys(excludedLinksProducts).forEach(id => {
      if (Object.prototype.hasOwnProperty.call(productsQuantitiesForDistribution, id)) {
        const totalOfDistributedLinks = productsQuantitiesForDistribution[id];
        const totalOfExcludedLinks = excludedLinksProducts[id];
        productsQuantitiesForDistribution[id] = totalOfDistributedLinks - totalOfExcludedLinks;
      }
    });
  }
  for (const productId in productsQuantitiesForDistribution) {
    if (productsQuantitiesForDistribution[productId] <= 0 && Object.prototype.hasOwnProperty.call(productsQuantitiesForDistribution, productId)) {
      delete productsQuantitiesForDistribution[productId];
    }
  }
  return productsQuantitiesForDistribution;
}
export function mapProductsWithUpdatedQuantities(inventoryProducts, recipientsProductsMap) {
  const productVariantIds = Object.keys(recipientsProductsMap);
  return inventoryProducts.reduce((mappedProducts, product) => {
    const productId = product._id;
    const productVariant = product.variants.find(({
      _id
    }) => productVariantIds.includes(String(_id)));
    if (!productVariant) {
      return mappedProducts;
    }
    const {
      _id: productVariantId,
      quantity = 0,
      inReserveQuantity = 0
    } = productVariant;
    const receivedProductsQuantity = recipientsProductsMap[String(productVariantId)];
    const updatedProductVariant = _objectSpread({}, productVariant, {
      quantity: quantity - receivedProductsQuantity,
      inReserveQuantity: inReserveQuantity - receivedProductsQuantity
    });
    const {
      inStock: prevInStock = product.inStock || 0,
      inReserveQuantity: prevInReserveQuantity = product.inReserveQuantity || 0,
      variants: prevVariants = []
    } = mappedProducts[productId] || {};
    const nextInStock = prevInStock - receivedProductsQuantity;
    const nextInReserveQuantity = prevInReserveQuantity - receivedProductsQuantity;
    return _objectSpread({}, mappedProducts, {
      [productId]: {
        inStock: nextInStock,
        inReserveQuantity: nextInReserveQuantity,
        variants: [...prevVariants, updatedProductVariant]
      }
    });
  }, {});
}
export const isSomeProductZeroStockAndProduction = products => {
  return products.some(product => {
    const {
      inStock,
      inProduction,
      inReserveQuantity
    } = product;
    return (inStock || 0) + (inProduction || 0) - (inReserveQuantity || 0) === 0;
  });
};