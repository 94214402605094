var _class;
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { GiveawayTypes } from 'swag-common/interfaces';
export class GiveawayUrl {}
_class = GiveawayUrl;
_defineProperty(GiveawayUrl, "getGiveawayPreview", (inventoryId, giveawayId) => `/inventory/${inventoryId}/giveaway/${giveawayId}/preview`);
_defineProperty(GiveawayUrl, "getGiveawayPreviewSuccess", (inventoryId, giveawayId) => `/inventory/${inventoryId}/giveaway/${giveawayId}/preview/success`);
_defineProperty(GiveawayUrl, "getGiveawayUserLanding", ({
  link,
  category = '',
  giveawayType = GiveawayTypes.swag
}) => {
  const landingType = giveawayType in GiveawayTypes ? _class.getGiveawayLinkNameByType(giveawayType) : giveawayType;
  return `/${landingType}/${link}${category}`;
});
_defineProperty(GiveawayUrl, "getGiveawayUserLandingCheckout", (link, giveawayType) => `${_class.getGiveawayUserLanding({
  link,
  giveawayType
})}/checkout`);
_defineProperty(GiveawayUrl, "getGiveawayUserLandingSuccess", (link, giveawayType) => `${_class.getGiveawayUserLanding({
  link,
  giveawayType
})}/success`);
_defineProperty(GiveawayUrl, "getGiveawayUserLandingEnded", (link, giveawayType) => `${_class.getGiveawayUserLanding({
  link,
  giveawayType
})}/ended`);
_defineProperty(GiveawayUrl, "getGiveawayUserLandingDeleted", (link, giveawayType) => `${_class.getGiveawayUserLanding({
  link,
  giveawayType
})}/deleted`);
_defineProperty(GiveawayUrl, "notFound", giveawayType => `${_class.getGiveawayUserLanding({
  link: 'not-found',
  giveawayType
})}`);
_defineProperty(GiveawayUrl, "getGiveawayLinkNameByType", giveawayType => giveawayType === GiveawayTypes.goodie ? 'goodie' : 'gift');
_defineProperty(GiveawayUrl, "getGiveawayTypeByLinkName", linkName => linkName === 'goodie' ? GiveawayTypes.goodie : GiveawayTypes.swag);
_defineProperty(GiveawayUrl, "giveawayTypeLink", () => {
  const giftLink = _class.getGiveawayLinkNameByType(GiveawayTypes.swag);
  const goodieLink = _class.getGiveawayLinkNameByType(GiveawayTypes.goodie);
  return `:giveawayType(${giftLink}|${goodieLink})`;
});
_defineProperty(GiveawayUrl, "getGiveawayUserLandingFull", ({
  baseUrl,
  link = '',
  giveawayType
}) => `${baseUrl}${_class.getGiveawayUserLanding({
  link,
  giveawayType
})}`);