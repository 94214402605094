import { goodieGiveawayApi } from 'swag-client-common/api/goodie-giveaway.api';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { GIVEAWAY_ADMIN_PREFIX } from './giveaway-common.actions';
const GOODIE_GIVEAWAY_CREATING_PREFIX = `${GIVEAWAY_ADMIN_PREFIX}CREATING_`;
export const GET_SETTINGS_SUCCESS = `${GOODIE_GIVEAWAY_CREATING_PREFIX}GET_SETTINGS_SUCCESS`;
export const GET_SETTINGS_FAIL = `${GOODIE_GIVEAWAY_CREATING_PREFIX}GET_SETTINGS_FAIL`;
export const GET_AVERAGE_PRODUCT_PRICE_SUCCESS = `${GOODIE_GIVEAWAY_CREATING_PREFIX}GET_AVERAGE_PRODUCT_PRICE_SUCCESS`;
export const GET_AVERAGE_PRODUCT_PRICE_FAIL = `${GOODIE_GIVEAWAY_CREATING_PREFIX}GET_AVERAGE_PRODUCT_PRICE_FAIL`;
export const getGoodieSettings = createAsyncLoadedAction({
  fn: goodieGiveawayApi.getSettings,
  success: res => ({
    type: GET_SETTINGS_SUCCESS,
    payload: res
  }),
  error: error => ({
    type: GET_SETTINGS_FAIL,
    error
  }),
  postError: dispatch => {
    dispatch(showNotification({
      text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
  }
});
export const getGoodieProductAveragePrice = createAsyncLoadedAction({
  fn: goodieGiveawayApi.getProductsAveragePrice,
  success: res => ({
    type: GET_AVERAGE_PRODUCT_PRICE_SUCCESS,
    payload: {
      price: res.averageProductPrice
    }
  }),
  error: error => ({
    type: GET_AVERAGE_PRODUCT_PRICE_FAIL,
    error
  }),
  postError: dispatch => {
    dispatch(showNotification({
      text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
  }
});
export const UPDATE_BUDGET = `${GOODIE_GIVEAWAY_CREATING_PREFIX}UPDATE_BUDGET`;
export const setGoodieGiveawayBudget = budget => ({
  type: UPDATE_BUDGET,
  payload: {
    budget
  }
});
export const SET_RECIPIENT_QUANTITY = `${GOODIE_GIVEAWAY_CREATING_PREFIX}SET_GOODIE_GIVEAWAY_RECIPIENT_QUANTITY`;
export const setGoodieGiveawayRecipientQuantity = quantity => {
  return {
    type: SET_RECIPIENT_QUANTITY,
    payload: quantity
  };
};
export const SET_RECIPIENTS_INPUTS_TOUCHED = `${GOODIE_GIVEAWAY_CREATING_PREFIX}SET_RECIPIENTS_INPUT_TOUCHED`;
export const setRecipientsInputsTouched = value => {
  return {
    type: SET_RECIPIENTS_INPUTS_TOUCHED,
    payload: value
  };
};