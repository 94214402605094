import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { REQUEST_DEMO_POPUP } from 'swag-client-common/constants';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { requestDemoHubspotCalendarLinkSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { OffersDropdownComponent } from './offers-dropdown';
import { offerOptionsSelector } from './common-props';
const mapStateToProps = createStructuredSelector({
  offerOptions: offerOptionsSelector,
  isShown: () => true,
  hubspotCalendarLink: requestDemoHubspotCalendarLinkSelector
});
const mapDispatchToProps = dispatch => ({
  openRequestDemoPopup: () => dispatch(showPopup({
    popup: REQUEST_DEMO_POPUP
  }))
});
export const OffersDropdown = connect(mapStateToProps, mapDispatchToProps)(OffersDropdownComponent);