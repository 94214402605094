function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { generateTitleForAllProductsPage } from 'swag-client-common/utils/seo.utils';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { Price } from 'swag-client-common/utils/price';
import { getProductLink } from 'swag-client-common/utils/tricks';
import { pathnameSelector } from '../common/common.selectors';
import { isAllProductsListPageSelector, productsSelector, currentCategorySelector, appliedCategoryFilterSelector, categoryByIdsSelector, currentCategoryFAQQuestionsSelector } from '../product-listing/product-listing.selectors';
import { productSelector, isOutOfStockSelector } from '../product/product.selectors';
import { metaTagsSelector } from '../pages/pages.selectors';
import { getMetaTagsByPathname } from '../../utils/get-meta-tags-by-pathname.utils';
import getCanonicalUrl from '../../utils/get-canonical-url.utils';
import { isAllSwagCategory } from '../product-listing/product-listing.utils';
import { breadcrumbsSelector } from '../navigation/navigation.selectors';
const origin = window.location.origin;
export const metaSelector = createSelector(pathnameSelector, metaTagsSelector, productsSelector, productSelector, currentCategorySelector, isAllProductsListPageSelector, appliedCategoryFilterSelector, categoryByIdsSelector, isOutOfStockSelector, currentCategoryFAQQuestionsSelector, breadcrumbsSelector, (pathname, tags, products, product, currentCategory, isAllProductsListPage, appliedCategoryFilter, categoryByIds, isOutOfStock, faqQuestions, breadcrumbs) => {
  const canonical = getCanonicalUrl(location);
  const productsList = products.map(({
    seoSlug
  }) => ({
    url: `${origin}${getProductLink(seoSlug)}`
  }));
  if (isAllSwagCategory()) {
    const data = getMetaTagsByPathname(tags, APP_ROUTES.ALL_SWAG);
    let title = data === null || data === void 0 ? void 0 : data.title;
    let description = data === null || data === void 0 ? void 0 : data.description;
    if (appliedCategoryFilter && appliedCategoryFilter.length === 2 && categoryByIds[appliedCategoryFilter[1]]) {
      title = categoryByIds[appliedCategoryFilter[1]].seoBrowserTabTitle;
      description = categoryByIds[appliedCategoryFilter[1]].seoBrowserTabDescription;
    }
    return {
      title,
      description,
      canonical,
      image: data === null || data === void 0 ? void 0 : data.image,
      type: 'category',
      productsList,
      breadcrumbs
    };
  }
  const meta = getMetaTagsByPathname(tags, pathname);
  if (meta) {
    return _objectSpread({}, meta, {
      type: meta.type || 'page',
      canonical
    });
  }
  const isProductPage = window.location.pathname.indexOf('product') !== -1;
  if (isProductPage && product && product._id) {
    const {
      seoTitle,
      seoDescription,
      descr,
      name,
      logo,
      logoBranded,
      seoSlug
    } = product;
    const edgePrices = Price.calculateDefaultEdgePrice({
      product
    });
    const title = seoTitle || `${name.split(' ').map(word => word ? word[0].toUpperCase() + word.slice(1) : word).join(' ')} - Swag.com`;
    return {
      robots: null,
      title,
      schemaOrgProduct: {
        name: title,
        image: logoBranded || logo,
        url: seoSlug ? `https://swag.com${APP_ROUTES.PRODUCT}/${seoSlug}` : null,
        description: seoDescription || descr,
        sku: product.num,
        color: product.colored ? product.variants.map(({
          name
        }) => name).join('/') : null,
        price: Price.formatNumber(edgePrices.minPrice, {
          addComma: false
        }),
        inStock: !isOutOfStock
      },
      description: seoDescription || descr,
      type: 'product',
      canonical: canonical,
      image: logoBranded || logo,
      breadcrumbs
    };
  }
  const {
    seoBrowserTabTitle,
    seoBrowserTabDescription,
    name
  } = currentCategory;
  if (!name) {
    return {};
  }
  let title = seoBrowserTabTitle || `Custom promotional ${name}`;
  const description = seoBrowserTabDescription || '';
  if (isAllProductsListPage) {
    title = generateTitleForAllProductsPage(name);
  }
  return {
    robots: null,
    title,
    description,
    canonical,
    type: 'category',
    image: products && products[0] && products[0].logo,
    faqQuestions,
    productsList,
    breadcrumbs
  };
});