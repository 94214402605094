import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { isConfirmPublicApiCardRemovingPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { removePublicApiCreditCard } from '../../../../../../redux/api-settings/api-settings.action';
import { ConfirmCreditCardRemovingPopup as ConfirmCreditCardRemovingPopupComponent } from './confirm-card-removing-popup';
const mapStateToProps = createStructuredSelector({
  isOpen: isConfirmPublicApiCardRemovingPopupShownSelector
});
const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(hidePopup()),
  onSubmit: () => {
    dispatch(removePublicApiCreditCard());
    dispatch(hidePopup());
  }
});
export const ConfirmCreditCardRemovingPopup = connect(mapStateToProps, mapDispatchToProps)(ConfirmCreditCardRemovingPopupComponent);