import { createSelector } from 'reselect';
import { isBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { calculateBundleQuantitiesByContentSelector } from '../swag-bundles/selectors/swag-bundles-calculation-bundle-content.selectors';
import { selectedProductsSelector } from './products.selectors';
export const selectedProductsOutOfStockSelector = createSelector(selectedProductsSelector, state => state, (selectedProducts, state) => selectedProducts.filter(product => {
  const {
    inStock = 0,
    inReserveQuantity = 0,
    inProduction = 0,
    variants
  } = product;
  if (isBundle(variants[0])) {
    const {
      availableToSend
    } = calculateBundleQuantitiesByContentSelector(state, {
      productId: product._id
    });
    return availableToSend <= 0;
  }
  return inStock + inProduction - inReserveQuantity <= 0;
}));
export const selectedProductsOutOfStockWithoutProductionSelector = createSelector(selectedProductsSelector, state => state, (selectedProducts, state) => selectedProducts.filter(product => {
  const {
    inStock = 0,
    inReserveStockQuantity = 0,
    variants
  } = product;
  if (isBundle(variants[0])) {
    const {
      inStock
    } = calculateBundleQuantitiesByContentSelector(state, {
      productId: product._id
    });
    return inStock <= 0;
  }
  return inStock - inReserveStockQuantity <= 0;
}));