import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import PromocodeApi from '../../api/promocode.api';
const PREFIX = 'PAYMENT_';
export const GATHER_ADDRESS_INFO = `${PREFIX}GATHER_ADDRESS_INFO`;
export const gatherBillingAddressInfo = () => ({
  type: GATHER_ADDRESS_INFO
});
export const GATHER_CARD_INFO = `${PREFIX}GATHER_CARD_INFO`;
export const gatherCardInfo = () => ({
  type: GATHER_CARD_INFO
});
export const REMOVE_PROMOCODE_ERROR = `${PREFIX}REMOVE_PROMOCODE_ERROR`;
export const GET_PROMOCODE_STARTED = `${PREFIX}GET_PROMOCODE_STARTED`;
export const GET_PROMOCODE_ERROR = `${PREFIX}GET_PROMOCODE_ERROR`;
export const GET_PROMOCODE_SUCCESS = `${PREFIX}GET_PROMOCODE_SUCCESS`;
export const getPromocode = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REMOVE_PROMOCODE_ERROR
    });
    dispatch({
      type: GET_PROMOCODE_STARTED
    });
  },
  fn: PromocodeApi.getByCode,
  success: promocode => ({
    type: GET_PROMOCODE_SUCCESS,
    payload: promocode
  }),
  error: error => ({
    type: GET_PROMOCODE_ERROR,
    error
  })
});
export const REMOVE_PROMOCODE = `${PREFIX}REMOVE_PROMOCODE`;
export const removePromocode = () => ({
  type: REMOVE_PROMOCODE
});