import _flatten from "lodash/flatten";
import _pick from "lodash/pick";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { isBundle, isBundleWithSizes } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { capitalize } from 'swag-common/utils/strings';
import { GIVEAWAY_SHIPMENT_ROWS_PER_PAGE, GiveawayCopyLinkTypeTab, GiveawayLinkCsvSorting, GiveawayProductChoice, GiveawayStep, isGiveawayNameValid } from 'swag-common/constants/giveaway.constants';
import { getIsValidGiveawayValidation } from 'swag-common/business-logic/giveaway/validation/get-giveaway-validation';
import { GIVEAWAY_VALIDATION_MESSAGES } from 'swag-client-common/utils/constants';
import { GiveawayTypes } from 'swag-common/interfaces';
import { GiveawayUrl } from 'swag-common/utils/urls/giveaway.url';
import { isGiveawayStepPassed } from 'swag-common/business-logic/giveaway/is-giveaway-step-passed';
import { isGiveawayEnded } from 'swag-common/business-logic/giveaway/is-giveaway-ended';
import { isUserInventoryOwner } from 'swag-common/business-logic/inventory/is-user-inventory-owner.logic';
import { isGiveawaySuspended } from 'swag-common/business-logic/giveaway/is-giveaway-suspended';
import checkIsCustomProductApparel from 'swag-common/utils/custom-product/check-is-custom-product-apparel';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import isBoxWithSizes from 'swag-common/business-logic/custom-store-product/is-box-with-sizes.logic';
import { getInventoryGoodieProductsDetails, getInventoryProductsDetails } from 'swag-common/business-logic/inventory/get-distribution-products-details.logic';
import { getRangeOfDaysToShipAfter } from 'swag-common/business-logic/custom-store-shipout/get-shipout-shipped-date.logic';
import { checkIsAllRecipientsShippedOrDelivered } from 'swag-common/business-logic/custom-store-shipout/check-is-all-recipients-shipped-or-delivered';
import { checkIsGiveawayWithGoodieType, checkIsGiveawayWithSwagType, getGiveawayTypeLabel } from 'swag-common/utils/giveaway/giveaway-type.util';
import { getInHandDateFormatted, getPaymentMethodForDistributions } from 'swag-common/utils/order';
import { dateFormatter } from 'swag-common/utils/date-lib';
import { getGiveawayColorWithFallback } from 'swag-common/utils/giveaway/giveaway-colors.util';
import { checkIsCustomProductForbiddenToShipInternationally } from 'swag-common/utils/order/validate-order-for-international-shipping.util';
import { TOOLTIP_ERROR_MESSAGE, VALIDATION_ERROR_MESSAGES } from 'swag-client-common/constants/validation-error-messages';
import { demoInventoryNotExtendedSelector, isSelectedDemoInventorySelector, selectedInventorySelector } from '../inventory/inventory.selectors';
import { getUniqueColorsOfProductWithQuantities, isProductsLoadedSelector, productsSelector } from '../products/products.selectors';
import { advancedDistributionsSelector } from '../advanced-distributions/advanced-distributions.selectors';
import { calculateBundleQuantitiesByContentSelector } from '../swag-bundles/selectors/swag-bundles-calculation-bundle-content.selectors';
import { giveawayIdSelector, selectedGiveawaySelector } from './selectors/common.selectors';
import { isGiveawayNotecardStepValid, selectedGiveawayNotecardEditorSelector } from './selectors/giveaway-notecard.selectors';
import { isSomeProductZeroStockAndProduction } from './giveaway.utils';
import { goodieGiveawayBudgetRootSelector, isBudgetStepValidSelector } from './selectors/goodie-giveaway-budget.selectors';
export const giveawaySelector = state => state.giveaway;
export const giveawayEditingSelector = createSelector(giveawaySelector, giveaway => giveaway.editedModel);
export const giveawayIsEditingSelector = createSelector(giveawaySelector, giveaway => giveaway.editing);
export const giveawayEditingNameSelector = createSelector(giveawayEditingSelector, editing => editing.name);
export const giveawayEditingDescriptionSelector = createSelector(giveawayEditingSelector, editing => editing.description);
export const variantCapMapSelector = createSelector(giveawaySelector, giveaway => giveaway.variantCapMap);
export const isGiveawayOneProductEnabledSelector = createSelector(giveawaySelector, giveaway => giveaway.isOneProduct);
export const giveawayEditingFilenamePrefixSelector = createSelector(giveawayEditingNameSelector, name => {
  return name ? name + '_' : '';
});
export const giveawayEditingPrimaryColorSelector = createSelector(giveawayEditingSelector, editing => editing.primaryColor);
export const giveawayEditingLogoSelector = createSelector(giveawayEditingSelector, editing => editing.logo);
export const giveawayEditingTypeSelector = createSelector(giveawayEditingSelector, editing => editing.giveawayType);
export const isGoodieGiveawaySelector = createSelector(giveawayEditingTypeSelector, giveawayType => {
  return checkIsGiveawayWithGoodieType({
    giveawayType
  });
});
export const giveawayNamingHeadingSelector = createSelector(giveawayEditingTypeSelector, giveawayType => {
  return checkIsGiveawayWithSwagType({
    giveawayType
  }) ? 'Create your gift giveaway!' : 'Create your Goodie Giveaway!';
});
export const lastGiveawaySelector = createSelector(giveawaySelector, giveaway => giveaway === null || giveaway === void 0 ? void 0 : giveaway.lastGiveaway);
export const lastGiveawayItemSelector = createSelector(lastGiveawaySelector, lastGiveaway => lastGiveaway.item);
export const isLoadedGiveawayLastGiveawaySelector = createSelector(lastGiveawaySelector, giveaway => giveaway.isLoaded);
export const giveawayPreviewUrlSelector = createSelector(selectedInventorySelector, giveawayIdSelector, GiveawayUrl.getGiveawayPreview);
export const giveawayStepSelector = createSelector(giveawaySelector, ({
  step
}) => step);
export const giveawayActiveStepSelector = createSelector(giveawayStepSelector, ({
  activeStep
}) => activeStep);
export const giveawayPassedStepsSelector = createSelector(giveawayStepSelector, ({
  passedSteps
}) => passedSteps);
export const giveawaySkippedStepsSelector = createSelector(giveawayStepSelector, ({
  skippedSteps
}) => skippedSteps);
export const giveawayPassedStepsSavedSelector = createSelector(giveawayStepSelector, step => step.passedStepsSaved);
export const giveawayProductIdsSelector = createSelector(giveawaySelector, giveaway => giveaway.productIds);
export const doesGiveawayHaveSomeProductsSelected = createSelector(giveawayProductIdsSelector, productIds => productIds.length > 0);
export const selectedProductsForGiveawaySelector = createSelector(productsSelector, giveawayProductIdsSelector, (products, productsIds) => products.filter(({
  _id
}) => productsIds.includes(_id)));
const popupParamsSelector = state => state.popupManager.params;
export const selectedApparelProductsForGiveawaySelector = createSelector(selectedProductsForGiveawaySelector, popupParamsSelector, (selectedProducts, popupParams) => {
  const {
    selectedProductId
  } = popupParams;
  const products = selectedProducts.reduce((all, product) => {
    if (product._id !== selectedProductId) {
      return all;
    }
    const isProductBox = isBox(product.swagProductId);
    const isBoxContainApparel = isProductBox && isBoxWithSizes(product);
    const isBundleContainApparel = isBundle(product.variants[0]) && isBundleWithSizes(product.variants[0]);
    if (!checkIsCustomProductApparel(product) && !isBoxContainApparel && !isBundleContainApparel) {
      return all;
    }
    const colors = getUniqueColorsOfProductWithQuantities(product, true);
    return [...all, ...colors];
  }, []);
  return products;
});
export const isBundleSelectedApparelProductsForGiveawaySelector = createSelector(selectedProductsForGiveawaySelector, popupParamsSelector, (selectedProducts, popupParams) => {
  const {
    selectedProductId
  } = popupParams;
  const isBundleSelected = selectedProducts.some(product => {
    if (product._id !== selectedProductId) {
      return false;
    }
    return isBundle(product.variants[0]);
  });
  return isBundleSelected;
});
export const getTotalStockAndProductionOfSelectedProductsSelector = createSelector(selectedProductsForGiveawaySelector, selectedProducts => {
  return selectedProducts.reduce((acc, product) => {
    var _product$inStock, _product$inProduction;
    return {
      inStock: acc.inStock + ((_product$inStock = product.inStock) !== null && _product$inStock !== void 0 ? _product$inStock : 0),
      inProduction: acc.inProduction + ((_product$inProduction = product.inProduction) !== null && _product$inProduction !== void 0 ? _product$inProduction : 0)
    };
  }, {
    inStock: 0,
    inProduction: 0
  });
});
export const isAllProductsZeroInStockAndProductionSelector = createSelector(getTotalStockAndProductionOfSelectedProductsSelector, total => total.inStock === 0 && total.inProduction === 0);
export const giveawayProductIdsExistSelector = createSelector(giveawayProductIdsSelector, productIds => !!productIds.length);
export const giveawayCopyLinksTypeTabSelector = createSelector(giveawaySelector, giveaway => giveaway.copyLinksTypeTab);
export const isGiveawaySingleLinkModeEnabledSelector = createSelector(giveawaySelector, giveaway => giveaway.singleLinkMode);
export const giveawayCustomSingleLinkRootSelector = createSelector(giveawaySelector, giveaway => giveaway.customSingleLink);
export const giveawayCustomSingleLinkValueSelector = createSelector(giveawayCustomSingleLinkRootSelector, customLink => customLink.value);
export const giveawayCustomSingleLinkErrorSelector = createSelector(giveawayCustomSingleLinkRootSelector, customLink => customLink.error);
export const isGiveawayCustomSingleLinkValidSelector = createSelector(giveawayCustomSingleLinkValueSelector, giveawayCustomSingleLinkErrorSelector, (value, error) => !error && value && value.length >= 5 && value.length <= 120);
export const isGiveawayReserveModeEnabledSelector = createSelector(giveawaySelector, giveaway => giveaway.reserveMode);
export const giveawayProductChoiceSelector = createSelector(giveawayEditingSelector, editing => editing.productChoice);
export const giveawayProductQuantitySelector = createSelector(giveawayEditingSelector, editing => editing.productQuantity);
export const giveawaySingleLinkDomainsSelector = createSelector(giveawayEditingSelector, editing => editing.domains);
export const isGiveawaySingleLinkCapEnabledSelector = createSelector(giveawayEditingSelector, editing => editing.isCapEnabled);
export const giveawaySingleLinkCapValueSelector = createSelector(giveawayEditingSelector, editing => editing.capValue);
export const giveawaySingleLinkSubmissionsSelector = createSelector(giveawayEditingSelector, editing => editing.submissions);
export const giveawayProductsQuantitySelector = createSelector(giveawaySelector, giveaway => giveaway.productIds.length);
export const isProductChoiceStepValidSelector = createSelector(giveawayProductsQuantitySelector, giveawayEditingSelector, (quantity, editing) => editing.productChoice === GiveawayProductChoice.all || editing.productChoice === GiveawayProductChoice.choice && editing.productQuantity <= quantity);
export const isGiveawayCapValueValidSelector = createSelector(giveawayIsEditingSelector, isGiveawaySingleLinkCapEnabledSelector, isGiveawaySingleLinkModeEnabledSelector, giveawaySingleLinkSubmissionsSelector, (editing, isCapEnabled, isSingleLink, submissions) => editing && isCapEnabled && isSingleLink && submissions > 0);
export const giveawayColorsSelector = createSelector(giveawayEditingSelector, editing => editing.colors);
export const giveawayProductsSelectorFactory = productsIdsSelectorFunction => createSelector(productsIdsSelectorFunction, productsSelector, (productIds, inventoryProducts) => inventoryProducts.filter(({
  _id
}) => productIds.includes(_id)));
export const giveawayProductsSelector = giveawayProductsSelectorFactory(giveawayProductIdsSelector);
export const giveawayProductsWithColorsSelectorFactory = productsIdsSelectorFunction => createSelector(giveawayProductsSelectorFactory(productsIdsSelectorFunction), products => products.map(product => {
  return {
    product,
    colors: getUniqueColorsOfProductWithQuantities(product)
  };
}));
export const giveawayProductsWithColorsByProductIdsSelector = selector => giveawayProductsWithColorsSelectorFactory(selector);
export const giveawayProductsWithColorsSelector = giveawayProductsWithColorsSelectorFactory(giveawayProductIdsSelector);
export const giveawayProductIdsWithColorSelector = createSelector(giveawayProductsWithColorsSelector, products => {
  return products.map(({
    product
  }) => product._id);
});
export const giveawayProductIdsWithMultipleColorsSelector = createSelector(giveawayProductsWithColorsSelector, products => {
  return products.filter(({
    colors
  }) => colors.length > 1).map(({
    product
  }) => product._id);
});
export const isSomeProductZeroStockAndProductionSelector = createSelector(giveawayProductsSelector, isSomeProductZeroStockAndProduction);
export const dataToSaveGiveawaySelector = createSelector(giveawayEditingSelector, giveawayActiveStepSelector, giveawayPassedStepsSelector, giveawayProductIdsSelector, isGiveawaySingleLinkModeEnabledSelector, giveawayCopyLinksTypeTabSelector, isGiveawayReserveModeEnabledSelector, isGiveawayOneProductEnabledSelector, variantCapMapSelector, selectedGiveawayNotecardEditorSelector, goodieGiveawayBudgetRootSelector, giveawaySingleLinkDomainsSelector, (editing, step, passedSteps, productIds, isSingleLink, copyLinksTypeTab, isReserve, isGiveawayOneProductEnabled, variantCapMap, notecard, goodieBudget, domains) => {
  const {
    budget,
    recipientQuantity
  } = goodieBudget;
  const draft = _objectSpread({}, _pick(editing, ['isCapEnabled', 'logo', 'name', 'colors', 'primaryColor', 'productChoice', 'productQuantity', 'description', 'giveawayType']), editing.isCapEnabled && isSingleLink && {
    capValue: editing.capValue
  }, {
    step,
    isReserve,
    passedSteps,
    isSingleLink,
    copyLinksTypeTab,
    products: productIds,
    isOneProduct: isGiveawayOneProductEnabled,
    variantCapMap: new Array(...variantCapMap.values()),
    notecard: notecard && _pick(notecard, ['editorRawContent', 'color', 'logo']),
    budget,
    recipientQuantity,
    domains
  });
  return draft;
});
export const dataToSaveGiveawaySelectorExtended = createSelector(dataToSaveGiveawaySelector, giveawaySingleLinkDomainsSelector, (dataToSave, domains) => _objectSpread({}, dataToSave, {
  domains
}));
const mapDataToSaveToDataToSaveDraft = dataToSave => {
  const dataToSaveDraft = {
    autopayCard: null,
    autopayPaymentMethod: null,
    logo: dataToSave.logo,
    name: dataToSave.name,
    colors: dataToSave.colors,
    primaryColor: dataToSave.primaryColor,
    productChoice: dataToSave.productChoice,
    productQuantity: dataToSave.productQuantity || 1,
    step: dataToSave.step,
    isSingleLink: dataToSave.isSingleLink,
    copyLinksTypeTab: dataToSave.copyLinksTypeTab,
    description: dataToSave.description,
    isCapEnabled: dataToSave.isCapEnabled,
    capValue: dataToSave.capValue || 0,
    isReserve: dataToSave.isReserve,
    isOneProduct: dataToSave.isOneProduct,
    variantCapMap: dataToSave.variantCapMap,
    notecard: dataToSave.notecard,
    products: dataToSave.products,
    domains: dataToSave.domains,
    giveawayType: dataToSave.giveawayType,
    budget: dataToSave.budget,
    recipientQuantity: dataToSave.recipientQuantity,
    passedSteps: dataToSave.passedSteps
  };
  return dataToSaveDraft;
};
export const dataToSaveGiveawayDraftSelector = createSelector(dataToSaveGiveawaySelectorExtended, mapDataToSaveToDataToSaveDraft);
export const dataToSaveGiveawayDraftCreationSelector = createSelector(dataToSaveGiveawaySelectorExtended, dataToSave => {
  return _objectSpread({}, mapDataToSaveToDataToSaveDraft(dataToSave), {
    products: dataToSave.products
  });
});

/**
 * Simplified version of selector
 */

export const isGiveawayFormValidSelectorByStepSelector = step => {
  const validator = getIsValidGiveawayValidation(step);
  return createSelector(giveawayEditingSelector, giveawayProductsWithColorsSelector, (editing, productsWithColors) => validator({
    editing,
    productsWithColors
  }));
};
export const isGiveawayNameValidSelector = createSelector(giveawayEditingNameSelector, name => isGiveawayNameValid(name));
export const isGiveawayCustomizeStepValid = isGiveawayFormValidSelectorByStepSelector(GiveawayStep.customize);
export const productSelectorByProductId = productId => {
  return createSelector(productsSelector, inventoryProducts => inventoryProducts.find(({
    _id
  }) => _id === productId));
};
const productIdFromPropsSelector = (state, props) => props.productId;
const productByProductIdSelector = createSelector(productIdFromPropsSelector, productsSelector, (productId, inventoryProducts) => {
  return inventoryProducts.find(({
    _id
  }) => _id === productId);
});
export const productSelectorByProductIdWithAdditionalInformation = createSelector(productByProductIdSelector, product => {
  if (!product) {
    return null;
  }
  const isProductBox = isBox(product.swagProductId);
  const isBoxContainApparel = isProductBox && isBoxWithSizes(product);
  const isBundleContainApparel = isBundle(product.variants[0]) && isBundleWithSizes(product.variants[0]);
  const isForbiddenToShipInternationally = checkIsCustomProductForbiddenToShipInternationally(product);
  return _objectSpread({}, product, {
    isApparel: checkIsCustomProductApparel(product) || isBoxContainApparel || isBundleContainApparel,
    isForbiddenToShipInternationally
  });
});
export const giveawayProductColorsSelector = productId => {
  return createSelector(productSelectorByProductId(productId), product => {
    if (!product) {
      return [];
    }
    return getUniqueColorsOfProductWithQuantities(product, true);
  });
};
export const giveawayProductColorsToShowSelector = createSelector(productByProductIdSelector, isGiveawayReserveModeEnabledSelector, calculateBundleQuantitiesByContentSelector, (product, isGiveawayReserveMode, bundleQuantities) => {
  if (!product) {
    return [];
  }
  if (isBundle(product.variants[0])) {
    const variant = product.variants[0];
    return [{
      colorValue: variant.colorValue,
      colorName: variant.colorName,
      productId: product._id,
      images: variant.images,
      quantity: bundleQuantities.inStock,
      overallAvailable: bundleQuantities.availableToSend
    }];
  }
  const productUniqueColors = getUniqueColorsOfProductWithQuantities(product, true);
  return productUniqueColors.filter(({
    overallAvailable
  }) => {
    if (!isGiveawayReserveMode) {
      return true;
    }
    const available = overallAvailable || 0;
    return isGiveawayReserveMode && available === 0 || available > 0;
  });
});
export const giveawaySelectedProductColorsSelector = createSelector(giveawayProductColorsToShowSelector, giveawayColorsSelector, productIdFromPropsSelector, (colorsToShow, colors, productId) => {
  return colorsToShow.filter(currentColorObject => {
    const items = colors[productId] || [];
    const active = items.includes(currentColorObject.colorValue);
    return active;
  });
});
export const giveawayProductColorsWithQuantitySelector = productId => {
  return createSelector(giveawayProductColorsSelector(productId), isGiveawayReserveModeEnabledSelector, (colors, isReserve) => isReserve ? colors.filter(color => color.overallAvailable !== 0) : colors);
};
export const giveawayProductColorsWithStockSelector = productId => {
  return createSelector(giveawayProductColorsSelector(productId), colors => {
    return colors.filter(color => color.quantity !== 0);
  });
};
export const isGiveawayProductColorActive = (productId, color) => {
  return createSelector(giveawayColorsSelector, colors => {
    const items = colors[productId] || [];
    const active = items.includes(color);
    return active;
  });
};
export const isLoadedGiveawayProductsSelector = createSelector(isProductsLoadedSelector, isProductsLoaded => isProductsLoaded);
export const giveawayProductIdsWithMultipleColorsLengthSelector = createSelector(giveawayProductIdsWithMultipleColorsSelector, productIds => productIds.length);
export const giveawayProductsOfferDescriptionSelector = createSelector(giveawayProductIdsWithColorSelector, giveawayProductIdsWithMultipleColorsLengthSelector, (productsIds, multipleColors) => {
  const textTitle = `${productsIds.length === 1 ? 'This is the product' : 'These are the products'} that you are going to offer!`;
  let textDescription;
  if (multipleColors === 1) {
    if (productsIds.length === 1) {
      textDescription = 'Your product comes';
    } else {
      textDescription = 'One of your product comes';
    }
  } else {
    textDescription = 'Several of your products come';
  }
  const textDescriptionCommon = 'in multiple colors, please select what color(s) you want to offer!';
  return `${textTitle} ${multipleColors === 0 ? '' : `${textDescription} ${textDescriptionCommon}`}`;
});
export const giveawayDataForNextStepSelector = createSelector(giveawaySelector, state => state, giveawayActiveStepSelector, giveawayProductIdsWithMultipleColorsLengthSelector, giveawayPassedStepsSavedSelector, (giveaway, state, activeStep, productsWithMultipleColorsNumber, passedStepsSaved) => {
  const isStepValid = isGiveawayFormValidSelectorByStepSelector(activeStep)(state);
  return {
    data: giveaway,
    isStepValid,
    productsWithMultipleColorsNumber,
    passedStepsSaved
  };
});
export const isGiveawayPageLoaded = createSelector(isLoadedGiveawayProductsSelector, isLoadedGiveawayLastGiveawaySelector, (isLoadedProducts, isLoadedLastGiveaway) => isLoadedProducts && isLoadedLastGiveaway);
export const giveawaySendTypeTabSelector = createSelector(giveawaySelector, giveaway => giveaway.sendTypeTab);
const giveawayLinksSelector = createSelector(giveawaySelector, giveaway => giveaway.links);
export const giveawayFewLinksSelector = createSelector(giveawayLinksSelector, links => links.few);
export const giveawayCsvLinksSelector = createSelector(giveawayLinksSelector, links => links.uploadCsv);
const mapLinkItemsSelector = state => items => items.map(item => _objectSpread({}, item, {
  link: getGiveawayUserLandingFullSelector(location.origin, item.link)(state)
}));
export const giveawayFewLinksItemsSelector = createSelector(giveawayFewLinksSelector, mapLinkItemsSelector, (links, mapLinkItems) => mapLinkItems(links.items));
export const giveawaySingleLinkItemSelector = createSelector(giveawayFewLinksSelector, links => links.items.find(link => link.isSingleLink) || null);
export const isGiveawayFewLinksLoadedSelector = createSelector(giveawayFewLinksSelector, links => links.loaded);
export const giveawayFileUploadedSelector = createSelector(giveawayCsvLinksSelector, links => links.fileLoaded);
export const isGiveawayFileUploadedSelector = createSelector(giveawayFileUploadedSelector, fileLoaded => !!fileLoaded);
export const giveawayCsvLinksItemsSelector = createSelector(giveawayCsvLinksSelector, mapLinkItemsSelector, (links, mapLinkItems) => mapLinkItems(links.items));
export const isGiveawayCsvLinksLoadedSelector = createSelector(giveawayCsvLinksSelector, links => links.loaded);
export const isGiveawayCsvLinksSortingSelector = createSelector(giveawayCsvLinksSelector, links => links.sorting);
export const giveawayCsvLinksItemsSortedSelector = createSelector(giveawayCsvLinksItemsSelector, isGiveawayCsvLinksSortingSelector, (items, sorting) => {
  const itemsSorted = [...items];
  if (sorting === GiveawayLinkCsvSorting.none) {
    return [...items];
  }
  itemsSorted.sort((a, b) => {
    return (sorting === GiveawayLinkCsvSorting.az ? 1 : -1) * a.firstName.localeCompare(b.firstName);
  });
  return itemsSorted;
});
export const giveawayCsvLinksExistsSelector = createSelector(giveawayCsvLinksItemsSelector, items => !!items.length);
export const giveawayFewLinksExistsSelector = createSelector(giveawayFewLinksItemsSelector, items => !!items.length);
export const availableToEditGiveawayStepsSelector = createSelector((_, {
  giveaway
}) => giveaway, giveaway => [giveaway.step]);
export const isGiveawayEditingSelector = createSelector(giveawaySelector, selectedGiveawaySelector, (giveaway, selectedGiveaway) => giveaway.editing && ((selectedGiveaway === null || selectedGiveaway === void 0 ? void 0 : selectedGiveaway.step) === GiveawayStep.completed || (selectedGiveaway === null || selectedGiveaway === void 0 ? void 0 : selectedGiveaway.step) === GiveawayStep.suspended));
export const isGiveawayStepPassedSelector = step => createSelector(giveawayPassedStepsSelector, passedSteps => isGiveawayStepPassed(passedSteps, step));
export const isGiveawayStepSavedPassedSelector = step => createSelector(giveawayPassedStepsSavedSelector, passedSteps => isGiveawayStepPassed(passedSteps, step));
export const isGiveawayEndedByGiveawaySelector = createSelector((_, {
  giveaway: {
    step
  }
}) => step, isGiveawayEnded);
export const isGiveawaySuspendedByGiveawaySelector = createSelector((_, {
  giveaway: {
    step
  }
}) => step, isGiveawaySuspended);
export const giveawayBreakdownProductsSelector = createSelector((_, props) => props.giveaway, productsSelector, (giveaway, products) => {
  if (!giveaway) {
    return [];
  }
  if (giveaway.giveawayType === GiveawayTypes.goodie) {
    return Object.values(giveaway.usedProductsFromLinks);
  }
  const mapVariantIdToProduct = products.reduce((map, product) => _objectSpread({}, map, product.variants.reduce((perProduct, variant) => _objectSpread({}, perProduct, {
    [String(variant._id)]: variant
  }), {})), {});
  const breakdown = Object.entries(giveaway.usedProductsFromLinks).reduce((total, [productId, {
    count
  }]) => {
    if (!mapVariantIdToProduct[productId]) {
      return {};
    }
    const {
      name,
      parentId
    } = mapVariantIdToProduct[productId];
    if (total[parentId]) {
      return _objectSpread({}, total, {
        [parentId]: _objectSpread({}, total[parentId], {
          count: total[parentId].count + count
        })
      });
    }
    return _objectSpread({}, total, {
      [parentId]: {
        name: capitalize(name),
        count: 1
      }
    });
  }, {});
  return Object.values(breakdown);
});
export const isSelectedDemoGiveawaySelector = createSelector(isSelectedDemoInventorySelector, selectedGiveawaySelector, demoInventoryNotExtendedSelector, (isDemoInventory, giveaway, inventory) => {
  return isDemoInventory && inventory && giveaway && isUserInventoryOwner(inventory, giveaway.authorId);
});
export const giveawayShipmentsSelector = createSelector(giveawaySelector, giveaway => giveaway.shipments);
export const giveawayShipmentsListSelector = createSelector(giveawayShipmentsSelector, shipments => shipments.list);
export const shipmentsPageSelector = createSelector(giveawayShipmentsSelector, shipments => shipments.page);
export const shipmentsTotalSelector = createSelector(giveawayShipmentsSelector, shipments => shipments.total);
export const isShipmentsPaginationShouldShownSelector = createSelector(shipmentsTotalSelector, total => total > GIVEAWAY_SHIPMENT_ROWS_PER_PAGE);
export const giveawayShipmentsListWithFormattedRecipientsSelector = createSelector(giveawayShipmentsListSelector, productsSelector, (list, products) => {
  const productsVariants = _flatten(products.map(product => product.variants));
  return list.map(shipment => {
    const {
      paymentMethod
    } = shipment;
    const isAllRecipientsShippedOrDelivered = checkIsAllRecipientsShippedOrDelivered(shipment.status);
    let shippingDate;
    if (isAllRecipientsShippedOrDelivered) {
      var _earliestRecipient$sh, _earliestRecipient$sh2;
      const earliestRecipient = shipment.recipients.reduce((pre, cur) => {
        var _pre$shipment, _cur$shipment;
        return new Date((pre === null || pre === void 0 ? void 0 : (_pre$shipment = pre.shipment) === null || _pre$shipment === void 0 ? void 0 : _pre$shipment.shippedAt) || Date.now()).getTime() > new Date((cur === null || cur === void 0 ? void 0 : (_cur$shipment = cur.shipment) === null || _cur$shipment === void 0 ? void 0 : _cur$shipment.shippedAt) || Date.now()).getTime() ? cur : pre;
      });
      shippingDate = earliestRecipient !== null && earliestRecipient !== void 0 && (_earliestRecipient$sh = earliestRecipient.shipment) !== null && _earliestRecipient$sh !== void 0 && _earliestRecipient$sh.shippedAt ? dateFormatter(new Date(earliestRecipient === null || earliestRecipient === void 0 ? void 0 : (_earliestRecipient$sh2 = earliestRecipient.shipment) === null || _earliestRecipient$sh2 === void 0 ? void 0 : _earliestRecipient$sh2.shippedAt)) : 'N/A';
    } else {
      shippingDate = getRangeOfDaysToShipAfter(shipment.creationDate, '', true);
    }
    return _objectSpread({}, shipment, {
      shippingDate,
      paymentMethod: getPaymentMethodForDistributions(paymentMethod),
      creationDate: dateFormatter(new Date(shipment.creationDate)) || 'N/A',
      recipients: shipment.recipients.map(rec => {
        var _rec$goodieProducts;
        return {
          _id: rec.giveawayLinkId,
          firstName: rec.firstName,
          lastName: rec.lastName,
          email: rec.email,
          phone: rec.phone,
          address: rec.address,
          unit: rec.unit,
          city: rec.city,
          state: rec.state,
          zip: rec.zip,
          country: rec.country,
          totalCost: rec.totalAmountPaid,
          shipment: rec.shipment,
          status: rec.status,
          budget: rec.budget,
          shipping: rec.shipment.shipmentPrice,
          appliedPromocode: rec.appliedPromocode,
          products: (_rec$goodieProducts = rec.goodieProducts) !== null && _rec$goodieProducts !== void 0 && _rec$goodieProducts.length ? getInventoryGoodieProductsDetails(rec.goodieProducts) : getInventoryProductsDetails(productsVariants.flatMap(variant => {
            const variantId = String(variant._id);
            if (Object.keys(rec.products).includes(variantId)) {
              return _objectSpread({}, variant, {
                shipoutQuantity: rec.products[variantId]
              });
            }
            return [];
          }))
        };
      })
    });
  });
});
export const isGiveawayTypeCanBeChangedSelector = createSelector(giveawaySelector, giveaway => {
  return !giveaway.isChangeable;
});
export const doesGiveawayHaveFeedbacksSelector = createSelector((_, props) => {
  return props.giveaway;
}, ({
  linksWithFeedback
}) => {
  return linksWithFeedback !== 0 && linksWithFeedback !== undefined;
});
export const getVariantCapsValidationSelector = createSelector(variantCapMapSelector, variantCapMap => {
  return Array.from(variantCapMap.values()).reduce((acc, {
    capAmount,
    colorVariantId
  }) => {
    if (colorVariantId && capAmount === 0) {
      acc[colorVariantId] = [GIVEAWAY_VALIDATION_MESSAGES.MIN_QUANTITY_CAP];
    }
    return acc;
  }, {});
});
export const getGiveawayColorsValidationSelector = createSelector(giveawayColorsSelector, allProductsColors => {
  return Object.entries(allProductsColors).reduce((acc, [productColorId, productColors]) => {
    if (!(productColors !== null && productColors !== void 0 && productColors.length)) {
      acc[productColorId] = [GIVEAWAY_VALIDATION_MESSAGES.SELECT_AT_LEAST_ONE_COLOR];
    }
    return acc;
  }, {});
});
const areGiveawayColorsValidSelector = createSelector(getGiveawayColorsValidationSelector, colorsValidation => {
  return !Object.values(colorsValidation).some(validation => validation);
});
const areVariantCapsValidSelector = createSelector(getVariantCapsValidationSelector, isGiveawayOneProductEnabledSelector, (variantCapValidation, isGiveawayOneProductEnabled) => {
  if (isGiveawayOneProductEnabled) {
    return true;
  }
  return !Object.values(variantCapValidation).some(validation => validation);
});
export const giveawayEditingIsValidatedCapSelector = createSelector(giveawayEditingSelector, areVariantCapsValidSelector, (editing, isCapValid) => {
  return editing.isValidatedCap && isCapValid;
});
const isGiveawayReserveStepValid = createSelector(giveawayEditingIsValidatedCapSelector, areGiveawayColorsValidSelector, (isCapValid, areColorsValid) => {
  return isCapValid && areColorsValid;
});
export const isGiveawayValidToComplete = createSelector(giveawayCopyLinksTypeTabSelector, giveawayFewLinksExistsSelector, giveawayCsvLinksExistsSelector, giveawaySingleLinkItemSelector, isGiveawaySingleLinkModeEnabledSelector, isGiveawaySingleLinkCapEnabledSelector, giveawayEditingIsValidatedCapSelector, (tab, few, csv, singleLink, isSingleLink, capEnabled, capValidated) => !isSingleLink && tab === GiveawayCopyLinkTypeTab.uploadCsv && csv || !isSingleLink && tab === GiveawayCopyLinkTypeTab.createFewLinks && few || !capEnabled && isSingleLink && Boolean(singleLink) || capEnabled && capValidated);

// To prevent circular dependency this selector were added here
const innerGiveawayCompletedRootSelector = createSelector(giveawaySelector, giveaway => giveaway.completed);
const innerGiveawayCompletedListSelector = createSelector(innerGiveawayCompletedRootSelector, completed => completed.list);
export const giveawayCompletedListWithNotecardProductSelector = createSelector(innerGiveawayCompletedListSelector, productsSelector, (completedGiveaways, products) => {
  const completedGiveawaysWithNotecards = completedGiveaways.map(giveaway => {
    if (!giveaway.notecardId) {
      return giveaway;
    }
    const notecardProduct = products.find(({
      variants
    }) => variants.some(variant => variant._id === giveaway.notecardId));
    if (!notecardProduct) {
      return giveaway;
    }
    const {
      inStock,
      inProduction,
      inHandDate,
      isStrictInHandDate,
      defaultImage
    } = notecardProduct;
    return _objectSpread({}, giveaway, {
      notecard: _objectSpread({}, giveaway.notecard, {
        inStock,
        inProduction,
        previewSrc: defaultImage,
        inHandDate: getInHandDateFormatted({
          inHandDate: inHandDate,
          isStrictInHandDate: isStrictInHandDate
        })
      })
    });
  });
  return completedGiveawaysWithNotecards;
});
export const giveawayPopupSelector = createSelector(giveawayCompletedListWithNotecardProductSelector, popupParamsSelector, (list, popupParams) => {
  const neededGiveaway = list.find(giveaway => giveaway._id === (popupParams === null || popupParams === void 0 ? void 0 : popupParams.giveawayId));
  return neededGiveaway || null;
});
export const giveawayNotecardPopupSelector = createSelector(giveawayCompletedListWithNotecardProductSelector, popupParamsSelector, (list, popupParams) => {
  const neededGiveaway = list.find(giveaway => giveaway._id === (popupParams === null || popupParams === void 0 ? void 0 : popupParams.giveawayId));
  if (!neededGiveaway || !neededGiveaway.notecardId) {
    return null;
  }
  return neededGiveaway.notecard || null;
});
export const isGiveawayCompletedListLoading = createSelector(innerGiveawayCompletedRootSelector, ({
  isLoading
}) => {
  return isLoading;
});
export const editingGiveawayColorWithFallbackSelector = createSelector(giveawayEditingPrimaryColorSelector, giveawayColor => getGiveawayColorWithFallback(giveawayColor));
export const editingGiveawayTypeSelector = createSelector(giveawayEditingSelector, giveaway => (giveaway === null || giveaway === void 0 ? void 0 : giveaway.giveawayType) || GiveawayTypes.swag);
export const editingGiveawayTypeLabelSelector = createSelector(editingGiveawayTypeSelector, type => {
  return getGiveawayTypeLabel(type);
});
export const editingGiveawayTypeLabelLowercaseSelector = createSelector(editingGiveawayTypeLabelSelector, label => label.toLowerCase());
export const editingGiveawayHeadingSelector = createSelector(editingGiveawayTypeSelector, editingGiveawayTypeLabelSelector, (giveawayType, label) => {
  if (giveawayType === GiveawayTypes.goodie) {
    return `You can easily create a ${label} that you can send to whomever you want! Recipients will be able to choose what they want from a catalog of carefully selected products. Once they make their choices, all they have to do is input their address and we will send them their Goodie Giveaway items.`;
  }
  return `You are now able to easily create a ${label} that you can share with whoever you
    want to reward. Recipients will be able choose the product they want, input
    their address and then we will ship them their swag reward.`;
});
export const giveawayNamingPlaceholderSelector = createSelector(giveawayEditingTypeSelector, editingGiveawayTypeLabelSelector, (giveawayType, label) => {
  return checkIsGiveawayWithSwagType({
    giveawayType
  }) ? `Enter your ${label} name here` : `Enter ${label} name here`;
});
const getGiveawayUserLandingSelector = (link, category = '') => createSelector(giveawayEditingTypeSelector, giveawayType => {
  return GiveawayUrl.getGiveawayUserLanding({
    link,
    category,
    giveawayType
  });
});
export const getGiveawayUserLandingFullSelector = (baseUrl, link = '') => createSelector(getGiveawayUserLandingSelector(link), landingLink => `${baseUrl}${landingLink}`);
const isSelectLastStepValid = createSelector(giveawayEditingPrimaryColorSelector, giveawayEditingLogoSelector, (primaryColor, logo) => !!primaryColor && !!logo);
const giveawayStepsValidationMap = {
  [GiveawayStep.naming]: isGiveawayFormValidSelectorByStepSelector(GiveawayStep.naming),
  [GiveawayStep.selectProducts]: doesGiveawayHaveSomeProductsSelected,
  [GiveawayStep.productsChoice]: isProductChoiceStepValidSelector,
  [GiveawayStep.reserve]: isGiveawayReserveStepValid,
  [GiveawayStep.customize]: isGiveawayCustomizeStepValid,
  [GiveawayStep.notecard]: isGiveawayNotecardStepValid,
  [GiveawayStep.send]: isGiveawayValidToComplete,
  [GiveawayStep.budget]: isBudgetStepValidSelector,
  [GiveawayStep.selectLast]: isSelectLastStepValid
};
export const isGiveawayCurrentStepValidSelector = createSelector(state => state, giveawayActiveStepSelector, (rootState, activeStep) => {
  const selector = giveawayStepsValidationMap[activeStep];
  if (!selector) {
    return false;
  }
  return selector(rootState);
});
export const areGiveawayStepButtonsDisabledSelector = createSelector(isGiveawayCurrentStepValidSelector, isValid => !isValid);
const isProductSelectedSelector = createSelector(giveawaySelector, giveaway => Boolean(giveaway && (giveaway === null || giveaway === void 0 ? void 0 : giveaway.productIds.length)));
export const tooltipHasErrorSelectProduct = createSelector(giveawaySelector, isProductSelectedSelector, (giveaway, isProductSelected) => {
  var _giveaway$step;
  return giveaway && ((_giveaway$step = giveaway.step) === null || _giveaway$step === void 0 ? void 0 : _giveaway$step.activeStep) === GiveawayStep.selectProducts && !isProductSelected;
});
export const tooltipErrorSelectProductSelector = createSelector(tooltipHasErrorSelectProduct, hasSelectProductError => {
  return hasSelectProductError ? TOOLTIP_ERROR_MESSAGE.SELECT_PRODUCTS : VALIDATION_ERROR_MESSAGES.NOT_VALID_ON_SAVE;
});
export const swagGiveawayDefaultCapSelector = createSelector(giveawayProductsSelector, giveawayColorsSelector, (giveawayProducts, selectedProductColors) => {
  const productsCaps = giveawayProducts.reduce((acc, product) => {
    const colorsWithQuantities = getUniqueColorsOfProductWithQuantities(product, true);
    const currentSelectedColors = selectedProductColors[product._id];
    if (!currentSelectedColors) {
      return acc;
    }
    const selectedColorsWithQuantities = colorsWithQuantities.filter(({
      colorValue
    }) => currentSelectedColors.includes(colorValue));
    const colorsCaps = selectedColorsWithQuantities.map(({
      overallAvailable = 0,
      inProduction = 0
    }) => {
      const capSize = overallAvailable || inProduction;
      return capSize < 0 ? 0 : capSize;
    });
    return [...acc, ...colorsCaps];
  }, []);
  return Math.min(...productsCaps);
});
export const haveUpcomingDistributionsForChangingGiveawayReserveStatusSelector = createSelector(advancedDistributionsSelector, distributions => Boolean(distributions.length));