import { InventorySettingsPaymentWidget, InventorySettingsTabs } from '../../utils/constants';
import { resetInventoryStripeFormValidations } from './../stripe-form/stripe-form.actions';
const PREFIX = 'INVENTORY_SETTINGS_';
export const SET_INVENTORY_SETTINGS_TAB = `${PREFIX}SET_INVENTORY_SETTINGS_TAB`;
export const setInventorySettingsTabAction = tab => ({
  type: SET_INVENTORY_SETTINGS_TAB,
  payload: tab
});
export const SET_INVENTORY_SETTINGS_TAB_CONTENT_TYPE = `${PREFIX}SET_INVENTORY_SETTINGS_TAB_CONTENT_TYPE`;
export const setInventorySettingsTabPaymentWidgetAction = (tab, paymentWidget) => ({
  type: SET_INVENTORY_SETTINGS_TAB_CONTENT_TYPE,
  payload: {
    tab,
    paymentWidget
  }
});
export const onBackClickAction = () => dispatch => {
  dispatch(resetInventoryStripeFormValidations());
  dispatch(goToPaymentMethodsListAction());
};
export const goToPaymentMethodsListAction = () => setInventorySettingsTabPaymentWidgetAction(InventorySettingsTabs.paymentMethods, InventorySettingsPaymentWidget.listPaymentMethods);
export const goToPaymentMethodsEditAction = () => setInventorySettingsTabPaymentWidgetAction(InventorySettingsTabs.paymentMethods, InventorySettingsPaymentWidget.editPaymentMethod);
export const SET_DEFAULT_TAB = `${PREFIX}SET_DEFAULT_TAB`;
export const setDefaultTab = tab => ({
  type: SET_DEFAULT_TAB,
  payload: {
    tab
  }
});