export const NOTECARD_SELECTION_OPTIONS = {
  CREATE_NOTECARD: 'create-notecard',
  NO_NOTECARD: 'no-notecard'
};
export const notecardRadioGroupItems = [{
  label: 'Add a notecard',
  value: NOTECARD_SELECTION_OPTIONS.CREATE_NOTECARD
}, {
  label: "I don't need a notecard",
  value: NOTECARD_SELECTION_OPTIONS.NO_NOTECARD
}];
export const NO_APPAREL_SIZE = 'NO_APPAREL_SIZE';