import { createApi } from 'swag-client-common/libs/rapi';
import { baseUrl as inventoryBaseUrl } from 'swag-client-common/api/inventory.api';
const baseUrl = `${inventoryBaseUrl}/swag-bundle`;
export const SwagBundlesApi = createApi({
  proceed: {
    url: `${baseUrl}/proceed`,
    method: 'post'
  },
  edit: {
    url: `${baseUrl}/edit`,
    method: 'post'
  },
  orderMoreAddons: {
    url: `${baseUrl}/order-more-addons`,
    method: 'post'
  },
  delete: {
    url: `${baseUrl}/remove/:productId`,
    method: 'delete'
  },
  getById: {
    url: `${inventoryBaseUrl}/:inventoryId/swag-bundle/:bundleId`,
    method: 'get'
  }
});