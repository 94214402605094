import { createSelector } from 'reselect';
import { capitalize } from 'swag-common/utils/strings';
import { productNameSelector, productSeoSlugSelector, productCategoriesIdSelector } from '../product/product.selectors';
import { categoriesMapSelector, categoryByIdsSelector, currentCategorySelector } from '../product-listing/product-listing.selectors';
import { getBreadcrumb, getProductLink, createCategoryBreadcrumb } from './navigation.utils';
const activeCategorySelector = createSelector(productCategoriesIdSelector, categoryByIdsSelector, currentCategorySelector, (productCategories = [], categoriesById = {}, currentCategory) => {
  const productCategory = productCategories.find(c => c.isDefault) || productCategories[0];
  const category = categoriesById[productCategory === null || productCategory === void 0 ? void 0 : productCategory.categoryId];
  if (category) {
    return category;
  }
  return currentCategory || null;
});
export const breadcrumbsSelector = createSelector(activeCategorySelector, productSeoSlugSelector, productNameSelector, categoriesMapSelector, (activeCategory, seoSlug, name, categoriesMap) => {
  const categories = Object.values(categoriesMap);
  if (!categories.length) {
    return [];
  }
  const breadcrumb = createCategoryBreadcrumb(activeCategory, categories);
  if (seoSlug && name) {
    const productLink = getProductLink(seoSlug);
    const productName = capitalize(name);
    breadcrumb.push(getBreadcrumb(productName, productLink));
  }
  return breadcrumb;
});