import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { DesignUploadMode } from 'swag-common/interfaces';
import { OrderApi } from 'swag-client-common/api/order.api';
export const uploadLogoCreator = handleSuccess => createAsyncLoadedAction({
  transformData: data => {
    const formData = new FormData();
    formData.append(DesignUploadMode.fullDesign, data);
    formData.set('view', DesignUploadMode.fullDesign);
    return formData;
  },
  fn: OrderApi.uploadDesign,
  success: handleSuccess
});