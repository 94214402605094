function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { totalPriceAfterPromocodeApplied } from 'swag-common/business-logic/payments/total-price-after-promocode-applied';
import isProductBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { checkIsApparel } from 'swag-client-common/utils/product.utils';
import { CREDIT_CARD } from 'swag-common/constants/payment-methods';
import { GiveawayPayFeesPopupFilterOption } from 'swag-common/constants/giveaway.constants';
import { checkIsGiveawayWithGoodieType } from 'swag-common/utils/giveaway/giveaway-type.util';
import { isBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { productsSelector } from '../../products/products.selectors';
import { promocodeSelector } from '../../payment/payment.selectors';
import { userRoleBasedAmountApprovalSelector, userBasedAmountApprovalSelector } from '../../inventory/inventory.selectors';
import { giveawayEditingTypeSelector } from '../giveaway.selectors';
import { advancedDistributionsSelector } from '../../advanced-distributions/advanced-distributions.selectors';
import { sortGiveawayRecipients, filterByOtherResults, selectedGiveawaySelector } from './common.selectors';
export const giveawaySelector = state => state.giveaway;
export const giveawayPaymentRootSelector = createSelector(giveawaySelector, ({
  payment
}) => payment);
export const giveawayPaymentSelectedRecipientsListSelector = createSelector(giveawayPaymentRootSelector, ({
  selectedRecipients
}) => selectedRecipients);
export const giveawayRecipientsSelector = createSelector(giveawayPaymentRootSelector, ({
  list
}) => list);
export const selectedLinkToBeRemovedSelector = createSelector(giveawayPaymentRootSelector, ({
  selectedLinkToBeRemoved
}) => selectedLinkToBeRemoved);
export const isGiveawayLoadingPayFeesSelector = createSelector(giveawayPaymentRootSelector, ({
  isLoading
}) => isLoading);
export const payFeesSelectedLinksCountSelector = createSelector(giveawayPaymentSelectedRecipientsListSelector, selected => selected.length);
export const isGiveawayPayFeesNeedRestoreValueSelector = createSelector(giveawayPaymentRootSelector, ({
  restoreInfo
}) => restoreInfo);
export const isGiveawayPayFeesNeedToBeRestoredSelector = createSelector(isGiveawayPayFeesNeedRestoreValueSelector, ({
  toBeRestored
}) => toBeRestored);
export const productsQuantitiesSelector = createSelector(giveawayPaymentRootSelector, ({
  productsQuantities
}) => productsQuantities);
export const isAllGiveawayPaymentRecipientsSelectedSelector = createSelector(giveawayPaymentRootSelector, ({
  isAllSelected
}) => isAllSelected);
export const giveawayPaymentIsLoadingSelector = createSelector(giveawayPaymentRootSelector, ({
  isLoading
}) => isLoading);
export const isPayFeesSelectAllCheckBoxEnabledSelector = createSelector(giveawayRecipientsSelector, list => !list.length);
export const giveawayPaymentRecipientsDetailsSelector = createSelector(giveawayPaymentRootSelector, ({
  details
}) => details);
export const giveawayPaymentActiveSortingSelector = createSelector(giveawayPaymentRootSelector, ({
  sort
}) => sort);
const formatRecipients = (list, selectedRecipients) => {
  return list.map(item => {
    const {
      shipment,
      address,
      unit,
      city,
      state,
      zip,
      firstName,
      lastName,
      pickAndPackPrice,
      _id,
      budget,
      includedTaxes
    } = item;
    const {
      shipmentPrice = 0
    } = shipment || {};
    const fullName = `${firstName} ${lastName}`;
    const fullAddress = `${address} ${unit}, ${city}, ${state} ${zip}`;
    const taxVat = includedTaxes.vat || 0;
    const taxDutyFees = includedTaxes.dutyFees || 0;
    const taxFees = includedTaxes.fees || 0;
    let totalAmount = shipmentPrice + taxVat + taxDutyFees + taxFees + (pickAndPackPrice || 0);
    const isGoodieGiveawayLink = Boolean(budget);
    if (isGoodieGiveawayLink) {
      totalAmount += budget;
    }
    const selected = selectedRecipients.includes(_id);
    return _objectSpread({}, item, {
      fullName,
      fullAddress,
      totalAmount,
      selected,
      taxVat,
      taxDutyFees,
      taxFees
    });
  });
};
export const giveawayPaymentRecipientsSelector = createSelector(giveawayRecipientsSelector, giveawayPaymentSelectedRecipientsListSelector, formatRecipients);
export const giveawayPaymentSelectedRecipientsSelector = createSelector(giveawayPaymentRecipientsSelector, list => {
  return list.filter(item => item.selected);
});
export const isAnyGiveawayRecipientsSelectedSelector = createSelector(giveawayPaymentSelectedRecipientsSelector, list => list.length > 0);
export const giveawayPaymentProductsMapSelector = createSelector(giveawayPaymentRecipientsSelector, list => {
  return list.filter(item => item.selected).reduce((productsMap, cur) => {
    Object.keys(cur.products).forEach(id => {
      if (Object.prototype.hasOwnProperty.call(productsMap, id)) {
        return productsMap[id]++;
      }
      productsMap[id] = 1;
    });
    return productsMap;
  }, {});
});
export const giveawayPaymentProductsQuantityMapSelector = createSelector(productsSelector, selectedGiveawaySelector, (products, giveaway) => {
  const productsMap = products.reduce((productsMap, cur) => {
    cur.variants.forEach(variant => {
      if (giveaway) {
        productsMap[String(variant._id)] = giveaway.isReserve ? variant.quantity : ((variant === null || variant === void 0 ? void 0 : variant.quantity) || 0) - (variant.inReserveQuantity || 0);
      } else {
        productsMap[String(variant._id)] = variant.quantity;
      }
    });
    return productsMap;
  }, {});
  return productsMap;
});
export const isGiveawayBundleProductSelectedSelector = createSelector(productsSelector, giveawayPaymentProductsMapSelector, (products, giveawayProductsMap) => products.some(product => {
  var _product$variants$0$b;
  return isBundle(product.variants[0]) && ((_product$variants$0$b = product.variants[0].bundleContent) === null || _product$variants$0$b === void 0 ? void 0 : _product$variants$0$b.some(v => giveawayProductsMap[String(v.customProductId)]));
}));
export const payFeesSelectedRecipientsCountSelector = createSelector(giveawayPaymentSelectedRecipientsSelector, selectedLinks => {
  return selectedLinks.length || 0;
});
export const giveawayTaxVatSelectedRecipientsSelector = createSelector(giveawayPaymentSelectedRecipientsSelector, isAllGiveawayPaymentRecipientsSelectedSelector, giveawayPaymentRecipientsDetailsSelector, (list, isAllSelected, details) => {
  if (isAllSelected) {
    return details.vatTaxes;
  }
  return list.reduce((taxVatSum, currentRecipient) => taxVatSum + currentRecipient.includedTaxes.vat, 0);
});
export const giveawayTaxDutyFeesSelectedRecipientsSelector = createSelector(giveawayPaymentSelectedRecipientsSelector, isAllGiveawayPaymentRecipientsSelectedSelector, giveawayPaymentRecipientsDetailsSelector, (list, isAllSelected, details) => {
  if (isAllSelected) {
    return details.dutyFeesTaxes;
  }
  return list.reduce((taxDutyFeesSum, currentRecipient) => taxDutyFeesSum + currentRecipient.includedTaxes.dutyFees, 0);
});
export const giveawayFeesSelectedRecipientsSelector = createSelector(giveawayPaymentSelectedRecipientsSelector, isAllGiveawayPaymentRecipientsSelectedSelector, giveawayPaymentRecipientsDetailsSelector, (list, isAllSelected, details) => {
  if (isAllSelected) {
    return details.feesTaxes;
  }
  return list.reduce((taxDutyFeesSum, currentRecipient) => taxDutyFeesSum + currentRecipient.includedTaxes.fees, 0);
});
export const giveawayTaxSumSelector = createSelector(giveawayTaxVatSelectedRecipientsSelector, giveawayTaxDutyFeesSelectedRecipientsSelector, giveawayFeesSelectedRecipientsSelector, (taxVat, taxDutyFees, taxFees) => {
  return taxVat + taxDutyFees + taxFees;
});
export const giveawayTotalShippingCostSelector = createSelector(giveawayPaymentSelectedRecipientsSelector, isAllGiveawayPaymentRecipientsSelectedSelector, giveawayPaymentRecipientsDetailsSelector, (list, isAllSelected, details) => {
  if (isAllSelected) {
    return details.shipping;
  }
  return list.reduce((cost, cur) => {
    var _cur$shipment;
    return cost + (((_cur$shipment = cur.shipment) === null || _cur$shipment === void 0 ? void 0 : _cur$shipment.shipmentPrice) || 0);
  }, 0);
});
export const giveawayTotalProductsCostSelector = createSelector(giveawayPaymentSelectedRecipientsSelector, list => {
  return list.reduce((cost, cur) => cost + (cur.budget || 0), 0);
});
export const giveawayTotalPickAndPackCostSelector = createSelector(giveawayPaymentSelectedRecipientsSelector, isAllGiveawayPaymentRecipientsSelectedSelector, giveawayPaymentRecipientsDetailsSelector, (list, isAllSelected, details) => {
  if (isAllSelected) {
    return details.pickAndPack;
  }
  return list.reduce((cost, cur) => cost + cur.pickAndPackPrice, 0);
});
export const giveawayTotalCostSelector = createSelector(giveawayTotalShippingCostSelector, giveawayTotalPickAndPackCostSelector, giveawayTaxVatSelectedRecipientsSelector, giveawayTaxDutyFeesSelectedRecipientsSelector, giveawayFeesSelectedRecipientsSelector, giveawayTotalProductsCostSelector, giveawayEditingTypeSelector, (shipping, pickAndPack, taxVat, taxDutyFees, taxFees, productsCost, giveawayType) => {
  const isGoodieGiveaway = checkIsGiveawayWithGoodieType({
    giveawayType
  });
  if (isGoodieGiveaway) {
    return shipping + productsCost + taxVat + taxDutyFees + taxFees;
  }
  return shipping + pickAndPack + taxVat + taxDutyFees + taxFees;
});
export const giveawayPaymentProductsErrorsSelector = createSelector(productsSelector, productsQuantitiesSelector, payFeesSelectedLinksCountSelector, (products, productsMap, selectedLength) => {
  if (!selectedLength) {
    return [];
  }
  const errors = Object.keys(productsMap).map(id => {
    var _variant, _product, _variant3, _variant3$product, _variant4, _variant5, _variant5$product, _variant6;
    let product = null;
    let variant = null;
    let isBundleProduct = false;
    let sizeIndex = 0;
    for (const productItem of products) {
      const customProduct = productItem.variants.find(v => String(v._id) === id);
      if (customProduct) {
        product = productItem;
        variant = customProduct;
        sizeIndex = variant.sizeIndex;
        break;
      }
      if (isBundle(productItem.variants[0])) {
        var _productItem$variants;
        const bundleVariant = (_productItem$variants = productItem.variants[0].bundleContent) === null || _productItem$variants === void 0 ? void 0 : _productItem$variants.find(v => String(v.customProductId) === id);
        if (bundleVariant) {
          var _bundleVariant$produc;
          product = productItem;
          variant = bundleVariant;
          sizeIndex = ((_bundleVariant$produc = bundleVariant.product) === null || _bundleVariant$produc === void 0 ? void 0 : _bundleVariant$produc.sizeIndex) || 0;
          isBundleProduct = true;
          break;
        }
      }
    }
    let productSize = (_variant = variant) !== null && _variant !== void 0 && _variant.sizeSettings ? variant.sizeSettings.options[sizeIndex] : '';
    if (isProductBox((_product = product) === null || _product === void 0 ? void 0 : _product.swagProductId)) {
      var _variant2, _variant2$boxContent;
      const content = (_variant2 = variant) === null || _variant2 === void 0 ? void 0 : (_variant2$boxContent = _variant2.boxContent) === null || _variant2$boxContent === void 0 ? void 0 : _variant2$boxContent.map(v => v.product);
      const apparel = content === null || content === void 0 ? void 0 : content.find(p => checkIsApparel(p.swagProductId));
      if (apparel && apparel.sizeSettings) {
        var _apparel$sizeSettings, _apparel$sizeSettings2;
        productSize = (_apparel$sizeSettings = apparel.sizeSettings) === null || _apparel$sizeSettings === void 0 ? void 0 : (_apparel$sizeSettings2 = _apparel$sizeSettings.options) === null || _apparel$sizeSettings2 === void 0 ? void 0 : _apparel$sizeSettings2[apparel.sizeIndex];
      }
    }
    const variantName = isBundleProduct ? (_variant3 = variant) === null || _variant3 === void 0 ? void 0 : (_variant3$product = _variant3.product) === null || _variant3$product === void 0 ? void 0 : _variant3$product.name : (_variant4 = variant) === null || _variant4 === void 0 ? void 0 : _variant4.name;
    const variantColorName = isBundleProduct ? (_variant5 = variant) === null || _variant5 === void 0 ? void 0 : (_variant5$product = _variant5.product) === null || _variant5$product === void 0 ? void 0 : _variant5$product.colorName : (_variant6 = variant) === null || _variant6 === void 0 ? void 0 : _variant6.colorName;
    return `You need ${productsMap[id]} more ${variantName} (${variantColorName})${productSize.length > 0 ? ' (' + productSize + ')' : ''} to fulfill these orders`;
  });
  return errors;
});
export const giveawayTotalCostWithPromocodeSelector = createSelector(giveawayTotalCostSelector, promocodeSelector, totalPriceAfterPromocodeApplied);
export const showErrorsSelector = createSelector(giveawayPaymentIsLoadingSelector, productsQuantitiesSelector, payFeesSelectedLinksCountSelector, (isLoading, productsMap, selectedLength) => {
  const isAnyErrors = Object.values(productsMap).length;
  return Boolean(selectedLength && isAnyErrors && !isLoading);
});
export const isGiveawayPaymentAvailableSelector = createSelector(isGiveawayLoadingPayFeesSelector, showErrorsSelector, giveawayPaymentSelectedRecipientsSelector, (isLoading, showErrors, selected) => !showErrors && selected.length && !isLoading);
export const giveawayPaymentSearchAndFilterSelector = createSelector(giveawayPaymentRootSelector, ({
  searchAndFilter
}) => searchAndFilter);
export const giveawayPaymentSearchAndFilterResultsSelector = createSelector(giveawayPaymentSearchAndFilterSelector, ({
  results
}) => results);
export const isAllGiveawayRecipientsSelectedSelector = createSelector(giveawayPaymentRootSelector, ({
  isAllSelected
}) => isAllSelected);
export const giveawayPaymentSearchCurrentIndexSelector = createSelector(giveawayPaymentSearchAndFilterSelector, ({
  activeResultIndex
}) => activeResultIndex);
export const payFeesPopupSearchQuerySelector = createSelector(giveawayPaymentSearchAndFilterSelector, ({
  query
}) => query);
export const payFeesPopupFilterSelector = createSelector(giveawayPaymentSearchAndFilterSelector, ({
  filter
}) => filter);
export const giveawayPaymentSortedRecipientsSelector = createSelector(giveawayPaymentRecipientsSelector, giveawayPaymentActiveSortingSelector, giveawayPaymentSearchAndFilterResultsSelector, payFeesPopupSearchQuerySelector, payFeesPopupFilterSelector, giveawayPaymentIsLoadingSelector, (recipients, sorting, results, query, filter) => {
  if (!query && filter === GiveawayPayFeesPopupFilterOption.all) {
    return sortGiveawayRecipients(recipients, sorting);
  }
  return sortGiveawayRecipients(filterByOtherResults(recipients, results), sorting);
});
export const giveawayPaymentSearchCurrentResultSelector = createSelector(giveawayPaymentSearchCurrentIndexSelector, giveawayPaymentSearchAndFilterResultsSelector, payFeesPopupSearchQuerySelector, (index, results, query) => {
  if (results.length && query) {
    return results[index];
  }
  return null;
});
export const giveawayPaymentListLength = createSelector(giveawayPaymentSortedRecipientsSelector, payment => payment === null || payment === void 0 ? void 0 : payment.length);
export const giveawayPaymentSearchShouldShowAdditionalText = createSelector(giveawayPaymentIsLoadingSelector, payFeesPopupSearchQuerySelector, (isLoading, query) => {
  return !isLoading && Boolean(query);
});
export const payFeesPopupSearchResultInfoSelector = createSelector(giveawayPaymentSearchAndFilterResultsSelector, giveawayPaymentSearchCurrentIndexSelector, (results, activeResult) => {
  if (results.length === 0) {
    return 'No results found';
  }
  return `${activeResult + 1} of ${results.length}`;
});
export const payFeesPopupSearchResultIconsDisabledSelector = createSelector(giveawayPaymentSearchAndFilterResultsSelector, results => !results.length);
export const anySearchAndFilterAppliedSelector = createSelector(payFeesPopupSearchQuerySelector, payFeesPopupFilterSelector, (search, filter) => Boolean(search) || filter !== GiveawayPayFeesPopupFilterOption.all);
export const giveawayPaymentShowStubSelector = createSelector(giveawayRecipientsSelector, giveawayPaymentSearchAndFilterResultsSelector, giveawayPaymentIsLoadingSelector, anySearchAndFilterAppliedSelector, (list, results, loading, anySearchAndFilterOptions) => {
  return list.length && anySearchAndFilterOptions && !results.length && !loading;
});
export const isGiveawayPayFeesApprovalFlowSelector = createSelector(userRoleBasedAmountApprovalSelector, userBasedAmountApprovalSelector, giveawayTotalCostWithPromocodeSelector, (roleBaseApproval, userBasedApproval, giveawayTotal) => {
  if (userBasedApproval.isApprovalFlow) {
    return giveawayTotal >= userBasedApproval.amount;
  }
  if (roleBaseApproval.isApprovalFlow) {
    return giveawayTotal >= roleBaseApproval.amount;
  }
  return false;
});
export const giveawayAutopayPaymentMethodSelector = createSelector(selectedGiveawaySelector, giveaway => (giveaway === null || giveaway === void 0 ? void 0 : giveaway.autopayPaymentMethod) || null);
export const giveawayAutopayPaymentCardSelector = createSelector(selectedGiveawaySelector, giveaway => (giveaway === null || giveaway === void 0 ? void 0 : giveaway.autopayCard) || null);
export const isGiveawayAutopayOnSelector = createSelector(giveawayAutopayPaymentMethodSelector, paymentMethod => !!paymentMethod);
export const giveawayAutopayLabelSelector = createSelector(isGiveawayAutopayOnSelector, giveawayAutopayPaymentCardSelector, giveawayAutopayPaymentMethodSelector, (isAutopayOn, cardWithAutoPay, paymentMethod) => {
  if (isAutopayOn) {
    const card = cardWithAutoPay;
    const isCreditCard = paymentMethod === CREDIT_CARD;
    const paymentMethodLabel = isCreditCard ? `card number ending in ${card === null || card === void 0 ? void 0 : card.last4}` : 'credits';
    return `Auto pay (Charged weekly from ${paymentMethodLabel})`;
  }
  return 'Enable auto pay:';
});
export const getGiveawayFeesPayPopupFlowSelector = createSelector(giveawayPaymentRootSelector, giveawayPayment => {
  return giveawayPayment.giveawayFeesPayPopupFlow;
});
export const isBuyCreditsFromGiveawayFeesPageSelector = createSelector(giveawayPaymentRootSelector, giveawayPayment => {
  return giveawayPayment.buyCreditsFromGiveawayFeesPage;
});
export const getLinkToBeRemoved = createSelector(giveawayRecipientsSelector, selectedLinkToBeRemovedSelector, (recipients, linkId) => recipients.find(({
  _id
}) => String(_id) === linkId));
export const haveUpcomingDistributionsForRemovingLinkSelector = createSelector(advancedDistributionsSelector, distributions => Boolean(distributions.length));