export function getTotalAmountForLink({
  pickAndPackPrice,
  shipment,
  includedTaxes,
  budget,
  goodieProducts
}) {
  var _shipment$shipmentPri;
  const isGoodieGiveaway = Boolean(goodieProducts === null || goodieProducts === void 0 ? void 0 : goodieProducts.length);
  const {
    vat = 0,
    dutyFees = 0,
    fees = 0
  } = includedTaxes;
  let sum = vat + dutyFees + fees;
  if (isGoodieGiveaway) {
    sum += budget || 0;
  }
  return (pickAndPackPrice !== null && pickAndPackPrice !== void 0 ? pickAndPackPrice : 0) + ((_shipment$shipmentPri = shipment === null || shipment === void 0 ? void 0 : shipment.shipmentPrice) !== null && _shipment$shipmentPri !== void 0 ? _shipment$shipmentPri : 0) + sum;
}