function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { DEFAULT_CATEGORY_ID } from 'swag-common/constants/custom-store.constants';
import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import { mapCartItemToRequiredFieldsForUpdate } from 'swag-common/utils/items/map-cart-item-to-required-fields.util';
import { getDefaultProductionTimeIdx } from '../../../customer/redux/product/product.utils';
import { categoriesSelector, categoriesToPresentSelector } from '../categories/categories.selectors';
import { selectedInventorySelector } from '../inventory/inventory.selectors';
import { orderMoreBoxItemsSelector } from '../order-more-box/order-more-box.selectors';
import { orderMoreRegularItemSelector, swagProductSelector } from '../order-more/order-more.selectors';
import { productCategoryIdsSelector, storeProductsSelector } from '../products/products.selectors';
export const categoriesDataSelector = createSelector(categoriesSelector, storeProductsSelector, (categories, products) => {
  const amountPerCategory = products.reduce((count, product) => {
    const productCategory = product.categoryId[0] || DEFAULT_CATEGORY_ID;
    return _objectSpread({}, count, {
      [productCategory]: (count[productCategory] || 0) + 1
    });
  }, {
    [DEFAULT_CATEGORY_ID]: 0
  });
  return categories.map(category => {
    const logo = findCategoryLogo(category._id, products);
    return _objectSpread({}, category, {
      logo,
      // @todo. auto generated. fix this error
      // @ts-ignore
      itemsNumber: amountPerCategory[category._id] || 0
    });
  });
});
export const productCategorySelector = createSelector(categoriesToPresentSelector, productCategoryIdsSelector, (categories, ids) => {
  let category = 'Uncategorized';
  const categoryBasedOnId = ids.length && categories.find(category => category._id === ids[0]);
  if (categoryBasedOnId) {
    category = categoryBasedOnId.name;
  }
  return category;
});
const findCategoryLogo = (categoryId, products) => {
  let logo;
  products.forEach(product => {
    const logoNotFound = !logo;
    if (product.categoryId.includes(categoryId) && logoNotFound) {
      logo = product.defaultImage;
    }
  });
  return logo;
};
const orderMoreItemsByProductTypeSelector = createSelector(swagProductSelector, orderMoreBoxItemsSelector, orderMoreRegularItemSelector, (swagProduct, orderMoreBoxItems, orderMoreRegularItem) => {
  const isBoxProduct = isBox(swagProduct);
  if (isBoxProduct) {
    return orderMoreBoxItems;
  }
  return orderMoreRegularItem;
});
export const orderMoreItemsSelector = createSelector(selectedInventorySelector, orderMoreItemsByProductTypeSelector, (inventoryId, items) => {
  return items.map(item => {
    var _mappedItem$prod, _mappedItem$prod$prod, _mappedItem$prod$prod2;
    const mappedItem = mapCartItemToRequiredFieldsForUpdate(_objectSpread({}, item, {
      inventoryId,
      originalInventoryId: inventoryId
    }));
    const prodTime = getDefaultProductionTimeIdx((_mappedItem$prod = mappedItem.prod) === null || _mappedItem$prod === void 0 ? void 0 : (_mappedItem$prod$prod = _mappedItem$prod.productSettings) === null || _mappedItem$prod$prod === void 0 ? void 0 : (_mappedItem$prod$prod2 = _mappedItem$prod$prod[0]) === null || _mappedItem$prod$prod2 === void 0 ? void 0 : _mappedItem$prod$prod2.productionTimeList);
    delete mappedItem.prod;
    return _objectSpread({}, mappedItem, {
      prodTime
    });
  });
});