import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/custom-store-api/v1/inventory/:inventoryId/payment-method';
const PaymentMethodsApi = createApi({
  addCard: {
    url: `${baseUrl}`,
    method: 'post'
  },
  getCards: {
    url: `${baseUrl}`,
    method: 'get'
  },
  updateCard: {
    url: `${baseUrl}/:cardId`,
    method: 'put'
  },
  deleteCard: {
    url: `${baseUrl}/:cardId`,
    method: 'delete'
  }
});
export default PaymentMethodsApi;