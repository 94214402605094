export const addProductsWithColorsToGiveawayTypeCreator = storePrefix => `${storePrefix}_ADD_PRODUCTS_WITH_COLORS_TO_GIVEAWAY`;
export const addProductsWithColorsToGiveawayFactory = storePrefix => (productIds, defaultColors, capValue) => ({
  type: addProductsWithColorsToGiveawayTypeCreator(storePrefix),
  payload: {
    productIds,
    colors: defaultColors,
    capValue
  }
});
export const addProductsToGiveawayTypeCreator = storePrefix => `${storePrefix}_ADD_PRODUCTS_TO_GIVEAWAY`;
export const addProductsToGiveawayFactory = storePrefix => (productIds, capValue) => ({
  type: addProductsToGiveawayTypeCreator(storePrefix),
  payload: {
    productIds,
    capValue
  }
});
export const addProductWithColorsToGiveawayTypeCreator = storePrefix => `${storePrefix}_ADD_PRODUCT_WITH_COLORS_TO_GIVEAWAY`;
export const addProductWithColorsToGiveawayFactory = storePrefix => (productId, colors, capValue) => ({
  type: addProductWithColorsToGiveawayTypeCreator(storePrefix),
  payload: {
    productId,
    colors,
    capValue
  }
});
export const removeProductWithColorsFromGiveawayTypeCreator = storePrefix => `${storePrefix}_REMOVE_PRODUCT_WITH_COLORS_TO_GIVEAWAY`;
export const removeProductWithColorsFromGiveawayFactory = storePrefix => (productId, capValue) => ({
  type: removeProductWithColorsFromGiveawayTypeCreator(storePrefix),
  payload: {
    productId,
    capValue
  }
});