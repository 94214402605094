export default function getUniqueColorsOfProduct(variants) {
  const uniqueColors = {};
  return variants.filter(({
    colorValue
  }) => {
    if (colorValue in uniqueColors) {
      return false;
    }
    uniqueColors[colorValue] = true;
    return true;
  });
}