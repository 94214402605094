import { createSelector } from 'reselect';
export const rootRootingSelector = state => state.routing;
export const isInventoryPageUrlSelector = createSelector(rootRootingSelector, routing => {
  var _routing$locationBefo;
  return !!(routing !== null && routing !== void 0 && (_routing$locationBefo = routing.locationBeforeTransitions) !== null && _routing$locationBefo !== void 0 && _routing$locationBefo.pathname.startsWith('/inventory/'));
});
export const isGiveawayGoodiePageUrlSelector = createSelector(rootRootingSelector, routing => {
  var _routing$locationBefo2;
  return !!(routing !== null && routing !== void 0 && (_routing$locationBefo2 = routing.locationBeforeTransitions) !== null && _routing$locationBefo2 !== void 0 && _routing$locationBefo2.pathname.startsWith('/gift-catalog'));
});
export const isBundleEditPageUrlSelector = createSelector(rootRootingSelector, routing => {
  var _routing$locationBefo3;
  return /^\/inventory\/.*?\/bundle\/.*?\/edit$/.test((routing === null || routing === void 0 ? void 0 : (_routing$locationBefo3 = routing.locationBeforeTransitions) === null || _routing$locationBefo3 === void 0 ? void 0 : _routing$locationBefo3.pathname) || '');
});
export const previousPathnameSelector = createSelector(rootRootingSelector, routing => {
  var _routing$previousLoca;
  return (routing === null || routing === void 0 ? void 0 : (_routing$previousLoca = routing.previousLocationBeforeTransitions) === null || _routing$previousLoca === void 0 ? void 0 : _routing$previousLoca.pathname) || null;
});
export const pathnameSelector = createSelector(rootRootingSelector, state => {
  const routing = state.locationBeforeTransitions || window.location;
  return routing.pathname || '';
});