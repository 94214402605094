import { BC_ENTERPRISE, isBroadcastChannelSupported } from 'swag-client-common/shared-worker/broadcast-chanel';
import { port } from 'swag-client-common/shared-worker/enterprise/enterprise-worker-runner';
import { BCEvents } from 'swag-client-common/shared-worker/shared-worker.events';
import { SOCKET_EVENTS } from 'swag-common/constants/socket-events';
let lastMessageId = '';
export function cancelCalculation(userId) {
  port.postMessage({
    type: SOCKET_EVENTS.CANCEL_CALCULATE_SHIPPING,
    payload: userId
  });
  lastMessageId = '';
}
export function trackShipmentCalculationProgress({
  onProgress
}) {
  BC_ENTERPRISE.addEventListener(BCEvents.MESSAGE, event => {
    const {
      type,
      payload
    } = isBroadcastChannelSupported ? event.data : event;
    switch (type) {
      case SOCKET_EVENTS.CALCULATE_SHIPPING_PROGRESS:
        {
          if (payload._id !== lastMessageId) {
            onProgress(payload);
            lastMessageId = payload._id;
          }
          break;
        }
      default:
        {
          break;
        }
    }
  });
}