function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { calculateMaxPossibleQuantityOfBundles } from 'swag-common/utils/swag-bundles/calculate-max-possible-quantity-of-bundles.util';
import { selectedProductsDataForCalculationBundlesQuantitySelector } from '../selectors/swag-bundles-calculation-selected-products.selectors';
import { isSwagBundleProductVariantActiveSelector, selectedVariantsWithQuantityByProductIdSelector, swagBundlesProductsColorsWithCalculatedQuantitySelector, swagBundlesSelectedProductsVariantsWithQuantitySelector } from '../selectors/swag-bundles-selected-variants-with-quantity.selectors';
import { getEditSwagBundleGroupedProductsColorsWithQuantitySelector, selectedSwagBundleIdSelector } from '../selectors/swag-bundles-selected.selectors';
import { swagBundlesSelectedProductsIdsSelector } from '../swag-bundles.selectors';
import { SWAG_BUNDLES_ADMIN_PREFIX } from './swag-bundles-common.actions';
export const SELECT_SWAG_BUNDLES_PRODUCT_VARIANTS = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_SWAG_BUNDLES_PRODUCT_VARIANT`;
export const toggleSwagBundlesProductVariantWithQuantity = (productId, colorValue) => (dispatch, getState) => {
  const state = getState();
  const selectedVariants = selectedVariantsWithQuantityByProductIdSelector(productId)(state);
  const isVariantChosen = isSwagBundleProductVariantActiveSelector(productId, colorValue)(state);
  const updatedVariantsWithQuantity = [];
  if (isVariantChosen) {
    updatedVariantsWithQuantity.push(...selectedVariants.filter(variant => variant.colorValue !== colorValue));
  } else {
    updatedVariantsWithQuantity.push(...selectedVariants, {
      colorValue,
      quantity: 1
    });
  }
  dispatch({
    type: SELECT_SWAG_BUNDLES_PRODUCT_VARIANTS,
    payload: {
      productId,
      variantIdsWithQuantity: updatedVariantsWithQuantity
    }
  });
  dispatch(calculateMaxPossibleQuantityOfBundlesAfterVariantSelectionChanged);
};
export const INITIAL_SELECT_SWAG_BUNDLES_PRODUCTS_VARIANTS = `${SWAG_BUNDLES_ADMIN_PREFIX}_INITIAL_SELECT_SWAG_BUNDLES_PRODUCTS_VARIANTS`;
export const initialSelectSwagBundlesProductsVariantsWithQuantity = () => (dispatch, getState) => {
  const state = getState();
  const isSwagBundleEdit = selectedSwagBundleIdSelector(state);
  const selectedProductsIds = swagBundlesSelectedProductsIdsSelector(state);
  const variantsWithQuantityInEditedBundle = getEditSwagBundleGroupedProductsColorsWithQuantitySelector(state);
  const selectedProductsVariants = swagBundlesSelectedProductsVariantsWithQuantitySelector(state);
  const initialSelectedProductsVariantsWithQuantity = selectedProductsIds.reduce((acc, id) => {
    const productId = String(id);
    const colors = swagBundlesProductsColorsWithCalculatedQuantitySelector(state, {
      productId
    });
    if (selectedProductsVariants[productId]) {
      return acc;
    }
    if (isSwagBundleEdit && variantsWithQuantityInEditedBundle[productId]) {
      acc[productId] = [...variantsWithQuantityInEditedBundle[productId]];
      return acc;
    }
    if (colors.length === 1) {
      const [firstColor] = colors;
      acc[productId] = [{
        colorValue: firstColor.colorValue,
        quantity: 1
      }];
      return acc;
    }
    acc[productId] = [];
    return acc;
  }, {});
  dispatch({
    type: INITIAL_SELECT_SWAG_BUNDLES_PRODUCTS_VARIANTS,
    payload: initialSelectedProductsVariantsWithQuantity
  });
  dispatch(calculateMaxPossibleQuantityOfBundlesAfterVariantSelectionChanged);
};
export const initialSelectSwagBundlesProductVariant = productId => (dispatch, getState) => {
  var _selectedProductsVari;
  const state = getState();
  const isSwagBundleEdit = selectedSwagBundleIdSelector(state);
  const variantsWithQuantityInEditedBundle = getEditSwagBundleGroupedProductsColorsWithQuantitySelector(state);
  const selectedProductsVariantsState = swagBundlesSelectedProductsVariantsWithQuantitySelector(state);
  const colors = swagBundlesProductsColorsWithCalculatedQuantitySelector(state, {
    productId
  });
  const updatedSelectedProductVariantsWithQuantity = {};
  if ((_selectedProductsVari = selectedProductsVariantsState[productId]) !== null && _selectedProductsVari !== void 0 && _selectedProductsVari.length) {
    return;
  }
  if (isSwagBundleEdit && variantsWithQuantityInEditedBundle[productId]) {
    updatedSelectedProductVariantsWithQuantity[productId] = [...variantsWithQuantityInEditedBundle[productId]];
  } else if (colors.length === 1) {
    const [firstColor] = colors;
    updatedSelectedProductVariantsWithQuantity[productId] = [{
      colorValue: firstColor.colorValue,
      quantity: 1
    }];
  } else {
    updatedSelectedProductVariantsWithQuantity[productId] = [];
  }
  dispatch({
    type: INITIAL_SELECT_SWAG_BUNDLES_PRODUCTS_VARIANTS,
    payload: updatedSelectedProductVariantsWithQuantity
  });
  dispatch(calculateMaxPossibleQuantityOfBundlesAfterVariantSelectionChanged);
};
export const CHANGE_QUANTITY_OF_BUNDLE_SELECTED_VARIANT = `${SWAG_BUNDLES_ADMIN_PREFIX}_CHANGE_QUANTITY_OF_BUNDLE_SELECTED_VARIANT`;
export const changeQuantityOfBundleSelectedVariant = (productId, colorValueWithQuantity) => (dispatch, getState) => {
  const state = getState();
  const selectedProducts = swagBundlesSelectedProductsVariantsWithQuantitySelector(state);
  const selectedVariants = selectedProducts[productId] || [];
  const isVariantChosenWithSameQuantity = selectedVariants.some(({
    colorValue,
    quantity
  }) => colorValue === colorValueWithQuantity.colorValue && quantity === colorValueWithQuantity.quantity);
  if (!isVariantChosenWithSameQuantity) {
    dispatch({
      type: CHANGE_QUANTITY_OF_BUNDLE_SELECTED_VARIANT,
      payload: {
        productId,
        colorValueWithQuantity
      }
    });
    dispatch(calculateMaxPossibleQuantityOfBundlesAfterVariantSelectionChanged);
  }
};
export const SET_BUNDLE_LIMITING_PRODUCT = `${SWAG_BUNDLES_ADMIN_PREFIX}_SET_BUNDLE_LIMITING_PRODUCT`;
export const setCurrentBundleLimitingProduct = bundleLimitingProduct => ({
  type: SET_BUNDLE_LIMITING_PRODUCT,
  payload: bundleLimitingProduct
});
export const SET_CURRENTLY_MAX_POSSIBLE_QUANTITY_OF_BUNDLES = `${SWAG_BUNDLES_ADMIN_PREFIX}_SET_CURRENTLY_MAX_POSSIBLE_QUANTITY_OF_BUNDLES`;
export const setCurrentlyMaxPossibleQuantityOfBundles = quantities => ({
  type: SET_CURRENTLY_MAX_POSSIBLE_QUANTITY_OF_BUNDLES,
  payload: quantities
});
export const calculateMaxPossibleQuantityOfBundlesAfterVariantSelectionChanged = (dispatch, getState) => {
  const state = getState();
  const calculationData = selectedProductsDataForCalculationBundlesQuantitySelector(state);
  const _calculateMaxPossible = calculateMaxPossibleQuantityOfBundles(calculationData, true),
    {
      bundleLimitingProduct
    } = _calculateMaxPossible,
    quantitiesOfBundles = _objectWithoutProperties(_calculateMaxPossible, ["bundleLimitingProduct"]);
  dispatch(setCurrentlyMaxPossibleQuantityOfBundles(quantitiesOfBundles));
  dispatch(setCurrentBundleLimitingProduct(bundleLimitingProduct));
};
export const UNSELECT_SWAG_BUNDLES_PRODUCT_VARIANTS = `${SWAG_BUNDLES_ADMIN_PREFIX}_UNSELECT_SWAG_BUNDLES_PRODUCT_VARIANTS`;
export const unselectSwagBundlesProductVariants = productId => dispatch => {
  dispatch({
    type: UNSELECT_SWAG_BUNDLES_PRODUCT_VARIANTS,
    payload: productId
  });
  dispatch(calculateMaxPossibleQuantityOfBundlesAfterVariantSelectionChanged);
};