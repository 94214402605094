import { createSelector } from 'reselect';
import { getStripeElementsErrorMessage } from '../../utils/get-stripe-elements-error-message';
import { paymentStateLoadingSelector } from '../checkout/checkout.selectors';
export const stripeFormSelector = createSelector(state => state.stripeForm, form => form);
export const stripeCardNumberValidationErrorSelector = createSelector(stripeFormSelector, form => {
  return form.cardValidationError.number;
});
export const stripeCardExpiryValidationErrorSelector = createSelector(stripeFormSelector, form => {
  return form.cardValidationError.expiry;
});
export const stripeCardCvcValidationErrorSelector = createSelector(stripeFormSelector, form => {
  return form.cardValidationError.cvc;
});
export const stripeCardHasErrorSelector = createSelector(stripeCardNumberValidationErrorSelector, stripeCardExpiryValidationErrorSelector, stripeCardCvcValidationErrorSelector, (numberError, expiryError, cvcError) => {
  return numberError || expiryError || cvcError;
});
export const stripeCardNumberValidationErrorStringSelector = createSelector(stripeCardNumberValidationErrorSelector, error => {
  return error && getStripeElementsErrorMessage(error);
});
export const stripeCardExpiryValidationErrorStringSelector = createSelector(stripeCardExpiryValidationErrorSelector, error => {
  return error && getStripeElementsErrorMessage(error);
});
export const stripeCardCvcValidationErrorStringSelector = createSelector(stripeCardCvcValidationErrorSelector, error => {
  return error && getStripeElementsErrorMessage(error);
});
export const isStripeCardHasEmptyFieldSelector = createSelector(stripeFormSelector, form => form.cardValidationEmpty.number || form.cardValidationEmpty.expiry || form.cardValidationEmpty.cvc);
export const isStripeCardFieldsCompletedSelector = createSelector(stripeFormSelector, form => form.cardValidationCompleted.number && form.cardValidationCompleted.expiry && form.cardValidationCompleted.cvc);
export const isStripeElementsValid = createSelector(stripeCardHasErrorSelector, isStripeCardHasEmptyFieldSelector, isStripeCardFieldsCompletedSelector, (cardHasErrors, cardHasEmptyFields, isCardFieldsCompleted) => {
  return !cardHasErrors && !cardHasEmptyFields && isCardFieldsCompleted;
});
export const isStripeEcommerceElementsValidSelector = createSelector(isStripeElementsValid, paymentStateLoadingSelector, (isStripeElementsValid, isLoading) => isStripeElementsValid && !isLoading);
export const getStripeErrorsMessageSelector = createSelector(stripeCardNumberValidationErrorStringSelector, stripeCardExpiryValidationErrorStringSelector, stripeCardCvcValidationErrorStringSelector, (cardNumberError, cardExpiryError, cardCvcError) => {
  if (cardNumberError) {
    return cardNumberError;
  }
  if (cardExpiryError) {
    return cardExpiryError;
  }
  if (cardCvcError) {
    return cardCvcError;
  }
  return null;
});