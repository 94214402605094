import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CREDIT_CARD, CREDITS } from 'swag-common/constants/payment-methods';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { CONFIRM_DEFAULT_PAYMENT_SET_POPUP } from 'swag-client-common/constants';
import { shownPopupSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
// @todo imports from non common directories are forbidden
import { isPublicAPIDefaultPaymentMethodCreditsSelector, isPublicAPIDefaultPaymentMethodSelectedCreditCardSelector, publicAPIDefaultCreditCardIdSelector, publicApiDefaultPaymentMethodSelector, selectedPaymentMethodIdSelector } from '../../../../custom-store-admin/redux/payment-methods/payment-methods.selectors';
// @todo imports from non common directories are forbidden
import { setPublicAPIDefaultPayment } from '../../../../custom-store-admin/redux/payment-methods/payment-methods.actions';
// @todo imports from non common directories are forbidden
import { MakeApiDefaultPaymentMethodWidget as Component } from './make-api-default-payment-method-widget';
const creditCardMapStateToProps = createStructuredSelector({
  label: () => 'Make this a default payment method for 3rd-party integrations',
  value: isPublicAPIDefaultPaymentMethodSelectedCreditCardSelector,
  paymentMethod: publicApiDefaultPaymentMethodSelector,
  apiDefaultCard: publicAPIDefaultCreditCardIdSelector,
  card: selectedPaymentMethodIdSelector,
  prevPopup: shownPopupSelector
});
const creditsMapStateToProps = createStructuredSelector({
  label: () => 'Make this a default payment method for 3rd-party integrations',
  value: isPublicAPIDefaultPaymentMethodCreditsSelector,
  paymentMethod: publicApiDefaultPaymentMethodSelector,
  apiDefaultCard: publicAPIDefaultCreditCardIdSelector,
  prevPopup: shownPopupSelector
});
const handlePaymentMethodChange = (paymentMethodToAvoid, dispatch) => (method, value, card, prevPopup, apiDefaultCard) => {
  if (!!apiDefaultCard && !value || method === paymentMethodToAvoid) {
    dispatch(showPopup({
      popup: CONFIRM_DEFAULT_PAYMENT_SET_POPUP,
      popupToShowAfter: prevPopup,
      paymentMethodToAvoid
    }));
    return;
  }
  if (value) {
    dispatch(setPublicAPIDefaultPayment(null, null));
    return;
  }
  const isCreditCard = paymentMethodToAvoid === CREDITS;
  dispatch(setPublicAPIDefaultPayment(isCreditCard ? CREDIT_CARD : CREDITS, isCreditCard ? card : null));
};
const creditCardMapDispatchToProps = dispatch => ({
  onClick: handlePaymentMethodChange(CREDITS, dispatch)
});
const creditsMapDispatchToProps = dispatch => ({
  onClick: handlePaymentMethodChange(CREDIT_CARD, dispatch)
});
export const CreditCardWidget = connect(creditCardMapStateToProps, creditCardMapDispatchToProps)(Component);
export const CreditsWidget = connect(creditsMapStateToProps, creditsMapDispatchToProps)(Component);