import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { DEMO_INVENTORY_WARNING_POPUP } from 'swag-client-common/constants';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { InventoryUrl } from 'swag-common/utils/urls/inventory.url';
import { push } from 'react-router-redux';
import { saveGiveawayModelActionFactory } from 'swag-client-common/redux/partials/giveaway-selected/giveaway-selected.actions';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { inventoryLoadedAsyncParameters, getPendingActivitiesAction } from '../../inventory/inventory.actions';
import { mergeCreateAsyncOptions } from '../../redux.utils';
import { InventoryApi } from '../../../api/inventory.api';
import { selectedInventorySelector, isSelectedDemoInventorySelector } from '../../inventory/inventory.selectors';
import { deselectAllProducts } from '../../products/products.actions';
import { giveawayCompletedListSelector, giveawayCompletedListFilterSelector, giveawayCompletedCurrentPageSelector } from '../selectors/campaigns.selector';
import { giveawayDraftCurrentPageSelector } from '../selectors/draft.selectors';
import { GIVEAWAY_ADMIN_PREFIX, resetGiveaway } from './giveaway-common.actions';
import { setHasGiveawayNotecard } from './giveaway-notecard.actions';
export const GET_COMPLETED_LIST_STARTED = `${GIVEAWAY_ADMIN_PREFIX}GET_COMPLETED_LIST_STARTED`;
export const GET_COMPLETED_LIST_SUCCESS = `${GIVEAWAY_ADMIN_PREFIX}GET_COMPLETED_LIST_SUCCESS`;
export const GET_COMPLETED_LIST_ERROR = `${GIVEAWAY_ADMIN_PREFIX}GET_COMPLETED_LIST_ERROR`;
const getGiveawayCompletedListParams = {
  preFn: dispatch => {
    dispatch({
      type: GET_COMPLETED_LIST_STARTED
    });
  },
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    const page = giveawayCompletedCurrentPageSelector(getState());
    const filter = giveawayCompletedListFilterSelector(getState());
    return {
      inventoryId,
      page: page - 1,
      filter
    };
  },
  fn: InventoryApi.giveaway.getCompleted,
  success: res => {
    return {
      type: GET_COMPLETED_LIST_SUCCESS,
      payload: res
    };
  },
  error: () => ({
    type: GET_COMPLETED_LIST_ERROR
  })
};
export const getCompletedGiveawayById = createAsyncLoadedAction({
  fn: InventoryApi.giveaway.getCompletedGiveawayById,
  success: giveaway => dispatch => {
    dispatch(saveGiveawayModelActionFactory(GIVEAWAY_ADMIN_PREFIX)(giveaway));
  },
  error: () => showNotification({
    text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
  })
});
export const getGiveawayCompletedList = createAsyncLoadedAction(getGiveawayCompletedListParams);
export const GET_DRAFT_LIST_SUCCESS = `${GIVEAWAY_ADMIN_PREFIX}GET_DRAFT_LIST_SUCCESS`;
export const GET_DRAFT_LIST_ERROR = `${GIVEAWAY_ADMIN_PREFIX}GET_DRAFT_LIST_ERROR`;
const getGiveawayDraftListParams = {
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    const page = giveawayDraftCurrentPageSelector(getState());
    return {
      inventoryId,
      page: page - 1
    };
  },
  fn: InventoryApi.giveaway.getDraft,
  success: res => ({
    type: GET_DRAFT_LIST_SUCCESS,
    payload: res
  }),
  error: error => ({
    type: GET_DRAFT_LIST_ERROR,
    error
  })
};
const inventoryLoadedAsyncParametersObject = inventoryLoadedAsyncParameters();
export const getGiveawayCompletedListEntering = createAsyncAction(mergeCreateAsyncOptions(getGiveawayCompletedListParams, inventoryLoadedAsyncParametersObject));
export const getGiveawayDraftList = createAsyncLoadedAction(getGiveawayDraftListParams);
export const getGiveawayDraftListEntering = createAsyncAction(mergeCreateAsyncOptions(getGiveawayDraftListParams, inventoryLoadedAsyncParametersObject));
export const REMOVE_GIVEAWAY_SUCCESS = `${GIVEAWAY_ADMIN_PREFIX}REMOVE_GIVEAWAY_SUCCESS`;
export const REMOVE_GIVEAWAY_ERROR = `${GIVEAWAY_ADMIN_PREFIX}REMOVE_GIVEAWAY_ERROR`;
export const removeGiveaway = createAsyncLoadedAction({
  transformData: (giveawayId, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    return {
      giveawayId,
      inventoryId
    };
  },
  fn: InventoryApi.giveaway.removeById,
  success: () => ({
    type: REMOVE_GIVEAWAY_SUCCESS
  }),
  error: () => ({
    type: REMOVE_GIVEAWAY_ERROR
  }),
  postSuccess: (dispatch, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    dispatch(getGiveawayDraftList());
    dispatch(getPendingActivitiesAction({
      inventoryId
    }));
  }
});
const endGiveawayRequest = createAsyncLoadedAction({
  fn: InventoryApi.giveaway.endGiveaway,
  success: () => getGiveawayCompletedList(),
  postSuccess: (dispatch, _, transformedData) => {
    dispatch(hidePopup());
    dispatch(getPendingActivitiesAction({
      inventoryId: transformedData.inventoryId
    }));
  },
  postError: (dispatch, getState, err) => {
    dispatch(showNotification({
      text: err.message
    }));
  }
});
export const endGiveawayAction = giveaway => (dispatch, getState) => {
  if (isSelectedDemoInventorySelector(getState())) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.GiveawayEnd
    }));
  }
  dispatch(endGiveawayRequest({
    inventoryId: String(giveaway.inventoryId),
    giveawayId: String(giveaway._id)
  }));
};
export const REMOVE_GIVEAWAY_LINK_SUCCESS = `${GIVEAWAY_ADMIN_PREFIX}REMOVE_GIVEAWAY_LINK_SUCCESS`;
export const removeGiveawayLinkSuccess = (link, giveawayId, giveawayStep) => {
  const {
    taxVat,
    taxDutyFees,
    taxFees,
    totalAmount,
    pickAndPackPrice,
    shipment,
    products
  } = link;
  const {
    shipmentPrice = 0
  } = shipment || {};
  return {
    type: REMOVE_GIVEAWAY_LINK_SUCCESS,
    payload: {
      id: link._id,
      giveawayId,
      taxVat,
      taxDutyFees,
      taxFees,
      totalAmount,
      pickAndPackPrice,
      shipmentPrice,
      giveawayStep,
      products
    }
  };
};
export const RESET_GIVEAWAY_COMPLETED = `${GIVEAWAY_ADMIN_PREFIX}RESET_GIVEAWAY_COMPLETED`;
export const resetGiveawayCompleted = () => ({
  type: RESET_GIVEAWAY_COMPLETED
});
export const SET_COMPLETED_PAGE = `${GIVEAWAY_ADMIN_PREFIX}SET_COMPLETED_PAGE`;
export const setCompletedPage = page => ({
  type: SET_COMPLETED_PAGE,
  payload: page
});
export const SET_GIVEAWAY_COMPLETED_ACTIVE_TAB = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_COMPLETED_ACTIVE_TAB`;
export const setGiveawayCompletedActiveTab = tab => ({
  type: SET_GIVEAWAY_COMPLETED_ACTIVE_TAB,
  payload: tab
});
export const SET_GIVEAWAY_COMPLETED_FILTER = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_COMPLETED_FILTER`;
export const setGiveawayCompletedFilter = filter => ({
  type: SET_GIVEAWAY_COMPLETED_FILTER,
  payload: filter
});
export const SET_PAGE = `${GIVEAWAY_ADMIN_PREFIX}SET_PAGE`;
export const setPage = page => ({
  type: SET_PAGE,
  payload: page
});
export const goToEditGiveawayStepAction = (step, giveaway) => dispatch => {
  dispatch(push(InventoryUrl.getGiveawayPage(giveaway.inventoryId, String(giveaway._id), step)));
};
export const goToEditGiveawayStepActionWithReset = (step, giveaway, shouldSetHasGiveawayNotecard = false) => dispatch => {
  if (shouldSetHasGiveawayNotecard) {
    dispatch(setHasGiveawayNotecard(true));
  }
  dispatch(resetGiveaway());
  dispatch(deselectAllProducts());
  dispatch(goToEditGiveawayStepAction(step, giveaway));
};
export const selectCompletedGiveaway = giveawayId => (dispatch, getState) => {
  const giveaways = giveawayCompletedListSelector(getState());
  const giveaway = giveaways.find(g => String(g._id) === giveawayId);
  if (giveaway) {
    dispatch(saveGiveawayModelActionFactory(GIVEAWAY_ADMIN_PREFIX)(giveaway));
  }
};
export const SET_GIVEAWAY_FEEDBACK = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_FEEDBACK`;
export const setGiveawayFeedback = createAsyncLoadedAction({
  transformData: (giveawayId, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    return {
      giveawayId,
      inventoryId
    };
  },
  fn: InventoryApi.giveaway.getFeedback,
  success: feedback => ({
    type: SET_GIVEAWAY_FEEDBACK,
    payload: feedback
  }),
  error: (err, dispatch) => {
    dispatch(showNotification({
      text: 'Oops, something went wrong'
    }));
  }
});
export const RESET_GIVEAWAY_FEEDBACK = `${GIVEAWAY_ADMIN_PREFIX}RESET_GIVEAWAY_FEEDBACK`;
export const resetGiveawayFeedBack = () => ({
  type: RESET_GIVEAWAY_FEEDBACK
});