import { getPosts } from 'swag-client-common/redux/blog/blog.actions';
import { getSuperSpeedSettings } from 'swag-client-common/redux/settings/settings-other.actions';
import { getGamificationLevels, getGamificationTransactions, getScoreHistory } from '../../redux/gamification/gamification.actions';
import { getCoreProductsCount } from '../../redux/pages/pages.actions';
export function fetchInitialStatesOther(dispatch) {
  dispatch(getPosts());
  dispatch(getCoreProductsCount());
  dispatch(getSuperSpeedSettings());
}
export const getGamification = dispatch => {
  dispatch(getGamificationLevels());
  dispatch(getGamificationTransactions());
  dispatch(getScoreHistory());
};
export const getCheckoutTenantData = () => undefined;