import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { ProductsApi } from '../../api/product.api';
import { selectedInventorySelector } from '../inventory/inventory.selectors';
const PREFIX = 'ADVANCED_DISTRIBUTIONS/';
export const GET_ADVANCED_DISTRIBUTION_STARTED = `${PREFIX}ADVANCED_DISTRIBUTION_STARTED`;
export const GET_ADVANCED_DISTRIBUTION_SUCCESS = `${PREFIX}ADVANCED_DISTRIBUTION_SUCCESS`;
export const GET_ADVANCED_DISTRIBUTION_ERROR = `${PREFIX}ADVANCED_DISTRIBUTION_ERROR`;
export const getAdvancedDistributionForProducts = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_ADVANCED_DISTRIBUTION_STARTED
  }),
  transformData: ({
    parentIds,
    productIds
  }, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    return {
      productIds,
      parentIds,
      inventoryIds: [inventoryId]
    };
  },
  fn: ProductsApi.getAdvancedDistribution,
  success: payload => ({
    type: GET_ADVANCED_DISTRIBUTION_SUCCESS,
    payload
  }),
  error: () => ({
    type: GET_ADVANCED_DISTRIBUTION_ERROR
  })
});