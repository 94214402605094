import Joi from 'joi';
import { GiveawayProductChoice, GiveawayStep, GIVEAWAY_DESCRIPTION_MAX_LENGTH, GIVEAWAY_NAME_MAX_LENGTH } from '../../../constants/giveaway.constants';
export const giveawayModelValidationKeys = {
  inventoryId: Joi.string(),
  name: Joi.string().min(3).max(GIVEAWAY_NAME_MAX_LENGTH),
  description: Joi.string().min(1).max(GIVEAWAY_DESCRIPTION_MAX_LENGTH),
  productChoice: Joi.string().valid(...Object.values(GiveawayProductChoice)),
  productQuantity: Joi.number().min(1),
  colors: Joi.object(),
  step: Joi.string().valid(...Object.values(GiveawayStep)),
  passedSteps: Joi.array().items(Joi.string().valid(...Object.values(GiveawayStep))),
  products: Joi.array().min(1).items(Joi.string()),
  logo: Joi.string(),
  primaryColor: Joi.string().length(7),
  domains: Joi.array().items(Joi.string()),
  isOneProduct: Joi.boolean(),
  variantCapMap: Joi.array().items(Joi.object())
};