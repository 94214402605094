import isBox from 'swag-common/utils/custom-store-product/product-is-box.util';
import getBoxVariantSizeName from 'swag-common/business-logic/custom-store-product/get-box-variant-size-name.logic';
export const getVariantBySize = ({
  variants,
  sizeName,
  sizeIndex
}) => {
  const variant = variants.find(variant => {
    if (isBox(variant.swagProductId)) {
      return getBoxVariantSizeName(variant) === sizeName;
    }
    return variant.sizeIndex === sizeIndex;
  });
  return variant || null;
};
export const getVariantBySizeName = ({
  variants,
  sizeName
}) => {
  const variant = variants.find(({
    sizeSettings,
    sizeIndex
  }) => {
    const options = (sizeSettings === null || sizeSettings === void 0 ? void 0 : sizeSettings.options) || [];
    const size = options[sizeIndex];
    return size === sizeName;
  });
  return variant || null;
};
export const getCommonSizeIndexFromVariant = ({
  variant,
  commonSizes
}) => {
  var _variant$sizeSettings;
  const options = ((_variant$sizeSettings = variant.sizeSettings) === null || _variant$sizeSettings === void 0 ? void 0 : _variant$sizeSettings.options) || [];
  const size = options[variant.sizeIndex || 0];
  const commonSizeIndex = commonSizes.findIndex(s => s === size);
  return commonSizeIndex;
};