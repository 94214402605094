import _initialize from "swag-client-common/redux-form-actions/initialize";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { userIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { DEMO_INVENTORY_WARNING_POPUP } from 'swag-client-common/constants';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { GIVEAWAY_PAY_POPUP_FLOW } from 'swag-common/constants/payment-methods';
import PaymentMethodsApi from '../../api/payment-methods.api';
import { isSelectedDemoInventorySelector, selectedInventorySelector } from '../inventory/inventory.selectors';
import { getToken } from '../stripe-form/stripe-form.actions';
import { goToPaymentMethodsEditAction, goToPaymentMethodsListAction } from '../inventory-settings/inventory-settigns.actions';
import { checkAccessRights } from '../../utils/enterprise-access-wrapper';
import { setGiveawayPayPopupFlowAction } from '../giveaway/actions/giveaway-pay-fees-popup.actions';
import { STRIPE_FORM_SUBMIT_SUCCESS } from '../stripe-form/stripe-form.actions';
import { isGiveawayNotecardPathSelector } from '../common/common.selectors';
import { inventoryDefaultCardIdSelector, inventoryDefaultCardSelector, inventoryPaymentBillingFormSelector, isInventoryDefaultRemovedSelector, paymentMethodFormSelector, paymentMethodIdSelector, paymentMethodIsEditSelector, publicAPIDefaultPaymentSelector, userCardsSelector, userDefaultCardIdSelector } from './payment-methods.selectors';
const PREFIX = 'PAYMENT_METHODS_';
const sendRequest = (address, stripe, card, onTokenReceive) => dispatch => {
  getToken(dispatch, address, stripe, card, onTokenReceive);
};
export const RESET_PAYMENT_METHOD = `${PREFIX}RESET_PAYMENT_METHOD`;
export const resetSelectedCard = () => ({
  type: RESET_PAYMENT_METHOD
});
export const SET_IS_INVENTORY_DEFAULT_REMOVED = `${PREFIX}SET_IS_INVENTORY_DEFAULT_REMOVED`;
export const toggleIsInventoryDefaultRemoved = () => ({
  type: SET_IS_INVENTORY_DEFAULT_REMOVED
});
export const SHOW_CHANGE_DEFAULT_INVENTORY_PAYMENT_METHOD_POPUP = 'SHOW_CHANGE_DEFAULT_INVENTORY_PAYMENT_METHOD_POPUP';
export const showConfirmChangePopup = ({
  showConfirmChangePopup
}) => {
  return {
    type: SHOW_CHANGE_DEFAULT_INVENTORY_PAYMENT_METHOD_POPUP,
    payload: showConfirmChangePopup
  };
};
export const addCard = (stripe, card) => (dispatch, getState) => {
  const state = getState();
  const inventoryId = selectedInventorySelector(state);
  const publicAPIDefaultPayment = publicAPIDefaultPaymentSelector(state);
  const values = paymentMethodFormSelector(state);
  const {
      holder,
      inventoryDefault,
      userDefault,
      firstName,
      lastName
    } = values,
    rest = _objectWithoutProperties(values, ["holder", "inventoryDefault", "userDefault", "firstName", "lastName"]);
  const address = _objectSpread({}, rest, {
    cardHolder: holder
  });
  dispatch(sendRequest(address, stripe, card, payload => dispatch(addPaymentMethod({
    inventoryId,
    cardToken: payload.id,
    inventoryDefault: Boolean(inventoryDefault),
    userDefault: Boolean(userDefault),
    publicAPIDefaultPayment: {
      paymentMethod: publicAPIDefaultPayment.value,
      card: publicAPIDefaultPayment.card
    },
    firstName,
    lastName
  }))));
};
const onAddCardSuccess = response => dispatch => {
  dispatch({
    type: ADD_CARD_SUCCESS,
    payload: response
  });
  dispatch({
    type: STRIPE_FORM_SUBMIT_SUCCESS
  });
};
export const ADD_CARD_REQUEST = `${PREFIX}ADD_CARD_REQUEST`;
export const ADD_CARD_SUCCESS = `${PREFIX}ADD_CARD_SUCCESS`;
export const ADD_CARD_FAILURE = `${PREFIX}ADD_CARD_FAILURE`;
export const addPaymentMethod = createAsyncLoadedAction({
  preFn: dispatch => {
    dispatch({
      type: ADD_CARD_REQUEST
    });
  },
  fn: PaymentMethodsApi.addCard,
  success: onAddCardSuccess,
  postSuccess: dispatch => {
    dispatch(showNotification({
      text: 'Credit card was successfully added'
    }));
    dispatch(showConfirmChangePopup({
      showConfirmChangePopup: false
    }));
    dispatch(goToPaymentMethodsListAction());
    dispatch(_initialize('paymentMethodForm', {}));
  },
  error: (error, transformedData, dispatch) => {
    dispatch(showNotification({
      text: error.message
    }));
    dispatch({
      type: ADD_CARD_FAILURE
    });
  },
  postError: dispatch => {
    dispatch(showConfirmChangePopup({
      showConfirmChangePopup: false
    }));
    dispatch(goToPaymentMethodsEditAction());
  }
});
export const GET_CARDS_REQUEST = `${PREFIX}GET_CARDS_REQUEST`;
export const GET_CARDS_SUCCESS = `${PREFIX}GET_CARDS_SUCCESS`;
export const GET_CARDS_ERROR = `${PREFIX}GET_CARDS_ERROR`;
export const getPaymentMethods = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    return {
      inventoryId
    };
  },
  preFn: dispatch => dispatch({
    type: GET_CARDS_REQUEST
  }),
  fn: PaymentMethodsApi.getCards,
  success: response => (dispatch, getState) => {
    dispatch({
      type: GET_CARDS_SUCCESS,
      payload: response
    });
    const isNotecardPage = isGiveawayNotecardPathSelector(getState());
    const {
      cards
    } = response;
    if (isNotecardPage) {
      dispatch(preselectCreditCard());
    }
    if (cards.length) {
      return dispatch(setGiveawayPayPopupFlowAction(GIVEAWAY_PAY_POPUP_FLOW.SAVE_CARD));
    }
    dispatch(setGiveawayPayPopupFlowAction(GIVEAWAY_PAY_POPUP_FLOW.PAY_BY_LINK));
  },
  error: error => ({
    type: GET_CARDS_ERROR,
    payload: error
  })
});
export const UPDATE_CARD_REQUEST = `${PREFIX}UPDATE_CARD_REQUEST`;
export const UPDATE_CARD_SUCCESS = `${PREFIX}UPDATE_CARD_SUCCESS`;
export const UPDATE_CARD_FAILURE = `${PREFIX}UPDATE_CARD_FAILURE`;
export const updatePaymentMethod = createAsyncLoadedAction({
  preFn: dispatch => {
    dispatch({
      type: UPDATE_CARD_REQUEST
    });
  },
  transformData: (data, getState, dispatch) => {
    const store = getState();
    const inventoryId = selectedInventorySelector(store);
    const cardId = paymentMethodIdSelector(store);
    const currentInventoryDefault = inventoryDefaultCardIdSelector(store);
    const values = paymentMethodFormSelector(store);
    const publicAPIDefaultPayment = publicAPIDefaultPaymentSelector(store);
    const {
      billingAddress,
      inventoryDefault,
      userDefault
    } = getBillingAddress(values);
    if (!inventoryDefault && String(currentInventoryDefault) === String(cardId)) {
      dispatch(toggleIsInventoryDefaultRemoved());
    }
    return {
      cardId,
      inventoryId,
      billingAddress,
      inventoryDefault: Boolean(inventoryDefault),
      userDefault: Boolean(userDefault),
      publicAPIDefaultPayment: {
        paymentMethod: publicAPIDefaultPayment.value,
        card: publicAPIDefaultPayment.card
      }
    };
  },
  fn: PaymentMethodsApi.updateCard,
  success: (response, data) => ({
    type: UPDATE_CARD_SUCCESS,
    payload: _objectSpread({}, response, {
      params: data
    })
  }),
  postSuccess: (dispatch, getState, data) => {
    dispatch(showConfirmChangePopup({
      showConfirmChangePopup: false
    }));
    dispatch(goToPaymentMethodsListAction());
    dispatch(_initialize('paymentMethodForm', {}));
    dispatch(setInitialPublicAPIDefaultPayment(data.publicAPIDefaultPayment.paymentMethod, data.publicAPIDefaultPayment.card));
    if (isInventoryDefaultRemovedSelector(getState())) {
      dispatch(showNotification({
        text: 'The inventory default payment method has been removed'
      }));
      return dispatch(toggleIsInventoryDefaultRemoved());
    }
    dispatch(resetSelectedCard());
    dispatch(showNotification({
      text: 'Credit card was successfully updated'
    }));
  },
  error: (error, transformedData, dispatch) => {
    dispatch(showNotification({
      text: error.message
    }));
    dispatch({
      type: UPDATE_CARD_FAILURE
    });
  },
  postError: dispatch => {
    dispatch(showConfirmChangePopup({
      showConfirmChangePopup: false
    }));
    dispatch(goToPaymentMethodsEditAction());
  }
});
export const EDIT_PAYMENT_METHOD = `${PREFIX}EDIT_PAYMENT_METHOD`;
export const openEditPaymentForm = cardId => dispatch => {
  dispatch({
    type: EDIT_PAYMENT_METHOD,
    payload: {
      cardId
    }
  });
  dispatch(goToPaymentMethodsEditAction());
};
export const SELECT_CARD = `${PREFIX}SELECT_CARD`;
export const selectCard = cardId => dispatch => {
  dispatch({
    type: SELECT_CARD,
    payload: {
      cardId
    }
  });
};
export const SAVE_CURRENT_TAB_IN_PAYMENT_POPUP = `${PREFIX}SAVE_CURRENT_TAB_IN_PAYMENT_POPUP
 `;
export const saveCurrentTabInPaymentPopup = tab => dispatch => {
  dispatch({
    type: SAVE_CURRENT_TAB_IN_PAYMENT_POPUP,
    payload: {
      tab
    }
  });
};
export const preselectCreditCard = () => (dispatch, getState) => {
  const state = getState();
  const cards = userCardsSelector(state);
  const userDefault = userDefaultCardIdSelector(state);
  const inventoryDefault = inventoryDefaultCardIdSelector(state);
  if (userDefault || inventoryDefault) {
    return dispatch(selectCard((userDefault || inventoryDefault).toString()));
  }
  if (cards.length > 0) {
    dispatch(selectCard(cards[0]._id.toString()));
  }
};
function getBillingAddress(values) {
  const {
    city,
    unit,
    state,
    street,
    zipcode,
    country,
    lastName,
    firstName,
    userDefault,
    inventoryDefault
  } = values;
  return {
    userDefault,
    inventoryDefault,
    billingAddress: {
      city,
      unit,
      state,
      street,
      country,
      lastName,
      firstName,
      zipcode
    }
  };
}
export const DELETE_CARD_REQUEST = `${PREFIX}DELETE_CARD_REQUEST`;
export const DELETE_CARD_SUCCESS = `${PREFIX}DELETE_CARD_SUCCESS`;
export const DELETE_CARD_ERROR = `${PREFIX}DELETE_CARD_ERROR`;
export const deleteCardActionRequest = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    const cardId = paymentMethodIdSelector(getState());
    return {
      inventoryId,
      cardId
    };
  },
  preFn: dispatch => dispatch({
    type: DELETE_CARD_REQUEST
  }),
  fn: PaymentMethodsApi.deleteCard,
  success: data => ({
    type: DELETE_CARD_SUCCESS,
    payload: data
  }),
  error: () => ({
    type: DELETE_CARD_ERROR
  }),
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(goToPaymentMethodsListAction());
  }
});
export const deleteCardAction = checkAccessRights(deleteCardActionRequest, DemoInventoryWarningTypes.PaymentMethodsRemove);
export const submitCardAction = (skipChecking = false, stripe, card) => (dispatch, getState) => {
  const state = getState();
  const isEdit = paymentMethodIsEditSelector(state);
  if (isSelectedDemoInventorySelector(getState())) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: isEdit ? DemoInventoryWarningTypes.PaymentMethodsEdit : DemoInventoryWarningTypes.PaymentMethodsAdd
    }));
  }
  const defaultInventoryCard = inventoryDefaultCardSelector(state);
  const userId = userIdSelector(state);
  const values = paymentMethodFormSelector(state);
  if (!skipChecking && defaultInventoryCard && defaultInventoryCard.userId !== userId && values.inventoryDefault) {
    return dispatch(showConfirmChangePopup({
      showConfirmChangePopup: true
    }));
  }
  if (isEdit) {
    dispatch(updatePaymentMethod());
  } else {
    dispatch(addCard(stripe, card));
  }
  dispatch(showConfirmChangePopup({
    showConfirmChangePopup: false
  }));
};
export const preparePaymentToInventoryProceed = (stripe, card, onTokenReceive) => (dispatch, getState) => {
  const store = getState();
  const {
    values
  } = inventoryPaymentBillingFormSelector(store);
  const {
    holder,
    firstName,
    lastName,
    state,
    city,
    country,
    unit,
    zipcode,
    street
  } = values;
  const address = {
    state,
    city,
    country,
    unit,
    zipcode,
    street,
    firstName,
    lastName,
    cardHolder: holder
  };
  return getToken(dispatch, address, stripe, card, onTokenReceive);
};
export const SET_PUBLIC_API_DEFAULT_PAYMENT = `${PREFIX}SET_PUBLIC_API_DEFAULT_PAYMENT`;
export const setPublicAPIDefaultPayment = (value, card, isAddingNew = false) => ({
  type: SET_PUBLIC_API_DEFAULT_PAYMENT,
  payload: {
    value,
    card,
    isAddingNew
  }
});
export const SET_INITIAL_PUBLIC_API_DEFAULT_PAYMENT = `${PREFIX}SET_INITIAL_PUBLIC_API_DEFAULT_PAYMENT`;
export const setInitialPublicAPIDefaultPayment = (value, card) => ({
  type: SET_INITIAL_PUBLIC_API_DEFAULT_PAYMENT,
  payload: {
    value,
    card
  }
});
export const RESET_PUBLIC_API_DEFAULT_PAYMENT = `${PREFIX}RESET_PUBLIC_API_DEFAULT_PAYMENT`;
export const resetPublicAPIDefaultPayment = () => ({
  type: RESET_PUBLIC_API_DEFAULT_PAYMENT
});