function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { variables } from 'swag-client-common/styles/variables-js';
import { INVENTORY_BUY_CREDITS_POPUP } from 'swag-client-common/constants';
export const COMMON_STRIPE_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: variables.fontFamilyRegular,
      fontSize: '15px',
      fontWeight: 'normal',
      height: 'inherit',
      letterSpacing: '0px',
      lineHeight: '26px',
      padding: '9px 0 9px',
      '::placeholder': {
        opacity: 1,
        color: variables.brownishGrey,
        fontSize: '15px',
        lineHeight: '26px'
      },
      width: '100%'
    },
    invalid: {
      color: 'inherit'
    }
  }
};
export function getDateStyles(popupType = '', isFixedStyles = false) {
  if (window.innerWidth <= 1440 || isFixedStyles) {
    return COMMON_STRIPE_ELEMENT_OPTIONS;
  }
  switch (popupType) {
    case INVENTORY_BUY_CREDITS_POPUP:
      {
        return {
          style: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style, {
            base: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base, {
              height: '39vw',
              lineHeight: '39vw',
              letterSpacing: '0',
              fontSize: '21vw',
              '::placeholder': _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base['::placeholder'], {
                fontSize: '21vw'
              })
            })
          })
        };
      }
    default:
      {
        return {
          style: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style, {
            base: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base, {
              height: '29vw',
              lineHeight: '29vw',
              fontSize: '16vw',
              '::placeholder': _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base['::placeholder'], {
                fontSize: '16vw'
              })
            })
          })
        };
      }
  }
}
export function getCVVStyles(popupType = '', isFixedStyles = false) {
  if (window.innerWidth <= 1440 || isFixedStyles) {
    return COMMON_STRIPE_ELEMENT_OPTIONS;
  }
  switch (popupType) {
    case INVENTORY_BUY_CREDITS_POPUP:
      {
        return {
          style: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style, {
            base: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base, {
              height: '50vw',
              lineHeight: '50vw',
              fontSize: '29vw',
              '::placeholder': _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base['::placeholder'], {
                fontSize: '29vw'
              })
            })
          })
        };
      }
    default:
      {
        return {
          style: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style, {
            base: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base, {
              height: '33vw',
              lineHeight: '33vw',
              fontSize: '19vw',
              '::placeholder': _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base['::placeholder'], {
                fontSize: '19vw'
              })
            })
          })
        };
      }
  }
}
export function getCardNumberStyles(popupType = '', isFixedStyles = false) {
  if (window.innerWidth <= 1440 || isFixedStyles) {
    return COMMON_STRIPE_ELEMENT_OPTIONS;
  }
  switch (popupType) {
    case INVENTORY_BUY_CREDITS_POPUP:
      {
        return {
          style: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style, {
            base: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base, {
              fontSize: '8vw',
              lineHeight: '11vw',
              height: '11vw',
              padding: '4vw 0 4vw',
              '::placeholder': _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base['::placeholder'], {
                fontSize: '8vw'
              })
            })
          })
        };
      }
    default:
      {
        return {
          style: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style, {
            base: _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base, {
              fontSize: '6vw',
              lineHeight: '10vw',
              height: '10vw',
              padding: '4vw 0 4vw',
              '::placeholder': _objectSpread({}, COMMON_STRIPE_ELEMENT_OPTIONS.style.base['::placeholder'], {
                fontSize: '6vw'
              })
            })
          })
        };
      }
  }
}