import { createSelector } from 'reselect';
import draftToHtml from 'draftjs-to-html';
import { isLoadingSelector } from '../../stripe-form/stripe-form.selectors';
import { giveawaySelector } from './common.selectors';
export const giveawayNotecardSelector = createSelector(giveawaySelector, giveaway => giveaway.notecardData);
export const selectedGiveawayNotecardAmountSelector = createSelector(giveawayNotecardSelector, notecardData => notecardData.amount);
export const isGiveawayNotecardPaidSelector = createSelector(giveawayNotecardSelector, notecardData => notecardData.isPaid);
export const areWidgetsShownAfterPaymentSelector = createSelector(giveawayNotecardSelector, notecardData => notecardData.areWidgetsShownAfterPayment);
export const selectedGiveawayNotecardCostSelector = createSelector(giveawayNotecardSelector, notecardData => notecardData.notecardCost);
export const selectedGiveawayNotecardTotalCostSelector = createSelector(giveawayNotecardSelector, notecardData => notecardData.notecardCost.total);
export const selectedGiveawayNotecardPropsSelector = createSelector(giveawayNotecardSelector, notecardData => notecardData.notecardProps);
export const selectedGiveawayNotecardEditorSelector = createSelector(giveawayNotecardSelector, notecardData => notecardData.notecardEditor);
export const giveawayNotecardContentSelector = createSelector(selectedGiveawayNotecardEditorSelector, notecardEditor => {
  var _notecardEditor$edito;
  if (notecardEditor !== null && notecardEditor !== void 0 && (_notecardEditor$edito = notecardEditor.editorRawContent) !== null && _notecardEditor$edito !== void 0 && _notecardEditor$edito.blocks) {
    return notecardEditor.editorRawContent;
  }
  return {
    blocks: [],
    entityMap: {}
  };
});
export const giveawayNotecardTextContentSelector = createSelector(giveawayNotecardContentSelector, draftToHtml);
export const giveawayNotecardColorSelector = createSelector(selectedGiveawayNotecardEditorSelector, notecardEditor => (notecardEditor === null || notecardEditor === void 0 ? void 0 : notecardEditor.color) || '');
export const giveawayNotecardLogoSelector = createSelector(selectedGiveawayNotecardEditorSelector, notecardEditor => (notecardEditor === null || notecardEditor === void 0 ? void 0 : notecardEditor.logo) || '');
export const giveawayNotecardDefaultImageSelector = createSelector(selectedGiveawayNotecardEditorSelector, notecardEditor => (notecardEditor === null || notecardEditor === void 0 ? void 0 : notecardEditor.defaultImage) || '');
export const giveawayNotecardUIDataSelector = createSelector(giveawaySelector, ({
  notecardUIData
}) => notecardUIData);
export const hasGiveawayNotecardSelector = createSelector(selectedGiveawayNotecardEditorSelector, notecardEditor => Boolean(notecardEditor));
export const isGiveawayNotecardEditorValidSelector = createSelector(giveawayNotecardUIDataSelector, ({
  isEditorInputValid
}) => isEditorInputValid);
export const isGiveawayNotecardAmountValidSelector = createSelector(giveawayNotecardUIDataSelector, ({
  isAmountValid
}) => isAmountValid);
export const isGiveawayNotecardStepValid = createSelector(hasGiveawayNotecardSelector, isGiveawayNotecardEditorValidSelector, isGiveawayNotecardAmountValidSelector, isGiveawayNotecardPaidSelector, (hasGiveawayNotecard, isEditorValid, isGiveawayNotecardAmountValid, paymentIsSuccessful) => {
  const isValidStep = isEditorValid && isGiveawayNotecardAmountValid && paymentIsSuccessful;
  return !hasGiveawayNotecard || isValidStep;
});
export const areNotecardQuantityAndPaymentWidgetsShownSelector = createSelector(isGiveawayNotecardPaidSelector, areWidgetsShownAfterPaymentSelector, (isPaid, areWidgetsShownAfterPayment) => {
  return !isPaid || isPaid && areWidgetsShownAfterPayment;
});
export const isPaymentEnabledSelector = createSelector(isGiveawayNotecardPaidSelector, giveawayNotecardLogoSelector, giveawayNotecardColorSelector, isGiveawayNotecardEditorValidSelector, isGiveawayNotecardAmountValidSelector, isLoadingSelector, (isPaid, logo, color, isValidEditor, isValidAmount, isLoading) => {
  const notecardDataIsValid = !!logo && !!color && isValidEditor && isValidAmount;
  return notecardDataIsValid && !isPaid && !isLoading;
});