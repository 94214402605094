import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/order';
export const OrderApi = createApi({
  calculateGiveawayNotecardCost: {
    url: `${baseUrl}/calculate-giveaway-notecard-costs`,
    method: 'post'
  },
  calculateSwagBundleAddonsCosts: {
    url: `${baseUrl}/calculate-bundle-addons-costs`,
    method: 'post'
  }
});