function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { featureTogglesSelector } from 'swag-client-common/redux/feature-toggles/feature-toggles.selectors';
import { DemoInventoryWarningTypes } from 'swag-common/constants/chameleon-tours';
import { InventoryUrl } from 'swag-common/utils/urls/inventory.url';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { GiveawayTypes } from 'swag-common/interfaces/giveaway';
import { push, replace } from 'react-router-redux';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { forcedPath } from 'swag-common/business-logic/redirect-wrapper-state';
import { addProductsToGiveawayFactory, addProductsWithColorsToGiveawayFactory, addProductWithColorsToGiveawayFactory, removeProductWithColorsFromGiveawayFactory } from 'swag-client-common/redux/partials/giveaway-products/giveaway-products.actions';
import { USER_ERROR_ACTIONS } from 'swag-common/constants/user-actions';
import { giveawayCompletedTabType, GiveawayLinkType, GiveawayStep, nonInventoryUrls } from 'swag-common/constants/giveaway.constants';
import { toDateFormat } from 'swag-common/utils/date-lib';
import { createAsyncAction } from 'swag-client-common/libs/redux-helpers';
import { getGiveawayNextStep } from 'swag-common/business-logic/giveaway/steps/get-next-giveaway-step';
import { getGiveawayPreviousStep } from 'swag-common/business-logic/giveaway/steps/get-previous-giveaway-step';
import { SendToMultipleAddressesStep } from 'swag-common/constants/inventory';
import { instantScrollTop } from 'swag-client-common/utils/tricks';
import { CHANGE_GIVEAWAY_RESERVE_STATUS_TO_NON_RESERVE_POPUP, CHANGE_GIVEAWAY_RESERVE_STATUS_TO_RESERVE_POPUP, DEMO_INVENTORY_WARNING_POPUP, GIVEAWAY_AVAILABLE_APPAREL_PRODUCTS_POPUP, GIVEAWAY_EDIT_SINGLE_LINK_POPUP, SELECT_GIVEAWAY_TYPE_POPUP } from 'swag-client-common/constants';
import { saveGiveawayModelActionFactory } from 'swag-client-common/redux/partials/giveaway-selected/giveaway-selected.actions';
import { getGiveawayStepsByType } from 'swag-common/utils/giveaway/get-giveaway-steps-by-type.util';
import { goodieGiveawayApi } from 'swag-client-common/api/goodie-giveaway.api';
import { InventoryApi } from '../../../api/inventory.api';
import { areSomeProductsSelectedSelector, productsListSelector, selectedProductsIdsSelector } from '../../products/products.selectors';
import { inventoryActivitiesSearchQuerySelector, isSelectedDemoInventorySelector, selectedInventorySelector } from '../../inventory/inventory.selectors';
import { deselectAllProducts, listInventoryProducts, preselectProducts, toggleSelectionProduct } from '../../products/products.actions';
import { postponeAction } from '../../../middleware/postpone-actions.middleware';
import { activeStepSelector } from '../../send-to-multiple-addresses/send-to-multiple-addresses.selectors';
import { deselectShipout } from '../../store-shipouts/store-shipouts.actions';
import { resetCalculation } from '../../send-to-multiple-addresses/send-to-multiple-addresses.actions';
import { downloadCSVAction } from '../../../middleware/csv-download.middleware';
import { scrollWindowToTopAction } from '../../../middleware/window-manipulations.middleware';
import { windowChangeHref } from '../../../../giveaway/middleware/window-manipulations.middleware';
import { getPendingActivitiesAction } from '../../inventory/inventory.actions';
import { dataToSaveGiveawayDraftCreationSelector, dataToSaveGiveawayDraftSelector, dataToSaveGiveawaySelector, giveawayActiveStepSelector, giveawayColorsSelector, giveawayCsvLinksItemsSortedSelector, giveawayCustomSingleLinkValueSelector, giveawayDataForNextStepSelector, giveawayEditingFilenamePrefixSelector, giveawayEditingTypeSelector, giveawayFewLinksItemsSelector, giveawayPassedStepsSelector, giveawayProductIdsSelector, giveawayProductsWithColorsByProductIdsSelector, giveawaySingleLinkItemSelector, giveawaySkippedStepsSelector, isAllProductsZeroInStockAndProductionSelector, isGiveawayCapValueValidSelector, isGiveawayCurrentStepValidSelector, isGiveawayEditingSelector, isGiveawayFormValidSelectorByStepSelector, isLoadedGiveawayLastGiveawaySelector, isLoadedGiveawayProductsSelector, isProductChoiceStepValidSelector, isSelectedDemoGiveawaySelector, lastGiveawayItemSelector, shipmentsPageSelector, swagGiveawayDefaultCapSelector } from '../giveaway.selectors';
import { giveawayFeedbackSelector } from '../selectors/campaigns.selector';
import { giveawayIdSelector, selectedGiveawaySelector } from '../selectors/common.selectors';
import { isGiveawayNotSavedInDraftSelector } from '../selectors/giveaway-selected.selectors';
import { paymentMethodIdSelector } from '../../payment-methods/payment-methods.selectors';
import { getPaymentMethods, preselectCreditCard } from '../../payment-methods/payment-methods.actions';
import { getGiveawayProducts, getMinInStockValue, isSomeProductZeroStockAndProduction } from '../giveaway.utils';
import { goodieGiveawayRecipientQuantitySelector } from '../selectors/goodie-giveaway-budget.selectors';
import { getAdvancedDistributionForProducts } from '../../advanced-distributions/advanced-distributions.actions';
import { uploadLogoCreator } from '../../common/common.actions';
import { GIVEAWAY_ADMIN_PREFIX, resetGiveaway } from './giveaway-common.actions';
import { goToEditGiveawayStepAction, selectCompletedGiveaway } from './giveaway-campaigns.actions';
import { restoreNotecardEditorState, setNotecardLogoAndColorFromGiveawayBeforeNotecardStep } from './giveaway-notecard.actions';
import { setGoodieGiveawayBudget, setGoodieGiveawayRecipientQuantity } from './goodie-giveaway-budget.actions';
export const getDefaultProductColors = (productIds, state) => {
  const productsWithColors = giveawayProductsWithColorsByProductIdsSelector(() => productIds)(state);
  return productsWithColors.reduce((acc, {
    product,
    colors
  }) => {
    const colorsInStock = colors.filter(color => color.quantity !== 0);
    if (colorsInStock.length === 0) {
      return _objectSpread({}, acc, {
        [product._id]: [colors[0].colorValue]
      });
    }
    return _objectSpread({}, acc, {
      [product._id]: [colorsInStock[0].colorValue]
    });
  }, {});
};
export const SET_GIVEAWAY_SKIPPED_STEPS = `${GIVEAWAY_ADMIN_PREFIX}_SET_GIVEAWAY_SKIPPED_STEPS`;
const setGiveawaySkippedStepsAction = steps => ({
  type: SET_GIVEAWAY_SKIPPED_STEPS,
  payload: steps
});
export const goToGiveawayMainPage = ({
  productId,
  isSelectProductsSkipped,
  type = GiveawayTypes.swag
}) => {
  return (dispatch, getState) => {
    if (productId) {
      dispatch(toggleSelectionProduct({
        productId,
        isSelected: true
      }));
    }
    const selectedProducts = selectedProductsIdsSelector(getState());
    const inventoryId = selectedInventorySelector(getState());
    if (!inventoryId) {
      return;
    }
    dispatch(resetGiveaway());
    dispatch(setGiveawayType(type));
    if (isSelectProductsSkipped) {
      dispatch(deselectAllProducts());
      dispatch(setGiveawaySkippedStepsAction([GiveawayStep.selectProducts]));
    }
    dispatch(addProductsWithInformationToGiveaway(selectedProducts));
    dispatch(push(InventoryUrl.getCreateGiveaway(inventoryId, type)));
  };
};
export const SET_GIVEAWAY_TYPE = `${GIVEAWAY_ADMIN_PREFIX}SET_TYPE`;
export const setGiveawayType = giveawayType => {
  return {
    type: SET_GIVEAWAY_TYPE,
    payload: giveawayType
  };
};
export const GIVEAWAY_NEXT_STEP = `${GIVEAWAY_ADMIN_PREFIX}_NEXT_STEP`;
const nextGiveawayStepAction = activeStep => (dispatch, getState) => {
  const state = getState();
  const giveawayType = giveawayEditingTypeSelector(state);
  const possibleSteps = getGiveawayStepsByType(giveawayType);
  const stepToFind = activeStep === GiveawayStep.selectLast ? GiveawayStep.customize : activeStep;
  let passedSteps = giveawayPassedStepsSelector(state);
  const maxPassedStep = passedSteps[passedSteps.length - 1];
  const indexCurrentStep = possibleSteps.indexOf(stepToFind);
  const indexMaxPassedStep = possibleSteps.indexOf(maxPassedStep);
  const skippedSteps = giveawaySkippedStepsSelector(state);
  skippedSteps.forEach(step => {
    const indexSkippedStep = possibleSteps.indexOf(step);
    if (indexSkippedStep > indexMaxPassedStep) {
      passedSteps = [...passedSteps, step];
    }
  });
  if (indexCurrentStep > indexMaxPassedStep) {
    passedSteps = [...passedSteps, stepToFind];
  }
  const payload = {
    activeStep,
    passedSteps
  };
  dispatch({
    type: GIVEAWAY_NEXT_STEP,
    payload
  });
};
export const GIVEAWAY_PASSED_STEPS = `${GIVEAWAY_ADMIN_PREFIX}_GIVEAWAY_PASSED_STEPS`;
const setGiveawayPassedStepsAction = steps => ({
  type: GIVEAWAY_PASSED_STEPS,
  payload: steps
});
export const ADD_GIVEAWAY_PASSED_STEP = `${GIVEAWAY_ADMIN_PREFIX}_ADD_GIVEAWAY_PASSED_STEP`;
const addGiveawayPassedStepAction = step => ({
  type: ADD_GIVEAWAY_PASSED_STEP,
  payload: step
});
export const REMOVE_GIVEAWAY_PASSED_STEP = `${GIVEAWAY_ADMIN_PREFIX}_REMOVE_GIVEAWAY_PASSED_STEP`;
const removeGiveawayPassedStepAction = step => ({
  type: REMOVE_GIVEAWAY_PASSED_STEP,
  payload: step
});
export const goToNextGiveawayStepAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    const isCurrentStepValid = isGiveawayCurrentStepValidSelector(state);
    if (!isCurrentStepValid) {
      return;
    }
    const data = giveawayDataForNextStepSelector(state);
    const activeStep = giveawayActiveStepSelector(state);
    const isEditing = isGiveawayEditingSelector(state);
    const giveawayType = giveawayEditingTypeSelector(state);
    const isDemo = isSelectedDemoInventorySelector(getState());
    if (activeStep === GiveawayStep.naming) {
      data.isAllProductsZero = isAllProductsZeroInStockAndProductionSelector(state);
    }
    const nextStep = getGiveawayNextStep(activeStep, data, giveawayType);
    const isGiveawayNotSavedInDraft = isGiveawayNotSavedInDraftSelector(state);
    if (nextStep === GiveawayStep.notecard && !isEditing) {
      dispatch(setNotecardLogoAndColorFromGiveawayBeforeNotecardStep());
      dispatch(getPaymentMethods());
      const selectedCard = paymentMethodIdSelector(state);
      if (!selectedCard) {
        dispatch(preselectCreditCard());
      }
    }
    dispatch(nextGiveawayStepAction(nextStep));
    if (nextStep === GiveawayStep.send && isGiveawayNotSavedInDraft) {
      return dispatch(saveForLaterToDraftsAction());
    }
    if (nextStep === GiveawayStep.selectLast) {
      return dispatch(createGiveawayFromLast());
    }
    if (!isDemo) {
      dispatch(createOrUpdateGiveawayDraftRequestAction());
    }
  };
};
export const goToSpecificStep = step => dispatch => {
  dispatch(nextGiveawayStepAction(step));
};
export const goToPreviousGiveawayStepAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    const activeStep = giveawayActiveStepSelector(state);
    const isCurrentStepValid = isGiveawayCurrentStepValidSelector(state);
    if (![GiveawayStep.notecard, GiveawayStep.send].includes(activeStep) && !isCurrentStepValid) {
      return;
    }
    const data = giveawayDataForNextStepSelector(state);
    const giveawayType = giveawayEditingTypeSelector(state);
    const nextStep = getGiveawayPreviousStep(activeStep, data, giveawayType);
    const isDemo = isSelectedDemoInventorySelector(state);
    dispatch(nextGiveawayStepAction(nextStep));
    if (!isDemo) {
      dispatch(createOrUpdateGiveawayDraftRequestAction());
    }
  };
};
export const saveGiveawayForLater = routeToNavigate => {
  return (dispatch, getState) => {
    const state = getState();
    if (isSelectedDemoInventorySelector(state)) {
      const inventoryId = selectedInventorySelector(state);
      dispatch(hidePopup());
      dispatch(replace(forcedPath(InventoryUrl.getActivitiesGiveawayCampaignsPage(inventoryId), {
        active: giveawayCompletedTabType.completed
      })));
      return dispatch(scrollWindowToTopAction());
    }
    dispatch(showPopup({
      popup: 'saveGiveawayForLaterPopup',
      routeToNavigate
    }));
  };
};
const updateReserveMode = productList => dispatch => {
  const reserveMode = !isSomeProductZeroStockAndProduction(productList);
  dispatch(setReserveMode(reserveMode));
};
export const addProductsToGiveaway = addProductsWithColorsToGiveawayFactory(GIVEAWAY_ADMIN_PREFIX);
export const addProductsWithInformationToGiveaway = productIds => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProductIds = giveawayProductIdsSelector(state);
    const defaultColors = getDefaultProductColors(productIds || selectedProductIds, state);
    const productList = productsListSelector(state);
    const giveawayProducts = getGiveawayProducts(productList, productIds || selectedProductIds);
    const minInStockValue = getMinInStockValue(giveawayProducts);
    dispatch(updateReserveMode(giveawayProducts));
    dispatch(addProductsToGiveaway(productIds || selectedProductIds, defaultColors, minInStockValue));
  };
};
const hasStepAfterProductsChoice = state => {
  const passedSteps = giveawayPassedStepsSelector(state);
  const data = giveawayDataForNextStepSelector(state);
  const giveawayType = giveawayEditingTypeSelector(state);
  const nextStep = getGiveawayNextStep(GiveawayStep.productsChoice, data, giveawayType);
  return passedSteps.includes(nextStep);
};
export const addProductToGiveaway = addProductWithColorsToGiveawayFactory(GIVEAWAY_ADMIN_PREFIX);
export const addProductWithInformationToGiveaway = productId => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProductIds = giveawayProductIdsSelector(state);
    const defaultColors = getDefaultProductColors([productId], state);
    const productList = productsListSelector(state);
    const updatedProductIds = [...selectedProductIds, productId];
    const giveawayProducts = getGiveawayProducts(productList, updatedProductIds);
    const minInStockValue = getMinInStockValue(giveawayProducts);
    if (selectedProductIds.length === 1 && hasStepAfterProductsChoice(state)) {
      dispatch(addGiveawayPassedStepAction(GiveawayStep.productsChoice));
    }
    const passedSteps = giveawayPassedStepsSelector(state);
    if (!passedSteps.includes(GiveawayStep.reserve)) {
      dispatch(updateReserveMode(giveawayProducts));
    }
    dispatch(addProductToGiveaway(productId, defaultColors, minInStockValue));
  };
};
export const removeProductFromGiveaway = removeProductWithColorsFromGiveawayFactory(GIVEAWAY_ADMIN_PREFIX);
export const removeProductWithInformationFromGiveaway = productId => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProductIds = giveawayProductIdsSelector(state);
    const productList = productsListSelector(state);
    const updatedProductIds = selectedProductIds.filter(selectedProductId => selectedProductId === productId);
    const giveawayProducts = getGiveawayProducts(productList, updatedProductIds);
    const minInStockValue = getMinInStockValue(giveawayProducts);
    const passedSteps = giveawayPassedStepsSelector(state);
    if (selectedProductIds.length === 2 && passedSteps.includes(GiveawayStep.productsChoice)) {
      dispatch(removeGiveawayPassedStepAction(GiveawayStep.productsChoice));
    }
    if (!passedSteps.includes(GiveawayStep.reserve)) {
      dispatch(updateReserveMode(giveawayProducts));
    }
    dispatch(removeProductFromGiveaway(productId, minInStockValue));
  };
};
export const CHANGE_GIVEAWAY_NAME = `${GIVEAWAY_ADMIN_PREFIX}_CHANGE_GIVEAWAY_NAME`;
export const changeGiveawayNameAction = name => ({
  type: CHANGE_GIVEAWAY_NAME,
  payload: name
});
export const CHANGE_GIVEAWAY_DESCRIPTION = `${GIVEAWAY_ADMIN_PREFIX}_CHANGE_GIVEAWAY_DESCRIPTION`;
export const changeGiveawayDescriptionAction = description => ({
  type: CHANGE_GIVEAWAY_DESCRIPTION,
  payload: description
});
export const saveGiveawayModelAction = saveGiveawayModelActionFactory(GIVEAWAY_ADMIN_PREFIX);
export const saveForLaterAction = ({
  route
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const dataToSave = dataToSaveGiveawaySelector(state);
    const inventoryId = selectedInventorySelector(state);
    if (isSelectedDemoInventorySelector(state)) {
      dispatch(hidePopup());
      dispatch(replace(forcedPath(InventoryUrl.getActivitiesGiveawayCampaignsPage(inventoryId), {
        active: giveawayCompletedTabType.completed
      })));
      return dispatch(scrollWindowToTopAction());
    }
    const isProductChoiceStepValid = isProductChoiceStepValidSelector(state);
    const isGiveawayFormValidSelectorByStep = isGiveawayFormValidSelectorByStepSelector(GiveawayStep.naming)(state);
    if (!isProductChoiceStepValid || !isGiveawayFormValidSelectorByStep) {
      dispatch(showNotification({
        text: 'Sorry, there are problems while saving for later'
      }));
      return;
    }
    dispatch(saveForLaterRequestAction({
      dataToSave,
      route
    }));
    dispatch(deselectAllProducts());
  };
};
export const saveForLaterToDraftsAction = () => {
  return (dispatch, getState) => {
    const dataToSave = dataToSaveGiveawaySelector(getState());
    dispatch(saveForLaterToDraftsRequestAction({
      dataToSave
    }));
  };
};
const giveawaySaveForLaterSuccessAction = (giveaway, transformedData, _, data) => {
  return (dispatch, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    const isEditing = isGiveawayEditingSelector(getState());
    dispatch(hidePopup());
    dispatch(showNotification({
      text: isEditing ? 'You have successfully saved changes' : 'You have successfully saved for later'
    }));
    dispatch(saveGiveawayModelAction(giveaway));
    if (data.route && nonInventoryUrls.includes(data.route)) {
      dispatch(windowChangeHref(data.route));
    } else {
      const isCompleted = giveaway.step === GiveawayStep.completed || giveaway.step === GiveawayStep.suspended;
      dispatch(replace(forcedPath(InventoryUrl.getActivitiesGiveawayCampaignsPage(inventoryId), {
        active: isCompleted ? giveawayCompletedTabType.completed : giveawayCompletedTabType.draft
      })));
    }
    dispatch(scrollWindowToTopAction());
  };
};
const giveawaySaveForLaterToDraftsSuccessAction = giveaway => {
  return dispatch => {
    dispatch(showNotification({
      text: 'Saved to drafts'
    }));
    dispatch(saveGiveawayModelAction(giveaway));
  };
};
const saveForLaterRequestParameters = {
  transformData: ({
    dataToSave
  }, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    const giveawayId = giveawayIdSelector(getState());
    const isEditing = isGiveawayEditingSelector(getState());
    return _objectSpread({}, dataToSave, {
      inventoryId,
      giveawayId,
      isEditing
    });
  },
  fn: InventoryApi.giveaway.saveForLater,
  error: ({
    message
  }) => showNotification({
    text: message
  })
};
export const saveForLaterRequestAction = createAsyncLoadedAction(_objectSpread({}, saveForLaterRequestParameters, {
  success: giveawaySaveForLaterSuccessAction
}));
export const saveForLaterToDraftsRequestAction = createAsyncLoadedAction(_objectSpread({}, saveForLaterRequestParameters, {
  success: giveawaySaveForLaterToDraftsSuccessAction
}));
export const saveSingleLinkModeRequestAction = createAsyncLoadedAction(_objectSpread({}, saveForLaterRequestParameters));
export const createOrUpdateGiveaway = createAsyncAction({
  transformData: (data, getState) => {
    const dataToSaveGiveaway = dataToSaveGiveawayDraftSelector(getState());
    const inventoryId = selectedInventorySelector(getState());
    const giveawayId = giveawayIdSelector(getState());
    return _objectSpread({}, data, dataToSaveGiveaway, {
      inventoryId,
      giveawayId
    });
  },
  fn: InventoryApi.giveaway.saveDraft,
  error: err => showNotification({
    text: err.message || USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
  }),
  success: giveaway => dispatch => dispatch(saveGiveawayModelAction(giveaway))
});
const createOrUpdateGiveawayDraftRequestAction = createAsyncAction({
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const giveawayId = giveawayIdSelector(state);
    const dataToSaveGiveaway = !giveawayId ? dataToSaveGiveawayDraftCreationSelector(state) : dataToSaveGiveawayDraftSelector(state);
    return _objectSpread({}, dataToSaveGiveaway, {
      inventoryId,
      giveawayId
    });
  },
  fn: InventoryApi.giveaway.saveDraft,
  error: () => showNotification({
    text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
  }),
  success: giveaway => dispatch => dispatch(saveGiveawayModelAction(giveaway))
});
export const createGiveawayFromLast = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const state = getState();
    const giveaway = lastGiveawayItemSelector(state);
    const inventoryId = selectedInventorySelector(state);
    const giveawayId = giveawayIdSelector(state);
    const dataToSaveGiveaway = !giveawayId ? dataToSaveGiveawayDraftCreationSelector(state) : dataToSaveGiveawayDraftSelector(state);
    if (giveaway) {
      const {
        logo,
        primaryColor
      } = giveaway;
      return _objectSpread({}, dataToSaveGiveaway, {
        logo,
        primaryColor,
        inventoryId,
        giveawayId
      });
    }
    return _objectSpread({}, dataToSaveGiveaway, {
      logo: '',
      primaryColor: '',
      inventoryId,
      giveawayId
    });
  },
  fn: InventoryApi.giveaway.saveDraft,
  success: giveaway => dispatch => dispatch(saveGiveawayModelAction(giveaway)),
  error: () => showNotification({
    text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
  })
});
const CLEAR_GIVEAWAY_DRAFT = `${GIVEAWAY_ADMIN_PREFIX}CLEAR_GIVEAWAY_DRAFT`;
export const clearGiveawayDraftAction = createAsyncLoadedAction({
  fn: InventoryApi.giveaway.clearDraft,
  error: () => showNotification({
    text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
  }),
  success: () => ({
    type: CLEAR_GIVEAWAY_DRAFT
  })
});
export const showLeaveConfirmationAction = (route, originalPath, popup, onClick) => {
  return (dispatch, getState) => {
    if (originalPath.includes('send-to-multiple-addresses')) {
      const activeStep = activeStepSelector(getState());
      if (activeStep === SendToMultipleAddressesStep.success) {
        if (route.includes('inventory')) {
          dispatch(deselectShipout());
          dispatch(resetCalculation());
          return dispatch(push(forcedPath(route)));
        }
        return dispatch(windowChangeHref(route));
      }
    }
    dispatch(showPopup({
      popup,
      route,
      onClick
    }));
  };
};
export const exitGiveawayToRouteAction = route => {
  return windowChangeHref(route);
};
export const exitGiveawayToInventoryPageAction = inventoryId => exitGiveawayToRouteAction(InventoryUrl.getMainPage(inventoryId));
const exitSuccessAction = (route, onClick) => () => dispatch => {
  if (route) {
    if (nonInventoryUrls.some(item => item === route)) {
      dispatch(windowChangeHref(route));
    } else {
      dispatch(replace(forcedPath(route)));
    }
  }
  dispatch(deselectAllProducts());
  onClick && onClick();
  dispatch(scrollWindowToTopAction());
  dispatch(hidePopup());
};
export const exitGiveawaySubmitAction = (route, onClick) => () => (dispatch, getState) => {
  const state = getState();
  const inventoryId = selectedInventorySelector(state);
  const giveawayId = giveawayIdSelector(state);
  postponeAction({
    untilAction: CLEAR_GIVEAWAY_DRAFT,
    actionCreator: exitSuccessAction(route, onClick)
  });
  if (giveawayId) {
    dispatch(clearGiveawayDraftAction({
      inventoryId,
      giveawayId
    }));
  } else {
    dispatch({
      type: CLEAR_GIVEAWAY_DRAFT
    });
  }
};
export const SET_GIVEAWAY_PRODUCT_CHOICE = `${GIVEAWAY_ADMIN_PREFIX}_SET_GIVEAWAY_PRODUCT_CHOICE`;
export const setGiveawayProductChoice = choice => ({
  type: SET_GIVEAWAY_PRODUCT_CHOICE,
  payload: choice
});
export const SET_GIVEAWAY_PRODUCT_QUANTITY = `${GIVEAWAY_ADMIN_PREFIX}_SET_GIVEAWAY_PRODUCT_QUANTITY`;
export const setGiveawayProductQuantity = productQuantity => ({
  type: SET_GIVEAWAY_PRODUCT_QUANTITY,
  payload: productQuantity
});
export const ADD_GIVEAWAY_SINGLE_LINK_DOMAIN = `${GIVEAWAY_ADMIN_PREFIX}ADD_GIVEAWAY_SINGLE_LINK_DOMAIN`;
export const REMOVE_GIVEAWAY_SINGLE_LINK_DOMAIN = `${GIVEAWAY_ADMIN_PREFIX}REMOVE_GIVEAWAY_SINGLE_LINK_DOMAIN`;
export const addGiveawaySingleLinkDomain = domain => ({
  type: ADD_GIVEAWAY_SINGLE_LINK_DOMAIN,
  payload: domain
});
export const removeGiveawaySingleLinkDomain = domain => ({
  type: REMOVE_GIVEAWAY_SINGLE_LINK_DOMAIN,
  payload: domain
});
export const SET_GIVEAWAY_CAP = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_CAP`;
export const setGiveawaySingleLinkCapValue = value => ({
  type: SET_GIVEAWAY_CAP,
  payload: value
});
export const changeGiveawayCapValueAction = value => (dispatch, getState) => {
  const {
    giveaway: {
      editedModel
    }
  } = getState();
  const isGiveawayCapValueValid = isGiveawayCapValueValidSelector(getState());
  if (isGiveawayCapValueValid && value < editedModel.submissions || value === 0) {
    dispatch(setGiveawayCapNotValidatedAction());
  } else {
    dispatch(setGiveawayCapValidatedAction());
  }
  dispatch(setGiveawaySingleLinkCapValue(value));
};
export const TOGGLE_IS_GIVEAWAY_CAP_ENABLED = `${GIVEAWAY_ADMIN_PREFIX}TOGGLE_IS_GIVEAWAY_CAP_ENABLED`;
const toggleIsGiveawayCapEnabledActionCreator = () => ({
  type: TOGGLE_IS_GIVEAWAY_CAP_ENABLED
});
export const toggleIsGiveawayCapEnabled = () => (dispatch, getState) => {
  const state = getState();
  const giveawayType = giveawayEditingTypeSelector(state);
  const defaultCap = giveawayType === GiveawayTypes.goodie ? goodieGiveawayRecipientQuantitySelector(state) : swagGiveawayDefaultCapSelector(state);
  dispatch(setGiveawaySingleLinkCapValue(defaultCap));
  dispatch(toggleIsGiveawayCapEnabledActionCreator());
};
export const openGiveawayEditSingleLinkPopup = () => (dispatch, getState) => {
  const singleLink = giveawaySingleLinkItemSelector(getState());
  if (singleLink) {
    dispatch(setGiveawayCustomSingleLink(singleLink.link));
  }
  dispatch(resetGiveawayCustomSingleLinkError());
  dispatch(showPopup({
    popup: GIVEAWAY_EDIT_SINGLE_LINK_POPUP
  }));
};
export const TOGGLE_COLOR_GIVEAWAY = `${GIVEAWAY_ADMIN_PREFIX}_TOGGLE_COLOR_GIVEAWAY`;
export const toggleColorGiveaway = (productId, color) => (dispatch, getState) => {
  const state = getState();
  const colors = giveawayColorsSelector(state);
  const variants = colors[productId] || [];
  const isVariantChosen = variants.includes(color);
  const colorsResult = [...variants.filter(i => i !== color), !isVariantChosen && color].filter(a => !!a);
  dispatch({
    type: TOGGLE_COLOR_GIVEAWAY,
    payload: {
      colors: {
        [productId]: colorsResult.length ? colorsResult : undefined
      }
    }
  });
};
export const SET_GIVEAWAY_PRIMARY_COLOR = `${GIVEAWAY_ADMIN_PREFIX}_SET_GIVEAWAY_PRIMARY_COLOR`;
export const setGiveawayPrimaryColor = color => dispatch => {
  dispatch({
    type: SET_GIVEAWAY_PRIMARY_COLOR,
    payload: color
  });
  dispatch(createOrUpdateGiveaway());
};
export const SET_GIVEAWAY_LOGO = `${GIVEAWAY_ADMIN_PREFIX}_SET_GIVEAWAY_LOGO`;
export const setGiveawayLogo = logoFile => dispatch => {
  dispatch(uploadLogoCreator(file => {
    const fileName = (file === null || file === void 0 ? void 0 : file.customerDesignLink) || '';
    dispatch({
      type: SET_GIVEAWAY_LOGO,
      payload: fileName
    });
    return createOrUpdateGiveaway({
      logo: fileName
    });
  })(logoFile));
};
const setGiveawayProducts = addProductsToGiveawayFactory(GIVEAWAY_ADMIN_PREFIX);
export const GIVEAWAY_LOADED = `${GIVEAWAY_ADMIN_PREFIX}GIVEAWAY_LOADED`;
export const getGiveawayById = step => createAsyncLoadedAction({
  fn: InventoryApi.giveaway.getById,
  success: giveaway => dispatch => {
    dispatch(restoreNotecardEditorState(giveaway));
    const linksCompleted = Number(giveaway.linksCompleted);
    let capValue = Number(giveaway.capValue);
    capValue = linksCompleted >= capValue ? linksCompleted : capValue;
    dispatch(saveGiveawayModelActionFactory(GIVEAWAY_ADMIN_PREFIX)(giveaway));
    if (giveaway.giveawayType === GiveawayTypes.goodie) {
      dispatch(setGoodieGiveawayRecipientQuantity(Number(giveaway.recipientQuantity)));
      dispatch(setGoodieGiveawayBudget(Number(giveaway.budget)));
    }
    dispatch(setGiveawayPassedStepsAction(giveaway.passedSteps));
    dispatch(nextGiveawayStepAction(step || giveaway.step));
    dispatch(giveawayEditAction());
    const productIds = giveaway.products.map(({
      _id
    }) => _id);
    dispatch(setGiveawayProducts(productIds, capValue));
    dispatch(preselectProducts(productIds));
    if (Object.prototype.hasOwnProperty.call(giveaway, 'isSingleLink')) {
      dispatch(setSingleLinkMode(String(giveaway.isSingleLink)));
    }
    if (Object.prototype.hasOwnProperty.call(giveaway, 'copyLinksTypeTab')) {
      dispatch(setCopyLinksTabAction(giveaway.copyLinksTypeTab));
    }
    if (typeof giveaway.isReserve === 'boolean') {
      dispatch(setReserveMode(giveaway.isReserve));
    }
    if (Object.prototype.hasOwnProperty.call(giveaway, 'isChangeable')) {
      dispatch(setIsChangeableMode(giveaway.isChangeable));
    }
    dispatch({
      type: GIVEAWAY_LOADED,
      payload: giveaway
    });
  },
  error: () => showNotification({
    text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
  })
});
export const SET_LAST_GIVEAWAY = `${GIVEAWAY_ADMIN_PREFIX}_GET_LAST_GIVEAWAY`;
export const SET_GIVEAWAY_CAP_VALIDATED = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_CAP_VALIDATED`;
export const SET_GIVEAWAY_CAP_NOT_VALIDATED = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_CAP_NOT_VALIDATED`;
const setLastGiveawayAction = giveaway => ({
  type: SET_LAST_GIVEAWAY,
  payload: giveaway
});
export const setGiveawayCapValidatedAction = () => ({
  type: SET_GIVEAWAY_CAP_VALIDATED
});
export const setGiveawayCapNotValidatedAction = () => ({
  type: SET_GIVEAWAY_CAP_NOT_VALIDATED
});
export const getLastGiveaway = createAsyncLoadedAction({
  fn: InventoryApi.giveaway.getLast,
  success: setLastGiveawayAction,
  error: err => {
    return !!err && !!err.status && err.status === 404 ? setLastGiveawayAction(null) : showNotification({
      text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    });
  }
});
export const setGiveawayCompleted = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    const giveawayId = giveawayIdSelector(getState());
    const dataToSave = dataToSaveGiveawaySelector(getState());
    const isEditing = isGiveawayEditingSelector(getState());
    return _objectSpread({}, dataToSave, {
      inventoryId,
      giveawayId,
      isEditing
    });
  },
  fn: InventoryApi.giveaway.setCompleted,
  error: () => showNotification({
    text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
  }),
  postSuccess: (dispatch, getState) => {
    const inventoryId = selectedInventorySelector(getState());
    dispatch(replace(forcedPath(InventoryUrl.getActivitiesGiveawayCampaignsPage(inventoryId), {
      active: giveawayCompletedTabType.completed
    })));
    dispatch(getPendingActivitiesAction({
      inventoryId
    }));
    dispatch(deselectAllProducts());
    instantScrollTop();
  }
});
export const SET_SEND_TYPE_TAB_CHANGE = `${GIVEAWAY_ADMIN_PREFIX}_SET_SEND_TYPE_TAB_CHANGE`;
export const setSendTypeTabAction = tab => ({
  type: SET_SEND_TYPE_TAB_CHANGE,
  payload: tab
});
export const SET_COPY_LINKS_TAB_CHANGE_ACTION = `${GIVEAWAY_ADMIN_PREFIX}_SET_COPY_LINKS_TAB_CHANGE_ACTION`;
export const setCopyLinksTabAction = tab => ({
  type: SET_COPY_LINKS_TAB_CHANGE_ACTION,
  payload: tab
});
export const ADD_SINGLE_LINK_ACTION = `${GIVEAWAY_ADMIN_PREFIX}_ADD_SINGLE_LINK_ACTION`;
export const addSingleLinkAction = payload => ({
  type: ADD_SINGLE_LINK_ACTION,
  payload
});
export const UPDATE_CUSTOM_SINGLE_LINK = `${GIVEAWAY_ADMIN_PREFIX}UPDATE_CUSTOM_SINGLE_LINK`;
export const updateSingleLinkAction = payload => ({
  type: UPDATE_CUSTOM_SINGLE_LINK,
  payload
});
const giveawayLinkWithUserMapper = items => {
  return items.map(item => ({
    firstName: item.firstName,
    link: item.link,
    email: item.email
  }));
};
export const ADD_GIVEAWAY_LINKS_CSV = `${GIVEAWAY_ADMIN_PREFIX}_ADD_GIVEAWAY_LINKS_CSV`;
export const addGiveawayLinksCsvLinksAction = items => ({
  type: ADD_GIVEAWAY_LINKS_CSV,
  payload: giveawayLinkWithUserMapper(items)
});
export const SET_GIVEAWAY_LINKS_CSV = `${GIVEAWAY_ADMIN_PREFIX}_SET_GIVEAWAY_LINKS_CSV`;
export const setGiveawayLinksCsvLinksAction = items => ({
  type: SET_GIVEAWAY_LINKS_CSV,
  payload: giveawayLinkWithUserMapper(items)
});
export const SET_GIVEAWAY_LINKS_CSV_SORTING = `${GIVEAWAY_ADMIN_PREFIX}_SET_GIVEAWAY_LINKS_CSV_SORTING`;
export const setGiveawayLinksCsvSortingAction = data => ({
  type: SET_GIVEAWAY_LINKS_CSV_SORTING,
  payload: data
});
export const ADD_LINKS_ACTION = `${GIVEAWAY_ADMIN_PREFIX}_ADD_LINKS_ACTION`;
export const setGiveawayFewLinksAction = data => ({
  type: ADD_LINKS_ACTION,
  payload: data
});
const createSingleLinkRequestAction = createAsyncLoadedAction({
  fn: InventoryApi.giveaway.createSingleLink,
  success: addSingleLinkAction
});
const createCustomSingleLinkRequestAction = createAsyncLoadedAction({
  fn: InventoryApi.giveaway.createSingleLink,
  success: addSingleLinkAction,
  error: err => setGiveawayCustomSingleLinkError(err.message),
  postSuccess: dispatch => {
    dispatch(hidePopup());
  }
});
const editCustomSingleLinkRequestAction = createAsyncLoadedAction({
  fn: InventoryApi.giveaway.editCustomSingleLink,
  success: updateSingleLinkAction,
  error: err => setGiveawayCustomSingleLinkError(err.message),
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(showNotification({
      text: 'Custom url successfully updated'
    }));
  }
});
export const submitCustomSingleLinkAction = () => (dispatch, getState) => {
  const state = getState();
  const singleLink = giveawaySingleLinkItemSelector(state);
  if (singleLink) {
    if (isSelectedDemoInventorySelector(state)) {
      return dispatch(showPopup({
        popup: DEMO_INVENTORY_WARNING_POPUP,
        warningType: DemoInventoryWarningTypes.GiveawaySingleLink
      }));
    }
    const giveawayId = giveawayIdSelector(state);
    const inventoryId = selectedInventorySelector(state);
    const customLink = giveawayCustomSingleLinkValueSelector(state);
    dispatch(editCustomSingleLinkRequestAction({
      inventoryId,
      giveawayId,
      customLink,
      id: singleLink._id
    }));
  } else {
    dispatch(createSingleLinkAction(true));
  }
};
const addGiveawayLinksLinksCommon = links => dispatch => {
  const linksCsv = links.reduce((acc, link) => {
    switch (link.type) {
      case GiveawayLinkType.uploadCSV:
        return _objectSpread({}, acc, {
          csv: [...acc.csv, link]
        });
      case GiveawayLinkType.single:
        return _objectSpread({}, acc, {
          few: [...acc.few, link]
        });
      case GiveawayLinkType.throughPlatform:
        return _objectSpread({}, acc, {
          throughPlatform: [...acc.throughPlatform, link]
        });
      default:
        return acc;
    }
  }, {
    csv: [],
    few: [],
    throughPlatform: []
  });
  dispatch(setGiveawayLinksCsvLinksAction(linksCsv.csv));
  dispatch(setGiveawayFewLinksAction(linksCsv.few));
  const singleLink = linksCsv.few.find(link => link.isSingleLink);
  if (singleLink) {
    dispatch(setGiveawayCustomSingleLink(singleLink.link));
  }
};
const getLinksRequestAction = createAsyncAction({
  fn: InventoryApi.giveaway.getLinks,
  success: links => dispatch => {
    if (links.length) {
      dispatch(addGiveawayLinksLinksCommon(links));
      if (links.every(link => link.isSingleLink)) {
        dispatch(createSingleLinkAction());
      }
      return;
    }
    dispatch(createSingleLinkAction());
  },
  error: () => showNotification({
    text: 'Error during getting links'
  })
});
export const createSingleLinkAction = (isSingleLink = false) => (dispatch, getState) => {
  const state = getState();
  const isDemo = isSelectedDemoGiveawaySelector(state);
  if (isDemo) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.GiveawaySingleLink
    }));
  }
  const giveawayId = giveawayIdSelector(state);
  const inventoryId = selectedInventorySelector(state);
  if (!isSingleLink) {
    return dispatch(createSingleLinkRequestAction({
      giveawayId,
      inventoryId
    }));
  }
  const customLink = giveawayCustomSingleLinkValueSelector(getState());
  return dispatch(createCustomSingleLinkRequestAction({
    giveawayId,
    inventoryId,
    isSingleLink,
    customLink
  }));
};
export const getLinksAction = () => (dispatch, getState) => {
  const giveawayId = giveawayIdSelector(getState());
  const inventoryId = selectedInventorySelector(getState());
  dispatch(getLinksRequestAction({
    giveawayId,
    inventoryId
  }));
};
export const downloadCopyPasteCsvAction = () => (dispatch, getState) => {
  const links = giveawayFewLinksItemsSelector(getState());
  const header = ['Link'];
  const rows = links.map(link => {
    return [link.link];
  });
  const giveawayName = giveawayEditingFilenamePrefixSelector(getState());
  dispatch(downloadCSVAction([header, ...rows], `${giveawayName}giveaway_copy_paste_links`));
};
export const downloadLinksExampleCSVAction = () => (dispatch, getState) => {
  const header = ['First Name', 'Email'];
  const rows = [['John', 'test@email.com']];
  const giveawayName = giveawayEditingFilenamePrefixSelector(getState());
  dispatch(downloadCSVAction([header, ...rows], `${giveawayName}giveaway_example_links`));
};
export const downloadCsvLinksCsvAction = () => (dispatch, getState) => {
  const links = giveawayCsvLinksItemsSortedSelector(getState());
  const header = ['First Name', 'Email', 'Link'];
  const rows = links.map(link => {
    return [link.firstName, link.email, link.link];
  });
  const giveawayName = giveawayEditingFilenamePrefixSelector(getState());
  dispatch(downloadCSVAction([header, ...rows], `${giveawayName}giveaway_csv_links`));
};
export const downloadCsvFeedbackAction = () => (dispatch, getState) => {
  const feedbackArray = giveawayFeedbackSelector(getState());
  const giveawayName = giveawayEditingFilenamePrefixSelector(getState());
  const header = ['First Name', 'Last Name', 'Email', 'Feedback', 'Created At'];
  const rows = feedbackArray.map(({
    feedbackCreatedAt,
    firstName,
    lastName,
    feedback,
    email
  }) => {
    return [firstName, lastName, email, feedback, toDateFormat(new Date(feedbackCreatedAt), 'd mmmm yyyy')];
  });
  dispatch(downloadCSVAction([header, ...rows], `${giveawayName}giveaway_csv_feedback`));
};
export const GIVEAWAY_LINKS_CSV_FILE_UPLOADED = `${GIVEAWAY_ADMIN_PREFIX}_GIVEAWAY_LINKS_CSV_FILE_UPLOADED`;
export const giveawayLinksCsvFileUploadedAction = data => ({
  type: GIVEAWAY_LINKS_CSV_FILE_UPLOADED,
  payload: data
});
const uploadGiveawayLinksCSVSuccessAction = (links, data) => dispatch => {
  const fileName = data.getAll('csv')[0].name;
  dispatch(showNotification({
    text: 'Links have been created'
  }));
  dispatch(giveawayLinksCsvFileUploadedAction(fileName));
  dispatch(setGiveawayLinksCsvLinksAction(links));
  dispatch(hidePopup());
};
export const uploadGiveawayLinksCSVRequestAction = (inventoryId, giveawayId, file) => createAsyncLoadedAction({
  transformData: (data, getState) => {
    const isEditing = isGiveawayEditingSelector(getState());
    const formData = new FormData();
    formData.append('csv', data);
    formData.append('isEditing', JSON.stringify(isEditing));
    return formData;
  },
  fn: InventoryApi.giveaway.giveawayFileUploadCreator(inventoryId, giveawayId).uploadLinksCsv,
  success: uploadGiveawayLinksCSVSuccessAction,
  error: err => {
    return !!err && !!err.status && err.status === 422 && showPopup({
      popup: 'giveawayCsvErrorPopup',
      errors: err.errors
    });
  }
})(file);
export const uploadGiveawayLinksCSVAction = file => (dispatch, getState) => {
  const state = getState();
  const isDemo = isSelectedDemoGiveawaySelector(state);
  if (isDemo) {
    return dispatch(showPopup({
      popup: DEMO_INVENTORY_WARNING_POPUP,
      warningType: DemoInventoryWarningTypes.GiveawayUploadLinks
    }));
  }
  const inventoryId = selectedInventorySelector(state);
  const giveawayId = giveawayIdSelector(state);
  dispatch(uploadGiveawayLinksCSVRequestAction(inventoryId, giveawayId, file));
};
export const SET_GIVEAWAY_CHARGE_LINKS_TOTAL = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_CHARGE_LINKS_TOTAL`;
export const setGiveawayPaymentRecipientsTotal = total => ({
  type: SET_GIVEAWAY_CHARGE_LINKS_TOTAL,
  payload: total
});
export const giveawayChangeRoutingStepAction = step => (dispatch, getState) => {
  const giveaway = selectedGiveawaySelector(getState());
  if (giveaway) {
    dispatch(goToEditGiveawayStepAction(step, giveaway));
  }
};
export const GIVEAWAY_EDIT_STATUS = `${GIVEAWAY_ADMIN_PREFIX}_EDIT_STATUS`;
const giveawayEditAction = () => ({
  type: GIVEAWAY_EDIT_STATUS
});
export const onEditGiveawayEnterAction = (inventoryId, giveawayId, step) => (dispatch, getState) => {
  const isLoadedGiveawayProducts = isLoadedGiveawayProductsSelector(getState());
  const isLoadedGiveawayLastGiveaway = isLoadedGiveawayLastGiveawaySelector(getState());
  if (!isLoadedGiveawayProducts) {
    dispatch(listInventoryProducts({
      inventoryId
    }));
  }
  if (!isLoadedGiveawayLastGiveaway) {
    dispatch(getLastGiveaway({
      inventoryId
    }));
  }
  dispatch(getGiveawayById(step)({
    inventoryId,
    giveawayId
  }));
};
export const goToGiveawayActivityAction = inventoryId => exitGiveawayToRouteAction(InventoryUrl.getActivitiesGiveawayCampaignsPage(inventoryId));
export const SET_SINGLE_LINK_MODE_VALUE = `${GIVEAWAY_ADMIN_PREFIX}SET_SINGLE_LINK_MODE_VALUE`;
export const setSingleLinkMode = value => ({
  type: SET_SINGLE_LINK_MODE_VALUE,
  payload: value === 'true'
});
export const saveSingleLinkModeAndSaveToDraftsAction = value => {
  return (dispatch, getState) => {
    dispatch(setSingleLinkMode(value));
    const dataToSave = dataToSaveGiveawaySelector(getState());
    dispatch(saveSingleLinkModeRequestAction({
      dataToSave
    }));
  };
};
export const SET_GIVEAWAY_CUSTOM_SINGLE_LINK = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_CUSTOM_SINGLE_LINK`;
export const setGiveawayCustomSingleLink = link => ({
  type: SET_GIVEAWAY_CUSTOM_SINGLE_LINK,
  payload: link
});
export const SET_IS_CHANGEABLE_VALUE = `${GIVEAWAY_ADMIN_PREFIX}SET_IS_CHANGEABLE_VALUE`;
export const setIsChangeableMode = value => ({
  type: SET_IS_CHANGEABLE_VALUE,
  payload: value
});
export const SET_RESERVE_MODE_VALUE = `${GIVEAWAY_ADMIN_PREFIX}SET_RESERVE_MODE_VALUE`;
export const setReserveMode = value => ({
  type: SET_RESERVE_MODE_VALUE,
  payload: value
});
export const changeReserveMode = value => (dispatch, getState) => {
  const isEditing = isGiveawayEditingSelector(getState());
  const isReserveSelected = value === 'true';
  if (isEditing) {
    dispatch(showPopup({
      popup: isReserveSelected ? CHANGE_GIVEAWAY_RESERVE_STATUS_TO_RESERVE_POPUP : CHANGE_GIVEAWAY_RESERVE_STATUS_TO_NON_RESERVE_POPUP
    }));
    return;
  }
  dispatch(setReserveMode(isReserveSelected));
};
export const SET_IS_ONE_PRODUCT = `${GIVEAWAY_ADMIN_PREFIX}SET_IS_ONE_PRODUCT`;
export const setIsOneProduct = value => ({
  type: SET_IS_ONE_PRODUCT,
  payload: value
});
export const SET_VARIANT_CAP = `${GIVEAWAY_ADMIN_PREFIX}SET_VARIANT_CAP`;
export const setVariantCap = ({
  customProductParentId,
  colorVariantId
}, capAmount) => ({
  type: SET_VARIANT_CAP,
  payload: {
    customProductParentId,
    colorVariantId,
    capAmount
  }
});
export const SET_GIVEAWAY_CUSTOM_SINGLE_LINK_ERROR = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_CUSTOM_SINGLE_LINK_ERROR`;
export const RESET_GIVEAWAY_CUSTOM_SINGLE_LINK_ERROR = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_CUSTOM_SINGLE_LINK_ERROR`;
export const setGiveawayCustomSingleLinkError = error => ({
  type: SET_GIVEAWAY_CUSTOM_SINGLE_LINK_ERROR,
  payload: error
});
export const resetGiveawayCustomSingleLinkError = () => ({
  type: RESET_GIVEAWAY_CUSTOM_SINGLE_LINK_ERROR
});
export const SET_GIVEAWAY_SHIPMENTS_SUCCESS = `${GIVEAWAY_ADMIN_PREFIX}SET_GIVEAWAY_SHIPMENTS_SUCCESS`;
export const getGiveawayShipmentsListEntering = createAsyncLoadedAction({
  transformData: (data, getState) => {
    const state = getState();
    const inventoryId = selectedInventorySelector(state);
    const page = shipmentsPageSelector(state);
    const search = inventoryActivitiesSearchQuerySelector(state);
    const params = {
      page,
      inventoryId
    };
    if (search) {
      params.search = search;
    }
    return params;
  },
  fn: InventoryApi.giveaway.getShipments,
  success: ({
    shipments,
    total,
    searchResults
  }) => ({
    type: SET_GIVEAWAY_SHIPMENTS_SUCCESS,
    payload: {
      shipments,
      total,
      searchResults
    }
  }),
  error: () => dispatch => {
    dispatch(showNotification({
      text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
  }
});
export const SET_SHIPMENTS_PAGE = `${GIVEAWAY_ADMIN_PREFIX}SET_SHIPMENTS_PAGE`;
export const setShipmentsPage = page => ({
  type: SET_SHIPMENTS_PAGE,
  payload: {
    page
  }
});
export const goToGiveawayPayFeesAction = (inventoryId, giveawayId) => dispatch => {
  dispatch(selectCompletedGiveaway(giveawayId));
  dispatch(push(InventoryUrl.getActivitiesGiveawayPayFeesPage(inventoryId, giveawayId)));
};
export const showAvailableApparelProducts = selectedProductId => dispatch => {
  dispatch(showPopup({
    popup: GIVEAWAY_AVAILABLE_APPAREL_PRODUCTS_POPUP,
    selectedProductId
  }));
};
const openSelectGiveawayTypePopup = createAsyncLoadedAction({
  fn: goodieGiveawayApi.getProductsCount,
  success: ({
    total
  }) => dispatch => {
    const areGoodieProductsCreated = total > 0;
    dispatch(showPopup({
      popup: SELECT_GIVEAWAY_TYPE_POPUP,
      areGoodieProductsCreated
    }));
  },
  error: () => dispatch => {
    dispatch(showNotification({
      text: USER_ERROR_ACTIONS.SOMETHING_WENT_WRONG
    }));
  }
});
export const handleCreateGiveaway = () => (dispatch, getState) => {
  const state = getState();
  const featureToggles = featureTogglesSelector(state);
  if (featureToggles.goodieGiveaway) {
    dispatch(openSelectGiveawayTypePopup());
    return;
  }
  const areSomeProductsSelected = areSomeProductsSelectedSelector(state);
  dispatch(goToGiveawayMainPage({
    isSelectProductsSkipped: areSomeProductsSelected,
    type: GiveawayTypes.swag
  }));
};
export const getAdvancedDistributionForChangingReserveStatus = () => (dispatch, getState) => {
  const state = getState();
  const giveaway = selectedGiveawaySelector(state);
  const products = (giveaway === null || giveaway === void 0 ? void 0 : giveaway.products) || [];
  const areProductsWithIds = !!products.length && typeof products[0] == 'string';
  const parentIds = areProductsWithIds ? products : products.map(({
    _id
  }) => _id);
  if (parentIds.length) {
    dispatch(getAdvancedDistributionForProducts({
      parentIds
    }));
  }
};