function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncLoadedAction } from 'swag-client-common/utils/create-async-loaded-actions.utils';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { InventoryApi } from '../../api/inventory.api';
import { selectedInventorySelector, inventoryUsersPermissionsSelector } from '../inventory/inventory.selectors';
import { checkAccessRights } from '../../utils/enterprise-access-wrapper';
import { Entity, getNotificationText, getSettings } from './approval-settings.utils';
import { isRoleApprovalEnabledSelector, isUserApprovalEnabledSelector, rootRoleSettingSelector, rootUserSettingSelector } from './approval-settings.selectors';
const PREFIX = 'APPROVAL_SETTINGS/';
export const UPDATE_INVENTORY_AFTER_SETTINGS_UPDATE = `${PREFIX}UPDATE_INVENTORY_AFTER_SETTINGS_UPDATE`;
const updateInventoryAfterChanges = (dispatch, getState, {
  inventoryId
}, res) => {
  const {
    isRoleApprovalEnabled,
    isUserApprovalEnabled
  } = res;
  dispatch({
    type: UPDATE_INVENTORY_AFTER_SETTINGS_UPDATE,
    payload: {
      inventoryId,
      isRoleApprovalEnabled,
      isUserApprovalEnabled
    }
  });
};
export const GET_SETTINGS_REQUEST = `${PREFIX}GET_SETTINGS_REQUEST`;
export const GET_SETTINGS_SUCCESS = `${PREFIX}GET_SETTINGS_SUCCESS`;
export const getApprovalSettings = createAsyncLoadedAction({
  transformData: (data, getState) => ({
    inventoryId: selectedInventorySelector(getState())
  }),
  preFn: dispatch => dispatch({
    type: GET_SETTINGS_REQUEST
  }),
  fn: InventoryApi.approval,
  success: response => ({
    type: GET_SETTINGS_SUCCESS,
    payload: response
  }),
  error: (error, data, dispatch) => {
    dispatch(showNotification({
      text: 'Something goes wrong while getting settings'
    }));
  },
  postSuccess: updateInventoryAfterChanges
});
export const UPDATE_SETTINGS_REQUEST = `${PREFIX}UPDATE_SETTINGS_REQUEST`;
export const UPDATE_SETTINGS_SUCCESS = `${PREFIX}UPDATE_SETTINGS_SUCCESS`;
export const updateApprovalSettingsRequest = createAsyncLoadedAction({
  transformData: (data, getState) => _objectSpread({}, data, {
    inventoryId: selectedInventorySelector(getState())
  }),
  fn: InventoryApi.updateApproval,
  success: response => ({
    type: UPDATE_SETTINGS_SUCCESS,
    payload: response
  }),
  error: (error, data, dispatch) => {
    dispatch(showNotification({
      text: 'Something goes wrong while updating settings'
    }));
  },
  postSuccess: (dispatch, getState, data, res) => {
    if (Object.prototype.hasOwnProperty.call(data, 'isUserApprovalEnabled')) {
      dispatch(showNotification({
        text: getNotificationText(Entity.User, data.isUserApprovalEnabled)
      }));
    }
    if (Object.prototype.hasOwnProperty.call(data, 'isRoleApprovalEnabled')) {
      dispatch(showNotification({
        text: getNotificationText(Entity.Role, data.isRoleApprovalEnabled)
      }));
    }
    updateInventoryAfterChanges(dispatch, getState, data, res);
  }
});
export const updateReduxApprovalSettings = params => (dispatch, getState) => {
  const state = getState();
  const users = inventoryUsersPermissionsSelector(state);
  const userSettings = rootUserSettingSelector(state);
  const roleSettings = rootRoleSettingSelector(state);
  const isRoleApprovalEnabled = isRoleApprovalEnabledSelector(state);
  const isUserApprovalEnabled = isUserApprovalEnabledSelector(state);
  const toUpdate = {
    roleSettings,
    userSettings,
    isRoleApprovalEnabled,
    isUserApprovalEnabled
  };
  const {
    setting
  } = params;
  if (setting) {
    toUpdate.userSettings = getSettings(userSettings, params, users);
    toUpdate.roleSettings = getSettings(roleSettings, params, users);
  }
  if (Object.prototype.hasOwnProperty.call(params, 'isUserApprovalEnabled')) {
    toUpdate.isUserApprovalEnabled = params.isUserApprovalEnabled;
  }
  if (Object.prototype.hasOwnProperty.call(params, 'isRoleApprovalEnabled')) {
    toUpdate.isRoleApprovalEnabled = params.isRoleApprovalEnabled;
  }
  dispatch({
    type: UPDATE_SETTINGS_SUCCESS,
    payload: _objectSpread({}, toUpdate)
  });
};
export const updateApprovalSettings = checkAccessRights(updateApprovalSettingsRequest,
// @todo. auto generated. fix this error
// @ts-ignore
null, updateReduxApprovalSettings);