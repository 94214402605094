import { uploadBrownBoxLogoToAWS, uploadCustomBoxLogoToAWS } from 'swag-client-common/redux/box-builder/box-builder.actions';
import { HIDE_LOADER, SHOW_LOADER } from 'swag-client-common/redux/common/common.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { generateImageFromHtml } from 'swag-client-common/utils/generate-image-from-html';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { SwagBundlesStep } from 'swag-common/constants/swag-bundles.constants';
import { MOCKUP_SWAG_BUNDLE_NOTECARD_HIDDEN_CONTAINER_ID } from '../../../components/partials/swag-bundle/swag-bundle-create-notecard/constants';
import { uploadLogoCreator } from '../../common/common.actions';
import { swagBundleNotecardColorSelector } from '../selectors/swag-bundles-notecard-data.selectors';
import { SWAG_BUNDLE_ADD_ONS_STEPS } from '../swag-bundles.constants';
import { isNotecardSelectedSelector, swagBundleSelectedBoxSelector, swagBundlesPackageDataSelector, swagBundlesSelectedPackagesSelector } from '../swag-bundles.selectors';
import { SWAG_BUNDLE_ADDONS_TYPES } from '../../../utils/constants';
import { SWAG_BUNDLES_ADMIN_PREFIX } from './swag-bundles-common.actions';
import { nextSwagBundlesStepAction, prevSwagBundlesStepAction } from './swag-bundles.actions';
import { calculateDefaultQuantity, calculateShippingCosts, clearShippingCost } from './swag-bundles-calculate-shipping.actions';

// Custom packaging actions
export const SWAG_BUNDLES_SELECT_BOX = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_BOX`;
export const SWAG_BUNDLES_UNSELECT_BOX = `${SWAG_BUNDLES_ADMIN_PREFIX}_UNSELECT_BOX`;
export const SWAG_BUNDLES_CHANGE_STEP = `${SWAG_BUNDLES_ADMIN_PREFIX}_CHANGE_STEP`;
export const SWAG_BUNDLES_SELECT_COLOR = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_COLOR`;
export const SWAG_BUNDLES_UNSELECT_LOGO = `${SWAG_BUNDLES_ADMIN_PREFIX}_UNSELECT_LOGO`;
export const SWAG_BUNDLES_SET_NOTE = `${SWAG_BUNDLES_ADMIN_PREFIX}_SET_NOTE`;
export const SWAG_BUNDLES_SELECT_CRINKLE_PAPER_COLOR = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_CRINKLE_PAPER_COLOR`;

// Custom notecard actions
export const SWAG_BUNDLES_SELECT_NOTECARD_COLOR = `${SWAG_BUNDLES_ADMIN_PREFIX}_SELECT_NOTECARD_COLOR`;
export const SWAG_BUNDLES_SET_NOTECARD_DEFAULT_IMAGE = `${SWAG_BUNDLES_ADMIN_PREFIX}SET_NOTECARD_DEFAULT_IMAGE`;
export const SWAG_BUNDLES_SET_NOTECARD_LOGO = `${SWAG_BUNDLES_ADMIN_PREFIX}SET_NOTECARD_LOGO`;
export const SWAG_BUNDLES_SET_NOTECARD_EDITOR_RAW_CONTENT = `${SWAG_BUNDLES_ADMIN_PREFIX}SET_NOTECARD_EDITOR_RAW_CONTENT`;
export const SWAG_BUNDLES_SET_IS_NOTECARD_SELECTED_TO_CREATE = `${SWAG_BUNDLES_ADMIN_PREFIX}SET_IS_NOTECARD_SELECTED_TO_CREATE`;
export const swagBundlesChangeStep = (step, skipCostCalculation = false) => async (dispatch, getState) => {
  const state = getState();
  const packageType = swagBundleSelectedBoxSelector(state);
  const isNotecardPreselected = isNotecardSelectedSelector(state);
  const packageData = swagBundlesPackageDataSelector(state);
  if (step === SWAG_BUNDLE_ADD_ONS_STEPS.NOTECARD_SELECTION && packageType !== SPECIAL_PRODUCT_TYPES.NO_BOX && !skipCostCalculation) {
    dispatch(calculateDefaultQuantity());
    await dispatch(calculateShippingCosts({
      type: SWAG_BUNDLE_ADDONS_TYPES.PACKAGE,
      isInitialCalculation: true
    }));
  }
  if (step === SWAG_BUNDLE_ADD_ONS_STEPS.BOX_TYPE_SELECTION) {
    dispatch(clearShippingCost({
      clearPackage: true
    }));
  }
  if (isNotecardPreselected && step === SWAG_BUNDLE_ADD_ONS_STEPS.NOTECARD_SELECTION && !!packageData) {
    dispatch(nextSwagBundlesStepAction(SwagBundlesStep.calculateShipping));
    return null;
  }
  return dispatch({
    type: SWAG_BUNDLES_CHANGE_STEP,
    payload: step
  });
};
export const swagBundlesSelectColor = ({
  cmyk,
  hex
}) => (dispatch, getState) => {
  const state = getState();
  const notecardColor = swagBundleNotecardColorSelector(state);
  dispatch({
    type: SWAG_BUNDLES_SELECT_COLOR,
    payload: cmyk !== null && cmyk !== void 0 ? cmyk : hex
  });
  if (!notecardColor) {
    dispatch(swagBundlesSelectNotecardColor(hex));
  }
};
export const swagBundlesSelectCrinklePaperColor = color => ({
  type: SWAG_BUNDLES_SELECT_CRINKLE_PAPER_COLOR,
  payload: color
});
export const swagBundlesSelectLogo = logo => (dispatch, getState) => {
  if (logo) {
    const state = getState();
    const selectedBoxType = swagBundleSelectedBoxSelector(state);
    if (selectedBoxType === SPECIAL_PRODUCT_TYPES.BROWN_BOX) {
      dispatch(uploadBrownBoxLogoToAWS(logo));
    } else {
      dispatch(uploadCustomBoxLogoToAWS(logo));
    }
  } else {
    dispatch({
      type: SWAG_BUNDLES_UNSELECT_LOGO,
      payload: logo
    });
  }
};
export const swagBundlesSetNote = note => ({
  type: SWAG_BUNDLES_SET_NOTE,
  payload: note
});
export const swagBundlesSelectBox = boxType => (dispatch, getState) => {
  const state = getState();
  const currentBox = swagBundleSelectedBoxSelector(state);
  if (currentBox !== boxType) {
    dispatch(swagBundlesSelectLogo(null));
    dispatch(swagBundlesSetNote(''));
    dispatch(swagBundlesSelectColor({
      cmyk: null,
      hex: ''
    }));
  }
  dispatch({
    type: SWAG_BUNDLES_SELECT_BOX,
    payload: boxType
  });
};
export const swagBundlesUnselectBox = () => ({
  type: SWAG_BUNDLES_UNSELECT_BOX,
  payload: null
});
export const swagBundlesChangePackagingStepByBoxType = callFromNotecardStep => (dispatch, getState) => {
  const state = getState();
  const selectedBox = swagBundleSelectedBoxSelector(state);
  switch (selectedBox) {
    case SPECIAL_PRODUCT_TYPES.CUSTOM_BOX:
      {
        dispatch(swagBundlesChangeStep(SWAG_BUNDLE_ADD_ONS_STEPS.CUSTOM_BOX_SELECTION));
        break;
      }
    case SPECIAL_PRODUCT_TYPES.BROWN_BOX:
      {
        dispatch(swagBundlesChangeStep(SWAG_BUNDLE_ADD_ONS_STEPS.BROWN_BOX_SELECTION));
        break;
      }
    case SPECIAL_PRODUCT_TYPES.COLORED_BOX:
      {
        dispatch(swagBundlesChangeStep(SWAG_BUNDLE_ADD_ONS_STEPS.COLORED_BOX_SELECTION));
        break;
      }
    case SPECIAL_PRODUCT_TYPES.FULLY_CUSTOM_BOX:
      {
        dispatch(swagBundlesChangeStep(SWAG_BUNDLE_ADD_ONS_STEPS.FULLY_CUSTOM_BOX_SELECTION));
        break;
      }
    case SPECIAL_PRODUCT_TYPES.NO_BOX:
      {
        dispatch(swagBundlesChangeStep(callFromNotecardStep ? SWAG_BUNDLE_ADD_ONS_STEPS.BOX_TYPE_SELECTION : SWAG_BUNDLE_ADD_ONS_STEPS.NOTECARD_SELECTION));
        break;
      }
    default:
      {
        break;
      }
  }
};
export const swagBundlesSelectNotecardColor = color => ({
  type: SWAG_BUNDLES_SELECT_NOTECARD_COLOR,
  payload: color
});
export const swagBundlesSetNotecardDefaultImage = defaultImage => ({
  type: SWAG_BUNDLES_SET_NOTECARD_DEFAULT_IMAGE,
  payload: defaultImage
});
export const swagBundlesSetNotecardLogo = logo => ({
  type: SWAG_BUNDLES_SET_NOTECARD_LOGO,
  payload: logo
});
export const swagBundlesSetNotecardEditorRawContentAction = editorRawContent => ({
  payload: editorRawContent,
  type: SWAG_BUNDLES_SET_NOTECARD_EDITOR_RAW_CONTENT
});
export const swagBundlesDeleteNotecardLogo = () => dispatch => {
  dispatch(swagBundlesSetNotecardLogo(''));
};
export const swagBundlesHandleNotecardStepBack = () => (dispatch, getState) => {
  const state = getState();
  const isPackagingPreSelected = swagBundlesSelectedPackagesSelector(state).length > 0;
  if (isPackagingPreSelected) {
    dispatch(prevSwagBundlesStepAction());
  } else {
    dispatch(swagBundlesChangePackagingStepByBoxType(true));
  }
};
export const swagBundlesHandleNotecardNextStep = () => dispatch => {
  dispatch(nextSwagBundlesStepAction(SwagBundlesStep.calculateShipping));
};
export const swagBundlesNotecardCreateSubmit = mockupContainer => async dispatch => {
  if (!mockupContainer) {
    dispatch(showNotification({
      text: 'No mockup was found. Please try again!'
    }));
    return;
  }
  dispatch({
    type: SHOW_LOADER
  });
  const imgUrl = await generateImageFromHtml({
    mockupContainer,
    hiddenContainerId: MOCKUP_SWAG_BUNDLE_NOTECARD_HIDDEN_CONTAINER_ID
  });
  if (!imgUrl) {
    dispatch({
      type: HIDE_LOADER
    });
    dispatch(showNotification({
      text: 'Error while saving notecard. Please try again!'
    }));
    return;
  }
  dispatch(calculateDefaultQuantity());
  await dispatch(calculateShippingCosts({
    type: SWAG_BUNDLE_ADDONS_TYPES.NOTECARD,
    isInitialCalculation: true
  }));
  dispatch(swagBundlesSetNotecardDefaultImage(imgUrl));
  dispatch(swagBundlesHandleNotecardNextStep());
  dispatch({
    type: HIDE_LOADER
  });
};
export const swagBundlesSetIsNotecardSelectedToCreate = isNotecardSelectedToCreate => ({
  payload: isNotecardSelectedToCreate,
  type: SWAG_BUNDLES_SET_IS_NOTECARD_SELECTED_TO_CREATE
});
export const setNotecardLogoFromFile = logoFile => dispatch => {
  dispatch(uploadLogoCreator(file => {
    const url = (file === null || file === void 0 ? void 0 : file.customerDesignLink) || '';
    return dispatch(swagBundlesSetNotecardLogo(url));
  })(logoFile));
};