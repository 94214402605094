import Joi from 'joi';
import { giveawayModelValidationKeys } from '../giveaway-model.validation';
const schema = Joi.object().keys({
  name: giveawayModelValidationKeys.name.required(),
  description: giveawayModelValidationKeys.description.required(),
  primaryColor: giveawayModelValidationKeys.primaryColor.required(),
  logo: giveawayModelValidationKeys.logo.required()
}).unknown();
export const validateGiveawayCustomize = ({
  editing: data
}) => {
  return schema.validate(data);
};