export const checkIsAllRecipientsShippedOrDelivered = status => {
  if (!status || !Object.keys(status).length) {
    return false;
  }
  const {
    delivered,
    shipped,
    totalRecipients
  } = status;
  return delivered + shipped === totalRecipients;
};