import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/custom-store-api/v1/inventory/:inventoryId/shipout';
const ShipOutApi = createApi({
  getById: {
    url: `${baseUrl}/:shipoutId`,
    method: 'get'
  },
  listCsv: {
    url: `${baseUrl}/`,
    method: 'get'
  },
  listPfi: {
    url: `${baseUrl}/pfi`,
    method: 'get'
  },
  uploadCSV: {
    url: `${baseUrl}/upload-csv`,
    method: 'post'
  },
  validate: {
    url: `${baseUrl}/validate`,
    method: 'post'
  },
  calculateCosts: {
    url: `${baseUrl}/calculate-costs`,
    method: 'post'
  },
  getReceiversFromCSV: {
    url: `${baseUrl}/get-csv-receivers`,
    method: 'put'
  },
  scheduleShipout: {
    url: `${baseUrl}/schedule-shipout`,
    method: 'post'
  },
  reevaluateReceiver: {
    url: `${baseUrl}/reevaluate-receiver`,
    method: 'put'
  },
  removeShipout: {
    url: `${baseUrl}/:shipoutId`,
    method: 'delete'
  },
  updateShipout: {
    url: `${baseUrl}/:shipoutId`,
    method: 'put'
  },
  payForShipout: {
    url: `${baseUrl}/pay`,
    method: 'post'
  },
  calculateShipoutCosts: {
    url: `${baseUrl}/calculate-costs-single-address`,
    method: 'post'
  },
  pullFromInventory: {
    url: `${baseUrl}/pull-from-inventory`,
    method: 'post'
  },
  listTransfers: {
    url: `${baseUrl}/transfer`,
    method: 'get'
  },
  transfer: {
    url: `${baseUrl}/transfer`,
    method: 'post'
  },
  generateInvoice: {
    url: `${baseUrl}/:shipoutId/generate-invoice`,
    method: 'get'
  },
  updateDraft: {
    url: `${baseUrl}/draft/:draftId`,
    method: 'put'
  },
  updateDraftReceiver: {
    url: `${baseUrl}/draft/receivers/:receiverId`,
    method: 'put'
  },
  removeDraftReceiver: {
    url: `${baseUrl}/draft/receivers/:receiverId`,
    method: 'delete'
  },
  updateDraftQuantities: {
    url: `${baseUrl}/draft/quantities`,
    method: 'put'
  }
});
export default ShipOutApi;