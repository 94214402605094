function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { FlatRatesTypes } from 'swag-common/constants/shipping.constants';
export function sortPossibleDeliveries(deliveries) {
  const deliveriesToProcess = _objectSpread({}, deliveries);
  const domesticRate = deliveries[FlatRatesTypes.Domestic];
  const internationalRate = deliveries[FlatRatesTypes.International];
  const flatRatesDeliveries = [];
  if (domesticRate) {
    // @todo. auto generated. fix this error
    // @ts-ignore
    flatRatesDeliveries.push(domesticRate);
    delete deliveriesToProcess[FlatRatesTypes.Domestic];
  }
  if (internationalRate) {
    // @todo. auto generated. fix this error
    // @ts-ignore
    flatRatesDeliveries.push(internationalRate);
    delete deliveriesToProcess[FlatRatesTypes.International];
  }
  if (flatRatesDeliveries.length > 1) {
    flatRatesDeliveries.sort(sortByPrice);
  }
  const regularDeliveries = [...Object.values(deliveriesToProcess)];
  regularDeliveries.sort(sortByPrice);
  return [...flatRatesDeliveries, ...regularDeliveries];
}
export function sortByPrice(a, b) {
  return a.shippingPrice - b.shippingPrice;
}