export const footerDataSelector = () => ({
  copyrightYearFrom: '',
  secondaryColor: '',
  primaryColor: '',
  email: '',
  companyName: '',
  scheduler: [],
  phone: '',
  socials: [],
  logo: '',
  timezone: ''
});