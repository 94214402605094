function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import Joi from 'joi';
import { COUNTRY_KEYS_LIST } from 'swag-common/constants/countries.constants';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
import { ZIP_PATTERNS } from 'swag-common/constants/validations';
export const getShippingFormSchema = withEmail => {
  const stringAny = Joi.string().min(1);
  const stringRequired = stringAny.required();
  const stateValidation = Joi.string().when('country', {
    is: COUNTRY_CODES.US,
    then: stringRequired,
    otherwise: stringAny.allow('').optional()
  });
  const phone = Joi.string().when('country', {
    is: COUNTRY_CODES.US,
    then: Joi.string().trim().max(30).min(6).regex(/^[\d\s()\-+]*$/i).optional().allow(null).allow(''),
    otherwise: Joi.string().trim().max(30).min(6).regex(/^[\d\s()\-+]*$/i).required()
  });
  const zip = Joi.string().trim().required().when('country', {
    switch: [{
      is: COUNTRY_CODES.US,
      then: Joi.string().regex(ZIP_PATTERNS[COUNTRY_CODES.US])
    }, {
      is: COUNTRY_CODES.CA,
      then: Joi.string().regex(ZIP_PATTERNS[COUNTRY_CODES.CA])
    }],
    otherwise: Joi.string()
  });
  const emailValidationKeys = withEmail ? {
    email: Joi.string().email({
      tlds: false
    }).required()
  } : {};
  const giveawayShippingKeys = _objectSpread({
    firstName: stringRequired,
    lastName: stringRequired,
    address: stringRequired,
    unit: stringAny.allow(null, '').optional(),
    city: stringRequired,
    country: stringRequired.valid(...COUNTRY_KEYS_LIST),
    state: stateValidation,
    zip,
    phone
  }, emailValidationKeys);
  return Joi.object().keys(giveawayShippingKeys).unknown();
};
export const isValidShippingForm = (values, withEmail) => {
  const schema = getShippingFormSchema(withEmail);
  return !schema.validate(values).error;
};