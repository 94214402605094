import { GiveawayTypes } from 'swag-common/interfaces';
import { GiveawayTypeAlternativeLabels, GiveawayTypeLabels } from 'swag-common/constants/giveaway.constants';
export const checkIsGiveawayWithSwagType = giveaway => {
  return (giveaway === null || giveaway === void 0 ? void 0 : giveaway.giveawayType) === GiveawayTypes.swag;
};
export const checkIsGiveawayWithGoodieType = giveaway => {
  return (giveaway === null || giveaway === void 0 ? void 0 : giveaway.giveawayType) === GiveawayTypes.goodie;
};
export const getGiveawayTypeLabel = (giveawayType, useSwagLabel) => {
  const labelsDictionary = useSwagLabel ? GiveawayTypeLabels : GiveawayTypeAlternativeLabels;
  const label = labelsDictionary[giveawayType];
  const defaultLabel = GiveawayTypeAlternativeLabels[GiveawayTypes.swag];
  return label || defaultLabel;
};