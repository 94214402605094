import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { CONFIRM_PUBLIC_API_CARD_REMOVING_POPUP } from 'swag-client-common/constants';
import { addCreditCard } from '../../../../../../redux/api-settings/api-settings.action';
import { isStripeElementsValid } from '../../../../../../redux/stripe-form/stripe-form.selectors';
import { isCreditCardInfoValid } from '../../../../../../redux/api-settings/api-settings.selectors';
import { CreditCardControlPanel as CreditCardControlPanelComponent } from './card-control-panel';
const mapDispatchToProps = dispatch => ({
  addCard: (stripe, card) => dispatch(addCreditCard(stripe, card)),
  showPopup: () => dispatch(showPopup({
    popup: CONFIRM_PUBLIC_API_CARD_REMOVING_POPUP
  }))
});
const mapStateToProps = createStructuredSelector({
  isValid: isCreditCardInfoValid,
  isStripeElementsValid: isStripeElementsValid
});
export const CreditCardControlPanel = connect(mapStateToProps, mapDispatchToProps)(CreditCardControlPanelComponent);