import { PRODUCT_PROPERTIES } from 'swag-common/constants/product.constants';
import { FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME } from 'swag-common/constants/custom-store.constants';
const getTitlePartByGivenHeaderName = (title, name) => {
  const productName = title.split(` ${name.toLowerCase()}`)[0];
  if (productName === title) {
    return '';
  }
  return productName;
};
export const getProductNameFromSizeHeader = title => {
  return getTitlePartByGivenHeaderName(title, PRODUCT_PROPERTIES.apparelSize) || getTitlePartByGivenHeaderName(title, PRODUCT_PROPERTIES.size) || '';
};
export const getProductNameFromColorHeader = title => {
  return getTitlePartByGivenHeaderName(title, PRODUCT_PROPERTIES.color) || '';
};
export const transformVariantsListIntoMap = product => {
  const {
    stockVariants,
    inProductionVariants
  } = product;
  const stockVariantsMap = {};
  const productionVariantsMap = {};
  const quantities = stockVariants.length ? stockVariants : inProductionVariants;
  quantities.forEach((stock, index) => {
    var _stockVariants$index, _inProductionVariants;
    stockVariantsMap[((_stockVariants$index = stockVariants[index]) === null || _stockVariants$index === void 0 ? void 0 : _stockVariants$index.colorName) || ''] = stockVariants[index];
    productionVariantsMap[((_inProductionVariants = inProductionVariants[index]) === null || _inProductionVariants === void 0 ? void 0 : _inProductionVariants.colorName) || ''] = inProductionVariants[index];
  });
  return {
    stockVariantsMap,
    productionVariantsMap,
    quantities
  };
};
export const getProductColor = ({
  header,
  row,
  name,
  isApparel,
  quantities
}) => {
  if (isApparel && quantities.length) {
    return quantities[0].colorName;
  }
  const headerCell = header.find(cell => cell.title === `${name.toLowerCase()} color`);
  if (!(headerCell !== null && headerCell !== void 0 && headerCell.original)) {
    return FULL_DESIGN_PRODUCT_DEFAULT_COLOR_NAME;
  }
  return row[headerCell.original || ''];
};