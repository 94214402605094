import { connect } from 'react-redux';
import { VIDEO_POPUP } from 'swag-client-common/constants';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { hideIntercom } from '../../../../customer/utils/ui.utils';
import { WhatWeDoVideo as WhatWeDoVideoComponent } from './what-we-do-video';
const mapDispatchToProps = dispatch => ({
  openVideoPopup: () => {
    hidePopup();
    hideIntercom(true);
    dispatch(showPopup({
      popup: VIDEO_POPUP
    }));
  }
});
export const WhatWeDoVideo = connect(null, mapDispatchToProps)(WhatWeDoVideoComponent);