import { createSelector } from 'reselect';
import draftToHtml from 'draftjs-to-html';
import { swagBundlesAddOnsBuilderSelector } from '../swag-bundles.selectors';
export const swagBundleNotecardDataSelector = createSelector(swagBundlesAddOnsBuilderSelector, ({
  notecardData
}) => notecardData);
export const swagBundleNotecardColorSelector = createSelector(swagBundleNotecardDataSelector, notecardData => notecardData.selectedNotecardColor);
export const swagBundleEditorRawContentSelector = createSelector(swagBundleNotecardDataSelector, notecardData => notecardData.editorRawContent);
export const swagBundleNotecardDefaultImageSelector = createSelector(swagBundleNotecardDataSelector, notecardData => notecardData.notecardDefaultImage);
export const swagBundlesSelectedNotecardLogoSelector = createSelector(swagBundleNotecardDataSelector, notecardData => notecardData.selectedNotecardLogo);
export const swagBundlesIsNotecardSelectedToCreateSelector = createSelector(swagBundleNotecardDataSelector, notecardData => notecardData.isNotecardSelectedToCreate);
export const swagBundleIsCreateNotecardSubmitDisabledSelector = createSelector(swagBundlesSelectedNotecardLogoSelector, swagBundleNotecardColorSelector, swagBundleEditorRawContentSelector, (selectedNotecardLogo, selectedNotecardColor, notecardEditorContent) => !selectedNotecardLogo || !selectedNotecardColor || !notecardEditorContent.blocks.length || notecardEditorContent.blocks.every(block => !block.text));
export const SwagBundlesNotecardTextContentSelector = createSelector(swagBundleEditorRawContentSelector, draftToHtml);