import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { stripeCardCvcValidationErrorStringSelector } from '../../../../customer/redux/stripe-form/stripe-form.selectors';
import { setStripeFieldValidationError, setStripeFieldEmpty, setStripeFieldCompleted } from '../../../../customer/redux/stripe-form/stripe-form.actions';
import withStripeElementsMethodsHOC from '../stripe-elements-hoc';
import StripeCardExpiryElement from './stripe-element-card-cvc';
const mapStateToProps = createStructuredSelector({
  errorCardCvcString: stripeCardCvcValidationErrorStringSelector
});
const mapDispatchToProps = dispatch => ({
  setStripeFieldError: error => {
    dispatch(setStripeFieldValidationError(error));
  },
  setStripeFieldEmpty: isEmpty => {
    dispatch(setStripeFieldEmpty(isEmpty));
  },
  setStripeFieldCompleted: isCompleted => {
    dispatch(setStripeFieldCompleted(isCompleted));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(withStripeElementsMethodsHOC(StripeCardExpiryElement));