import { getTrackingLink } from 'swag-common/utils/order';
import { downloadFileInBrowser } from 'swag-client-common/utils/download-file-in-browser';
import { addBusinessDays } from 'swag-common/utils/add-business-days.util';
import { IN_HAND_DATE_INTERVAL } from 'swag-common/constants/main-swag.constants';
import dateFormat from 'dateformat';
export const LONG_CELL_WIDTH = 175;
export const SHORT_CELL_WIDTH = 89;
export const NUMBER_CELL_WIDTH = 32;
export const CELL_HEIGHT = 52;
export const BORDER_WIDTH = 1;
export function getShipoutTrackingLink(receiver) {
  if (!receiver || !receiver.shipment || !receiver.shipment.trackingInfo) {
    return '';
  }
  const {
    postalService,
    trackingNumber
  } = receiver.shipment.trackingInfo;
  if (!postalService || !trackingNumber) {
    return '';
  }
  return postalService && trackingNumber && getTrackingLink(postalService, trackingNumber);
}
export function getShipoutRowLength(data) {
  return Object.keys(data || {}).length * LONG_CELL_WIDTH + SHORT_CELL_WIDTH + NUMBER_CELL_WIDTH;
}
export function getShipoutTableHeight(rowsNumber) {
  return CELL_HEIGHT * rowsNumber + rowsNumber + 1;
}
export function getShipoutArrowHeight(rowsNumber) {
  return CELL_HEIGHT * rowsNumber - 1;
}
export function downloadSMAExample(csvTemplateData) {
  if (!csvTemplateData) {
    return;
  }
  const {
    headers,
    examples,
    examplesOverseas = []
  } = csvTemplateData;
  const text = `${headers.join(',')}\n${examples.join(',')}\n${examplesOverseas.join(',')}`;
  const blob = new Blob(['', text], {
    type: 'text/csv'
  });
  downloadFileInBrowser({
    blob,
    name: 'swag_products_shipout.csv'
  });
}
export const getDistributionCustomerInHandDate = ({
  minDays,
  maxDays,
  isAdvanceDistribution,
  advanceDistributionStockInHand
}) => {
  const startDate = isAdvanceDistribution ? addBusinessDays({
    date: advanceDistributionStockInHand || new Date(),
    daysToAdd: IN_HAND_DATE_INTERVAL,
    cutOffTime: false
  }) : new Date();
  const minEstDate = dateFormat(addBusinessDays({
    date: startDate,
    daysToAdd: +maxDays,
    cutOffTime: false
  }), 'mmm dd');
  const maxEstDate = dateFormat(addBusinessDays({
    date: startDate,
    daysToAdd: +maxDays + IN_HAND_DATE_INTERVAL,
    cutOffTime: false
  }), 'mmm dd');
  return minDays === maxDays ? minEstDate : `${minEstDate} - ${maxEstDate}`;
};