import { ALL_SWAG_CATEGORY } from 'swag-client-common/constants';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
const EXCLUDE_LIST = ['boxes'];
export const getBreadcrumb = (text, href) => ({
  text,
  href
});
export const getCategoryLink = breadcrumb => breadcrumb.map(b => b.href).join('');
export const getProductLink = seoSlug => `${APP_ROUTES.PRODUCT}/${seoSlug}`;
const formatBreadcrumb = ([firstBreadcrumbItem, ...breadcrumb]) => {
  const result = [firstBreadcrumbItem];
  breadcrumb.forEach((part, index) => {
    result.push({
      text: part.text,
      href: getCategoryLink([firstBreadcrumbItem, breadcrumb[index]])
    });
  });
  return result;
};
export const createCategoryBreadcrumb = (category, categories) => {
  const breadcrumb = [];
  if (!category || category.slug === ALL_SWAG_CATEGORY) {
    breadcrumb.push(getBreadcrumb('All Swag', ''));
    return breadcrumb;
  }
  if (category.slug !== ALL_SWAG_CATEGORY) {
    breadcrumb.push(getBreadcrumb('All Swag', ''));
  }
  if (category.parent_id) {
    const parent = categories.find(c => c._id === category.parent_id);
    breadcrumb.push(getBreadcrumb(parent.name, `/${parent.slug}`));
  }
  if (!EXCLUDE_LIST.includes(category.name.toLowerCase())) {
    breadcrumb.push(getBreadcrumb(category.name, `/${category.slug}`));
  }
  breadcrumb[0] = {
    text: breadcrumb[0].text,
    href: `${APP_ROUTES.COLLECTIONS}${breadcrumb[0].href}`
  };
  return formatBreadcrumb(breadcrumb);
};