import { createSelector } from 'reselect';
import { PAYMENT_STATES } from 'swag-client-common/utils/constants';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
export const paymentStateSelector = state => state.payment.paymentState;
export const paymentErrorSelector = state => state.payment.paymentError;
export const promocodeSelector = state => state.payment.promocode;
export const promocodeCodeSelector = createSelector(promocodeSelector, promocode => (promocode === null || promocode === void 0 ? void 0 : promocode.code.toUpperCase()) || '');
export const promocodeValueSelector = createSelector(promocodeSelector, promocode => promocode ? promocode.code : null);
export const promocodeErrorSelector = state => state.payment.promocodeError;
export const isLoadingSelector = createSelector(paymentStateSelector, state => state === PAYMENT_STATES.LOADING);
export const is100PercentagesPromocodeSelector = createSelector(promocodeSelector, promocode => {
  if (!promocode) {
    return false;
  }
  return promocode.measurement === PromocodeMeasurementEnum.PERCENTAGES && promocode.discount === 100;
});