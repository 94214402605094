import { validateGiveawayProductChoice } from 'swag-common/business-logic/giveaway/validation/steps/giveaway-product-choice.validation';
import { validateGiveawayColors } from 'swag-common/business-logic/giveaway/validation/steps/giveaway-colors.validation';
import { GiveawayStep } from '../../../constants/giveaway.constants';
import { validateGiveawayNaming } from './steps/giveaway-naming.validation';
import { validateGiveawayCustomize } from './steps/giveaway-customize.validation';
const giveawayValidators = {
  [GiveawayStep.naming]: validateGiveawayNaming,
  [GiveawayStep.productsChoice]: validateGiveawayProductChoice,
  [GiveawayStep.reserve]: validateGiveawayColors,
  [GiveawayStep.customize]: validateGiveawayCustomize
};
const emptyValidator = data => ({
  // @todo. auto generated. fix this error
  // @ts-ignore
  error: null,
  // @todo. auto generated. fix this error
  // @ts-ignore
  errors: null,
  // @todo. auto generated. fix this error
  // @ts-ignore
  warning: null,
  value: data
});
const getIsValidWrapper = validator => data => {
  const result = validator(data);
  return !result.error;
};
export const getGiveawayValidation = step => {
  return giveawayValidators[step] || emptyValidator;
};
export const getIsValidGiveawayValidation = step => {
  return getIsValidWrapper(getGiveawayValidation(step));
};