export default function getProductQuantityForBox(product, generalQuantity) {
  const {
    quantIncrement,
    quantity,
    minQuant
  } = product;
  const productsRequested = generalQuantity * quantity;
  if (productsRequested < minQuant) {
    return minQuant;
  }
  const remainder = productsRequested % quantIncrement;
  if (!remainder) {
    return productsRequested;
  }
  return productsRequested + quantIncrement - remainder;
}