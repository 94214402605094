function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { checkoutStarted } from 'swag-client-common/redux/cart/cart.actions';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { getSuperSpeedFromLS, removeSuperSpeedProductIdFromLS } from 'swag-client-common/utils/super-speed-local-storage.utils';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { moveItemToDraft, setItemToEdit, moveItemToCart } from 'swag-client-common/redux/cart/cart.actions';
import { CONFIRM_TRANSFORM_SUPER_SPEED_ITEMS_POPUP } from 'swag-client-common/constants';
import { PRODUCT_BUILDER_MODES } from 'swag-client-common/utils/constants';
import { redirectWithSavingScroll } from 'swag-client-common/utils/tricks';
import { SUPER_SPEED_ERR_MSG } from 'swag-common/constants/error.const';
import { SettingsApi } from '../../api/settings.api';
import { orderModeSelector } from '../../../customer/redux/product/product.selectors';
import { PREFIX } from './settings.actions';
export const GET_SUPER_SPEED_SUCCESS = `${PREFIX}GET_SUPER_SPEED_SUCCESS`;
export const GET_SUPER_SPEED_ERROR = `${PREFIX}GET_SUPER_SPEED_ERROR`;
const superSpeedSettingsArgs = {
  fn: SettingsApi.getSuperSpeed,
  success: payload => ({
    type: GET_SUPER_SPEED_SUCCESS,
    payload
  }),
  error: errorAction(GET_SUPER_SPEED_ERROR),
  postSuccess: (dispatch, getState, transformedData, res) => {
    var _res$config;
    const isAvailableSuperSpeed = res === null || res === void 0 ? void 0 : (_res$config = res.config) === null || _res$config === void 0 ? void 0 : _res$config.enabled;
    const isSuperSpeedActive = getSuperSpeedFromLS();
    if (!isAvailableSuperSpeed && isSuperSpeedActive) {
      removeSuperSpeedProductIdFromLS();
    }
  }
};
export const getSuperSpeedSettings = createAsyncAction(superSpeedSettingsArgs);
export const getSuperSpeedSettingsForProductBuilder = createAsyncAction(_objectSpread({}, superSpeedSettingsArgs, {
  postSuccess: (dispatch, getState, transformedData, res) => {
    var _res$config2;
    const state = getState();
    const mode = orderModeSelector(state);
    const isReorder = mode === PRODUCT_BUILDER_MODES.REORDER_MODE;
    const isAvailableSuperSpeed = res === null || res === void 0 ? void 0 : (_res$config2 = res.config) === null || _res$config2 === void 0 ? void 0 : _res$config2.enabled;
    const isSuperSpeedActive = getSuperSpeedFromLS();
    if (!isAvailableSuperSpeed && isSuperSpeedActive) {
      removeSuperSpeedProductIdFromLS();
      if (isReorder) {
        dispatch(showNotification({
          text: SUPER_SPEED_ERR_MSG
        }));
      }
    }
  }
}));
export const validateSuperSpeedItemInCart = ({
  id,
  toDraft
}) => createAsyncAction(_objectSpread({}, superSpeedSettingsArgs, {
  postSuccess: (dispatch, getState, transformedData, res) => {
    var _res$config3;
    const isAvailableSuperSpeed = res === null || res === void 0 ? void 0 : (_res$config3 = res.config) === null || _res$config3 === void 0 ? void 0 : _res$config3.enabled;
    if (!isAvailableSuperSpeed) {
      return dispatch(showPopup({
        popup: CONFIRM_TRANSFORM_SUPER_SPEED_ITEMS_POPUP
      }));
    }
    const action = toDraft ? moveItemToDraft : moveItemToCart;
    dispatch(setItemToEdit(id));
    dispatch(action({
      itemId: id
    }));
  }
}));
export const validateSuperSpeedForCheckout = createAsyncAction(_objectSpread({}, superSpeedSettingsArgs, {
  postSuccess: (dispatch, getState, transformedData, res) => {
    var _res$config4;
    const isAvailableSuperSpeed = res === null || res === void 0 ? void 0 : (_res$config4 = res.config) === null || _res$config4 === void 0 ? void 0 : _res$config4.enabled;
    if (!isAvailableSuperSpeed) {
      return dispatch(showPopup({
        popup: CONFIRM_TRANSFORM_SUPER_SPEED_ITEMS_POPUP
      }));
    }
    redirectWithSavingScroll(APP_ROUTES.DELIVERY)();
    dispatch(checkoutStarted());
  }
}));